import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";

import AddMembersModal from "./AddMembersModal";
import styles from "./Chat.module.css";
import emojiIcon from "images/smile-wink-icon.svg";
import attachIcon from "images/attach-v2.svg";
import sendIcon from "images/send.svg";
import { useSelector } from "react-redux";
import Picker from "emoji-picker-react";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";

import forwardIcon from "images/forward-icon.svg";
import binIcon from "images/bin-icon-red.svg";
import closeIconWhite from "images/cross-white-14-x-14.svg";
import GroupMember from "./GroupMember";
import ParticipantItem from "./ParticipantItem";
import LocationModal from "../Home/LocationModal";

import useApi from "../../hooks/useApi";
// import profileApi from "../../api/profile";
import { nanoid } from "nanoid";
import MessageReply from "./MessageReply";
import Popover from "../Utils/Popover/Popover";
import FileAttachOptions from "./FileAttachOptions";
import useCompanyId from "../../hooks/useCompanyId";
import chatApi from "../../api/chat";
import CustomMentions from "../Utils/Mentions/CustomMentions";
import FileViewer from "components/Utils/FileViewer/FileViewer";
import useClickOutside from "hooks/useClickOutside";
import {
	getFileIcon,
	getLocationType,
	getVideoFrame,
} from "components/Utils/General";
import useSearchNewGroupMember from "./hooks/useSearchNewGroupMember";
import useUrlPreview from "hooks/useUrlPreview";

let typingTimer;
// let doneTypingInterval = 1000;

const RightSideFooter = forwardRef(
	(
		{
			onSendMessage,
			conversation,
			replyItem,
			onHeight,
			onCloseReply,
			onCloseSelect,
			selectActive,
			selectedCounter,
			onForwardMessage,
			onDeleteMessage,
		},
		ref
	) => {
		/**
		 * Refs
		 */

		const inputRef = useRef(null);
		// console.log("🚀 ~ inputRef >>", inputRef);
		// const inputFile = useRef(null);
		const wrapperRef = useRef(null);
		const replyContainerRef = useRef(null);
		const popoverRef = useRef(null);

		const chatFooterHeight = useSelector(
			(state) => state.ui.chatFooterHeight
		);

		const searchParticipantsApi = useApi(chatApi.searchParticipants, true);

		const {
			deleted,
			connection: { blocked, blocking, allowMessage, allowedByYou } = {},
			name,
		} = conversation?.minifiedProfile || {};

		const disabledConvo =
			blocked || blocking || deleted || !allowMessage || !allowedByYou;

		useImperativeHandle(ref, () => ({
			clearInput() {
				setChatMessage("");
				setChatMessageState({ media: [] });
			},
			focusInput() {
				inputRef.current?.focusInput();
			},
		}));

		useEffect(() => {
			if (replyItem) {
				const newHeight = replyContainerRef.current.clientHeight;
				onHeight(newHeight);
				// inputRef.current?.focusInput();
			}
		}, [replyItem]);

		useClickOutside(wrapperRef, () => setPickerOpened(false));

		useEffect(() => {
			inputRef.current?.focusInput();
		}, [conversation]);

		/**
		 * Selectors
		 */

		// const storeProfile = useSelector((state) => state.user.profileInfo);

		const companyId = useCompanyId();

		const { uuid: profileId } = useSelector((state) => {
			if (companyId) {
				return state.company.companyInfo;
			} else {
				return state.user.profileInfo;
			}
		});

		/**
		 * States
		 */

		const [chatMessage, setChatMessage] = useState("");
		const [pickerOpened, setPickerOpened] = useState(false);

		// const [isRecording, setIsRecording] = useState(false);
		// const [uploadOpened, setUploadOpened] = useState(false);

		const [previewIndex, setPreviewIndex] = useState(0);
		const [chatMessageState, setChatMessageState] = useState({ media: [] });
		const { objectUrls, setObjectUrls, cleanupObjectUrls } =
			useUrlPreview();

		const fileArray = useMemo(() => {
			return chatMessageState.media.map((item, index) => {
				item.originalName = item.file.name;

				switch (true) {
					case item.file.type.startsWith("image/"):
						item.mediaType = "IMAGE";
						break;
					case item.file.type.startsWith("video/"):
						item.mediaType = "VIDEO";
						break;
					default:
						item.mediaType = "DOC";
						break;
				}

				return {
					file: item.file,
					// url: item.url,
					url: objectUrls[index],
					mediaType: item.mediaType,
					originalName: item.originalName,
				};
			});
		}, [chatMessageState, objectUrls]);

		/**
		 * Handelers
		 */

		// const doneTyping = () => {
		// 	typingRequest(false);
		// };

		// const handleOnKeyUp = () => {
		// 	clearTimeout(typingTimer);
		// 	typingTimer = setTimeout(doneTyping, doneTypingInterval);
		// };

		const handleOnKeyDown = (event) => {
			typingRequest(true);

			clearTimeout(typingTimer);

			if (
				(event.key === "Enter" || event.keyCode === 13) &&
				!event.shiftKey &&
				!blocking &&
				!blocked
			) {
				event.preventDefault();
				doSendMessage();
			}
		};

		//TODO >> not implemented yet by waleed
		const typingRequest = (state) => {
			// sendMessage("/app/ack.public", {
			//     from: storeProfile.uuid,
			//     to: conversation.minifiedProfile.uuid,
			//     ack: "typing",
			//     conversationId: conversation.uuid,
			//     status: `${state}`,
			// });
			// sendMessage(`/topic/messages.${conversation.minifiedProfile.uuid}`, {
			//     type: "TYPING",
			//     response: {
			//         conversationId: conversation.uuid,
			//         typing: state
			//     },
			// });
		};

		const doSendMessage = () => {
			const isMedia = chatMessageState.media.length > 0;

			if (!isMedia && !chatMessage) return;

			if (!isMedia) {
				if (chatMessage.trim().length > 0)
					sendSingleMessage(chatMessage, null, false);
			} else {
				sendMediaMessage(chatMessageState.media);
				// for (let i = 0; i < chatMessageState.media.length; i++)
				//     sendSingleMessage(chatMessageState.media[i].text, chatMessageState.media[i].file, true);
			}

			setChatMessage("");
			setChatMessageState({
				media: [],
			});
		};

		const sendMediaMessage = (medias) => {
			let toId = null;
			let toGroup = null;

			if (conversation.conversationType === "Default")
				toId = conversation.minifiedProfile.uuid;
			else if (conversation.conversationType === "Group")
				toGroup = conversation.groupDTO.uuid;
			else return alert("invalid type.....");

			// const id = nanoid(21);
			const newConvId = nanoid(21);

			const messageRequest = {
				// id,                                             //* added in HOC index.jsx
				// text: message,                                  //* added in HOC index.jsx
				// file: customMediaFile ? customMediaFile : null, //* added in HOC index.jsx
				newConversationId: conversation.isTemp ? newConvId : null,
				parentMessageId: replyItem ? replyItem.messageId : null,
				toGroup,
				toId,
			};

			const pendingMessage = {
				// messageId: id,    //* added in HOC index.jsx
				// message: message, //* added in HOC index.jsx
				conversationId: conversation.isTemp
					? newConvId
					: conversation.uuid,
				sendBy: { uuid: profileId },
				...(replyItem && { parent: replyItem }),
				messageStatus: "PENDING",
				messageTime: new Date().getTime(),
				media: "LOADING",
			};

			const pendingConv = {
				conversationTime: new Date().getTime(),
				conversationType: "Default",
				lastUpdateTime: new Date().getTime(),
				message: pendingMessage,
				minifiedProfile: conversation.minifiedProfile,
				muted: false,
				totalUnreadCount: 0,
				unreadCount: 0,
				uuid: newConvId,
			};

			onSendMessage(
				messageRequest,
				pendingMessage,
				conversation.isTemp && pendingConv,
				medias
			);
		};

		const sendSingleMessage = (
			message,
			customMediaFile,
			isMedia,
			attachedProfileId,
			location = undefined
		) => {
			let toId = null;
			let toGroup = null;

			let latitude;
			let longitude;
			let locationTitle;
			let locationType;

			if (location) {
				const {
					latitude: lat,
					longitude: long,
					locationTitle: title,
					locationType: type,
				} = location;

				latitude = lat;
				longitude = long;
				locationTitle = title;
				locationType = type;
			}

			if (conversation.conversationType === "Default")
				toId = conversation.minifiedProfile.uuid;
			else if (conversation.conversationType === "Group")
				toGroup = conversation.groupDTO.uuid;
			else return alert("invalid type.....");

			const id = nanoid(21);
			const newConvId = nanoid(21);

			const messageRequest = {
				id,
				text: message,
				newConversationId: conversation.isTemp ? newConvId : null,
				parentMessageId: replyItem ? replyItem.messageId : null,
				toGroup,
				toId,
				file: customMediaFile ? customMediaFile : null,
				attachedProfileId: attachedProfileId ? attachedProfileId : null,
				...(location && {
					latitude,
					longitude,
					locationTitle,
					locationType,
				}),
			};

			const pendingMessage = {
				messageId: id,
				conversationId: conversation.isTemp
					? newConvId
					: conversation.uuid,
				sendBy: { uuid: profileId },
				...(replyItem && { parent: replyItem }),
				message: message,
				messageStatus: "PENDING",
				messageTime: new Date().getTime(),
				...(isMedia && { media: "LOADING" }),
				...(attachedProfileId && { attachedProfile: "LOADING" }),
			};

			const pendingConv = {
				conversationTime: new Date().getTime(),
				conversationType: "Default",
				lastUpdateTime: new Date().getTime(),
				message: pendingMessage,
				minifiedProfile: conversation.minifiedProfile,
				muted: false,
				totalUnreadCount: 0,
				unreadCount: 0,
				uuid: newConvId,
			};

			// raise an event to index.js
			onSendMessage(
				messageRequest,
				pendingMessage,
				conversation.isTemp && pendingConv
			);
		};

		const handleSendMessageButtonClicked = () => {
			if (!blocked && !blocking) {
				doSendMessage();
			}
		};

		const onEmojiSelected = (event, emojiObject) => {
			setChatMessage(`${chatMessage}${emojiObject.emoji}`);
		};

		const handleOnPickerClicked = () => {
			setPickerOpened(!pickerOpened);
		};

		const handleUploadFileChange = async ({ target }) => {
			const files = target.files;

			if (files.length > 0) {
				const newMediaItems = Array.from(files).map(async (file) => {
					let previewUrl;

					if (file.type.startsWith("image")) {
						previewUrl = URL.createObjectURL(file);
					} else if (file.type.startsWith("video")) {
						previewUrl = await getVideoFrame(file);
					} else {
						previewUrl = getFileIcon(file, file.name).icon;
					}

					return {
						file,
						url: previewUrl, //TODO >> handle case it's a video
						text: "",
					};
				});

				const resolvedUrls = await Promise.all(newMediaItems);

				setChatMessageState((prevState) => ({
					...prevState,
					media: [...prevState.media, ...resolvedUrls],
				}));

				//* Store object URLs for later revocation
				setObjectUrls((prevUrls) => [
					...prevUrls,
					...resolvedUrls.map((item) => item.url),
				]);
			}
		};

		useEffect(() => {
			return () => {
				cleanupObjectUrls();
			};
		}, [conversation]);

		const handleRemoveMediaItem = (index) => {
			setChatMessageState((prevState) => {
				const newMedia = prevState.media.filter((item, i) => {
					if (i === index) {
						// Revoke the object URL before removing the item
						URL.revokeObjectURL(item.url);
						return false;
					}
					return true;
				});

				return {
					...prevState,
					media: newMedia,
				};
			});

			// Optionally, if you're maintaining a separate list of object URLs:
			setObjectUrls((prevUrls) =>
				prevUrls.filter((url, i) => i !== index)
			);
		};

		const handleTextChangeWhileUploadingMedia = (index) => (text) => {
			const prevState = { ...chatMessageState };
			prevState.media[index].text = text;

			setChatMessageState(prevState);
		};

		const handleEmojiOnMediaPreview = (index) => (_, emojiObject) => {
			const prevState = { ...chatMessageState };
			prevState.media[index].text =
				prevState.media[index].text + emojiObject.emoji;

			setChatMessageState(prevState);
		};

		const handleSendMedia = () => {
			doSendMessage();
			cleanupObjectUrls();
		};

		// * =================== SHARE CONTACT HANDLER ======================
		const [shareContactModal, setShareContactModal] = useState(false);
		const [searchInput, setSearchInput] = useState("");
		// const [searchProfile, setSearchProfile] = useState([]);

		const [groupParticipants, setGroupParticipants] = useState([]);

		const {
			ref: viewRef,
			infiniteQuery: { data, isFetching },
		} = useSearchNewGroupMember(searchInput, shareContactModal);

		// const searchProfileApi = useApi(profileApi.search);

		const handleCloseModal = () => {
			setShareContactModal(false);
			// setSearchProfile([]);
			setSearchInput("");
			setGroupParticipants([]);
		};

		// const handleSearchInputChange = (e) => {
		// 	const query = e.target.value;
		// 	setSearchInput(query);
		// 	if (query) {
		// 		searchProfileApi.request(undefined, undefined, query);
		// 	}
		// };

		const handleSearchInputChange = ({ target }) => {
			const query = target.value;
			setSearchInput(query);
		};

		// useEffect(() => {
		// 	if (
		// 		!searchProfileApi.loading &&
		// 		searchProfileApi.data &&
		// 		searchProfileApi.responseCode === 200
		// 	) {
		// 		setSearchProfile(searchProfileApi.data);
		// 	}
		// }, [searchProfileApi.data]);

		const handleNewGroupParticipant = (participant) => {
			setGroupParticipants((prevState) => [participant, ...prevState]);
			setSearchInput("");
		};

		const handleRemoveParticipant = (participant) => {
			const participants = [...groupParticipants];
			const updatedParticipants = participants.filter(
				(item) => item.uuid !== participant.uuid
			);
			setGroupParticipants(updatedParticipants);
		};

		// const participantsList = groupParticipants.map((participant) => (
		// 	<ParticipantItem
		// 		key={participant.uuid}
		// 		name={participant.name}
		// 		onRemove={() => handleRemoveParticipant(participant)}
		// 	/>
		// ));

		const participantsList = useMemo(
			() =>
				groupParticipants.map((participant) => (
					<ParticipantItem
						key={participant.uuid}
						name={participant.name}
						onRemove={() => handleRemoveParticipant(participant)}
					/>
				)),
			[groupParticipants]
		);

		const groupProfileList = useMemo(
			() =>
				data?.pages?.map((page) =>
					page
						.filter(
							(user) =>
								!groupParticipants
									.map((item) => item.uuid)
									.includes(user.uuid)
						)
						.map((profile, i) => (
							<div
								key={profile.uuid}
								ref={page.length === i + 1 ? viewRef : null}
								className={styles.contact_item}
								onClick={() =>
									handleNewGroupParticipant(profile)
								}
							>
								<div className={styles.contact_item_wrapper}>
									<GroupMember
										key={profile.uuid}
										data={profile}
										isChatLeftSide={true}
										disableDecoration={true}
									/>
								</div>
							</div>
						))
				),
			[data, groupParticipants]
		);

		// const profileListCopy = [...searchProfile];

		// const filteredArray = profileListCopy.filter((profile) => {
		// 	return !groupParticipants
		// 		.map((item) => item.uuid)
		// 		.includes(profile.uuid);
		// });

		// const groupProfileList = filteredArray.map((profile) => {
		// 	return (
		// 		<div
		// 			key={profile.uuid}
		// 			className={styles.contact_item}
		// 			onClick={() => handleNewGroupParticipant(profile)}
		// 		>
		// 			<div className={styles.contact_item_wrapper}>
		// 				<GroupMember
		// 					key={profile.uuid}
		// 					data={profile}
		// 				/>
		// 			</div>
		// 		</div>
		// 	);
		// });

		const handleShareContact = () => {
			groupParticipants.forEach((participant) => {
				sendSingleMessage("", null, false, participant.uuid);
			});
			handleCloseModal();
			setGroupParticipants([]);
		};

		// * =================== LOCATION HANDLER ======================

		// const getPlacesApi = useApi(placesApi.getPlaces);
		const [showLocationModal, setShowLocationModal] = useState(false);
		// const [locationSearchText, setLocationSearchText] = useState("");
		// const [locationList, setLocationList] = useState([]);

		// const handleInputChange = (query) => {
		//     setLocationSearchText(query);
		//     if (query) {
		//         getPlacesApi.request(query);
		//     }
		// };

		// const handleCloseLocationModal = () => {
		//     setShowLocationModal(false);
		//     // setLocationSearchText("");
		//     // setLocationList([]);
		// };

		// useEffect(() => {
		//     if (!getPlacesApi.loading && getPlacesApi.responseCode === 200 && getPlacesApi.data) {
		//         setLocationList(getPlacesApi.data);
		//     }
		// }, [getPlacesApi.data]);

		const handleLocationClicked = (place) => {
			// TODO: SEND THE MESSAGE WITH LOCATION COORDINATES
			// console.log("you clicked on >>", place);

			const location = {
				latitude: place.geometry.location.lat,
				longitude: place.geometry.location.lng,
				locationTitle: place.name,
				placeId: place.place_id,
				locationType: getLocationType(place.types),
			};

			// handleCloseLocationModal();
			setShowLocationModal(false);
			sendSingleMessage("", null, false, null, location);
		};

		// const formattedLocationList =
		//     locationList.length > 0 &&
		//     locationList.map((place) => {
		//         return (
		//             <div className={styles.location_item} key={place.place_id} onClick={handleLocationClicked(place)}>
		//                 <img src={place.icon} alt={place.name} />
		//                 <div>
		//                     <label className={styles.name}>{place.name}</label>
		//                     <label className={styles.location}>{place.formatted_address}</label>
		//                 </div>
		//             </div>
		//         );
		//     });

		let infoText;

		if (
			conversation &&
			conversation.conversationType === "Default" &&
			disabledConvo
		) {
			switch (true) {
				case deleted:
					infoText =
						"This profile has been deleted. You can no longer send messages to this profile.";
					break;
				case blocked:
					infoText =
						"This profile has blocked you. You can no longer send messages to this profile.";
					break;
				case blocking:
					infoText =
						"You have blocked this profile. You can no longer send messages to this profile.";
					break;
				case !allowMessage:
					infoText = `${name} is not accepting messages from you. Please connect with them to send messages`;
					break;
				case !allowedByYou:
					infoText = `You are not accepting messages from ${name}. And so you cannot send them messages too. Update your settings if you want to start a conversation.`;
					break;
				default:
					break;
			}

			return (
				<div className={`${styles.chat_footer} ${styles.disabled}`}>
					{infoText}
				</div>
			);
		}

		return (
			<>
				{conversation && !selectActive && (
					<div className={styles.chat_footer}>
						{replyItem && (
							<div
								className={styles.reply_container}
								ref={replyContainerRef}
								style={{
									bottom: `${chatFooterHeight + 4}px`,
								}}
							>
								<MessageReply
									item={replyItem}
									onCloseReply={onCloseReply}
								/>
							</div>
						)}

						<div ref={wrapperRef}>
							<button
								className={styles.footer_button}
								onClick={handleOnPickerClicked}
							>
								<img src={emojiIcon} alt={"emoji"} />
							</button>

							{pickerOpened && (
								<div className={styles.emoji_container}>
									<Picker
										preload={false}
										onEmojiClick={onEmojiSelected}
										disableSkinTonePicker={true}
										native={true}
										pickerStyle={{
											width: "100%",
											borderRadius: "20px 20px 0 0",
										}}
									/>
								</div>
							)}
						</div>

						<Popover
							ref={popoverRef}
							allow={["top"]}
							render={
								<FileAttachOptions
									onUploadFileChange={(e) => {
										handleUploadFileChange(e);
										popoverRef.current.closePopover();
									}}
									onLocationModal={() => {
										setShowLocationModal(true);
										popoverRef.current.closePopover();
									}}
									onContactModal={() => {
										setShareContactModal(true);
										popoverRef.current.closePopover();
									}}
								/>
							}
						>
							<button
								className={`${styles.footer_button} ${styles.attach_button}`}
							>
								<img src={attachIcon} alt={"attach"} />
							</button>
						</Popover>

						<div className={styles.text_container}>
							<CustomMentions
								ref={inputRef}
								value={chatMessage}
								onChange={(text) => setChatMessage(text)}
								isChat={true}
								groupId={conversation?.groupDTO?.uuid}
								onKeyDown={handleOnKeyDown}
								placeholder={"Write a message"}
								searchApi={searchParticipantsApi}
								showEmojiButton={false}
							/>
							{/* <TextInput
                                ref={inputRef}
                                borderRadius={"15px"}
                                value={chatMessage}
                                onChange={(e) => setChatMessage(e.target.value)}
                                bgColor={"#ffffff"}
                                onKeyDown={handleOnKeyDown}
                                onKeyUp={handleOnKeyUp}
                                disabled={blocked || blocking}
                            /> */}
						</div>

						<button
							className={styles.send_button}
							onClick={handleSendMessageButtonClicked}
						>
							<img src={sendIcon} alt={"send"} />
						</button>

						{/*{attachedPanelOpened &&*/}
						{/*<div className={styles.attachment_wrapper}>*/}

						{/*</div>*/}
						{/*}*/}
					</div>
				)}

				{conversation && selectActive && (
					<div
						className={`${styles.chat_footer} ${styles.select_active}`}
					>
						<img
							src={forwardIcon}
							className={styles.select_icons}
							alt="Forward"
							onClick={onForwardMessage}
						/>
						<div className={styles.selected_counter}>
							{selectedCounter} Selected
							<img
								src={closeIconWhite}
								className={styles.closeIcon}
								alt="Close"
								onClick={onCloseSelect}
							/>
							<img
								src={binIcon}
								className={styles.select_icons}
								alt="Delete"
								onClick={onDeleteMessage}
							/>
						</div>
					</div>
				)}

				<GaawkModal
					show={chatMessageState.media.length > 0}
					handleClose={() => {
						setChatMessageState((prevState) => ({
							...prevState,
							media: [],
						}));
						cleanupObjectUrls();
					}}
					defaultModal={false}
					showHeader={true}
					title={"Upload Media"}
					closeAlign={"left"}
					rightAction={
						<button
							className={`${styles.send_button} ${styles.media_modal}`}
							onClick={handleSendMedia}
						>
							<img src={sendIcon} alt={"send"} />
						</button>
					}
					children={
						<div className={styles.media_modal_wrapper}>
							<FileViewer
								files={fileArray}
								isChatPreview={true}
								onDelete={handleRemoveMediaItem}
								deletableItems={true}
								onActiveSlide={setPreviewIndex}
							/>

							<div className={styles.preview_text_container}>
								<CustomMentions
									// ref={inputRef}
									onKeyDown={handleOnKeyDown}
									value={
										chatMessageState.media[previewIndex]
											?.text
									}
									onChange={handleTextChangeWhileUploadingMedia(
										previewIndex
									)}
									isChat={true}
									groupId={conversation?.groupDTO?.uuid}
									placeholder={"Write a message"}
									onEmojiSelected={handleEmojiOnMediaPreview(
										previewIndex
									)}
								/>
							</div>
						</div>
					}
				/>

				<LocationModal
					show={showLocationModal}
					onClose={() => setShowLocationModal(false)}
					onLocationClicked={handleLocationClicked}
					// inputValue={locationSearchText}
					// onChange={handleInputChange}
					// locationList={getPlacesApi.loading ? <List /> : formattedLocationList}
				/>

				{/* //TODO >> replace component with <MultiItemsModal /> */}

				<AddMembersModal
					show={shareContactModal}
					title={"Share contacts"}
					onClose={handleCloseModal}
					onCancel={handleCloseModal}
					closeAlign={"right"}
					searchInput={searchInput}
					onInputChange={handleSearchInputChange}
					isLoading={isFetching}
					onClear={() => {
						// setSearchProfile([]);
						setSearchInput("");
					}}
					participantsList={participantsList}
					contactList={groupProfileList?.flat() || []}
					onConfirm={handleShareContact}
					buttonText={"SHARE"}
				/>
			</>
		);
	}
);

export default RightSideFooter;
