import { useNavigate } from "react-router-dom";
import BioEdit from "../../components/Profile/ProfileEdit/BioEdit";
import Certificate from "../../components/Profile/ProfileEdit/Certificate/Certificate";
import CertificateEntry from "../../components/Profile/ProfileEdit/Certificate/CertificateEntry";
import Address from "../../components/Profile/ProfileEdit/Contacts/Address/Address";
import AddressEntry from "../../components/Profile/ProfileEdit/Contacts/Address/AddressEntry";
import Email from "../../components/Profile/ProfileEdit/Contacts/Email/Email";
import EmailEntry from "../../components/Profile/ProfileEdit/Contacts/Email/EmailEntry";
import Other from "../../components/Profile/ProfileEdit/Contacts/Other/Other";
import OtherEntry from "../../components/Profile/ProfileEdit/Contacts/Other/OtherEntry";
import Phone from "../../components/Profile/ProfileEdit/Contacts/Phone/Phone";
import PhoneEntry from "../../components/Profile/ProfileEdit/Contacts/Phone/PhoneEntry";
import Social from "../../components/Profile/ProfileEdit/Contacts/Social/Social";
import SocialEntry from "../../components/Profile/ProfileEdit/Contacts/Social/SocialEntry";
import EditInfo from "../../components/Profile/ProfileEdit/EditInfo/EditInfo";
import Education from "../../components/Profile/ProfileEdit/Education/Education";
import EducationEntry from "../../components/Profile/ProfileEdit/Education/EducationEntry";
import ExperienceEntry from "../../components/Profile/ProfileEdit/Experience/ExperienceEntry";
import Experience from "../../components/Profile/ProfileEdit/Experience/index";
import KeyPeople from "../../components/Profile/ProfileEdit/KeyPeople/KeyPeople";
import KeyPeopleEntry from "../../components/Profile/ProfileEdit/KeyPeople/KeyPeopleEntry";
import Language from "../../components/Profile/ProfileEdit/Language/index";
import LanguageEntry from "../../components/Profile/ProfileEdit/Language/LanguageEntry";
import CoverEdit from "../../components/Profile/ProfileEdit/ProfileCoverEdit";
import ProfilePicEdit from "../../components/Profile/ProfileEdit/ProfilePicEdit/index";
import Role from "../../components/Profile/ProfileEdit/Roles/index";
import Skill from "../../components/Profile/ProfileEdit/Skills/index";
import Software from "../../components/Profile/ProfileEdit/Software/index";
import SoftwareEntry from "../../components/Profile/ProfileEdit/Software/SoftwareEntry";
import SpecialProfileEdit from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileEdit";
import SpecialBaseEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/SpecialBaseEntry/SpecialBaseEntry";
import ProfileLeftPanel from "../../components/Profile/ProfileLeftPanel/ProfileLeftPanel";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "../Header.module.css";

// * route restriction is handled by <RequireAuth companyOnly={true}> or <RequireAuth userOnly={true}>

const EditProfileView = ({
	activeItem: selectedItem,
	activeKey,
	headerTitle,
}) => {
	const navigate = useNavigate();

	let component;
	let activeItem;

	switch (selectedItem) {
		case "bio":
			component = <BioEdit />;
			activeItem = "bio";
			break;
		case "certificate":
			component = <Certificate />;
			activeItem = "certificate";
			break;
		case "certificate-add-edit":
			component = <CertificateEntry />;
			activeItem = "certificate";
			break;
		case "keyPeople":
			component = <KeyPeople />;
			activeItem = "keyPeople";
			break;
		case "keyPeople-add":
			component = <KeyPeopleEntry />;
			activeItem = "keyPeople";
			break;
		case "language":
			component = <Language />;
			activeItem = "language";
			break;
		case "language-add-edit":
			component = <LanguageEntry />;
			activeItem = "language";
			break;
		case "software":
			component = <Software />;
			activeItem = "software";
			break;
		case "software-add-edit":
			component = <SoftwareEntry />;
			activeItem = "software";
			break;
		case "role":
			component = <Role />;
			activeItem = "roles";
			break;
		case "experience":
			component = <Experience />;
			activeItem = "experience";
			break;
		case "experience-add-edit":
			component = <ExperienceEntry />;
			activeItem = "experience";
			break;
		case "education":
			component = <Education />;
			activeItem = "education";
			break;
		case "education-add-edit":
			component = <EducationEntry />;
			activeItem = "education";
			break;
		case "skill":
			component = <Skill />;
			activeItem = "skills";
			break;
		case "specialProfile-edit":
			component = <SpecialProfileEdit />;
			activeItem = "specialProfile";
			break;
		case "specialProfile-add":
			component = <SpecialBaseEntry />;
			activeItem = "specialProfile";
			break;
		case "contact":
			component = <Phone />;
			activeItem = "mobile";
			break;
		case "contact-add-edit":
			component = <PhoneEntry />;
			activeItem = "mobile";
			break;
		case "email":
			component = <Email />;
			activeItem = "email";
			break;
		case "email-add-edit":
			component = <EmailEntry />;
			activeItem = "email";
			break;
		case "social":
			component = <Social />;
			activeItem = "social";
			break;
		case "social-add-edit":
			component = <SocialEntry />;
			activeItem = "social";
			break;
		case "address":
			component = <Address />;
			activeItem = "address";
			break;
		case "address-add-edit":
			component = <AddressEntry />;
			activeItem = "address";
			break;
		case "other":
			component = <Other />;
			activeItem = "other";
			break;
		case "other-add-edit":
			component = <OtherEntry />;
			activeItem = "other";
			break;
		case "profilePic":
			component = <ProfilePicEdit />;
			activeItem = "profilePic";
			break;
		case "coverPic":
			component = <CoverEdit />;
			activeItem = "coverPic";
			break;
		case "edit-info":
			component = <EditInfo />;
			break;
		default:
			break;
	}

	return (
		<DefaultPage
			rightSideContainerColor={"#FFF"}
			leftSideChildren={
				<ProfileLeftPanel
					activeKey={activeKey}
					activeItem={activeItem}
				/>
			}
			rightSideBgColor={"#FFFFFF"}
			rightSideChildren={component}
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> {headerTitle}
				</div>
			}
			defaultHeader={false}
		/>
	);
};

export default EditProfileView;
