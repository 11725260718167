import { useEffect, useState } from "react";
import styles from "./NotificationPrompt.module.scss";

import Modal from "react-bootstrap/Modal";

// import "../../../bootstrap-style.css";
// import { messaging } from "../../../firebase";
import { getMessaging, getToken } from "firebase/messaging";
import { ReactComponent as GaawkLogo } from "images/gaawk-logos/gaawk-default-black.svg";
// import GaawkModal from "../GaawkModal/GaawkModal";

import profileApi from "api/profile";
import useMutate from "hooks/useMutate";
import PrimaryButton from "../Button/PrimaryButton";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateToken } from "store/slices/fcmToken";
// import useFingerprint from "hooks/useFingerprint";
import getFingerprint from "../getFingerprint";

const NotificationPrompt = () => {
	const { token: storedFcmToken } = useSelector((state) => state.fcmToken);

	const dispatch = useDispatch();

	const {
		action: { mutate: registerToken },
	} = useMutate(
		profileApi.registerToken
		// (response) => {
		// 	console.log("TOKEN RESPONSE >>", response);
		// },
		// false,
		// (error) =>
		// 	console.log("%c SOMETHING WRONG HAPPENED >>", "color: lime;", error)
	);

	const {
		action: { mutate: unregisterToken },
	} = useMutate(profileApi.unregisterToken);

	const [permission, setPermission] = useState(Notification.permission);

	const [showModal, setShowModal] = useState(permission === "default");

	useEffect(() => {
		// Check for permission status when the component mounts
		if (permission === "granted") {
			subscribeUserToPush();
		} else if (permission === "denied") {
			// showEnableNotificationsInstructions();
		}

		// let platform = navigator.userAgentData.platform;
		// console.log(navigator.userAgentData);

		// Listen for changes in permission status
		navigator.permissions
			.query({ name: "notifications" })
			.then((permissionStatus) => {
				permissionStatus.onchange = () => {
					setPermission(permissionStatus.state);
					if (permissionStatus.state === "granted") {
						subscribeUserToPush();
					} else if (permissionStatus.state === "denied") {
						// showEnableNotificationsInstructions();
					}
				};
			});

		return () => {
			navigator.permissions
				.query({ name: "notifications" })
				.then((permissionStatus) => {
					permissionStatus.onchange = null;
				});
		};
	}, [permission]);

	const requestNotificationPermission = () => {
		Notification.requestPermission().then((permission) => {
			setPermission(permission);
			setShowModal(false);
			if (permission === "granted") {
				subscribeUserToPush();
			} else if (permission === "denied") {
				// showEnableNotificationsInstructions();
			}
		});
	};

	const messaging = getMessaging();

	const subscribeUserToPush = () => {
		getToken(messaging, {
			vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
		})
			.then((currentToken) => {
				if (currentToken) {
					saveTokenToServer(currentToken);
				} else {
					requestNotificationPermission();
					// console.log(
					// 	"No Instance ID token available. Request permission to generate one."
					// );
				}
			})
			.catch((err) => {
				// console.log("An error occurred while retrieving token. ", err);
			});
	};

	// console.log(
	// 	"%c user agent >>> ",
	// 	"color: lime; font-weight: bolder",
	// 	navigator.userAgent
	// );

	// console.log("BASE64 USER AGENT >>>", btoa(navigator.userAgent));

	// const fingerprint = useFingerprint();
	// console.log("🚀 ~ fingerprint >>", fingerprint);
	// console.log("B64 FINGER PRINT >>", btoa(fingerprint));

	const saveTokenToServer = async (token) => {
		if (storedFcmToken !== token) {
			storedFcmToken && unregisterToken(storedFcmToken); //* unregistering token before registering a new one
			dispatch(updateToken(token));

			const fingerprint = await getFingerprint();

			registerToken({
				token,
				agent: fingerprint ? btoa(fingerprint) : "",
			});
		} else {
			// console.log("Token already saved in store:", storedFcmToken);
		}
	};

	// const showEnableNotificationsInstructions = () => {
	// 	// Display instructions to the user
	// 	console.log("%c NOTIFICATION DENIED", "color: red; font-weight: bold");
	// 	// alert(
	// 	// 	"To enable notifications, follow these steps:\n1. Open Safari Preferences (Cmd + ,).\n2. Go to the 'Websites' tab.\n3. Select 'Notifications' from the left-hand menu.\n4. Find this website in the list and set the permission to 'Allow'."
	// 	// );
	// };

	return (
		<Modal
			style={{ zIndex: "2000" }}
			animation={false}
			show={showModal}
			// onHide={() => setShowModal(false)}
			backdrop={"static"}
			backdropClassName={classNames(
				styles.gaawk_modal_backdrop,
				styles.modal_on_top
			)}
			dialogClassName={styles.gaawk_modal_dialog}
			contentClassName={styles.gaawk_modal_content}
		>
			<Modal.Body className={styles.gaawk_modal_body}>
				<div className={styles.notification_prompt}>
					<div className={styles.logo_container}>
						<GaawkLogo width={110} />
					</div>
					<div>
						<p>
							Gaawk would like to keep you updated with relevant
							information and notifications. You can manage your
							notification preferences or turn them off at any
							time through your browser settings.
						</p>
						<div className={styles.button_container}>
							<PrimaryButton
								type="button"
								text="Manage notifications"
								onClick={requestNotificationPermission}
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default NotificationPrompt;
