import { pluralize } from "components/Utils/General";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import friendsIcon from "images/connection-icons/friends.svg";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import connectionApi from "../../../api/connection";
import useApi from "../../../hooks/useApi";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import styles from "./ConnectionTab.module.css";

const pageSize = 6;

const Friends = ({ profile, canEdit, connectionCount }) => {
	const navigate = useNavigate();
	const { userUrl } = useParams();

	const getFriendsApi = useApi(connectionApi.getFriends, true, true);

	const fetchFriends = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, profileId] = queryKey;

		const response = await getFriendsApi.request(
			profileId,
			pageParam,
			pageSize
		);

		return response.data;
	};

	const { items, isFetching } = useInfiniteScroll({
		queryKey: connectionsKeys.list(profile.uuid, "friends"),
		queryFn: fetchFriends,
		pageSize,
	});

	const handleViewAll = (action) => () => {
		navigate(
			`/profile/${userUrl ? `${userUrl}/` : ""}connections?tab=${action}`
		);
	};

	const friendsList = useMemo(
		() =>
			items.map((friend) => (
				<div className={styles.contact_item} key={friend.uuid}>
					<GroupMember data={friend} redirect={true} />
				</div>
			)),
		[items]
	);

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={friendsIcon} alt="Friends" />
					Friends
				</div>
				<SeeMore text="See All" onClick={handleViewAll("friends")} />
			</div>

			{isFetching && <ContactLoader />}

			{!isFetching && (
				<>
					<div className={styles.subtitle}>
						{canEdit
							? "You have "
							: profile.type === "USER"
							? `${profile.firstName} ${profile.lastName} has `
							: `${profile.name} has `}
						<span
							onClick={handleViewAll("friends")}
						>{`${connectionCount.friends}`}</span>{" "}
						{pluralize(connectionCount.friends, "friend")}.
					</div>

					<div>{friendsList}</div>
				</>
			)}

			{canEdit && (
				<>
					{connectionCount.friendPendingSent > 0 && (
						<div className={styles.more_section}>
							<div className={styles.more_info}>
								You have sent{" "}
								<span
									onClick={handleViewAll("pendingSent")}
								>{`${connectionCount.friendPendingSent}`}</span>{" "}
								{pluralize(
									connectionCount.friendPendingSent,
									"friend"
								)}{" "}
								{pluralize(
									connectionCount.friendPendingSent,
									"request"
								)}
								.
							</div>
							<div
								className={styles.view_all}
								onClick={handleViewAll("pendingSent")}
							>
								View all sent requests
							</div>
						</div>
					)}

					{connectionCount.friendPendingReceived > 0 && (
						<div className={styles.more_section}>
							<div className={styles.more_info}>
								You have{" "}
								<span
									onClick={handleViewAll("pendingReceived")}
								>{`${connectionCount.friendPendingReceived}`}</span>{" "}
								{pluralize(
									connectionCount.friendPendingReceived,
									"friend"
								)}{" "}
								{pluralize(
									connectionCount.friendPendingReceived,
									"request"
								)}
								.
							</div>
							<div
								className={styles.view_all}
								onClick={handleViewAll("pendingReceived")}
							>
								View all pending requests
							</div>
						</div>
					)}
				</>
			)}
		</ProfileTabContainer>
	);
};

export default Friends;
