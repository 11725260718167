import React from "react";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import styles from "./ManageSection.module.css";

const ManageSection = ({ title, text, btnText, onClick, btnClassName }) => {
	return (
		<div className={styles.container}>
			<h4>{title}</h4>
			<p>{text}</p>
			<div className={styles.btn_wrapper}>
				<PrimaryButton
					text={btnText}
					className={`${styles.btn} ${btnClassName}`}
					onClick={onClick}
				/>
			</div>
		</div>
	);
};

export default ManageSection;
