import { featuresSection } from "components/Utils/General";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import useGetQueryParam from "hooks/useGetQueryParam";
import { peopleKeys } from "queryKeys/people-key-factory";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import profileApi from "../../api/profile";
import CityModal from "../../components/Boards/BoardsComponents/CityModal";
import InfiniteUsersPage from "../../components/More/MoreComponents/UsersComponents/InfiniteUsersPage";
import People from "../../components/More/PeoplePage/People";
import Filter from "../../components/Utils/Filter/Filter";
import useUserLocation from "../../hooks/useUserLocation";
import useWindowSize from "../../hooks/useWindowSize";

const PeopleView = () => {
	const { pathname } = useLocation();

	const { paramValue: inputValue } = useGetQueryParam("q");

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const [showModal, setShowModal] = useState(false);
	const [countryFlag, setCountryFlag] = useState();

	const { userCoordinates, handleSelectedCity } = useUserLocation(setCountryFlag);

	let component;
	let headerTitle;

	switch (pathname) {
		case "/people":
			component = <People userCoordinates={userCoordinates} />;
			headerTitle = inputValue ? "Search People" : "People";
			break;

		case "/people/network":
			component = (
				<InfiniteUsersPage
					queryKey={peopleKeys.networkPeople("infinite")}
					endpoint={profileApi.getNetworkPeople}
				/>
			);
			headerTitle = "People you may know";
			break;

		case "/people/nearby":
			component = (
				<InfiniteUsersPage
					queryKey={peopleKeys.nearbyPeople("infinite", userCoordinates)}
					endpoint={profileApi.getPeopleNearby}
				/>
			);
			headerTitle = "People in your area";
			break;

		case "/people/my-company":
			component = (
				<InfiniteUsersPage
					queryKey={peopleKeys.companyPeople("infinite")}
					endpoint={profileApi.getPeopleFromMyCompany}
				/>
			);
			headerTitle = "People who work in your company";
			break;

		case "/people/similar":
			component = (
				<InfiniteUsersPage
					queryKey={peopleKeys.interestPeople("infinite", userCoordinates)}
					endpoint={profileApi.getPeopleInterests}
				/>
			);
			headerTitle = "People with similar interests";
			break;

		default:
			break;
	}

	return (
		<>
			<Filter
				title={headerTitle}
				isMobile={isMobile}
				headerButton={
					<button onClick={() => setShowModal(true)}>
						<img src={countryFlag} alt="" />
					</button>
				}
				leftSideChildren={<FeaturesSidebar active={featuresSection.people} />}
				rightSideChildren={component}
			/>

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default PeopleView;
