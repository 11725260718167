import client from "./client";

const endpoint = "/specialProfile";

const getAllSpecial = (requestedProfileId) =>
	client.get(endpoint + `?requestedProfileId=${requestedProfileId}`);

const addSpecialProfile = (data) => client.post(endpoint, data);
const updateSpecialProfile = (data) => client.put(endpoint, data);
const deleteSpecialProfile = (specialProfileId) =>
	client.delete(endpoint + `?specialProfileId=${specialProfileId}`);

const getSpecialById = (specialProfileId, agencyId = "") =>
	client.get(endpoint + `/${specialProfileId}?agencyId=${agencyId}`);

const getSpecialByUrl = (url, agencyId = "") =>
	client.get(
		endpoint + `/url/${url}${agencyId ? `?agencyId=${agencyId}` : ""}`
	);

const getMedias = (specialProfileId) =>
	client.get(endpoint + `/media?specialProfileId=${specialProfileId}`);

const updateGeneralDetails = (specialProfileId, data) =>
	client.put(
		endpoint + `/general?specialProfileId=${specialProfileId}`,
		data
	);

const updatePhysicalDetails = (specialProfileId, data) =>
	client.put(
		endpoint + `/physical?specialProfileId=${specialProfileId}`,
		data
	);

const updateVocalDetails = (specialProfileId, data) =>
	client.put(endpoint + `/vocal?specialProfileId=${specialProfileId}`, data);

const updateAdditionalDetails = (specialProfileId, data) =>
	client.put(
		endpoint + `/additional?specialProfileId=${specialProfileId}`,
		data
	);

const addMedia = (data, onUploadProgress) =>
	client.post(endpoint + "/media", data, {
		onUploadProgress: (progress) =>
			onUploadProgress(progress.loaded / progress.total),
	});

const deleteMedia = (specialProfileId, mediaId) =>
	client.delete(
		endpoint +
			`/media?specialProfileId=${specialProfileId}&mediaId=${mediaId}`
	);

const getTalents = (
	requestedProfileId,
	page = 0,
	size = 10,
	confirmed,
	type = "N_A"
) =>
	client.get(
		endpoint +
			`/agency/talents?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}&confirmed=${confirmed}&type=${type}`
	);

const updateAlias = (specialProfileId, alias) =>
	client.put(
		endpoint + `/alias?specialProfileId=${specialProfileId}&alias=${alias}`
	);

const search = (
	page,
	size,
	agencyId = "",
	body,
	agenciesOnly,
	lat = "",
	lng = ""
) =>
	client.post(
		endpoint +
			`/search?agencyId=${agencyId}&agenciesOnly=${agenciesOnly}&page=${page}&size=${size}${
				lat ? `&latitude=${lat}` : ""
			}${lng ? `&longitude=${lng}` : ""}`,
		body
	);

const searchFilters = (page, size, agencyId = "", body, agenciesOnly) =>
	client.post(
		endpoint +
			`/search/filters?page=${page}&size=${size}&agencyId=${agencyId}&agenciesOnly=${agenciesOnly}`,
		body
	);

const getDynamicFilter = (
	type,
	page,
	size,
	agencyId = "",
	body,
	agenciesOnly
) =>
	client.post(
		endpoint +
			`/search/filters/${type}?page=${page}&size=${size}&agencyId=${agencyId}&agenciesOnly=${agenciesOnly}`,
		body
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getAllSpecial,
	addSpecialProfile,
	updateSpecialProfile,
	deleteSpecialProfile,
	getSpecialById,
	getSpecialByUrl,
	getMedias,
	updateGeneralDetails,
	updatePhysicalDetails,
	updateVocalDetails,
	updateAdditionalDetails,
	addMedia,
	deleteMedia,
	getTalents,
	updateAlias,
	search,
	searchFilters,
	getDynamicFilter,
};
