import projectApi from "api/project";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import GaawkRadioButton from "components/Utils/SubComs/GaawkRadioButton/GaawkRadioButton";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useApi from "hooks/useApi";
import useCurrentUser from "hooks/useCurrentUser";
import usePaginatedResource from "hooks/usePaginatedResource";
import { ReactComponent as ForwardIcon } from "images/arrow-icon.svg";
import { classNames } from "primereact/utils";
import { projectKeys } from "queryKeys/projects-key-factory";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ProjectSwitcher.module.scss";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import routes from "components/Routing/routing-keys";
import useUrlParams from "hooks/useUrlParams";

const itemsPerPage = 20;

const ProjectSwitcher = ({ activeProjectTitle = "All Jobs" }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [switchModal, setSwitchModal] = useState(false);
	const { uuid: companyId } = useCurrentUser();
	const openStatusProject = true;

	const searchParams = new URLSearchParams(location.search);
	const activeProjectId = searchParams.get("projectId") || "";

	// * ==== FETCH PROJECTS PAGINATED ===

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getAllProjectsApi = useApi(projectApi.getAllProjects, true, true);

	const fetchProjects = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, ___, status] = queryKey;

		const response = await getAllProjectsApi.request(pageParam, itemsPerPage, status);
		return response.data;
	};

	const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = usePaginatedResource(
		projectKeys.lists(companyId, openStatusProject),
		fetchProjects,
		itemsPerPage,
		switchModal
	);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const paramConfigs = {
		projectId: {
			validator: (id) => typeof id === "string",
			defaultValue: "",
		},
	};

	const { setParams } = useUrlParams(paramConfigs);

	const handleProjectClick = (projectId = "") => {
		setParams({ projectId });
		setSwitchModal(false);
	};

	const pendingList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((project, i) => (
					<button
						ref={page.length === i + 1 ? viewRef : null}
						className={classNames(styles.project_item, {
							[styles.active]: project.uuid === activeProjectId,
						})}
						key={project.uuid}
						onClick={() => handleProjectClick(project.uuid)}
					>
						<p>{project.title}</p>
						<GaawkRadioButton
							standaloneMode
							readOnly
							checked={project.uuid === activeProjectId}
						/>
					</button>
				))
			),
		[activeProjectId, data, handleProjectClick]
	);

	return (
		<>
			<button className={styles.switcher_wrapper} onClick={() => setSwitchModal(true)}>
				<p>{activeProjectTitle}</p>
				<ForwardIcon className={styles.forward_icon} />
			</button>

			<GaawkModal
				show={switchModal}
				title={"Jobs & Open Projects"}
				closeAlign={"right"}
				handleClose={() => setSwitchModal(false)}
				defaultModal={false}
				showHeader={true}
			>
				<div className={styles.modal_container}>
					<p className={styles.info}>
						You can create or select the projects from here & can use them as container
						for your jobs.
					</p>

					<div className={styles.project_container}>
						{isLoading ? (
							<LoadingSpinner />
						) : (
							<>
								<button
									className={classNames(styles.project_item, {
										[styles.active]: activeProjectId === "",
									})}
									onClick={() => handleProjectClick()}
								>
									<p>All Jobs</p>
									<GaawkRadioButton
										standaloneMode
										readOnly
										checked={activeProjectId === ""}
									/>
								</button>

								{pendingList}

								{isFetching && <LoadingSpinner />}
							</>
						)}
					</div>

					<PrimaryButton
						text={"+ Add New Project"}
						className={styles.addProjectBtn}
						onClick={() => navigate(routes.addProject)}
					/>

					<SeeMore
						text={"Manage Projects"}
						className={styles.manage_projects_btn}
						color={"#000"}
						onClick={() => navigate(routes.allProjects)}
					/>
				</div>
			</GaawkModal>
		</>
	);
};

export default ProjectSwitcher;
