import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ConnectionTab.module.css";
import useApi from "../../../hooks/useApi";
import connectionApi from "../../../api/connection";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import blockIcon from "images/connection-icons/block.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import { pluralize } from "components/Utils/General";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { connectionsKeys } from "queryKeys/connections-key-factory";

const pageSize = 6;

const Blocked = ({ profile, canEdit, connectionCount }) => {
	const navigate = useNavigate();
	const { userUrl } = useParams();

	const getBlockedApi = useApi(connectionApi.getBlocked, true, true);

	const fetchBlocked = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const response = await getBlockedApi.request(pageParam, pageSize);
		return response.data;
	};

	const { items, isFetching } = useInfiniteScroll({
		queryKey: connectionsKeys.list(profile.uuid, "blocked"),
		queryFn: fetchBlocked,
		pageSize,
	});

	const handleViewAll = (action) => () => {
		navigate(
			`/profile/${userUrl ? `${userUrl}/` : ""}connections?tab=${action}`
		);
	};

	const blockedList = useMemo(
		() =>
			items.map((block) => (
				<div className={styles.contact_item} key={block.uuid}>
					<GroupMember data={block} redirect={true} />
				</div>
			)),
		[items]
	);

	if (!canEdit) return null;

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={blockIcon} alt="Blocked" />
					Blocked
				</div>

				<SeeMore text="See All" onClick={handleViewAll("blocked")} />
			</div>

			{isFetching && <ContactLoader />}

			{!isFetching && (
				<>
					<div className={styles.subtitle}>
						You have blocked{" "}
						<span
							onClick={handleViewAll("blocked")}
						>{`${connectionCount.blocking}`}</span>{" "}
						{pluralize(connectionCount.blocking, "Connection")}.
					</div>

					<div>{blockedList}</div>
				</>
			)}
		</ProfileTabContainer>
	);
};

export default Blocked;
