import routes from "components/Routing/routing-keys";
import useMutate from "hooks/useMutate";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import profileApi from "../../api/profile";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import BasicCheckbox from "../Utils/SubComs/Inputs/BasicCheckbox/BasicCheckbox";
import styles from "./BecomeAgency.module.css";
import CompanyItem from "./CompanyItem";

const BecomeAgency = ({ companyProfile }) => {
	const navigate = useNavigate();

	const { agency: isAgency, locationDTO } = companyProfile || {};

	const [isChecked, setIsChecked] = useState(false);
	const [isDeleteChecked, setIsDeleteChecked] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);

	const {
		action: { mutate: becomeAgency },
	} = useMutate(profileApi.becomeAgency, () => {
		setConfirmationModal(true);
	});
	const {
		action: { mutate: deleteAgency },
	} = useMutate(profileApi.deleteAgency, () => {
		toast.success("We're sorry to see you go");
		navigate(routes.profile());
	});

	return (
		<>
			<div className={styles.company_item}>
				<CompanyItem
					item={companyProfile}
					subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
				/>
			</div>
			<div className={styles.container}>
				{isAgency ? (
					<>
						<p>
							You will no longer have a talent tab on your profile
							page and users will no longer be able to send you
							the request for the special profiles to be listed on
							your page.
						</p>

						<div className={styles.terms_wrapper}>
							<BasicCheckbox
								isChecked={isDeleteChecked}
								onChange={() =>
									setIsDeleteChecked(
										(prevState) => !prevState
									)
								}
								text={
									<p>
										I understand that cancelling my
										subscription will result in the loss of
										my data, and I will no longer have
										access to it.
									</p>
								}
							/>
						</div>
						<div className={styles.button_container}>
							<PrimaryButton
								text={"confirm"}
								className={styles.btn}
								onClick={() => deleteAgency()}
								disabled={!isDeleteChecked}
							/>
						</div>
					</>
				) : (
					<>
						<p>
							By becoming an agency you can represent and manage
							talents, users with their talent profiles can send
							you requests to be on your talent page. You can
							enjoy 6 months of free subscription.
						</p>

						<div className={styles.terms_wrapper}>
							<BasicCheckbox
								isChecked={isChecked}
								onChange={() =>
									setIsChecked((prevState) => !prevState)
								}
								text={
									<p>
										I understand that my company will be
										represented as agency throughout gaawk
										and I will accept requests for talent
										profiles to be listed on my page.
										{/* Accept{" "}
												<span
													onClick={() => {
														alert(
															"Display terms & conditions"
														);
													}}
													className={styles.terms}
												>
													terms & conditions.
												</span> */}
									</p>
								}
							/>
						</div>
						<div className={styles.button_container}>
							<PrimaryButton
								text={"confirm"}
								className={styles.btn}
								onClick={becomeAgency}
								disabled={!isChecked}
							/>
						</div>

						{/* {showPayment && (
							<>
								<div className={styles.payment_container}>
									ADD PAYMENT METHODS HERE
								</div>
								<div className={styles.button_container}>
									<PrimaryButton
										text={"pay"}
										className={styles.btn}
										onClick={handlePayment}
										disabled={becomeAgencyApi.loading}
									/>
								</div>
							</>
						)} */}
					</>
				)}
			</div>

			<GaawkModal
				show={confirmationModal}
				handleClose={() => {
					setConfirmationModal(false);
					navigate(routes.profile());
				}}
				defaultModal={false}
				showHeader={true}
				title={"Become an Agency"}
				closeAlign={"right"}
				children={
					<div className={`${styles.container} ${styles.modal}`}>
						<div className={styles.congrats_message}>
							<h2>CONGRATULATIONS!</h2>
							<h4>Your company is now an agency.</h4>
						</div>
						<div className={styles.company_wrapper}>
							<CompanyItem
								item={companyProfile}
								subdata={`${companyProfile?.locationDTO?.name}, ${companyProfile?.locationDTO?.countryDTO?.name}`}
							/>
						</div>
						<div className={styles.info}>
							Congratulations! You can now represent and manage
							talents, people with their talent profiles can send
							you requests to be on your talent page.
						</div>
						<div className={styles.button_container}>
							<PrimaryButton
								text={"go to your agency"}
								className={styles.btn}
								onClick={() => navigate(routes.profile())}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default BecomeAgency;
