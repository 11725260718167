import { useParams } from "react-router-dom";
import postApi from "../../../../api/post";
import useApi from "../../../../hooks/useApi";
import styles from "./ServiceMedias.module.scss";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import useCompanyId from "../../../../hooks/useCompanyId";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import FileSaver from "file-saver";
import PostViewer from "../../GalleryTab/PostViewer";
import useEditSubPost from "../../../../hooks/useEditSubPost";
import EditMediaModal from "../../../Home/EditMediaModal";
import { toast } from "react-toastify";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import ServiceProductMediaItem from "../../../Utils/MediaItem/ServiceProductMediaItem";
import CreatePost from "../../../Home/CreatePost";
import useMutate from "../../../../hooks/useMutate";
import { servicesKeys } from "queryKeys/services-key-factory";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import GalleryContainer from "components/Utils/SubComs/GalleryContainer/GalleryContainer";

const feedSize = 50;

const ServiceMedias = () => {
	const queryClient = useQueryClient();

	const reportRef = useRef();
	const postRef = useRef(null);

	const { serviceId } = useParams();

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getServiceMediaApi = useApi(postApi.getServiceMedia, true, true);

	const fetchServiceMedia = async ({ pageParam = 0, queryKey }) => {
		const [, , , serviceUuid] = queryKey;
		const response = await getServiceMediaApi.request(
			serviceUuid,
			pageParam,
			feedSize
		);
		return response.data;
	};

	const invalidateMedias = () => {
		queryClient.invalidateQueries(servicesKeys.infinitMedias(serviceId));
	};

	const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery({
		queryKey: servicesKeys.infinitMedias(serviceId),
		queryFn: fetchServiceMedia,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === feedSize ? pages.length : undefined;
			return nextPage;
		},

		// staleTime: 300000, // 5 minutes
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) fetchNextPage();
	}, [inView, hasNextPage, isFetching, fetchNextPage]);

	const companyId = useCompanyId();

	const { uuid: userId, ...profile } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const profileName =
		profile.type === "COMPANY"
			? profile.name
			: `${profile.firstName} ${profile.lastName}`;

	//! ======== VIEWER HANDLER =========

	const serviceMedias = useMemo(() => {
		const array = [];

		data?.pages.forEach((page) =>
			page.forEach((media) => array.push(media))
		);

		return array;
	}, [data]);

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const handleShowSlider = (media) => {
		const fileIndex = serviceMedias.findIndex((item) => item === media);
		setInitialSlideIndex(fileIndex);
		setShowSlider(true);

		window.history.pushState(null, "", `/post/${media.uuid}`);
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		window.history.replaceState(null, "", `/services/${serviceId}/medias`);
	};

	useEffect(() => {
		if (showSlider) {
			window.history.replaceState(
				null,
				"",
				`/post/${serviceMedias[activeSlideIndex].uuid}`
			);
		}
	}, [activeSlideIndex, serviceMedias]);

	const filesArray = useMemo(
		() => serviceMedias?.map((item) => item.file),
		[serviceMedias]
	);

	const handleDownload = (media = undefined) => {
		const downloadable = media ? media : filesArray[activeSlideIndex];
		FileSaver.saveAs(`${downloadable.url}`, `${downloadable.originalName}`);
	};

	// ! edit media ================

	const updateMediaApi = useApi(postApi.updateMedia, true);

	const [showEditMediaModal, setShowEditMediaModal] = useState(false);
	const [editItem, setEditItem] = useState("");

	const handleShowEditMediaModal = (media) => {
		setShowEditMediaModal(true);
		setEditItem(media);
	};

	const { handleEdit } = useEditSubPost(invalidateMedias, updateMediaApi);

	// !=========== DELETE MEDIA ===============

	const handleDeleteSuccess = () => {
		invalidateMedias();
		if (showSlider && serviceMedias.length === activeSlideIndex + 1) {
			//* decrement activeSlideIndex by 1 when you're deleting the last subPost otherwise ERROR
			setActiveSlideIndex((prevState) => prevState - 1);
		}
	};

	const {
		warningModal,
		setWarningModal,
		handleRequestDelete,
		handleConfirmDelete,
	} = useMutate(postApi.deletePost, handleDeleteSuccess);

	// !====================================

	const mediaList = data?.pages.map((page) =>
		page.map((media, i) => (
			<ServiceProductMediaItem
				ref={viewRef}
				key={media.uuid}
				media={media}
				onClick={() => handleShowSlider(media)}
				onEdit={() => handleShowEditMediaModal(media)}
				onDelete={() => handleRequestDelete(media.uuid)}
				onShare={() => postRef.current.sharePost(media)}
				// onDownload={() => handleDownload(media.file)}
				onReport={() => reportRef.current.showReportModal(media.uuid)}
			/>
		))
	);

	if (!isFetching && data?.pages?.[0].length === 0)
		return <NoResults visible={true} text={"No medias available"} />;

	return (
		<>
			<GalleryContainer className={styles.container}>
				{mediaList}
			</GalleryContainer>

			{/* <div className={styles.items_wrapper}>{mediaList}</div> */}

			{serviceMedias && (
				<PostViewer
					onLoadMore={() => {
						if (hasNextPage) fetchNextPage();
					}}
					isLoading={isFetching}
					show={showSlider}
					onClose={handleCloseSlider}
					post={serviceMedias[activeSlideIndex]}
					files={filesArray}
					// postName={"Medias"}
					initialSlideIndex={initialSlideIndex}
					onActiveSlide={(index) => {
						setActiveSlideIndex(index);
					}}
					isOwner={
						userId === serviceMedias[activeSlideIndex]?.owner.uuid
					}
					onPostUpdate={invalidateMedias}
					onEdit={(post) => handleShowEditMediaModal(post)}
					onDelete={(post) => handleRequestDelete(post.uuid)}
					onShare={(post) => postRef.current.sharePost(post)}
					onDownload={handleDownload}
				/>
			)}

			<WarningModal
				show={warningModal}
				modalOnTop={false}
				headerText="Are you sure you want to delete this post?"
				warningText={
					<>
						Deleting this post will also be deleted from its parent
						post.
						<br />
						<br />
						This action cannot be undone.
					</>
				}
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>

			<CreatePost
				ref={postRef}
				name={profileName}
				profileImg={profile.profileImage}
				type={profile.type}
				onCreate={() => toast.success("Post successfully shared!")}
			/>

			<EditMediaModal
				show={showEditMediaModal}
				onClose={() => setShowEditMediaModal(false)}
				onReset={() => setEditItem("")}
				item={editItem}
				onConfirm={handleEdit}
				onToggle={(bool) => setShowEditMediaModal(bool)}
				// isEditing={true}
			/>

			<ReportModal ref={reportRef} category="post" />
		</>
	);
};

export default ServiceMedias;
