import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./UserVault.module.css";
import SimpleDropZone from "../Utils/SubComs/CustomDropZone/SimpleDropZone";

import FileItem from "./FileItem";

// import deleteIcon from "images/icon-exit.svg";
// import PrimaryButton from "../Utils/Button/PrimaryButton";
import LoadingBar from "../Utils/SubComs/LoadingBar/LoadingBar";
import useApi from "../../hooks/useApi";
import vaultApi from "../../api/vault";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import FileContainer from "components/Utils/SubComs/FileContainer/FileContainer";
import { toast } from "react-toastify";

const VaultFileUploader = () => {
	const navigate = useNavigate();
	const params = useParams();

	const addFileApi = useApi(vaultApi.addFile);

	/**
	 * States
	 */
	const [droppedFiles, setDroppedFiles] = useState([]);
	const [showProgress, setShowProgress] = useState(false);
	const [progress, setProgress] = useState(0);
	// const [counter, setCounter] = useState(0);

	/**
	 * Effects
	 */

	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		let timer;

		if (isMounted) {
			if (addFileApi.loading) {
				// console.log("uploading..........");
			} else {
				if (addFileApi.responseCode === 201) {
					if (progress === 100) {
						timer = setTimeout(() => {
							if (progress === 100) {
								if (params.id) {
									navigate(`/vault/${params.id}`, {
										replace: true,
									});
								} else {
									navigate("/vault", { replace: true });
								}
							}
						}, 2000);
					}
				} else {
					toast.error(`${addFileApi.data.message}`);
				}
			}
		}
		return () => {
			setIsMounted(true);
			clearTimeout(timer);
		};
	}, [addFileApi.loading, addFileApi.responseCode, progress]);

	/**
	 * Callbacks
	 */
	const onDropFiles = useCallback(
		(acceptedFiles) => {
			setDroppedFiles([...droppedFiles, ...acceptedFiles]);
		},
		[droppedFiles]
	);

	/**
	 * functions
	 */

	const removeFile = (index) => () => {
		setDroppedFiles(droppedFiles.filter((_, i) => i !== index));
	};

	const handleUploadFiles = () => {
		setShowProgress(true);
		if (droppedFiles.length > 0) {
			const itemsProgress = {};

			droppedFiles.forEach((file) => {
				addFileApi.request(file, params.id, (progress) => {
					// add each file progress in the itemsProgress object
					// overriding the value if it's already there (based on the file name)
					// todo: save the item progress based on a unique ID (otherwise what happens if 2 files have the same name ?)
					itemsProgress[file.name] = progress * 100;

					// sum all the progress values of each file
					let totalPercent = itemsProgress
						? Object.values(itemsProgress).reduce(
								(sum, num) => sum + num,
								0
						  )
						: 0;

					// dividing total % progress on total number of files
					setProgress(Math.round(totalPercent / droppedFiles.length));
				});
			});
		}
	};

	/**
	 * Mappers
	 */

	const mappedFiles =
		droppedFiles.length > 0 &&
		droppedFiles.map((file, index) => {
			return (
				<FileItem
					key={index}
					// name={file.name}
					// item={getFileIcon(file, file.name)}
					item={file}
					onRemove={removeFile(index)}
				/>
			);
		});

	return (
		<div className={styles.upload_container}>
			<div className={styles.upload_header}>
				<div>
					<h4>Upload Files</h4>
					<p>
						You need to add all of your media here, then press
						“Upload all files” to upload all your files into vault.
					</p>
				</div>
				{droppedFiles.length > 0 && (
					<PrimaryButton
						onClick={handleUploadFiles}
						type="button"
						text={"upload"}
					/>
				)}
			</div>

			<SimpleDropZone onDrop={onDropFiles} />

			{showProgress && droppedFiles.length > 0 && (
				<LoadingBar progress={progress} />
			)}

			<FileContainer>{mappedFiles}</FileContainer>
		</div>
	);
};

export default VaultFileUploader;
