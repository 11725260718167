import useWindowSize from "hooks/useWindowSize";
import LazyImage from "../../../Utils/LazyImage/LazyImage";
import styles from "./ServiceItemAlt.module.scss";
import { useMemo } from "react";
import ProfilePic from "components/Home/ProfilePic";

const ServiceItemAlt = ({ item }) => {
	const {
		image: { file: { customName } = {} } = {},
		description,
		name,
		minifiedProfile,
	} = item || {};

	const serviceName = name;

	const { width } = useWindowSize();

	const descriptionLength = useMemo(() => {
		if (width >= 1608) return 340;
		else if (width >= 1400 && width < 1608) {
			return 190;
		} else if (width >= 1166 && width < 1400) {
			return 550;
		} else if (width >= 974 && width < 1166) {
			return 300;
		} else if (width >= 748 && width < 974) {
			return 300;
		} else return 340;
	}, [width]);

	return (
		<div className={styles.item_container}>
			<div className={styles.serviceOwnerPicture}>
				<ProfilePic
					h={24}
					w={24}
					type={minifiedProfile.type}
					thumbnail={minifiedProfile?.profileImage?.image}
					name={minifiedProfile.name}
					nameClassName={styles.serviceOwner}
					letterSize={14}
				/>
			</div>
			<div className={styles.item_content_container}>
				{customName && (
					<div className={styles.image_container}>
						<LazyImage
							inlineStyle={styles.item_image}
							image={<img src={customName} alt={customName} />}
						/>
					</div>
				)}
				<div className={styles.content_wrapper}>
					<div className={styles.flex_row_between}>
						<span className={styles.item_service_name}>{serviceName}</span>
					</div>

					<p className={styles.description}>
						{description?.substring(0, descriptionLength)}
						{description.length >= descriptionLength && "..."}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ServiceItemAlt;
