import { pluralize } from "components/Utils/General";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import favoriteIcon from "images/connection-icons/favorite.svg";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import profileApi from "../../../api/profile";
import useApi from "../../../hooks/useApi";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import styles from "./ConnectionTab.module.css";

const pageSize = 6;

const Favorites = ({ profile, canEdit, connectionCount }) => {
	const navigate = useNavigate();
	const { userUrl } = useParams();

	const getFavoritesApi = useApi(profileApi.getFavorites, true, true);

	const fetchFavorites = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const response = await getFavoritesApi.request(pageParam, pageSize);
		return response.data;
	};

	const { items, isFetching } = useInfiniteScroll({
		queryKey: connectionsKeys.list(profile.uuid, "favorites"),
		queryFn: fetchFavorites,
		pageSize,
	});

	const handleViewAll = (action) => () => {
		navigate(
			`/profile/${userUrl ? `${userUrl}/` : ""}connections?tab=${action}`
		);
	};

	const favoritesList = useMemo(
		() =>
			items.map((favorite) => (
				<div className={styles.contact_item} key={favorite.uuid}>
					<GroupMember data={favorite} redirect={true} />
				</div>
			)),
		[items]
	);

	// if (connectionCount.favorites === 0 || !canEdit) return null;
	if (!canEdit) return null;

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={favoriteIcon} alt="Favorites" />
					Favorites
				</div>
				<SeeMore text="See All" onClick={handleViewAll("favorites")} />
			</div>

			{isFetching && <ContactLoader />}

			{!isFetching && (
				<>
					<div className={styles.subtitle}>
						You have added{" "}
						<span
							onClick={handleViewAll("favorites")}
						>{`${connectionCount.favorites}`}</span>{" "}
						{pluralize(connectionCount.favorites, "connection")} to
						your favorites.
					</div>

					<div>{favoritesList}</div>
				</>
			)}
		</ProfileTabContainer>
	);
};

export default Favorites;
