import { featuresSection } from "components/Utils/General";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import VaultHeader from "components/Vault/VaultHeader";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import VaultRightSide from "../../components/Vault/VaultRightSide";
import styles from "../Header.module.css";

const Vault = () => {
	const navigate = useNavigate();
	return (
		<DefaultPage
			defaultHeader={false}
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Vault
				</div>
			}
			rightSideBgColor={"#FFF"}
			bannerComponent={<VaultHeader />}
			defaultBannerHeight={105}
			leftSideChildren={
				<FeaturesSidebar active={featuresSection.vault} />
			}
			rightSideChildren={<VaultRightSide />}
			rightSideContainerColor={"#FFF"}
		/>
	);
};

export default Vault;
