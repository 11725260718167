import { pluralize } from "components/Utils/General";

const counterMessages = {
	friends: {
		self: (count) => `You have ${count} ${pluralize(count, "friend")}`,
		other: (name, count) =>
			`${name} has ${count} ${pluralize(count, "friend")}`,
	},
	pendingSent: {
		self: (count) =>
			`You have sent ${count} friend ${pluralize(count, "request")}`,
	},
	pendingReceived: {
		self: (count) =>
			`You have received ${count} friend ${pluralize(count, "request")}`,
	},
	yourWorkCircle: {
		self: (count) =>
			`You have ${count} ${pluralize(
				count,
				"connection"
			)} in your work circle`,
		other: (name, count) =>
			`${name} has ${count} ${pluralize(
				count,
				"connection"
			)} in their work circle`,
	},
	theirWorkCircle: {
		self: (count) =>
			`${count} ${pluralize(
				count,
				"connection"
			)} added you to their work circle`,
		other: (name, count) =>
			`${count} ${pluralize(
				count,
				"connection"
			)} added ${name} to their work circle`,
	},
	following: {
		self: (count) =>
			`You follow ${count} ${pluralize(count, "connection")}`,
		other: (name, count) =>
			`${name} follows ${count} ${pluralize(count, "connection")}`,
	},
	followers: {
		self: (count) =>
			`${count} ${pluralize(count, "connection")} follow you`,
		other: (name, count) =>
			`${count} ${pluralize(count, "connection")} follow ${name}`,
	},
	favorites: {
		self: (count) =>
			`You have favorited ${count} ${pluralize(count, "connection")}`,
	},
	blocked: {
		self: (count) =>
			`You have blocked ${count} ${pluralize(count, "connection")}`,
	},
};

export const getCounter = (activeTab, connectionCount, canEdit, fullName) => {
	const messageConfig = counterMessages[activeTab];
	if (!messageConfig) return;

	const countMap = {
		friends: connectionCount?.friends,
		pendingSent: connectionCount?.friendPendingSent,
		pendingReceived: connectionCount?.friendPendingReceived,
		yourWorkCircle: connectionCount?.inMyWorkCircle,
		theirWorkCircle: connectionCount?.inTheirWorkCircles,
		following: connectionCount?.following,
		followers: connectionCount?.followers,
		favorites: connectionCount?.favorites,
		blocked: connectionCount?.blocking,
	};
	const count = countMap[activeTab] || 0;

	return canEdit
		? messageConfig.self(count)
		: messageConfig.other(fullName, count);
};
