import { useState } from "react";

const useUrlPreview = () => {
	const [objectUrls, setObjectUrls] = useState([]);

	const cleanupObjectUrls = () => {
		objectUrls.forEach((url) => URL.revokeObjectURL(url));
		setObjectUrls([]);
	};

	return { objectUrls, setObjectUrls, cleanupObjectUrls };
};

export default useUrlPreview;
