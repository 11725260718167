import { useMemo } from "react";
import { pluralize } from "../../Utils/General";
import styles from "./Jobs.module.scss";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import filterIcon from "images/filter-icon-white.svg";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import NewJobCard from "components/Jobs/JobsComponents/NewJobCard/NewJobCard";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const Jobs = ({
	count,
	data,
	isPaginated = false,
	isFetchingNextPage,
	viewRef,
	isLoading,
	isMobile,
	onShowModal,
	onClick,
	tagsList,
	onToggleSave,
}) => {
	const itemsList = useMemo(
		() =>
			data?.map((job, index) => {
				const isLastItem = index === data.length - 1;
				return (
					<div key={job.uuid} ref={isLastItem && isPaginated ? viewRef : null}>
						<NewJobCard
							data={job}
							onToggleSave={() =>
								onToggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								})
							}
						/>
						{isLastItem && isFetchingNextPage && <LoadingSpinner />}
					</div>
				);
			}),
		[data, isPaginated, viewRef]
	);

	if (isLoading) return <LoadingSpinner />;

	if (!isLoading && count === 0)
		return <NoResults visible={true} isSearch={true} text={"No results"} />;

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Job")} found`}</h4>
				{isMobile && isPaginated && (
					<button className={styles.filter_button} onClick={onShowModal}>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && <div className={styles.active_filters}>{tagsList}</div>}

			<div className={styles.items_wrapper}>{itemsList}</div>

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}
		</>
	);
};

export default Jobs;
