import LandingPage from "components/Landing/LandingPage";
import useCompanyId from "hooks/useCompanyId";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import routes from "./routing-keys";
import ProtectedLayout from "components/Utils/PageArchs/ProtectedLayout/ProtectedLayout";

const RequireAuth = ({
	children,
	userOnly = false,
	companyOnly = false,
	restricted = false, //adding restriction for pages like "/login" that should not be accessed if already logged
}) => {
	const { uuid } = useSelector((state) => state.user.profileInfo);
	const companyId = useCompanyId();

	let location = useLocation();

	//! IF NOT LOGGED
	if (!uuid) {
		//* Redirect them to the /login page, but save the current location they were
		//* trying to go to when they were redirected. This allows us to send them
		//* along to that page after they login, which is a nicer user experience
		//* than dropping them off on the home page.
		if (location.pathname === routes.root) return <LandingPage />;
		else if (location.pathname === routes.login) return children;
		else return <Navigate to={routes.login} state={{ from: location }} />;
	}

	//~ IF LOGGED
	if (
		((companyId && !userOnly) || (!companyId && !companyOnly)) &&
		!restricted
	) {
		//~ IF ACCESS GRANTED
		return <ProtectedLayout>{children}</ProtectedLayout>;
	} else {
		//! IF NOT GRANTED
		return <Navigate to={routes.root} />;
	}
};

export default RequireAuth;
