import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteKeyPeople } from "../../../../store/slices/company";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./KeyPeople.module.css";
import KeyPeopleItem from "./KeyPeopleItem";
import useCurrentUser from "hooks/useCurrentUser";

const KeyPeople = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { keyPeople, uuid } = useCurrentUser();

	const [profileId, setProfileId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const [formattedKeyPeople, setFormattedKeyPeople] = useState([]);

	const handleDelete = (userId) => {
		setProfileId(userId);
		setWarningDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		dispatch(deleteKeyPeople(uuid, profileId));
		setWarningDeleteModal(false);
	};

	useEffect(() => {
		setFormattedKeyPeople(
			keyPeople.map((item) => (
				<div key={item.profile.uuid} className={styles.key_profile}>
					<KeyPeopleItem
						title={item.profile.name}
						subtitle={item.customRole ?? item.roleDTO.name}
						onDelete={() => handleDelete(item.profile.uuid)}
						image={item.profile?.profileImage?.image}
						showControls={true}
					/>
				</div>
			))
		);
	}, [keyPeople]);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD KEY PEOPLE"
					onClick={() => navigate(routes.addKeyPeople)}
				/>
			</div>

			{keyPeople.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Key People you have added</h4>

					<div
						className={`${styles.key_special_profiles} ${
							keyPeople.length > 2 ? styles.align_left : undefined
						}`}
					>
						{formattedKeyPeople}
					</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>

			{/* <div className={styles.wrapper}>
				<div className={styles.container}>
					<button onClick={() => navigate(routes.addKeyPeople)}>
						+ ADD KEY PEOPLE
					</button>
					{keyPeople.length > 0 && (
						<>
							<div className={styles.header}>
								<label>Key People you have added</label>
							</div>

							<div
								className={`${styles.key_special_profiles} ${
									keyPeople.length > 2
										? styles.align_left
										: undefined
								}`}
							>
								{formattedKeyPeople}
							</div>
						</>
					)}
				</div>
			</div>

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/> */}
		</>
	);
};

export default KeyPeople;
