export const peopleKeys = {
	all: ["people"],
	nearbyPeople: (type, userCoordinates) => [...peopleKeys.all, "nearby", type, userCoordinates],
	interestPeople: (type, userCoordinates) => [
		...peopleKeys.all,
		"interest",
		type,
		userCoordinates,
	],
	networkPeople: (type) => [...peopleKeys.all, "network", type],
	companyPeople: (type) => [...peopleKeys.all, "company", type],
};
