import { useMemo } from "react";
import ProfilePic from "./ProfilePic";
import styles from "./PostHeader.module.css";
import { getDiffIn, getMapsLink } from "../Utils/General";
// import GaawkLink from "../Utils/SubComs/GaawkLink/GaawkLink";
import { Link } from "react-router-dom";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";

const PostHeader = ({
	name,
	img,
	emotion,
	location,
	tags,
	createMode,
	postTime,
	visibility,
	type,
	url,
	onTagClicked,
	onOtherTagsClicked,
	postType,
	boardName,
	icon,
	onThumbnailClick,
}) => {
	const formattedPostType = useMemo(() => {
		switch (postType) {
			case "PROFILE_PIC":
				return " updated their profile picture";

			case "COVER_PIC":
				return " updated their cover picture";

			case "SHARED":
				return " shared a post";

			case "BOARD":
				return `Board > ${boardName}`;

			// case "CUSTOM_ALBUM":
			//             return " TEXT TO BE DONE"; //TODO

			// case "SHARED_ALBUM":
			// 	return " TEXT TO BE DONE"; //TODO

			// case "N_A":
			// return " TEXT TO BE DONE"

			default:
				break;
		}
	}, [postType]);

	const isSystemPost = useMemo(
		() => postType === "PROFILE_PIC" || postType === "COVER_PIC",
		[postType]
	);

	const formattedEmotion = emotion && (
		<span>
			<span>{` is feeling `}</span>
			<span className={styles.emotion_icon}>
				<img src={emotion.icon} alt={emotion.uuid} />
			</span>
			<span>{` ${emotion.name}`}</span>
		</span>
	);

	const formattedLocation = location && (
		<span>
			<span>{` ${
				isSystemPost ? " – " : emotion ? "" : `is `
			} ${location.type.toLowerCase()} `}</span>
			<a
				className={styles.link}
				href={getMapsLink(location)}
				target="_blank"
				rel="noreferrer"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{location.title}
			</a>
		</span>
	);

	const diff = getDiffIn(postTime);

	const formattedTags = tags?.length > 0 && (
		<span>
			<span>{` ${
				emotion || location ? "" : isSystemPost ? " – " : `is `
			} with `}</span>
			{createMode ? (
				<span onClick={onTagClicked} className={styles.tagged_names}>
					{tags[0].name}
				</span>
			) : (
				<Link
					onClick={(e) => e.stopPropagation()}
					to={`/profile/${tags[0].url}`}
					className={styles.tagged_names}
				>
					{tags[0].name}
				</Link>
			)}
			{tags.length > 1 && (
				<span>
					<span>{` and `}</span>
					<span
						onClick={onOtherTagsClicked}
						className={styles.tagged_names}
					>
						{tags.length === 2
							? "other"
							: `${tags.length - 1} others`}
					</span>
				</span>
			)}
		</span>
	);

	return (
		<div
			className={`${styles.post_header} ${
				createMode ? styles.align_center : undefined
			}`}
		>
			<ProfilePic
				name={name}
				thumbnail={img}
				emotion={formattedEmotion}
				location={formattedLocation}
				tags={formattedTags}
				postTagLine={formattedPostType}
				createMode={createMode}
				diff={diff}
				visibility={visibility}
				type={type}
				url={url}
				postType={postType}
				icon={icon}
				iconSize={24}
				onThumbnailClick={onThumbnailClick}
			/>
		</div>
	);
};

export default PostHeader;
