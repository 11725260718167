import { useEffect, useMemo } from "react";
import FileThumbnail from "../FileThumbnail/FileThumbnail";
import styles from "./ThumbnailContainer.module.scss";
import { getFileIcon, getVideoFrame } from "components/Utils/General";
import useUrlPreview from "hooks/useUrlPreview";

// only accepts images and pdfs at the moment
// need to add logic later to handle video thumb and clean the thumb >> //~ done in <MediaEntry />

const ThumbnailContainer = ({ items, onDelete, className }) => {
	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when postMedia changes

		const loadItems = async () => {
			const promises = items.map(async (media) => {
				const isFile = media instanceof Blob;

				if (isFile) {
					if (media.type.startsWith("image")) {
						return URL.createObjectURL(media);
					} else if (media.type.startsWith("video")) {
						const videoFirstFrame = await getVideoFrame(media);
						return videoFirstFrame;
					} else {
						const { icon } = getFileIcon(media, media.name);
						return icon;
					}
				} else {
					const file = media?.file;
					if (file.mediaType === "IMAGE") {
						return file.url;
					} else if (file?.mediaType === "VIDEO") {
						return file.thumbnail;
					} else {
						return (
							file?.fileTypeDTO?.url ||
							getFileIcon(file, file?.originalName).icon
						);
					}
				}
			});

			const resolvedUrls = await Promise.all(promises);
			setObjectUrls(resolvedUrls);
		};

		loadItems();

		// Cleanup function
		return () => {
			// newObjectUrls.forEach((url) => URL.revokeObjectURL(url));
			cleanupObjectUrls();
		};
	}, [items]);

	const handleDeleteThumb = (item, index) => {
		onDelete(item, index);

		//TODO >> not necessary ?
		//TODO >> since we're running the cleanup fn when items are changing, and deleting changes the items array
		// setObjectUrls((prevUrls) => {
		// 	const newUrls = [...prevUrls];
		// 	URL.revokeObjectURL(newUrls[index]);
		// 	newUrls.splice(index, 1);
		// 	return newUrls;
		// });
	};

	const thumbnailList = useMemo(
		() =>
			items.map((item, index) => {
				return (
					<FileThumbnail
						key={index}
						thumbnail={objectUrls[index]}
						isImage={
							item?.type?.startsWith("image") ||
							item?.file?.previewType === "IMAGE"
						}
						onDelete={
							onDelete
								? () => handleDeleteThumb(item, index)
								: undefined
						}
					/>
				);
			}),
		[items, objectUrls]
	);

	return (
		<div className={`${styles.container} ${className}`}>
			{thumbnailList}
		</div>
	);
};

export default ThumbnailContainer;
