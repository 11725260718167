import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
	name: "User",
	initialState: {
		userLogin: {},
		profileInfo: {},
		reqUrl: {},
		reqUser: {},
		// currentPositions: [],
		// searchUserList: [],
	},
	reducers: {
		// userLoginUpdated: (state, action) => {
		//     state.userLogin = action.payload;
		// },
		userLoginUpdated: (state, action) => {
			state.userLogin = { ...state.userLogin, ...action.payload };
		},
		resetUserLogin: (state, action) => {
			state.userLogin = {};
		},

		profileInfoUpdated: (state, action) => {
			state.profileInfo = action.payload;
		},

		bioUpdated: (state, action) => {
			state.profileInfo.bio = action.payload.bio;
		},

		languageUpdated: (state, action) => {
			state.profileInfo.languages = action.payload.languages;
		},

		languageDeleted: (state, action) => {
			const languageId = action.payload.passedData;
			state.profileInfo.languages = state.profileInfo.languages.filter(
				(language) => language.uuid !== languageId
			);
		},

		softwareUpdated: (state, action) => {
			state.profileInfo.softwareList = action.payload.softwareList;
		},

		softwareDeleted: (state, action) => {
			const softwareId = action.payload.passedData;
			state.profileInfo.softwareList =
				state.profileInfo.softwareList.filter(
					(software) => software.uuid !== softwareId
				);
		},

		profileImageUpdated: (state, action) => {
			state.profileInfo.profileImage = action.payload.profileImage;
		},

		coverUpdated: (state, action) => {
			state.profileInfo.profileCover = action.payload.profileCover;
		},

		certificateUpdated: (state, action) => {
			state.profileInfo.certificates = action.payload.certificates;
		},
		certificateFilesUpdated: (state, action) => {
			const { uuid: certificationId } = action.payload;
			const index = state.profileInfo.certificates.findIndex(
				(certificate) => certificate.uuid === certificationId
			);
			if (index > -1) {
				state.profileInfo.certificates[index].files =
					action.payload.files;
			}
		},
		certificateDeleted: (state, action) => {
			const certificateId = action.payload.passedData;
			state.profileInfo.certificates =
				state.profileInfo.certificates.filter(
					(certificate) => certificate.uuid !== certificateId
				);
		},
		updateCalendarState: (state) => {
			state.profileInfo.calendarEnabled =
				!state.profileInfo.calendarEnabled;
		},
		phoneUpdated: (state, action) => {
			state.profileInfo.contacts = action.payload.contacts;
		},
		phoneDeleted: (state, action) => {
			const contactId = action.payload.passedData;
			state.profileInfo.contacts = state.profileInfo.contacts.filter(
				(contact) => contact.uuid !== contactId
			);
		},
		siteUpdated: (state, action) => {
			state.profileInfo.sites = action.payload.sites;
		},
		siteDeleted: (state, action) => {
			const siteId = action.payload.passedData;
			state.profileInfo.sites = state.profileInfo.sites.filter(
				(site) => site.uuid !== siteId
			);
		},
		socialUpdated: (state, action) => {
			state.profileInfo.socials = action.payload.socials;
		},
		socialDeleted: (state, action) => {
			const socialId = action.payload.passedData;
			state.profileInfo.socials = state.profileInfo.socials.filter(
				(social) => social.uuid !== socialId
			);
		},
		addressUpdated: (state, action) => {
			state.profileInfo.addresses = action.payload.addresses;
		},
		addressDeleted: (state, action) => {
			const addressId = action.payload.passedData;
			state.profileInfo.addresses = state.profileInfo.addresses.filter(
				(address) => address.uuid !== addressId
			);
		},
		otherUpdated: (state, action) => {
			state.profileInfo.others = action.payload.others;
		},
		otherDeleted: (state, action) => {
			const otherId = action.payload.passedData;
			state.profileInfo.others = state.profileInfo.others.filter(
				(other) => other.uuid !== otherId
			);
		},
		reqUrl: (state, action) => {
			state.reqUrl = action.payload;
		},
		reqUser: (state, action) => {
			state.reqUser = action.payload;
		},
		resetReqUrl: (state) => {
			state.reqUrl = {};
		},
		resetReqUser: (state) => {
			state.reqUser = {};
		},
		roleUpdated: (state, action) => {
			state.profileInfo.roles = action.payload.roles;
		},
		skillUpdated: (state, action) => {
			state.profileInfo.skills = action.payload.skills;
		},
		skillDeleted: (state, action) => {
			const skillId = action.payload.passedData;
			state.profileInfo.skills = state.profileInfo.skills.filter(
				(skill) => skill.uuid !== skillId
			);
		},
		experienceUpdated: (state, action) => {
			state.profileInfo.experiences = action.payload.experiences;
		},

		educationUpdated: (state, action) => {
			state.profileInfo.educations = action.payload.educations;
		},
		educationFilesUpdated: (state, action) => {
			const { uuid: educationId } = action.payload;
			const index = state.profileInfo.educations.findIndex(
				(education) => education.uuid === educationId
			);
			if (index > -1) {
				state.profileInfo.educations[index].files =
					action.payload.files;
			}
		},
		educationDeleted: (state, action) => {
			const educationId = action.payload.passedData;
			state.profileInfo.educations = state.profileInfo.educations.filter(
				(education) => education.uuid !== educationId
			);
		},
		updateSpecialProfileCounts: (state, action) => {
			state.profileInfo.specialProfileCounts = action.payload;
		},
	},
});

const {
	// userRegistred,
	// otpUpdated,
	// userLoginUpdated,
	// profileInfoUpdated,
	// bioUpdated,
	languageUpdated,
	languageDeleted,
	softwareUpdated,
	softwareDeleted,
	// profileImageUpdated,
	certificateDeleted,
	// coverUpdated,
	phoneUpdated,
	phoneDeleted,
	siteUpdated,
	siteDeleted,
	socialUpdated,
	socialDeleted,
	addressUpdated,
	addressDeleted,
	otherUpdated,
	otherDeleted,
	reqUrl,
	reqUser,
	experienceUpdated,
	educationDeleted,
	searchUserUpdated,
} = slice.actions;

export const {
	userLoginUpdated,
	profileInfoUpdated,
	resetReqUrl,
	resetReqUser,
	educationFilesUpdated,
	certificateFilesUpdated,
	updateCalendarState,
	updateSpecialProfileCounts,
	resetUserLogin,
	educationUpdated,
	certificateUpdated,
	roleUpdated,
	skillUpdated,
	bioUpdated,
	profileImageUpdated,
	coverUpdated,
} = slice.actions;
export default slice.reducer;

/**
 * get the token from the server
 */
export const userLogin = (login, password) =>
	apiActions.apiCallBegan({
		url: `/profile/user/login?login=${login}&password=${password}`,
		method: "POST",
		onSuccess: userLoginUpdated.type,
		skipAuth: true,
	});

/**
 * register user
 */

// export const registerUser = (data) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/register",
// 		method: "POST",
// 		data: data,
// 		onSuccess: userLoginUpdated.type,
// 	});

/**
 * get OTP
 */

// export const getOtp = (data) =>
//   apiActions.apiCallBegan({
//     url: `profile/user/sendOTP?email=${data.email}&mobile=${data.mobile}`,
//     method: "GET",
//     onSuccess: otpUpdated.type,
//   });

export const verifyEmail = () =>
	apiActions.apiCallBegan({
		url: "/profile/user/login/email/verify",
		method: "GET",
		onSuccess: userLoginUpdated.type,
	});

export const updateEmail = (email) =>
	apiActions.apiCallBegan({
		url: `/profile/user/login/email?email=${email}`,
		method: "PUT",
		onSuccess: userLoginUpdated.type,
	});

export const verifyPhone = (countryCode, mobile) =>
	apiActions.apiCallBegan({
		url: `/profile/user/login/mobile?countryCode=${countryCode}&mobile=${mobile}`,
		method: "PUT",
		onSuccess: userLoginUpdated.type,
	});

/**
 * get user info
 */

export const getProfileInfo = (uuid) =>
	apiActions.apiCallBegan({
		url: `/profile/user/get?requestedUserId=${uuid}`,
		method: "GET",
		onSuccess: profileInfoUpdated.type,
	});

/**
 * Language APIs
 * @param languageInfo
 */

export const addLanguage = (languageInfo) =>
	apiActions.apiCallBegan({
		url: "/profile/user/languages",
		method: "POST",
		data: languageInfo,
		onSuccess: languageUpdated.type,
		// showSuccessAlert: true,
	});

export const updateLanguage = (languageInfo) =>
	apiActions.apiCallBegan({
		url: "/profile/user/languages",
		method: "PUT",
		data: languageInfo,
		onSuccess: languageUpdated.type,
		// showSuccessAlert: true,
	});

export const deleteLanguage = (languageId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/languages?userLanguageId=${languageId}`,
		method: "DELETE",
		passedData: languageId,
		onSuccess: languageDeleted.type,
		// showSuccessAlert: true,
	});

/**
 * Software APIs
 */

export const addSoftware = (softwareInfo) =>
	apiActions.apiCallBegan({
		url: "/profile/user/software",
		method: "POST",
		data: softwareInfo,
		onSuccess: softwareUpdated.type,
		// showSuccessAlert: true,
	});

export const updateSoftware = (softwareInfo) =>
	apiActions.apiCallBegan({
		url: "/profile/user/software",
		method: "PUT",
		data: softwareInfo,
		onSuccess: softwareUpdated.type,
		// showSuccessAlert: true,
	});

export const deleteSoftware = (softwareId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/software?softwareId=${softwareId}`,
		method: "DELETE",
		onSuccess: softwareDeleted.type,
		passedData: softwareId,
		// showSuccessAlert: true,
	});

/**
 * Profile Image
 */
// export const updateProfileImage = (formData, imgData) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		// header: uuid,
// 		url: `/profile/user/profileImage?${array.join("&")}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: profileImageUpdated.type,
// 		// showSuccessAlert: true,
// 		contentType: "multipart/form-data",
// 	});
// };

// export const deleteProfileImage = () =>
// 	apiActions.apiCallBegan({
// 		// headerId: uuid,
// 		url: "/profile/user/profileImage",
// 		method: "DELETE",
// 		// showSuccessAlert: true,
// 		onSuccess: profileImageUpdated.type,
// 	});

export const makeTempPermanent = () =>
	apiActions.apiCallBegan({
		url: "/profile/user/profileImage/permTemp",
		method: "PATCH",
		// showSuccessAlert: true,
		onSuccess: profileImageUpdated.type,
	});

export const removeTemp = () =>
	apiActions.apiCallBegan({
		url: "/profile/user/profileImage/removeTemp",
		method: "DELETE",
		// showSuccessAlert: true,
		onSuccess: profileImageUpdated.type,
	});

/**
 * Cover image
 */

// export const updateCoverImage = (formData, imgData) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		// headerId: uuid,
// 		url: `/profile/user/coverImage?${array.join("&")}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: coverUpdated.type,
// 		contentType: "multipart/form-data",
// 		// showSuccessAlert: true,
// 	});
// };
// export const deleteCoverImage = () =>
// 	apiActions.apiCallBegan({
// 		// headerId: uuid,
// 		url: "/profile/user/coverImage",
// 		method: "DELETE",
// 		onSuccess: coverUpdated.type,
// 		// showSuccessAlert: true,
// 	});

/**
 * Courses & Certifications
 */

export const addCertificate = (body) =>
	apiActions.apiCallBegan({
		url: "/profile/user/certificate",
		method: "POST",
		data: body,
		onSuccess: certificateUpdated.type,
		// showSuccessAlert: true,
	});

export const updateCertificate = (body) =>
	apiActions.apiCallBegan({
		url: "/profile/user/certificate",
		method: "PUT",
		data: body,
		onSuccess: certificateUpdated.type,
		// showSuccessAlert: true,
		// headerId: uuid,
		// data: formData,
		// contentType: "multipart/form-data",
	});

export const deleteCertificate = (certificationId) =>
	apiActions.apiCallBegan({
		// headerId: uuid,
		url: `/profile/user/certificate?certificationId=${certificationId}`,
		method: "DELETE",
		passedData: certificationId,
		onSuccess: certificateDeleted.type,
		// showSuccessAlert: true,
	});

export const addCertificateFiles = (formData) =>
	apiActions.apiCallBegan({
		url: "/profile/user/certificate/file",
		method: "POST",
		data: formData,
		onSuccess: certificateUpdated.type,
		contentType: "multipart/form-data",
		// showSuccessAlert: true,
	});

/**
 * Phone
 */

export const addPhone = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/contact",
		method: "POST",
		data: data,
		onSuccess: phoneUpdated.type,
		// showSuccessAlert: true,
	});

export const updatePhone = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/contact",
		method: "PUT",
		data: data,
		onSuccess: phoneUpdated.type,
		// showSuccessAlert: true,
	});

export const deletePhone = (contactId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/contact?contactId=${contactId}`,
		method: "DELETE",
		passedData: contactId,
		onSuccess: phoneDeleted.type,
		// showSuccessAlert: true,
	});

/**
 * Email / Website
 */

export const addSite = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/site",
		method: "POST",
		data: data,
		onSuccess: siteUpdated.type,
		// showSuccessAlert: true,
	});

export const updateSite = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/site",
		method: "PUT",
		data: data,
		onSuccess: siteUpdated.type,
		// showSuccessAlert: true,
	});

export const deleteSite = (siteId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/site?siteId=${siteId}`,
		method: "DELETE",
		passedData: siteId,
		onSuccess: siteDeleted.type,
		// showSuccessAlert: true,
	});

/**
 * Social
 */

export const addSocial = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/social",
		method: "POST",
		data: data,
		onSuccess: socialUpdated.type,
		// showSuccessAlert: true,
	});

export const updateSocial = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/social",
		method: "PUT",
		data: data,
		onSuccess: socialUpdated.type,
		// showSuccessAlert: true,
	});

export const deleteSocial = (userSocialId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/social?userSocialId=${userSocialId}`,
		method: "DELETE",
		passedData: userSocialId,
		onSuccess: socialDeleted.type,
		// showSuccessAlert: true,
	});

/**
 * Addresses
 */

export const addAddress = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/address",
		method: "POST",
		data: data,
		onSuccess: addressUpdated.type,
		// showSuccessAlert: true,
	});

export const updateAddress = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/address",
		method: "PUT",
		data: data,
		onSuccess: addressUpdated.type,
		// showSuccessAlert: true,
	});

export const deleteAddress = (addressId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/address?addressId=${addressId}`,
		method: "DELETE",
		passedData: addressId,
		onSuccess: addressDeleted.type,
		// showSuccessAlert: true,
	});

/**
 * Other Info
 */

export const addOther = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/other",
		method: "POST",
		data,
		onSuccess: otherUpdated.type,
		// showSuccessAlert: true,
	});

export const updateOther = (data) =>
	apiActions.apiCallBegan({
		url: "/profile/user/other",
		method: "PUT",
		data,
		onSuccess: otherUpdated.type,
		// showSuccessAlert: true,
	});

export const deleteOther = (otherId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/other?otherUuid=${otherId}`,
		method: "DELETE",
		passedData: otherId,
		onSuccess: otherDeleted.type,
		// showSuccessAlert: true,
	});

/**
 * Get other users profile
 */

export const getUrlProfile = (url) =>
	apiActions.apiCallBegan({
		url: `/profile/url?url=${url}`,
		method: "GET",
		onSuccess: reqUrl.type,
		onError: reqUrl.type,
	});

export const getUserProfile = (id) =>
	apiActions.apiCallBegan({
		url: `/profile/user/get?requestedUserId=${id}`,
		method: "GET",
		onSuccess: reqUser.type,
		onError: reqUser.type,
	});

/**
 * Get company profile
 */

export const getCompanyProfile = (companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/get?profileId=${companyId}`,
		method: "GET",
		onSuccess: reqUser.type,
		onError: reqUser.type,
	});

/**
 * Experiences
 */

export const addExperience = (experience) =>
	apiActions.apiCallBegan({
		url: "/profile/user/experience",
		method: "POST",
		data: experience,
		onSuccess: experienceUpdated.type,
		// showSuccessAlert: true,
	});

export const editExperience = (experience) =>
	apiActions.apiCallBegan({
		url: "/profile/user/experience",
		method: "PUT",
		data: experience,
		onSuccess: experienceUpdated.type,
		// showSuccessAlert: true,
	});

export const deleteExperience = (experienceId, roleId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/experience?experienceId=${experienceId}&roleId=${roleId}`,
		method: "DELETE",
		onSuccess: experienceUpdated.type,
		// showSuccessAlert: true,
	});

/**
 * Education
 */

export const addEducation = (body) =>
	apiActions.apiCallBegan({
		url: "/profile/user/education",
		method: "POST",
		data: body,
		onSuccess: educationUpdated.type,
		// showSuccessAlert: true,
	});

export const editEducation = (body) =>
	apiActions.apiCallBegan({
		url: "/profile/user/education",
		method: "PUT",
		data: body,
		onSuccess: educationUpdated.type,
		// showSuccessAlert: true,
	});

export const addEducationFiles = (formData) =>
	apiActions.apiCallBegan({
		url: "/profile/user/education/file",
		method: "POST",
		data: formData,
		onSuccess: educationUpdated.type,
		contentType: "multipart/form-data",
		// showSuccessAlert: true,
	});

export const deleteEducation = (educationId) =>
	apiActions.apiCallBegan({
		url: `/profile/user/education?educationId=${educationId}`,
		method: "DELETE",
		passedData: educationId,
		onSuccess: educationDeleted.type,
		// showSuccessAlert: true,
	});

// SEARCH USERS
export const searchUser = (query) =>
	apiActions.apiCallBegan({
		url: `/profile/user/search?q=${query}`,
		method: "GET",
		onSuccess: searchUserUpdated.type,
	});
