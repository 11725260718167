import client from "./client";

const endpoint = "/connection";

// ! Friends ================
const getFriends = (requestedProfileId, page, size, query = "") =>
	client.get(
		endpoint +
			`/friends?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}${
				query && `&q=${query}`
			}`
	);

const getSent = (page, size, query = "") =>
	client.get(
		endpoint + `/friends/pending/sent?page=${page}&size=${size}&q=${query}`
	);

const getReceived = (page, size, query = "") =>
	client.get(
		endpoint +
			`/friends/pending/received?page=${page}&size=${size}&q=${query}`
	);

const sendFriendRequest = (targetProfileId) =>
	client.post(endpoint + `/friends?targetProfileId=${targetProfileId}`);

const revokeFriendRequest = (targetProfileId) =>
	client.delete(
		endpoint + `/friends/cancel?targetProfileId=${targetProfileId}`
	);

const deleteFriend = (targetProfileId) =>
	client.delete(endpoint + `/friends?targetProfileId=${targetProfileId}`);

const acceptFriend = (targetProfileId) =>
	client.patch(
		endpoint + `/friends/accept?targetProfileId=${targetProfileId}`
	);

const declineFriend = (targetProfileId) =>
	client.delete(
		endpoint + `/friends/ignore?targetProfileId=${targetProfileId}`
	);

// ! Work ===================
const getWorkCircle = (requestedProfileId, page, size, query = "") =>
	client.get(
		endpoint +
			`/work/sent?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}&q=${query}`
	);

const getReceivedWorkCircle = (requestedProfileId, page, size, query = "") =>
	client.get(
		endpoint +
			`/work/received?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}&q=${query}`
	);

const addWorkCircle = (targetProfileId) =>
	client.post(endpoint + `/work?targetProfileId=${targetProfileId}`);

const removeWorkCircle = (targetProfileId) =>
	client.delete(endpoint + `/work?targetProfileId=${targetProfileId}`);

// ! Follow =================
const getFollowers = (requestedProfileId, page, size, query = "") =>
	client.get(
		endpoint +
			`/follow/followers?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}${
				query && `&q=${query}`
			}`
	);

const follow = (targetProfileId) =>
	client.post(endpoint + `/follow?targetProfileId=${targetProfileId}`);

const unfollow = (targetProfileId) =>
	client.delete(endpoint + `/follow?targetProfileId=${targetProfileId}`);

const getFollowing = (requestedProfileId, page, size, query = "") =>
	client.get(
		endpoint +
			`/follow/followings?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}&q=${query}`
	);

// ! Blocked ================
const getBlocked = (page, size, query = "") =>
	client.get(
		endpoint + `/blocked?page=${page}&size=${size}${query && `&q=${query}`}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getFriends,
	getSent,
	getReceived,
	sendFriendRequest,
	revokeFriendRequest,
	deleteFriend,
	acceptFriend,
	declineFriend,
	getWorkCircle,
	getReceivedWorkCircle,
	addWorkCircle,
	removeWorkCircle,
	getFollowers,
	getFollowing,
	follow,
	unfollow,
	getBlocked,
};
