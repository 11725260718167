import styles from "./MainHeader.module.scss";
import bellIcon from "images/bell-icon.png";
import arrowIcon from "images/icon-navigate-forward@2x.png";
import { useNavigate } from "react-router-dom";
import ProfilePic from "components/Home/ProfilePic";
import useCompanyId from "hooks/useCompanyId";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import ProfileSwitch from "../BurgerMenu/ProfileSwitch";
import useClickOutside from "hooks/useClickOutside";
import logoutIcon from "images/logout-icon.svg";
import settingIcon from "images/settings-white.png";
import { logout } from "components/Utils/General";
import DynamicLogo from "../DynamicLogo/DynamicLogo";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import Popover from "components/Utils/Popover/Popover";
import NotificationsPopover from "components/Utils/Notifications/NotificationsPopover";
import useFetchNotifications from "hooks/useFetchNotifications";
import { deleteToken, getMessaging } from "firebase/messaging";
import BackButton from "components/Utils/Button/BackButton";
// import { messaging } from "../../../../firebase";

const MainHeader = ({ showBackButton = false, backLabel = "" }) => {
	const popoverRef = useRef(null);

	// console.log(
	// 	"%c user agent >>> ",
	// 	"color: lime; font-weight: bolder",
	// 	navigator.userAgent
	// );

	// console.log("BASE64 USER AGENT >>>", btoa(navigator.userAgent));

	const navigate = useNavigate();
	const companyId = useCompanyId();
	const currentUser = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const menuRef = useRef(null);

	const { type, firstName, lastName, name: companyName } = currentUser;
	const name = type === "USER" ? `${firstName} ${lastName}` : companyName;

	const thumbnail = currentUser?.profileImage?.framedImageURL
		? currentUser.profileImage.framedImageURL
		: currentUser?.profileImage?.originalImageURL
		? currentUser.profileImage.originalImageURL
		: undefined;

	const [menuOpen, setMenuOpen] = useState(false);

	const handleClick = (e) => {
		setMenuOpen((prevState) => !prevState);
	};

	const [notificationsOpen, setNotificationsOpen] = useState(false);

	const {
		query: { data, isFetching },
		viewRef,
		markAllAsRed,
		markAsRead,
		deleteNotification,
	} = useFetchNotifications(notificationsOpen);

	const { token } = useSelector((state) => state.fcmToken);

	const messaging = getMessaging();

	const {
		action: { mutate: logoutUser },
	} = useMutate(profileApi.logout, async () => {
		await deleteToken(messaging);
		logout();
	});

	const handleLogout = () => {
		logoutUser(token || "");
	};

	useClickOutside(menuRef, () => {
		setMenuOpen(false);
	});

	return (
		<div className={styles.header_background}>
			<header className={styles.main_header}>
				{showBackButton ? (
					<div className={styles.back_container}>
						<BackButton onBack={() => navigate(-1)} />
						{backLabel && <p>{backLabel}</p>}
					</div>
				) : (
					<DynamicLogo />
				)}

				<div className={styles.right_side}>
					<Popover
						allow={["bottom"]}
						ref={popoverRef}
						render={
							<NotificationsPopover
								data={data}
								ref={viewRef}
								isLoading={isFetching}
								onClose={popoverRef.current?.closePopover}
								onDelete={deleteNotification}
								onRead={markAsRead}
								onAllRead={markAllAsRed}
							/>
						}
					>
						<button
							onClick={() =>
								setNotificationsOpen((prevState) => !prevState)
							}
							className={styles.small}
						>
							<img
								src={bellIcon}
								className={styles.notification_icon}
								alt="notification"
							/>
						</button>
					</Popover>

					<div ref={menuRef}>
						<button
							onClick={handleClick}
							className={styles.profile_button}
						>
							<div className={styles.name_container}>
								<img
									src={arrowIcon}
									className={styles.arrow_icon}
									alt="arrow"
								/>

								<span className={styles.name}>{name}</span>
							</div>
							<ProfilePic
								w={39}
								h={39}
								type={currentUser.type}
								thumbnail={thumbnail}
								noMarginRight={true}
								name={currentUser.firstName || currentUser.name}
								enableName={false}
							/>
						</button>

						<div
							className={`${styles.menu} ${
								menuOpen ? styles.open : undefined
							} `}
						>
							<ProfileSwitch isOpen={menuOpen} />

							<div className={styles.other}>
								<button onClick={() => navigate("/settings")}>
									<img src={settingIcon} alt="Setting" />
									Settings
								</button>

								<button onClick={handleLogout}>
									<img src={logoutIcon} alt="logout" />
									<span>Sign out</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</header>
		</div>
	);
};

export default MainHeader;
