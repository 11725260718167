import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import styles from "./Filters.module.css";

import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import {
	buildTypes as buildTypesArray,
	hairLength as hairLengthTypes,
	hairStyle as hairStyleTypes,
	eyeHairColor,
} from "../../ProfileEdit/SpecialProfile/special-types";

const PhysicalFilters = forwardRef(
	({ onChange, selectedStaticFilters }, ref) => {
		const didMountRef = useRef(false);

		const buildRef = useRef();
		const eyeColorRef = useRef();
		const hairTypeRef = useRef();
		const hairColorRef = useRef();
		const hairLengthRef = useRef();

		const [physicalFilters, setPhysicalFilters] = useState(
			selectedStaticFilters
		);

		const {
			minHeight = "",
			maxHeight = "",
			minWeight = "",
			maxWeight = "",
			minWaist = "",
			maxWaist = "",
			minChest = "",
			maxChest = "",
			minShoe = "",
			maxShoe = "",
			buildTypes = [],
			eyeColors = [],
			hairTypes = [],
			hairColors = [],
			hairLengths = [],
		} = physicalFilters || {};

		useEffect(() => {
			let timer;
			if (
				didMountRef.current &&
				Object.keys(physicalFilters).length > 0
			) {
				timer = setTimeout(() => {
					onChange(physicalFilters);
				}, 500);
			}
			didMountRef.current = true;
			return () => clearTimeout(timer);
		}, [physicalFilters]);

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "buildTypes":
						buildRef.current.removeItem(tag);
						break;
					case "eyeColors":
						eyeColorRef.current.removeItem(tag);
						break;
					case "hairColors":
						hairColorRef.current.removeItem(tag);
						break;
					case "hairTypes":
						hairTypeRef.current.removeItem(tag);
						break;
					case "hairLengths":
						hairLengthRef.current.removeItem(tag);
						break;
					default:
						break;
				}
			},
			clear() {
				setPhysicalFilters({});
				buildRef.current.clear();
				eyeColorRef.current.clear();
				hairTypeRef.current.clear();
				hairColorRef.current.clear();
				hairLengthRef.current.clear();
			},
		}));

		return (
			<div className={styles.padding_container}>
				<div className={styles.filter_item}>
					<label>Height (cm)</label>
					<div className={styles.inline_input}>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									minHeight: target.value,
								}))
							}
							type="number"
							value={minHeight}
							placeholder="Min"
							min={0}
						/>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									maxHeight: target.value,
								}))
							}
							type="number"
							value={maxHeight}
							placeholder="Max"
							min={0}
						/>
					</div>
				</div>

				<div className={styles.filter_item}>
					<label>Weight (kg)</label>
					<div className={styles.inline_input}>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									minWeight: target.value,
								}))
							}
							type="number"
							value={minWeight}
							placeholder="Min"
							min={0}
						/>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									maxWeight: target.value,
								}))
							}
							type="number"
							value={maxWeight}
							placeholder="Max"
							min={0}
						/>
					</div>
				</div>

				<MultiCheckbox
					ref={buildRef}
					title={"Build"}
					options={buildTypesArray}
					onSelect={(builds) => {
						setPhysicalFilters((prevState) => ({
							...prevState,
							buildTypes: builds,
						}));
					}}
					selected={buildTypes}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				<MultiCheckbox
					ref={eyeColorRef}
					title={"Eyes Color"}
					options={eyeHairColor}
					onSelect={(colors) => {
						setPhysicalFilters((prevState) => ({
							...prevState,
							eyeColors: colors,
						}));
					}}
					selected={eyeColors}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				<MultiCheckbox
					ref={hairTypeRef}
					title={"Hair Type"}
					options={hairStyleTypes}
					onSelect={(types) => {
						setPhysicalFilters((prevState) => ({
							...prevState,
							hairTypes: types,
						}));
					}}
					selected={hairTypes}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				<MultiCheckbox
					ref={hairColorRef}
					title={"Hair Color"}
					options={eyeHairColor}
					onSelect={(colors) => {
						setPhysicalFilters((prevState) => ({
							...prevState,
							hairColors: colors,
						}));
					}}
					selected={hairColors}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				<MultiCheckbox
					ref={hairLengthRef}
					title={"Hair Length"}
					options={hairLengthTypes}
					onSelect={(lengths) => {
						setPhysicalFilters((prevState) => ({
							...prevState,
							hairLengths: lengths,
						}));
					}}
					selected={hairLengths}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				<div className={styles.filter_item}>
					<label>Waist (cm)</label>
					<div className={styles.inline_input}>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									minWaist: target.value,
								}))
							}
							type="number"
							value={minWaist}
							placeholder="Min"
							min={0}
						/>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									maxWaist: target.value,
								}))
							}
							type="number"
							value={maxWaist}
							placeholder="Max"
							min={0}
						/>
					</div>
				</div>

				<div className={styles.filter_item}>
					<label>Chest (cm)</label>
					<div className={styles.inline_input}>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									minChest: target.value,
								}))
							}
							type="number"
							value={minChest}
							placeholder="Min"
							min={0}
						/>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									maxChest: target.value,
								}))
							}
							type="number"
							value={maxChest}
							placeholder="Max"
							min={0}
						/>
					</div>
				</div>

				<div className={styles.filter_item}>
					<label>Shoe Size (Euro Size)</label>
					<div className={styles.inline_input}>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									minShoe: target.value,
								}))
							}
							type="number"
							value={minShoe}
							placeholder="Min"
							min={0}
						/>
						<TextInput
							onChange={({ target }) =>
								setPhysicalFilters((prevstate) => ({
									...prevstate,
									maxShoe: target.value,
								}))
							}
							type="number"
							value={maxShoe}
							placeholder="Max"
							min={0}
						/>
					</div>
				</div>
			</div>
		);
	}
);

export default PhysicalFilters;
