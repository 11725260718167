import { formatFiltersValues } from "components/Utils/General";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import useApi from "hooks/useApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { useMemo } from "react";
import GroupMember from "../../../Chat/GroupMember";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "../../../Utils/SubComs/NoResults/NoResults";
import styles from "./UserSection.module.scss";

const PAGE_SIZE = 40;

const SearchUserSection = ({ searchQueryKey, searchEndpoint }) => {
	const searchPeopleApi = useApi(searchEndpoint, true, true);

	const fetch = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchPeopleApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const { items, isFetchingNextPage, viewRef, isLoading } = useInfiniteScroll({
		queryKey: searchQueryKey,
		queryFn: fetch,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.list?.length === PAGE_SIZE ? pages.length : undefined;
				return nextPage;
			},
		},
	});

	const data = items?.flatMap((page) => page.list);

	const count = items?.[0]?.count;

	const itemsList = useMemo(
		() =>
			data?.map((user, index) => {
				const isLastItem = index === data.length - 1;
				return (
					<div
						key={user.uuid}
						ref={isLastItem ? viewRef : null}
						className={styles.contact_item}
					>
						<GroupMember data={user} redirect={true} />
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</div>
				);
			}),
		[data, viewRef, isFetchingNextPage]
	);

	if (isLoading) return <LoadingSpinner />;

	if (!isLoading && count === 0)
		return <NoResults visible={true} isSearch={true} text={"No results"} />;

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>
				<div className={styles.title_wrapper}>
					<h3>Search Results</h3>
				</div>

				<div>{itemsList}</div>
			</div>
		</div>
	);
};

export default SearchUserSection;
