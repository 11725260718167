import ProfilePic from "components/Home/ProfilePic";
import { useNavigate } from "react-router-dom";
import styles from "./NewJobCard.module.scss";
import { ReactComponent as TickIcon } from "images/tick.svg";
import { ReactComponent as BookmarkIcon } from "images/bookmark.svg";
import { capitalize, getDiffIn, getWorkingLocation, pluralize } from "components/Utils/General";
import Tag from "components/Utils/SubComs/Tags/Tag";
import useCurrentUser from "hooks/useCurrentUser";

const NewJobCard = ({ data, onToggleSave }) => {
	const navigate = useNavigate();

	const { type } = useCurrentUser();

	const {
		jobType,
		castingCallDTO: { gender } = {},
		freelanceJob: { workingLocation: freelanceWorkingLocation } = {},
		longTermJob: { workingLocation } = {},
		commonConnections,
		commonConnectionsCount,
		insertionTime,
		appliedTime,
		savedJob,
		project,
		uuid: jobId,
		minifiedCompany: { name: companyName, profileImage },
		name,
	} = data;

	const handleBookmark = (e) => {
		e.stopPropagation();
		onToggleSave();
	};

	const connectionsList = commonConnections.map((item) => (
		<ProfilePic key={`${jobId}--${item.uuid}`} w={24} h={24} thumbnail={item?.profileImage?.image} noMarginRight={true} type={item.type} enableName={false} name={item.name} letterSize={11} />
	));

	return (
		<div className={styles.container} role={"button"} onClick={() => navigate(`/jobs/${jobId}`)}>
			<ProfilePic thumbnail={profileImage?.image} enableName={false} name={companyName} w={48} h={48} />

			<div className={styles.job_details}>
				<div className={styles.title_wrapper}>
					<h3>{name}</h3>
					{type === "USER" && (
						<button onClick={handleBookmark}>
							<BookmarkIcon height={16} fill={savedJob ? undefined : "transparent"} stroke={savedJob ? undefined : "#000"} strokeWidth={savedJob ? undefined : 2} />
						</button>
					)}
				</div>
				<p className={styles.company_name}>{companyName}</p>
				<div className={styles.job_type}>
					<p>
						<span>{jobType === "Long_Term" ? "Jobs" : jobType === "Casting_Call" ? "Casting Call" : "Freelance"}</span>
						<span>
							{(jobType === "Freelance" || jobType === "Long_Term") && getWorkingLocation(freelanceWorkingLocation || workingLocation)}
							{jobType === "Casting_Call" && capitalize(gender)}
						</span>
						<span>{getDiffIn(insertionTime)}</span>
					</p>
					{appliedTime && (
						<div className={styles.applied}>
							<TickIcon width={12} /> Applied
						</div>
					)}
				</div>

				{commonConnectionsCount > 0 && (
					<div className={styles.connections_wrapper}>
						<div
							className={styles.thumbnail_wrapper}
							style={{
								gridTemplateColumns:
									commonConnectionsCount === 4 || commonConnectionsCount === 5
										? "repeat(3, 1fr)"
										: commonConnectionsCount === 3 || commonConnectionsCount === 2
										? "repeat(2, 1fr)"
										: commonConnectionsCount === 1
										? "repeat(1, 1fr)"
										: undefined,
								marginRight: commonConnectionsCount === 1 || commonConnectionsCount === 3 || commonConnectionsCount === 5 ? "10px" : undefined,
							}}
						>
							{commonConnectionsCount > 5
								? [...connectionsList, <div className={styles.more_profile} key="more">{`+${commonConnectionsCount - commonConnections.length}`}</div>]
								: connectionsList}
						</div>
						<p>{`${commonConnectionsCount} ${pluralize(commonConnectionsCount, "Connection")}`}</p>
					</div>
				)}

				{project && (
					<div className={styles.project_wrapper}>
						<Tag itemName="Project" customStyle={styles.project_tag} />
						<p>{project.title}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default NewJobCard;
