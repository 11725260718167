export const servicesKeys = {
	all: ["services"],

	lists: () => [...servicesKeys.all, "listServices"],

	searchList: () => [...servicesKeys.lists(), "search"],

	search: (userId, query) => [...servicesKeys.searchList(), userId, query],

	serviceLvl: (level, userId, parentId = "", hasProducts = false) =>
		[...servicesKeys.lists(), level, userId, parentId, hasProducts].filter(
			(item) => item !== undefined
		),
	// serviceLvl: (level, userId, parentId = "", hasProducts = false) =>
	// 	[
	// 		...servicesKeys.lists(),
	// 		{ level },
	// 		userId,
	// 		parentId,
	// 		{ hasProducts },
	// 	].filter((item) => item !== undefined),

	details: () => [...servicesKeys.all, "details"],

	infinitMedias: (serviceId) => [
		...servicesKeys.details(),
		"mediasInfinit",
		serviceId,
	],

	countableMedias: (serviceId) => [
		...servicesKeys.details(),
		"mediasCountable",
		serviceId,
	],

	products: (serviceId) => [...servicesKeys.details(), "products", serviceId],

	detail: (serviceId) => [...servicesKeys.details(), serviceId],

	suggested: () => [...servicesKeys.lists(), "suggested"],
};
