import client from "./client";

const endpoint = "/search";

const searchAll = (q = "", locationId) =>
	client.get(endpoint + `/all?q=${q}&locationId=${locationId}`);

//* USERS ===========
const searchUsers = (page, size, request) =>
	client.post(endpoint + `/users?page=${page}&size=${size}`, request);

const usersFilters = (request) => client.post(endpoint + `/users/filter`, request);

const usersDynamicFilters = (type, page, size, body) =>
	client.post(endpoint + `/users/filter/${type}?page=${page}&size=${size}`, body);

//* COMPANIES =======
const searchCompanies = (page, size, request) =>
	client.post(endpoint + `/companies?page=${page}&size=${size}`, request);

const companiesFilters = (request) => client.post(endpoint + `/companies/filter`, request);

const companiesDynamicFilters = (type, page, size, body) =>
	client.post(endpoint + `/companies/filter/${type}?page=${page}&size=${size}`, body);

//* POSTS ===========
const searchPosts = (page, size, request) =>
	client.post(endpoint + `/posts?page=${page}&size=${size}`, request);

// unused for the moment
// const postsFilters = (request) =>
// 	client.post(endpoint + `/posts/filter`, request);

//* PRODUCTS ========
const searchProducts = (page, size, filter, sort = "NEWEST") =>
	client.post(endpoint + `/products?page=${page}&size=${size}&sort=${sort}`, filter);

const productsFilters = (request) => client.post(endpoint + `/products/filters`, request);

const productsDynamicFilters = (type, page, size, body) =>
	client.post(endpoint + `/products/filters/${type}?page=${page}&size=${size}`, body);

// const productsColors = (page, size, request) =>
// 	client.post(
// 		endpoint + `/products/filters/colors?page=${page}&size=${size}`,
// 		request
// 	);

// const productsMaterials = (page, size, request) =>
// 	client.post(
// 		endpoint + `/products/filters/materials?page=${page}&size=${size}`,
// 		request
// 	);

// const productsOwners = (page, size, request) =>
// 	client.post(
// 		endpoint + `/products/filters/owners?page=${page}&size=${size}`,
// 		request
// 	);

// const productsServices = (page, size, request) =>
// 	client.post(
// 		endpoint + `/products/filters/services?page=${page}&size=${size}`,
// 		request
// 	);

//* SERVICES ========
const searchServices = (page, size, filter, numberOfProducts = 0) =>
	client.post(
		endpoint + `/services?page=${page}&size=${size}&numberOfProducts=${numberOfProducts}`,
		filter
	);

const servicesFilters = (request) => client.post(endpoint + `/services/filters`, request);

const servicesCategories = (page, size, request) =>
	client.post(endpoint + `/services/filters/categories?page=${page}&size=${size}`, request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	searchAll,
	searchUsers,
	usersFilters,
	usersDynamicFilters,
	// languageFilters,
	// rolesFilters,
	// skillsFilters,
	// softwareFilters,
	searchCompanies,
	companiesFilters,
	companiesDynamicFilters,
	// companiesIndustries,
	// companiesSpecialties,
	searchPosts,
	// postsFilters,
	searchProducts,
	productsFilters,
	productsDynamicFilters,
	// productsColors,
	// productsMaterials,
	// productsOwners,
	// productsServices,
	searchServices,
	servicesFilters,
	servicesCategories,
};
