import styles from "./UserSection.module.scss";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import GroupMember from "../../../Chat/GroupMember";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";

const PAGE_SIZE = 5;

const UserSection = ({ title, endpoint, queryKey, onViewMore }) => {
	const api = useApi(endpoint, true, true);

	const fetch = async ({ queryKey }) => {
		const [_, __, ___, coordinates] = queryKey;

		const response = await api.request(0, PAGE_SIZE, coordinates?.lat, coordinates?.lng);
		return response.data;
	};

	const { data, isLoading } = useQuery({
		queryKey,
		queryFn: fetch,
	});

	const formattedUsers = useMemo(
		() =>
			data?.map((user) => (
				<div className={styles.contact_item} key={user.uuid}>
					<GroupMember data={user} redirect={true} />
				</div>
			)),
		[data]
	);

	if (data?.length === 0) return null;

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>
				<h3>{title}</h3>

				{isLoading ? <LoadingSpinner /> : <div>{formattedUsers}</div>}

				{!isLoading && (
					<div className={styles.button_wrapper}>
						<SeeMore text="See more" onClick={onViewMore} />
					</div>
				)}
			</div>
		</div>
	);
};

export default UserSection;
