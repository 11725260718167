import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCompanyOther } from "../../../../../store/slices/company";
import { deleteOther } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Other.module.css";
import OtherItem from "./OtherItem";

const Other = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { others, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const [warningDeleteModal, setWarningDeleteModal] = useState(false);
	const [otherId, setOtherId] = useState("");

	const handleOtherDelete = (otherId) => {
		setWarningDeleteModal(true);
		setOtherId(otherId);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanyOther(otherId, companyId)
				: deleteOther(otherId)
		);
		setWarningDeleteModal(false);
	};

	const otherList = others.map((other) => (
		<OtherItem
			key={other.uuid}
			item={other}
			onEditClicked={() =>
				navigate(routes.editOther, {
					state: other,
				})
			}
			onDelete={() => handleOtherDelete(other.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD NEW OTHER INFO"
					onClick={() => navigate(routes.addOther)}
				/>
			</div>

			{others.length > 0 && (
				<div className={`${styles.container} ${styles.list}`}>
					<h4 className={styles.title}>Your other contact info</h4>
					<div className={styles.contact_section}>{otherList}</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Other;
