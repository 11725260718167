import styles from "./HeaderBar.module.css";
// import BurgerMenuIcon from "images/burger-menu-20-x-16.svg";
// import GaawkLogo from "images/gaawk-logo-79-x-25.svg";
// import GaawkLogoWhite from "images/gaawk-logo-white-110-x-35.svg";
// import closeIcon from "images/close-icon-white-20-x-20.svg";
// import BurgerMenu from "../BurgerMenu/BurgerMenu";
// import useClickOutside from "../../../../hooks/useClickOutside";
import useWindowSize from "../../../../hooks/useWindowSize";
import DynamicLogo from "../DynamicLogo/DynamicLogo";

const HeaderBar = ({
	editMode,
	headerLeftContent,
	headerRightContent,
	hasMobileHeader,
	fullBar = false,
}) => {
	// useImperativeHandle(ref, () => ({
	//     openMenu() {
	//         setMenuStatus(true);
	//     },
	// }));

	// const menuRef = useRef(null);
	// const [menuStatus, setMenuStatus] = useState(false);

	// useClickOutside(menuRef, () => setMenuStatus(false));

	const { width } = useWindowSize();
	const isMobile = width < 748;

	return (
		<>
			<header
				style={{ display: "flex" }}
				className={`${styles.header} ${
					editMode ? styles.edit : undefined
				}`}
			>
				<div
					className={`${styles.left_header_wrapper}
                    ${editMode ? styles.sm_hide_left : undefined}
                    ${isMobile && !hasMobileHeader ? styles.show : undefined}`}
				>
					{/* <div ref={menuRef}>
                            {!menuStatus && (
                                <button onClick={() => setMenuStatus(true)}>
                                    <img src={BurgerMenuIcon} alt={"Menu"} />
                                </button>
                            )}
                            {menuStatus && (
                                <button onClick={() => setMenuStatus(false)}>
                                    <img src={closeIcon} alt={"close"} />
                                </button>
                            )}

                            <BurgerMenu
                                isOpen={menuStatus}
                                onClose={() => setMenuStatus(false)}
                            />

                            <button
                                className={styles.logo_button}
                                onClick={() => history.push("/")}
                            >
                                <img
                                    src={editMode ? GaawkLogoWhite : GaawkLogo}
                                    alt={"Gaawk"}
                                />
                            </button>
                        </div> */}

					{/* <button
                            className={styles.logo_button}
                            onClick={() => history.push("/")}
                        >
                            <img
                                src={editMode ? GaawkLogoWhite : GaawkLogo}
                                alt={"Gaawk"}
                            />
                        </button> */}

					<DynamicLogo />

					{headerLeftContent && headerLeftContent}
				</div>

				<div
					className={`${styles.right_header_wrapper}
                    ${isMobile && !hasMobileHeader ? styles.hide : styles.show}
                    ${fullBar ? styles.full_bar : undefined}
                    `}
				>
					{headerRightContent && headerRightContent}
				</div>
			</header>
		</>
	);
};

export default HeaderBar;
