export const connectionsKeys = {
	all: ["connections"],

	lists: () => [...connectionsKeys.all, "list"],

	//* types options: "sent" / "received"
	requests: (type, query = "") => [...connectionsKeys.lists(), type, query],

	list: (profileId, type, query) => [
		...connectionsKeys.lists(),
		profileId,
		type,
		query,
	],

	recent: () => [...connectionsKeys.lists(), "recent"],

	count: (profileId) => [...connectionsKeys.all, profileId, "count"],

	// searchList: () => [...servicesKeys.lists(), "search"],

	// search: (userId, query) => [...servicesKeys.searchList(), userId, query],

	// serviceLvl: (level, userId, parentId) =>
	//     [...servicesKeys.lists(), { level }, userId, parentId].filter(
	//         (item) => item !== undefined
	//     ),

	// details: () => [...servicesKeys.all, "details"],

	// medias: () => [...servicesKeys.details(), "medias"],

	// infinitMedias: (serviceId) => [
	//     ...servicesKeys.details(),
	//     "mediasInfinit",
	//     serviceId,
	// ],

	// countableMedias: (serviceId) => [
	//     ...servicesKeys.details(),
	//     "mediasCountable",
	//     serviceId,
	// ],

	// products: (serviceId) => [...servicesKeys.details(), "products", serviceId],

	// detail: (serviceId) => [...servicesKeys.details(), serviceId],
};
