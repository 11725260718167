import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteAddress } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Address.module.css";
import AddressItem from "./AddressItem";
import { deleteCompanyAddress } from "../../../../../store/slices/company";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import routes from "components/Routing/routing-keys";

const Address = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { addresses, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const [addressId, setAddressId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const handleDelete = (addressId) => {
		setAddressId(addressId);
		setWarningDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanyAddress(addressId, companyId)
				: deleteAddress(addressId)
		);
		setWarningDeleteModal(false);
	};

	const addressesList = addresses.map((address) => (
		<AddressItem
			key={address.uuid}
			item={address}
			onEditClicked={() =>
				navigate(routes.editAddress, {
					state: address,
				})
			}
			onDelete={() => handleDelete(address.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW ADDRESS"
					onClick={() => navigate(routes.addAddress)}
				/>
			</div>

			{addresses.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Addresses</h4>
					<div className={styles.contact_section}>
						{addressesList}
					</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Address;
