import { useEffect, useState } from "react";
import styles from "./Companies.module.scss";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import UserSection from "../MoreComponents/UsersComponents/UserSection";
import profileApi from "../../../api/profile";
import SearchUserSection from "../MoreComponents/UsersComponents/SearchUserSection";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import searchApi from "api/search";
import routes from "components/Routing/routing-keys";
import { searchKeys } from "queryKeys/search-key-factory";
import { useSelector } from "react-redux";
import { companiesKeys } from "queryKeys/companies-key-factory";

const Companies = ({ userCoordinates }) => {
	const navigate = useNavigate();

	//! =========== SEARCH INPUT HANDLER =============

	const [searchParams, setSearchParams] = useSearchParams({ q: "" });

	const [searchInput, setSearchInput] = useState(searchParams.get("q"));

	const debouncedSearch = useDebounce(searchInput);

	useEffect(() => {
		setSearchParams(
			(prev) => {
				prev.set("q", debouncedSearch);
				return prev;
			},
			{ replace: true }
		);
	}, [debouncedSearch]);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	// !==========

	const companiesFiltersObject = useSelector((state) => state.filters.companiesFilters);

	const storedCompaniesFilters = {
		...companiesFiltersObject,
		q: debouncedSearch,
		locationIdForSort: userCoordinates.id,
	};

	return (
		<div className={styles.container}>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={() => setSearchInput("")}
					border={false}
					placeholder={"Search Companies"}
					classNameInput={styles.search_input}
				/>
			</div>

			{searchInput ? (
				<SearchUserSection
					searchQueryKey={searchKeys.companies(storedCompaniesFilters)}
					searchEndpoint={searchApi.searchCompanies}
				/>
			) : (
				<>
					<UserSection
						title="Companies you may know"
						queryKey={companiesKeys.networkCompanies("static")}
						endpoint={profileApi.getNetworkCompanies}
						onViewMore={() => navigate(routes.companiesNetwork)}
					/>

					<UserSection
						title="Companies hiring now"
						queryKey={companiesKeys.hiringCompanies("static", userCoordinates)}
						endpoint={profileApi.getHiringCompanies}
						onViewMore={() => navigate(routes.companiesHiring)}
					/>

					<UserSection
						title="Companies near you"
						queryKey={companiesKeys.nearbyCompanies("static", userCoordinates)}
						endpoint={profileApi.getNearbyCompanies}
						onViewMore={() => navigate(routes.companiesNearby)}
					/>
				</>
			)}
		</div>
	);
};

export default Companies;
