import { useSelector } from "react-redux";
import useCompanyId from "./useCompanyId";

const useCurrentUser = () => {
	const companyId = useCompanyId();

	const currentUser = useSelector((state) =>
		companyId ? state.company.companyInfo : state.user.profileInfo
	);

	return currentUser;
};

export default useCurrentUser;
