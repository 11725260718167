const routes = {
	root: "/",
	login: "/login",
	profile: (url = "") => `/profile/${url}`,
	editProfile: `/profile/edit`,
	specialProfilesList: (pathname) => `/profile/sp${pathname ? `${pathname}` : ""}`,
	specialProfile: (url = "", pathname) => `/sp/${url}${pathname ? `${pathname}` : ""}`,
	serviceDetails: (serviceId) => `/services/${serviceId}`,
	post: (postId, subPostId = undefined) => `/post/${postId}${subPostId ? `/${subPostId}` : ""}`,
	postsByHashtag: (hashtag) => `/posts?hashtag=${hashtag}`,
	album: (albumId) => `/profile/album/${albumId}`,
	boards: "/boards",
	board: (boardId) => `/boards/${boardId}`,
	manageBoards: "/boards/manage",
	job: (jobId) => `/jobs/${jobId}`,
	jobProject: (projectId) => `/jobs/project/${projectId}`,
	jobs: "/jobs",
	jobApplicants: (jobId) => `/jobs/${jobId}/applicants`,
	jobCreate: "/jobs/create",
	addProject: "/jobs/project/add",
	allProjects: "/jobs/projects",
	project: (projectId) => `/jobs/project/${projectId}`,
	projectEdit: (projectId) => `/jobs/project/${projectId}/edit`,
	keyPeople: "/profile/key-people",
	addKeyPeople: "/profile/key-people/add",
	storage: "/storage",
	plans: "/plans",
	subscribe: (query = undefined) => `/plans/subscribe${query ?? ""}`,
	payment: "/plans/payment",
	invoice: "/plans/invoice",
	jobEdit: (jobId) => `/jobs/${jobId}/edit`,
	jobApply: (jobId) => `/jobs/${jobId}/apply`,
	applicant: (jobId, applicantId) => `/jobs/${jobId}/applicants/${applicantId}`,
	applicationConfirm: (jobId) => `/jobs/${jobId}/confirmation`,

	experience: "/profile/experience",
	addExperience: "/profile/experience/add",
	editExperience: "/profile/experience/edit",
	certificate: "/profile/certificate",
	addCertificate: "/profile/certificate/add",
	editCertificate: "/profile/certificate/edit",
	language: "/profile/language",
	addLanguage: "/profile/language/add",
	editLanguage: "/profile/language/edit",
	software: "/profile/software",
	addSoftware: "/profile/software/add",
	editSoftware: "/profile/software/edit",
	education: "/profile/education",
	addEducation: "/profile/education/add",
	editEducation: "/profile/education/edit",
	phone: "/profile/contact",
	addPhone: "/profile/contact/add",
	editPhone: "/profile/contact/edit",
	email: "/profile/email",
	addEmail: "/profile/email/add",
	editEmail: "/profile/email/edit",
	social: "/profile/social",
	addSocial: "/profile/social/add",
	editSocial: "/profile/social/edit",
	address: "/profile/address",
	addAddress: "/profile/address/add",
	editAddress: "/profile/address/edit",
	other: "/profile/other",
	addOther: "/profile/other/add",
	editOther: "/profile/other/edit",

	peopleNetwork: "/people/network",
	peopleNearby: "/people/nearby",
	peopleInMyCompany: "/people/my-company",
	peopleSimilar: "/people/similar",

	companiesNetwork: "/companies/network",
	companiesHiring: "/companies/hiring",
	companiesNearby: "/companies/nearby",

	//TODO: ADD ROUTES >>

	// /jobs/${jobId}/review  //! ROUTE NO LONGER NEEDED
	// /jobs/all //! ROUTE NO LONGER NEEDED

	// /jobs/${jobId}/confirmation

	// /jobs/saved  >> user only?
	// /jobs/applied >> user only?
	// /jobs/results
	// /jobs/agency
	// /jobs/talents
	// /jobs/filter

	// /profile/calendar/add
	// /profile/calendar/edit
	// /profile/calendar

	vaultFolder: (vaultId) => `/vault/${vaultId}`,
	vaultItem: (itemId) => `/vault/item/${itemId}`,
	updateWorkStatus: "/profile/workstatus",
	companies: "/companies",
	chat: "/chat",
	help: "/help",
	about: "/about",
	privacy: "/privacy-policy",
	terms: "/terms",
	faq: "/faq",
	evoServices: "https://www.evolutionservices.com/",

	myCompanies: "/my-companies",
	addCompany: "/my-companies/add",
};

export default routes;
