import ScrollContainer from "react-indiana-drag-scroll";
import styles from "../ScrollableMenu/ProfileScrollableMenu.module.css";

const MenuItem = ({ isActive, onClick, children }) => (
	<div className={styles.col}>
		<span className={isActive ? styles.active : ""} onClick={onClick}>
			{children}
		</span>
	</div>
);

const ConnectionScrollableMenu = ({
	activeTab,
	onItemClicked,
	canEdit,
	profile,
}) => {
	const { type, firstName, name } = profile || {};
	const shortName = type === "USER" ? firstName : name;

	const menuItems = [
		{ id: "friends", label: "Friends", alwaysShow: true },
		{ id: "pendingSent", label: "Sent Requests", alwaysShow: false },
		{ id: "pendingReceived", label: "Pending Requests", alwaysShow: false },
		{
			id: "yourWorkCircle",
			label: canEdit ? "Your work circle" : "Their work circle",
			alwaysShow: true,
		},
		{
			id: "theirWorkCircle",
			label: canEdit
				? "Work circles you're in"
				: `Work circles ${shortName} is in`,
			alwaysShow: true,
		},
		{
			id: "following",
			label: canEdit ? "People you follow" : "Following",
			alwaysShow: true,
		},
		{
			id: "followers",
			label: canEdit ? "People who follow you" : "Followers",
			alwaysShow: true,
		},
		{ id: "favorites", label: "Favorites", alwaysShow: false },
		{ id: "blocked", label: "Blocked", alwaysShow: false },
	];

	return (
		<ScrollContainer className={styles.scroll_container}>
			<section className={styles.tiles}>
				{menuItems.map(
					({ id, label, alwaysShow }) =>
						(alwaysShow || canEdit) && (
							<MenuItem
								key={id}
								isActive={activeTab === id}
								onClick={() => onItemClicked(id)}
							>
								{label}
							</MenuItem>
						)
				)}
			</section>
		</ScrollContainer>
	);
};

export default ConnectionScrollableMenu;
