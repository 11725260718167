import Checkbox from "components/Utils/SubComs/Inputs/Checkbox/Checkbox";
import useGetQueryParam from "hooks/useGetQueryParam";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPostsFilters, updatePostsFilters } from "store/slices/filters";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import ConnectionFilter from "./ConnectionFilter";
import styles from "./PeopleFilter.module.scss";

const PostsFilter = ({ userCoordinates, companyId }) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const dispatch = useDispatch();

	const postsFiltersObject = useSelector((state) => state.filters.postsFilters);

	const storedPostsFilters = {
		...postsFiltersObject,
		q: inputValue,
		locationIdForSort: userCoordinates.id,
	};

	useEffect(() => {
		return () => {
			//CLEARS COMPANIES SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetPostsFilters());
		};
	}, []);

	const {
		includeMedia,
		friends = false,
		inMyCircle = false,
		inTheirCircle = false,
		fromDate = null,
		toDate = null,
	} = storedPostsFilters || {};

	return (
		<div className={styles.container}>
			<div className={styles.datePickerContainer}>
				{/* <label>Date</label> */}
				<div className={styles.datePickerWrapper}>
					<div className={styles.datePickerItem}>
						<label>From</label>
						<DayPicker
							className={`form-start-date`}
							onChange={(startDate) =>
								dispatch(
									updatePostsFilters({
										fromDate: startDate?.getTime(),
									})
								)
							}
							value={fromDate && new Date(fromDate)}
							isClearable={true}
						/>
					</div>
					<div className={styles.datePickerItem}>
						<label>To</label>
						<DayPicker
							className={`form-end-date`}
							onChange={(endDate) =>
								dispatch(
									updatePostsFilters({
										toDate: endDate?.getTime(),
									})
								)
							}
							value={toDate && new Date(toDate)}
							isClearable={true}
						/>
					</div>
				</div>
			</div>

			<div className={styles.agency_wrapper}>
				<h3>Media</h3>
				<div className={styles.agency_checkbox}>
					<Checkbox
						className={styles.agency_checkbox}
						w="14px"
						h="14px"
						checked={includeMedia}
						onChange={() =>
							dispatch(
								updatePostsFilters({
									includeMedia: !includeMedia,
								})
							)
						}
						name={"media"}
						customBackgroundColor={"gaawk"}
					/>
					<span
						onClick={() =>
							dispatch(
								updatePostsFilters({
									includeMedia: !includeMedia,
								})
							)
						}
					>
						Media Posts Only
					</span>
				</div>
			</div>

			<ConnectionFilter
				friends={friends}
				friendLabel="From my friends"
				onFriendChange={
					!companyId &&
					((value) =>
						dispatch(
							updatePostsFilters({
								friends: value,
							})
						))
				}
				inMyCircle={inMyCircle}
				myCircleLabel="From my work circle"
				onMyWorkChange={(value) =>
					dispatch(
						updatePostsFilters({
							inMyCircle: value,
						})
					)
				}
				inTheirCircle={inTheirCircle}
				theirCircleLabel="From accounts who added me to their work circle"
				onTheirWorkChange={(value) =>
					dispatch(
						updatePostsFilters({
							inTheirCircle: value,
						})
					)
				}
			/>

			<div className={styles.button_container}>
				<PrimaryButton
					type="button"
					text={"clear filters"}
					className={`${styles.btn} ${styles.clear}`}
					onClick={() => dispatch(resetPostsFilters())}
				/>
			</div>
		</div>
	);
};

export default PostsFilter;
