import BecomeAgency from "components/Companies/BecomeAgency";
import CompanyManage from "components/Companies/CompanyManage";
import CompanyTransfer from "components/Companies/CompanyTransfer";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import BackButton from "components/Utils/Button/BackButton";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../views/Header.module.css";
import useFetchProfile from "hooks/useFetchProfile";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import useCurrentUser from "hooks/useCurrentUser";

const CompanyManageRoute = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { uuid } = useCurrentUser();

	const {
		profileData: companyProfile,
		isFetching,
		isFetchingUrl,
	} = useFetchProfile();

	const isLoadingProfile = isFetching || isFetchingUrl;

	const {
		type,
		companyUserType,
		uuid: companyId,
		agency,
	} = companyProfile || {};

	const isNotCompany = type !== "COMPANY";
	const isNotCorrectCompany = uuid !== companyId;
	const isNotOwner = companyUserType !== "OWNER";
	const isNotAdmin = companyUserType !== "ADMIN";

	const isUnauthorized =
		isNotCompany ||
		isNotCorrectCompany ||
		(pathname.endsWith("/manage") ? isNotOwner && isNotAdmin : isNotOwner);

	let component;
	let headerTitle;

	switch (true) {
		case pathname.endsWith("/manage/transfer"):
			headerTitle = "Transfer Company";
			component = <CompanyTransfer companyProfile={companyProfile} />;
			break;

		case pathname.endsWith("/manage/agency"):
			headerTitle = agency ? "Agency Cancellation" : "Become an Agency";
			component = <BecomeAgency companyProfile={companyProfile} />;
			break;

		case pathname.endsWith("/manage"):
			headerTitle = "Manage Company";
			component = (
				<CompanyManage
					isLoadingProfile={isLoadingProfile}
					companyProfile={companyProfile}
				/>
			);
			break;

		default:
			break;
	}

	if (!isLoadingProfile && isUnauthorized) {
		throw new Response("", {
			statusText: "You are not authorized to access this page",
			status: 401,
		});
	}

	return (
		<DefaultPage
			rightSideContainerColor={"#FFF"}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideBgColor={"#FFFFFF"}
			rightSideChildren={isLoadingProfile ? <LoadingPage /> : component}
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> {headerTitle}
				</div>
			}
			defaultHeader={false}
		/>
	);
};

export default CompanyManageRoute;
