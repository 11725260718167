import { useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import CompanyItem from "components/Companies/CompanyItem";
import routes from "components/Routing/routing-keys";
import BackButton from "components/Utils/Button/BackButton";
import CtaButton from "components/Utils/Button/CtaButton";
import EditButton from "components/Utils/Button/EditButton";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import {
	capitalize,
	companySizeList,
	featuresSection,
} from "components/Utils/General";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { format } from "date-fns";
import useCurrentUser from "hooks/useCurrentUser";
import useJob from "hooks/useJob";
import useMutate from "hooks/useMutate";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonConnections from "../JobsComponents/CommonConnections";
import JobDates from "../JobsComponents/JobDates/JobDates";
import JobInfo from "../JobsComponents/JobInfo/JobInfo";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import JobLocation from "../JobsComponents/JobLocation";
import JobStatus from "../JobsComponents/JobStatus/JobStatus";
import styles from "./NewJobDetails.module.scss";
import { useInView } from "react-intersection-observer";
import useApi from "hooks/useApi";
import usePaginatedResource from "hooks/usePaginatedResource";
import NewJobCard from "../JobsComponents/NewJobCard/NewJobCard";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import ProjectCard from "../JobsComponents/ProjectCard/ProjectCard";
import JobActions from "../JobsComponents/JobActions/JobActions";
import { ReactComponent as TickIcon } from "images/tick.svg";
import { ReactComponent as MinusIcon } from "images/minus.svg";
import FormatSpecialText from "components/Utils/SubComs/FormatSpecialText/FormatSpecialText";

const itemsPerPage = 20;

const NewJobDetails = () => {
	const navigate = useNavigate();
	const { uuid, type } = useCurrentUser();
	const { jobId } = useParams();
	const { data: job, isFetching } = useJob(jobId);

	const [invalidate, setInvalidate] = useState("");

	const {
		closed,
		insertionTime,
		description,
		name,
		minifiedCompany,
		minifiedCompany: {
			uuid: companyId,
			locationDTO,
			size,
			bio,
			// industry //TODO >> not coming from backend at the moment
		} = {},
		jobType,
		requirements,
		castingCallDTO,
		freelanceJob,
		longTermJob,
		skills,
		location,
		commonConnections,
		commonConnectionsCount,
		compensation,
		currency,
		frequency,
		project,
		seenTime,
		appliedTime,
	} = job || {};

	const jobData = castingCallDTO || freelanceJob || longTermJob;

	const isJobOwner = uuid === companyId;

	// !------- toggle job status ---------
	const queryClient = useQueryClient();

	const {
		action: { mutate: toggleStatus },
	} = useMutate(jobApi.updateJobStatus, () => {
		queryClient.invalidateQueries(jobsKeys.detail(jobId));
	});
	// !----------- skills list -----------

	const skillsList = useMemo(
		() => skills?.map((tag) => <Tag key={tag.uuid} itemName={tag.name} />),
		[skills]
	);

	// !------- show date condition -------
	const showDateBlock =
		(jobType === "Freelance" &&
			freelanceJob.startDate > 0 &&
			freelanceJob.endDate > 0) ||
		(jobType === "Casting_Call" && castingCallDTO.dateTime > 0);

	// !------- Other company's jobs -------

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getJobsApi = useApi(jobApi.getJobByCompanyId, true, true);

	const fetchJobs = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, companyId, jobType, closed, excludeId, projectId] =
			queryKey;

		const response = await getJobsApi.request(
			companyId,
			pageParam,
			itemsPerPage,
			jobType,
			closed,
			excludeId,
			projectId
		);
		return response.data;
	};

	const {
		data: otherJobs,
		isFetching: isFetchingOtherJobs,
		hasNextPage,
		fetchNextPage,
	} = usePaginatedResource(
		jobsKeys.jobsByCompany(companyId, "", false, jobId),
		fetchJobs,
		itemsPerPage,
		!!job
	);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage]);

	const otherJobsList = useMemo(
		() =>
			otherJobs?.pages?.map((page) =>
				page.map((job, i) => (
					<div
						key={job.uuid}
						ref={page.length === i + 1 ? viewRef : null}
					>
						<NewJobCard
							data={job}
							onToggleSave={() => {
								setInvalidate("other");
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								});
							}}
						/>
					</div>
				))
			),
		[otherJobs, viewRef]
	);

	// !------- similar  jobs -------

	const { ref: viewRefSimilar, inView: similarInView } = useInView({
		triggerOnce: true,
	});

	const getSimilarJobsApi = useApi(jobApi.getSimilarJobs, true, true);

	const fetchSimilarJobs = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, ___, jobId] = queryKey;

		const response = await getSimilarJobsApi.request(
			jobId,
			pageParam,
			itemsPerPage
		);
		return response.data;
	};

	const {
		data: similarJobs,
		isFetching: isFetchingSimilarJobs,
		hasNextPage: hasNextPageSimilar,
		fetchNextPage: fetchNextPageSimilar,
	} = usePaginatedResource(
		jobsKeys.similarJobs(jobId),
		fetchSimilarJobs,
		itemsPerPage,
		!!job
	);

	useEffect(() => {
		if (similarInView && hasNextPageSimilar) {
			fetchNextPageSimilar();
		}
	}, [similarInView, hasNextPageSimilar]);

	const similarJobsList = useMemo(
		() =>
			similarJobs?.pages?.map((page) =>
				page.map((job, i) => (
					<div
						key={job.uuid}
						ref={page.length === i + 1 ? viewRefSimilar : null}
					>
						<NewJobCard
							data={job}
							onToggleSave={() => {
								setInvalidate("similar");
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
								});
							}}
						/>
					</div>
				))
			),
		[similarJobs, viewRefSimilar]
	);

	//  !=============

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, () => {
		if (invalidate === "similar") {
			queryClient.invalidateQueries(jobsKeys.similarJobs(jobId));
		} else {
			queryClient.invalidateQueries(
				jobsKeys.jobsByCompany(companyId, "", false, jobId)
			);
		}
		setInvalidate("");
	});

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header}>
						<BackButton onBack={() => navigate(-1)} />
						<p>{name}</p>
					</div>

					{!isFetching && <JobActions job={job} />}
				</>
			}
			defaultHeader={false}
			leftSideChildren={<FeaturesSidebar active={featuresSection.jobs} />}
			rightSideChildren={
				isFetching ? (
					<LoadingPage />
				) : (
					<div className={styles.container}>
						<JobStatus
							canEdit={isJobOwner}
							active={!closed}
							onToggleStatus={() =>
								toggleStatus({
									jobId,
									status: closed,
								})
							}
						/>

						<div className={styles.company_wrapper}>
							<CompanyItem
								item={minifiedCompany}
								subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
							/>
							{isJobOwner && (
								<EditButton
									onClick={() =>
										navigate(routes.jobEdit(jobId))
									}
								/>
							)}
						</div>

						<JobInfo jobType={jobType} jobData={jobData} />

						<div className={styles.date_container}>
							<p className={styles.important_info}>
								Posted on{" "}
								<span>
									{format(
										new Date(insertionTime),
										"dd/MM/yyyy"
									)}
								</span>
							</p>
							<hr />
						</div>

						<div className={styles.other_info_container}>
							{jobType !== "Casting_Call" && (
								<div className={styles.button_container}>
									{isJobOwner ? (
										<CtaButton
											text={"View Applicants"}
											onClick={() =>
												navigate(
													routes.jobApplicants(jobId)
												)
											}
											className={styles.cta_btn}
										/>
									) : appliedTime > 0 ? (
										<div
											className={styles.application_info}
										>
											<div>
												<TickIcon width={12} /> Applied
												on:{" "}
												{format(
													new Date(appliedTime),
													"dd/MM/yyyy"
												)}
											</div>
											<div>
												{seenTime > 0 ? (
													<TickIcon width={12} />
												) : (
													<MinusIcon width={12} />
												)}
												Application viewed:{" "}
												{seenTime > 0
													? format(
															new Date(seenTime),
															"dd/MM/yyyy"
													  )
													: "-"}
											</div>
										</div>
									) : (
										type === "USER" && (
											<PrimaryButton
												type={"button"}
												text={"Apply"}
												onClick={() =>
													navigate(
														routes.jobApply(jobId)
													)
												}
												className={styles.apply_btn}
											/>
										)
									)}
								</div>
							)}

							{project && (
								<ProjectCard
									project={project}
									showActions={false}
								/>
							)}

							{showDateBlock && (
								<JobInfoItem
									title={"Job Dates"}
									content={
										jobType === "Freelance" ? (
											<JobDates
												startDate={
													freelanceJob.startDate
												}
												endDate={freelanceJob.endDate}
											/>
										) : (
											<p
												className={
													styles.important_info
												}
											>
												On:{" "}
												<span>
													{format(
														new Date(
															castingCallDTO.dateTime
														),
														"dd/MM/yyyy"
													)}
												</span>{" "}
												- Starting:{" "}
												<span>
													{format(
														new Date(
															castingCallDTO.dateTime
														),
														"h:mm aaa"
													)}
												</span>
											</p>
										)
									}
								/>
							)}

							{description && (
								<JobInfoItem
									title={"Job Description"}
									content={
										<FormatSpecialText text={description} />
									}
								/>
							)}

							{requirements && (
								<JobInfoItem
									title={"Requirements"}
									content={
										<FormatSpecialText
											text={requirements}
										/>
									}
								/>
							)}

							{skills.length > 0 && (
								<JobInfoItem
									title={"Skills"}
									content={
										<div className={styles.tags_container}>
											{skillsList}
										</div>
									}
								/>
							)}

							<JobLocation location={location} />

							{compensation > 0 && (
								<JobInfoItem
									title={"We offer"}
									content={
										<p className={styles.important_info}>
											Salary:{" "}
											<span>
												{`${
													currency.key
												} ${compensation} ${capitalize(
													frequency
												)}`}
											</span>
										</p>
									}
								/>
							)}

							<JobInfoItem
								title={"About the company"}
								content={
									<>
										<CompanyItem
											item={minifiedCompany}
											subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
										/>
										<div className={styles.company_stats}>
											{/* <p>Industry: </p> */}
											<p>
												Company Size:{" "}
												{
													companySizeList.find(
														(item) =>
															item.value === size
													).label
												}
											</p>
										</div>
										<p>{bio}</p>
									</>
								}
							/>

							{commonConnections?.length > 0 && (
								<CommonConnections
									count={commonConnectionsCount}
									data={commonConnections}
									company={minifiedCompany}
								/>
							)}

							{otherJobs?.pages?.[0]?.length > 0 && (
								<JobInfoItem
									title={"Other jobs by this company"}
									content={
										<div className={styles.jobs_wrapper}>
											{otherJobsList}
											<LoadingSpinner
												visible={isFetchingOtherJobs}
											/>
										</div>
									}
								/>
							)}

							{similarJobs?.pages?.[0]?.length > 0 && (
								<JobInfoItem
									title={"Similar Jobs"}
									content={
										<div className={styles.jobs_wrapper}>
											{similarJobsList}
											<LoadingSpinner
												visible={isFetchingSimilarJobs}
											/>
										</div>
									}
								/>
							)}
						</div>
					</div>
				)
			}
			rightSideContainerColor={"#FFF"}
		/>
	);
};

export default NewJobDetails;
