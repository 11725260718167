import React from "react";
import styles from "./LeftSideMsg.module.css";

import contactIcon from "images/contact-icon.svg";
import attachIcon from "images/icon-attach.svg";
import photoIcon from "images/photo-icon.svg";
import videoIcon from "images/video-icon.svg";
import binIcon from "images/bin-icon-gray.svg";
import locationIcon from "images/location_icon.svg";
// import micIcon from '../../images/mic-grey.svg'

const LeftSideMsg = ({ message }) => {
	const {
		message: msg,
		attachedProfile,
		media,
		deleted,
		location,
	} = message || {};

	let msgContent;
	let msgIcon;

	if (msg) {
		msgContent = msg;
	} else {
		if (deleted) {
			msgContent = "This message has been deleted";
		} else if (attachedProfile) {
			msgContent = "Profile";
		} else if (media && media === "LOADING") {
			msgContent = "";
		} else if (media && media.file.previewType === "IMAGE") {
			msgContent = "Image";
		} else if (media && media.file.previewType === "VIDEO") {
			msgContent = "Video";
		} else if (
			media &&
			media.file.previewType !== "IMAGE" &&
			media.file.previewType !== "VIDEO"
		) {
			msgContent = `${media.file.extension} File`;
		} else if (location) {
			msgContent = "Location";
		} else {
			msgContent = "";
		}
		// TODO: need to handle voicenote later
	}

	if (deleted) {
		msgIcon = <img src={binIcon} alt="deleted" />;
	} else if (attachedProfile) {
		msgIcon = <img src={contactIcon} alt="contact" />;
	} else if (media && media === "LOADING") {
		msgContent = "";
	} else if (media && media.file.previewType === "IMAGE") {
		msgIcon = <img src={photoIcon} alt="media" />;
	} else if (media && media.file.previewType === "VIDEO") {
		msgIcon = <img src={videoIcon} alt="video" />;
	} else if (
		media &&
		media.file.previewType !== "IMAGE" &&
		media.file.previewType !== "VIDEO"
	) {
		msgIcon = (
			<img src={attachIcon} className={styles.smaller_icon} alt="file" />
		);
	} else if (location) {
		msgIcon = (
			<img
				src={locationIcon}
				className={styles.smaller_icon}
				alt="location"
			/>
		);
	}

	return (
		<div className={styles.msg_container}>
			{msgIcon && msgIcon}
			{msgContent}
		</div>
	);
};

export default LeftSideMsg;
