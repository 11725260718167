import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCompanyPhone } from "../../../../../store/slices/company";
import { deletePhone } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Phone.module.css";
import PhoneItem from "./PhoneItem";

const Phone = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { contacts: contactList, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const [warningDeleteModal, setWarningDeleteModal] = useState(false);
	const [contactId, setContactId] = useState("");

	const handlePhoneDelete = (phoneId) => {
		setWarningDeleteModal(true);
		setContactId(phoneId);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanyPhone(contactId, companyId)
				: deletePhone(contactId)
		);
		setWarningDeleteModal(false);
	};

	const contacts = contactList.map((contact) => (
		<PhoneItem
			key={contact.uuid}
			item={contact}
			onEditClicked={() =>
				navigate(routes.editPhone, {
					state: contact,
				})
			}
			onDelete={() => handlePhoneDelete(contact.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW PHONE NUMBER"
					onClick={() => navigate(routes.addPhone)}
				/>
			</div>

			{contactList.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Mobile / Landline</h4>
					<div className={styles.contact_section}>{contacts}</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Phone;
