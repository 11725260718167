import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { useMemo } from "react";
import useApi from "../../../../hooks/useApi";
import GroupMember from "../../../Chat/GroupMember";
import LoadingSpinner from "../../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "../../../Utils/SubComs/NoResults/NoResults";
import styles from "./InfiniteUsersPage.module.scss";

const PAGE_SIZE = 40;

const InfiniteUsersPage = ({ queryKey, endpoint }) => {
	const api = useApi(endpoint, true, true);

	const fetch = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, ___, coordinates] = queryKey;

		const response = await api.request(
			pageParam,
			PAGE_SIZE,
			coordinates?.lat,
			coordinates?.lng
		);
		return response.data;
	};

	const { items, isFetchingNextPage, viewRef, isLoading } = useInfiniteScroll({
		queryKey,
		queryFn: fetch,
		pageSize: PAGE_SIZE,
	});

	const itemsList = useMemo(
		() =>
			items?.map((user, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<div
						key={user.uuid}
						ref={isLastItem ? viewRef : null}
						className={styles.contact_item}
					>
						<GroupMember data={user} redirect={true} />
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</div>
				);
			}),
		[items, viewRef, isFetchingNextPage]
	);

	if (isLoading)
		return (
			<div className={styles.section_wrapper}>
				<LoadingSpinner />
			</div>
		);

	if (!isLoading && items?.length === 0)
		return <NoResults visible={true} isSearch={true} text={"No results"} />;

	return (
		<div className={styles.container}>
			<div className={styles.section_wrapper}>{itemsList}</div>
		</div>
	);
};

export default InfiniteUsersPage;
