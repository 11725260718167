import styles from "./SelectCommands.module.css";
// import copyIcon from "images/copy-icon.svg";
// import binIcon from "images/bin-icon.svg";
// import moveIcon from "images/move-icon.svg";
// import shareIconBlue from "images/share-icon.svg";
// import downloadIconBlue from "images/download-icon-blue.svg";
// import downloadBtn from "images/download-btn-blue.svg";
// import clearIcon from "images/clear-icon-gray.svg";
import { ReactComponent as ShareIcon } from "images/share.svg";
import { ReactComponent as MoveItem } from "images/move-item.svg";
import { ReactComponent as CopyIcon } from "images/copy.svg";
import { ReactComponent as DeleteIcon } from "images/deleteInMenu.svg";
import { ReactComponent as ClearIcon } from "images/clear.svg";

const SelectCommands = ({
	items,
	onDelete,
	onShare,
	onMove,
	onCopy,
	onClear,
	// isFile,
}) => {
	const allowMove = !items.some((i) => i.gaawkFolder === true);
	const allowDelete = !items.some((item) => item.enableDelete === false);

	return (
		<div className={styles.multiselect_commands}>
			<button
				className={styles.multiselect_commands_item}
				onClick={onClear}
			>
				<ClearIcon />
				<span>Clear</span>
			</button>
			{/* <div className={styles.multiselect_commands_item}>
                        <img src={downloadIconBlue} alt={"Download"} />
                        <span>Download</span>
                    </div> */}
			{/* {(isFile || (!isFile && allowAction)) && ( */}
			<button
				className={styles.multiselect_commands_item}
				onClick={onShare}
			>
				<ShareIcon />
				<span>Share</span>
			</button>
			{/*  )} */}

			{allowMove && (
				<button
					className={styles.multiselect_commands_item}
					onClick={onMove}
				>
					<MoveItem />
					<span>Move</span>
				</button>
			)}

			{/* {(isFile || (!isFile && allowAction)) && ( */}

			<button
				className={styles.multiselect_commands_item}
				onClick={onCopy}
			>
				<CopyIcon />
				<span>Copy</span>
			</button>
			{/* )} */}
			{allowDelete && (
				<button
					className={styles.multiselect_commands_item}
					onClick={onDelete}
				>
					<DeleteIcon />
					<span>Delete</span>
				</button>
			)}
		</div>
	);
};

export default SelectCommands;
