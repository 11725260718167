import { pluralize } from "components/Utils/General";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import followIcon from "images/connection-icons/follow.svg";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useNavigate, useParams } from "react-router-dom";
import connectionApi from "../../../api/connection";
import useApi from "../../../hooks/useApi";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import styles from "./ConnectionTab.module.css";
import { useMemo } from "react";

const pageSize = 6;

const Follow = ({ profile, canEdit, connectionCount }) => {
	const navigate = useNavigate();
	const { userUrl } = useParams();

	const getFollowingApi = useApi(connectionApi.getFollowing, true, true);

	const fetchFollowing = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, profileId] = queryKey;

		const response = await getFollowingApi.request(
			profileId,
			pageParam,
			pageSize
		);

		return response.data;
	};

	const { items, isFetching } = useInfiniteScroll({
		queryKey: connectionsKeys.list(profile.uuid, "following"),
		queryFn: fetchFollowing,
		pageSize,
	});

	const handleViewAll = (action) => () => {
		navigate(
			`/profile/${userUrl ? `${userUrl}/` : ""}connections?tab=${action}`
		);
	};

	const followingList = useMemo(
		() =>
			items.map((follower) => (
				<div className={styles.contact_item} key={follower.uuid}>
					<GroupMember data={follower} redirect={true} />
				</div>
			)),
		[items]
	);

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img
						src={followIcon}
						// className={styles.section_icon}
						alt="Follow"
					/>
					Follow
				</div>
				<SeeMore text="See All" onClick={handleViewAll("following")} />
			</div>

			{isFetching && <ContactLoader />}

			{!isFetching && (
				<>
					<div className={styles.subtitle}>
						{canEdit
							? "You follow "
							: profile.type === "USER"
							? `${profile.firstName} ${profile.lastName} follows `
							: `${profile.name} follows `}
						<span
							onClick={handleViewAll("following")}
						>{`${connectionCount.following}`}</span>{" "}
						{pluralize(connectionCount.following, "Connection")}.
					</div>

					<div>{followingList}</div>
				</>
			)}

			{connectionCount.followers > 0 && (
				<div className={styles.more_section}>
					<div className={styles.more_info}>
						<span
							onClick={handleViewAll("followers")}
						>{`${connectionCount.followers}`}</span>{" "}
						{pluralize(connectionCount.followers, "Connection")}{" "}
						follow{" "}
						{canEdit
							? "you"
							: profile.type === "USER"
							? `${profile.firstName} ${profile.lastName}`
							: `${profile.name}`}
						.
					</div>
					<div
						className={styles.view_all}
						onClick={handleViewAll("followers")}
					>
						View all followers
					</div>
				</div>
			)}
		</ProfileTabContainer>
	);
};

export default Follow;
