import PeopleFilter from "./FilterTabs/PeopleFilter";
import CompaniesFilter from "./FilterTabs/CompaniesFilter";
import PostsFilter from "./FilterTabs/PostsFilter";
import styles from "./SearchFilterLeftSide.module.scss";
import JobsFilter from "./FilterTabs/JobsFilter";
import ProductsFilter from "./FilterTabs/ProductsFilter";
import { cloneElement, forwardRef, useImperativeHandle, useRef } from "react";
import ServicesFilter from "./FilterTabs/ServicesFilter";

const SearchFilterLeftSide = forwardRef(({ activeTab, userCoordinates, companyId }, ref) => {
	const filterRef = useRef();

	useImperativeHandle(ref, () => ({
		updateDynamicFilters(key, tag) {
			filterRef.current?.updateDynamicFilters(key, tag);
		},
	}));

	let displayFilter;

	switch (true) {
		case activeTab === "people":
			displayFilter = <PeopleFilter />;
			break;

		case activeTab === "companies":
			displayFilter = <CompaniesFilter />;
			break;

		case activeTab === "posts":
			displayFilter = <PostsFilter />;
			break;

		case activeTab === "jobs":
			displayFilter = <JobsFilter />;
			break;

		case activeTab === "products":
			displayFilter = <ProductsFilter />;
			break;

		case activeTab === "services":
			displayFilter = <ServicesFilter />;
			break;

		default:
			break;
	}

	return (
		<div className={styles.container}>
			{displayFilter &&
				cloneElement(displayFilter, {
					ref: filterRef,
					userCoordinates: userCoordinates,
					companyId: companyId,
				})}
		</div>
	);
});

export default SearchFilterLeftSide;
