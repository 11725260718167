import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const initialState = {
	companyInfo: {},
};

const slice = createSlice({
	name: "Company",
	initialState,
	reducers: {
		companyInfoUpdated: (state, action) => {
			state.companyInfo = action.payload;
		},

		bioUpdate: (state, action) => {
			state.companyInfo.bio = action.payload.bio;
		},

		bioDelete: (state) => {
			state.companyInfo.bio = "";
		},
		certificateUpdated: (state, action) => {
			state.companyInfo.certificates = action.payload.certificates;
		},
		certificateDeleted: (state, action) => {
			const certificateId = action.payload.passedData;
			state.companyInfo.certificates =
				state.companyInfo.certificates.filter(
					(certificate) => certificate.uuid !== certificateId
				);
		},

		companyCertificateFilesUpdated: (state, action) => {
			const { uuid: certificationId } = action.payload;
			const index = state.companyInfo.certificates.findIndex(
				(certificate) => certificate.uuid === certificationId
			);
			if (index > -1) {
				state.companyInfo.certificates[index].files =
					action.payload.files;
			}
		},

		addKeyPerson: (state, action) => {
			state.companyInfo.keyPeople = action.payload;
		},
		removeKeyPerson: (state, action) => {
			const profileId = action.payload.passedData;
			state.companyInfo.keyPeople = state.companyInfo.keyPeople.filter(
				(item) => item.profile.uuid !== profileId
			);
		},
		otherUpdated: (state, action) => {
			state.companyInfo.others = action.payload.others;
		},
		otherDeleted: (state, action) => {
			const otherId = action.payload.passedData;
			state.companyInfo.others = state.companyInfo.others.filter(
				(other) => other.uuid !== otherId
			);
		},
		socialUpdated: (state, action) => {
			state.companyInfo.socials = action.payload.socials;
		},
		socialDeleted: (state, action) => {
			const socialId = action.payload.passedData;
			state.companyInfo.socials = state.companyInfo.socials.filter(
				(social) => social.uuid !== socialId
			);
		},
		addressUpdated: (state, action) => {
			state.companyInfo.addresses = action.payload.addresses;
		},
		addressDeleted: (state, action) => {
			const addressId = action.payload.passedData;
			state.companyInfo.addresses = state.companyInfo.addresses.filter(
				(address) => address.uuid !== addressId
			);
		},
		siteUpdated: (state, action) => {
			state.companyInfo.sites = action.payload.sites;
		},
		siteDeleted: (state, action) => {
			const siteId = action.payload.passedData;
			state.companyInfo.sites = state.companyInfo.sites.filter(
				(site) => site.uuid !== siteId
			);
		},
		phoneUpdated: (state, action) => {
			state.companyInfo.contacts = action.payload.contacts;
		},
		phoneDeleted: (state, action) => {
			const contactId = action.payload.passedData;
			state.companyInfo.contacts = state.companyInfo.contacts.filter(
				(contact) => contact.uuid !== contactId
			);
		},
		companyProfileImageUpdated: (state, action) => {
			state.companyInfo.profileImage = action.payload.profileImage;
		},
		coverUpdated: (state, action) => {
			state.companyInfo.profileCover = action.payload.profileCover;
		},
		resetCompanyInfo: () => initialState,
	},
});

export const {
	companyInfoUpdated,
	bioUpdate,
	bioDelete,
	certificateUpdated,
	certificateDeleted,
	companyCertificateFilesUpdated,
	addKeyPerson,
	removeKeyPerson,
	otherUpdated,
	otherDeleted,
	socialUpdated,
	socialDeleted,
	addressUpdated,
	addressDeleted,
	siteUpdated,
	siteDeleted,
	phoneUpdated,
	phoneDeleted,
	companyProfileImageUpdated,
	coverUpdated,
	resetCompanyInfo,
} = slice.actions;

export default slice.reducer;

export const getCompanyInfo = (companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/get?companyId=${companyId}`,
		method: "GET",
		onSuccess: companyInfoUpdated.type,
	});

/**
 * Courses & Certifications
 */
export const addCompanyCertificate = (body, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/certificate?companyId=${companyId}`,
		method: "POST",
		data: body,
		onSuccess: certificateUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyCertificate = (certificationId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/certificate?certificationId=${certificationId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: certificationId,
		onSuccess: certificateDeleted.type,
		showSuccessAlert: true,
	});

export const updateCompanyCertificate = (body, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/certificate?companyId=${companyId}`,
		method: "PUT",
		data: body,
		onSuccess: certificateUpdated.type,
		showSuccessAlert: true,
	});

export const addCompanyCertificateFiles = (formData) =>
	apiActions.apiCallBegan({
		url: "/profile/company/certificate/file",
		method: "POST",
		data: formData,
		onSuccess: certificateUpdated.type,
		contentType: "multipart/form-data",
		// showSuccessAlert: true,
	});

// export const deleteCompanyCertificateFiles = (certificationId, fileId, companyId) =>
//     apiActions.apiCallBegan({
//         url: `/profile/company/certificate/file?certificationId=${certificationId}&fileId=${fileId}&companyId=${companyId}`,
//         method: "DELETE",
//         onSuccess: certificateUpdated.type,
//         // showSuccessAlert: true,
//     });

/**
 * Key People
 */

export const deleteKeyPeople = (companyId, profileId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/users/key?companyId=${companyId}&profileId=${profileId}`,
		method: "DELETE",
		onSuccess: removeKeyPerson.type,
		passedData: profileId,
		// showSuccessAlert: true,
	});

// OTHER

export const addCompanyOther = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/other?companyId=${companyId}`,
		method: "POST",
		data,
		onSuccess: otherUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanyOther = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/other?companyId=${companyId}`,
		method: "PUT",
		data,
		onSuccess: otherUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyOther = (otherId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/other?otherUuid=${otherId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: otherId,
		onSuccess: otherDeleted.type,
		showSuccessAlert: true,
	});

//SOCIAL

export const addCompanySocial = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/social?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: socialUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanySocial = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/social?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: socialUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanySocial = (userSocialId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/social?userSocialId=${userSocialId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: userSocialId,
		onSuccess: socialDeleted.type,
		showSuccessAlert: true,
	});

/**
 * Addresses
 */

export const addCompanyAddress = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/address?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: addressUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanyAddress = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/address?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: addressUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyAddress = (addressId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/address?addressId=${addressId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: addressId,
		onSuccess: addressDeleted.type,
		showSuccessAlert: true,
	});

//SITE / EMAIL

export const addCompanySite = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/site?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: siteUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanySite = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/site?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: siteUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanySite = (siteId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/site?siteId=${siteId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: siteId,
		onSuccess: siteDeleted.type,
		showSuccessAlert: true,
	});

/**
 * Phone
 */

export const addCompanyPhone = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/contact?companyId=${companyId}`,
		method: "POST",
		data: data,
		onSuccess: phoneUpdated.type,
		showSuccessAlert: true,
	});

export const updateCompanyPhone = (data, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/contact?companyId=${companyId}`,
		method: "PUT",
		data: data,
		onSuccess: phoneUpdated.type,
		showSuccessAlert: true,
	});

export const deleteCompanyPhone = (contactId, companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/contact?contactId=${contactId}&companyId=${companyId}`,
		method: "DELETE",
		passedData: contactId,
		onSuccess: phoneDeleted.type,
		showSuccessAlert: true,
	});

//PROFILE & COVER IMAGE

// export const updateCompanyProfileImage = (formData, imgData, companyId) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage?${array.join(
// 			"&"
// 		)}&companyId=${companyId}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: companyProfileImageUpdated.type,
// 		showSuccessAlert: true,
// 		contentType: "multipart/form-data",
// 	});
// };

// export const deleteCompanyProfileImage = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage?companyId=${companyId}`,
// 		method: "DELETE",
// 		showSuccessAlert: true,
// 		onSuccess: companyProfileImageUpdated.type,
// 	});

export const makeTempPermanentCompany = (companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/profileImage/permTemp?companyId=${companyId}`,
		method: "PATCH",
		showSuccessAlert: true,
		onSuccess: companyProfileImageUpdated.type,
	});

export const removeTempCompany = (companyId) =>
	apiActions.apiCallBegan({
		url: `/profile/company/profileImage/removeTemp?companyId=${companyId}`,
		method: "DELETE",
		showSuccessAlert: true,
		onSuccess: companyProfileImageUpdated.type,
	});

// export const updateCompanyCoverImage = (formData, imgData, companyId) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		url: `/profile/company/coverImage?${array.join(
// 			"&"
// 		)}&companyId=${companyId}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: coverUpdated.type,
// 		contentType: "multipart/form-data",
// 		showSuccessAlert: true,
// 	});
// };
// export const deleteCompanyCoverImage = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/coverImage?companyId=${companyId}`,
// 		method: "DELETE",
// 		onSuccess: coverUpdated.type,
// 		showSuccessAlert: true,
// 	});
