import { combineReducers } from "redux";
import jwt from "../slices/jwt";
import userReducer from "../slices/user";
import frameReducer from "../slices/frame";
import postReducer from "../slices/post";
import commentReducer from "../slices/comment";
import replyReducer from "../slices/replies";
import cityReducer from "../slices/city";
import countryReducer from "../slices/country";
import certificationReducer from "../slices/certification";
import profileReducer from "../slices/profile";
import emotionReducer from "../slices/emoji";
import placesReducer from "../slices/places";
import otpReducer from "../slices/otp";
import uiReducer from "../slices/ui";
import skillReducer from "../slices/skill";
import hashtagReducer from "../slices/hashtag";
import converationReducer from "../slices/conversation";
import messageReducer from "../slices/message";
import vaultReducer from "../slices/vault";
import groupReducer from "../slices/group";
import companyReducer from "../slices/company";
import serviceReducer from "../slices/service";
import registrationReducer from "../slices/registration";
import productsReducer from "../slices/products";
import fcmTokenReducer from "../slices/fcmToken";
import activeTabReducer from "../slices/activeTab";
import socketReducer from "../slices/socket";
import chatReducer from "../slices/chat";
import filtersReducer from "../slices/filters";

export default combineReducers({
	jwt,
	user: userReducer,
	frame: frameReducer,
	post: postReducer,
	comment: commentReducer,
	replies: replyReducer,
	city: cityReducer,
	country: countryReducer,
	profile: profileReducer,
	emotion: emotionReducer,
	places: placesReducer,
	certification: certificationReducer,
	otp: otpReducer,
	ui: uiReducer,
	skill: skillReducer,
	hashtag: hashtagReducer,
	converation: converationReducer,
	message: messageReducer,
	vault: vaultReducer,
	group: groupReducer,
	company: companyReducer,
	service: serviceReducer,
	products: productsReducer,
	registration: registrationReducer,
	fcmToken: fcmTokenReducer,
	activeTab: activeTabReducer,
	socket: socketReducer,
	chat: chatReducer,
	filters: filtersReducer,
});
