import ThreeColumnPage from "components/Utils/PageArchs/ThreeColumnPage/ThreeColumnPage";
import { useRef } from "react";
import HomeLeftSidebar from "./Components/HomeLeftSidebar/HomeLeftSidebar";
import HomeRightSidebar from "./Components/HomeRightSidebar/HomeRightSidebar";
import styles from "./PostPreview.module.scss";
import PostItem from "./PostItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { postsKeys } from "queryKeys/posts-key-factory";
import useApi from "hooks/useApi";
import postApi from "api/post";
import { useLocation, useNavigate } from "react-router-dom";
import PostLoader from "components/Utils/SubComs/CustomLoader/PostLoader";
import usePostViewer from "hooks/usePostViewer";
import useUpdateReaction from "hooks/useUpdateReaction";
import FullScreenModal from "components/Utils/GaawkModal/FullScreenModal";
import PostFullScreen from "./PostFullScreen";
import useMutate from "hooks/useMutate";
import routes from "components/Routing/routing-keys";
import usePostById from "hooks/usePostById";
import EditPostModal from "./EditPostModal";
import useCompanyId from "hooks/useCompanyId";
import { useSelector } from "react-redux";
import CreatePost from "./CreatePost";
import { toast } from "react-toastify";
// import boardsApi from "api/boards";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";

const PostPreview = () => {
	const getPostApi = useApi(postApi.getPostById, true, true);

	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const {
		postModal,
		handleItemClicked,
		handleCloseModal,
		subPostIndex,
		postId,
		subPostId,
	} = usePostViewer();

	const fetchPost = async ({ queryKey }) => {
		const [_, __, postId] = queryKey;
		const response = await getPostApi.request(postId);
		return response.data;
	};

	const queryKey = postsKeys.detail(postId);

	const { isLoading, data: post } = useQuery({
		queryKey,
		queryFn: fetchPost,
	});

	const updatePostReaction = useUpdateReaction();

	const handlePostUpdate = (reaction) => {
		updatePostReaction(queryKey, reaction);
	};

	const {
		action: { mutate: deletePost },
	} = useMutate(postApi.deletePost, () => navigate(routes.root));

	const companyId = useCompanyId();

	const { type, url, profileImage, firstName, lastName, name } = useSelector(
		(state) => {
			if (companyId) {
				return state.company.companyInfo;
			} else {
				return state.user.profileInfo;
			}
		}
	);

	const isEditing = true;
	const {
		post: postToEdit,
		setPostId,
		showModal,
		setShowModal,
	} = usePostById(isEditing);

	// const handleSaveEdit = (editedPost) => {

	// 	const firstFiveMedia = editedPost["subPosts"].slice(0, 5);
	// 	editedPost["subPosts"] = firstFiveMedia;

	// 	queryClient.setQueryData(queryKey, () => {
	// 		return editedPost;
	// 	});
	// };

	// const handleDeleteMedia = (_, subPostId, prevSubPosts) => {

	// 	queryClient.setQueryData(queryKey, (prevData) => {
	// 		//* updating the count

	// 		prevData["childPostsCount"] -= 1;

	// 		//* updating the subposts of the post

	// 		const updatedSubPosts = prevData.subPosts.filter(
	// 			(sub) => sub.uuid !== subPostId
	// 		);

	// 		if (
	// 			updatedSubPosts.length < prevData["childPostsCount"] &&
	// 			prevData["childPostsCount"] >= 5 &&
	// 			updatedSubPosts.length < 5
	// 		) {
	// 			const postToAdd = prevSubPosts.find(
	// 				(sub) =>
	// 					!updatedSubPosts.map((x) => x.uuid).includes(sub.uuid)
	// 			);
	// 			updatedSubPosts.push(postToAdd);
	// 		}

	// 		prevData["subPosts"] = updatedSubPosts;

	// 		return prevData;
	// 	});
	// };

	// const handleDeleteAllMedia = () => {

	// 	queryClient.setQueryData(queryKey, (prevData) => {
	// 		//* updating the count

	// 		prevData["childPostsCount"] = 0;

	// 		//* updating the subposts of the post

	// 		prevData["subPosts"] = [];

	// 		//* updating the post in the feed
	// 		return prevData;
	// 	});
	// };

	// ! ========== POST SHARE ==========
	const postRef = useRef();

	const handleShare = (post) => {
		if (post.sharedPost) {
			postRef.current.sharePost(post.sharedPost);
		} else {
			postRef.current.sharePost(post);
		}
	};

	// ! ======= BOARD PIN / UNPIN ========

	// const onToggleSuccess = (response) => {
	// 	queryClient.setQueryData(queryKey, () => {
	// 		return response.data;
	// 	});
	// };

	// const {
	// 	action: { mutate: pinPost },
	// } = useMutate(boardsApi.pinPost, onToggleSuccess);

	// const {
	// 	action: { mutate: unpinPost },
	// } = useMutate(boardsApi.unpinPost, onToggleSuccess);

	// const handleTogglePin = (post) => {
	// 	const {
	// 		pinned: isPostPinned,
	// 		board: { uuid: boardId },
	// 		uuid: postId,
	// 	} = post;

	// 	if (isPostPinned) {
	// 		unpinPost({ boardId, postId });
	// 	} else {
	// 		pinPost({ boardId, postId });
	// 	}
	// };

	return (
		<>
			<CreatePost
				ref={postRef}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				onCreate={() => toast.success("Post successfully shared!")}
				// board={board}
			/>

			<ThreeColumnPage
				leftSideChildren={<HomeLeftSidebar />}
				centerChildren={
					<FeedContainer
						fixedContainer={true}
						className={styles.feed_container}
					>
						{isLoading ? (
							<PostLoader />
						) : (
							<PostItem
								post={post}
								canEditBoard={post.board?.moderator}
								isPinned={post.pinned}
								onItemClicked={handleItemClicked(post)}
								onDelete={() => deletePost(post.uuid)}
								onPostUpdate={handlePostUpdate}
								onEdit={(post) => setPostId(post.uuid)}
								onShare={handleShare}
								// onTogglePin={() => handleTogglePin(post)}
							/>
						)}
					</FeedContainer>
				}
				rightSideChildren={<HomeRightSidebar />}
			/>

			<FullScreenModal
				show={postModal}
				onClose={() => {
					//ESC KEY WHEN IN THE MODAL TRIGGERS THIS
					handleCloseModal();
					window.history.pushState(null, "", `/post/${postId}`);
				}}
				fullWidth={true}
				children={
					<PostFullScreen
						post={post}
						initialSlideIndex={subPostIndex}
						postId={postId}
						subPostId={subPostId}
						isModal={true}
						onClose={handleCloseModal}
						onRefresh={() => {
							if (post.subPosts.length > 1) {
								queryClient.invalidateQueries(queryKey);
							} else {
								deletePost(post.uuid);
							}
						}}
						onUpdateUrl={() =>
							window.history.pushState(null, "", pathname)
						}
					/>
				}
			/>

			<EditPostModal
				existingPost={postToEdit.data}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				url={url}
				show={showModal}
				toggleShow={(bool) => setShowModal(bool)}
				onInvalidate={() => queryClient.invalidateQueries(queryKey)}
				// onSaveEdit={handleSaveEdit}
				// onDeleteMedia={handleDeleteMedia}
				// onDeleteAllMedia={handleDeleteAllMedia}
				onClose={() => setPostId("")}
			/>
		</>
	);
};

export default PostPreview;
