import connectionApi from "api/connection";
import profileApi from "api/profile";
import useApi from "hooks/useApi";

export const useConnectionQueries = (pageSize = 20) => {
	const getFriendsApi = useApi(connectionApi.getFriends, true, true);
	const getSentApi = useApi(connectionApi.getSent, true, true);
	const getReceivedApi = useApi(connectionApi.getReceived, true, true);
	const getWorkCircleApi = useApi(connectionApi.getWorkCircle, true, true);
	const getReceivedWorkCircleApi = useApi(
		connectionApi.getReceivedWorkCircle,
		true,
		true
	);
	const getFollowingApi = useApi(connectionApi.getFollowing, true, true);
	const getFollowersApi = useApi(connectionApi.getFollowers, true, true);
	const getFavoritesApi = useApi(profileApi.getFavorites, true, true);
	const getBlockedApi = useApi(connectionApi.getBlocked, true, true);

	const queryFunctions = {
		friends: async ({ pageParam = 0, queryKey }) => {
			const [_, __, profileId, ___, query] = queryKey;
			const response = await getFriendsApi.request(
				profileId,
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
		pendingSent: async ({ pageParam = 0, queryKey }) => {
			const [_, __, ___, ____, query] = queryKey;
			const response = await getSentApi.request(
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
		pendingReceived: async ({ pageParam = 0, queryKey }) => {
			const [_, __, ___, ____, query] = queryKey;
			const response = await getReceivedApi.request(
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
		yourWorkCircle: async ({ pageParam = 0, signal, queryKey }) => {
			const [_, __, profileId, ___, query] = queryKey;
			const response = await getWorkCircleApi.request(
				profileId,
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
		theirWorkCircle: async ({ pageParam = 0, signal, queryKey }) => {
			const [_, __, profileId, ___, query] = queryKey;
			const response = await getReceivedWorkCircleApi.request(
				profileId,
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
		following: async ({ pageParam = 0, signal, queryKey }) => {
			const [_, __, profileId, ___, query] = queryKey;
			const response = await getFollowingApi.request(
				profileId,
				pageParam,
				pageSize,
				query
			);

			return response.data;
		},
		followers: async ({ pageParam = 0, signal, queryKey }) => {
			const [_, __, profileId, ___, query] = queryKey;
			const response = await getFollowersApi.request(
				profileId,
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
		favorites: async ({ pageParam = 0, signal, queryKey }) => {
			const [_, __, ___, ____, query] = queryKey;
			const response = await getFavoritesApi.request(
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
		blocked: async ({ pageParam = 0, signal, queryKey }) => {
			const [_, __, ___, ____, query] = queryKey;
			const response = await getBlockedApi.request(
				pageParam,
				pageSize,
				query
			);
			return response.data;
		},
	};

	return queryFunctions;
};
