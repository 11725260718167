import { pluralize } from "components/Utils/General";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import workIcon from "images/connection-icons/work.svg";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import connectionApi from "../../../api/connection";
import useApi from "../../../hooks/useApi";
import GroupMember from "../../Chat/GroupMember";
import ContactLoader from "../../Utils/SubComs/CustomLoader/ContactLoader";
import styles from "./ConnectionTab.module.css";

const pageSize = 6;

const Work = ({ profile, canEdit, connectionCount }) => {
	const navigate = useNavigate();
	const { userUrl } = useParams();

	const getWorkCircleApi = useApi(connectionApi.getWorkCircle, true, true);

	const fetchYourWorkCircle = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, profileId] = queryKey;

		const response = await getWorkCircleApi.request(
			profileId,
			pageParam,
			pageSize
		);

		return response.data;
	};

	const { items, isFetching } = useInfiniteScroll({
		queryKey: connectionsKeys.list(profile.uuid, "yourWorkCircle"),
		queryFn: fetchYourWorkCircle,
		pageSize,
	});

	const { type, firstName, lastName, name } = profile;
	const fullName = type === "USER" ? `${firstName} ${lastName}` : `${name}`;

	const handleViewAll = (action) => () => {
		navigate(
			`/profile/${userUrl ? `${userUrl}/` : ""}connections?tab=${action}`
		);
	};

	const workCircleList = useMemo(
		() =>
			items.map((contact) => (
				<div className={styles.contact_item} key={contact.uuid}>
					<GroupMember data={contact} redirect={true} />
				</div>
			)),
		[items]
	);

	return (
		<ProfileTabContainer>
			<div className={styles.section_title}>
				<div>
					<img src={workIcon} alt="Work" />
					Work circle
				</div>
				<SeeMore
					text="See All"
					onClick={handleViewAll("yourWorkCircle")}
				/>
			</div>

			{isFetching && <ContactLoader />}

			{!isFetching && (
				<>
					<div className={styles.subtitle}>
						{canEdit
							? "You have "
							: profile.type === "USER"
							? `${profile.firstName} ${profile.lastName} has `
							: `${profile.name} has `}
						<span
							onClick={handleViewAll("yourWorkCircle")}
						>{`${connectionCount.inMyWorkCircle}`}</span>
						{canEdit
							? ` ${pluralize(
									connectionCount.inMyWorkCircle,
									"connection"
							  )} in your work circle.`
							: ` ${pluralize(
									connectionCount.inMyWorkCircle,
									"connection"
							  )} in his/her work circle.`}
					</div>

					<div>{workCircleList}</div>
				</>
			)}

			{connectionCount.inTheirWorkCircles > 0 && (
				<div className={styles.more_section}>
					<div className={styles.more_info}>
						<span
							onClick={handleViewAll("theirWorkCircle")}
						>{`${connectionCount.inTheirWorkCircles}`}</span>{" "}
						{pluralize(
							connectionCount.inTheirWorkCircles,
							"Connection"
						)}{" "}
						added {canEdit ? "you" : `${fullName}`} to their work
						circle.
					</div>
					<div
						className={styles.view_all}
						onClick={handleViewAll("theirWorkCircle")}
					>
						View all connections
					</div>
				</div>
			)}
		</ProfileTabContainer>
	);
};

export default Work;
