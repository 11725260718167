import CtaButton from "components/Utils/Button/CtaButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCompanyCertificate } from "../../../../store/slices/company";
import { deleteCertificate } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./Certificate.module.css";
import CertificateItem from "./CertificateItem";
import useCurrentUser from "hooks/useCurrentUser";
import routes from "components/Routing/routing-keys";

const Certificate = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { certificates, uuid, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const [certificateId, setCertificateId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const [formattedCertificates, setFormattedCertificates] = useState([]);

	const handleDelete = (certificateId) => {
		setCertificateId(certificateId);
		setWarningDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		dispatch(
			editCompany
				? deleteCompanyCertificate(certificateId, uuid)
				: deleteCertificate(certificateId)
		);
		setWarningDeleteModal(false);
	};

	useEffect(() => {
		setFormattedCertificates(
			certificates.map((item) => (
				<CertificateItem
					key={item.uuid}
					item={item}
					onEditClicked={() =>
						navigate(routes.editCertificate, {
							state: item,
						})
					}
					onDelete={() => handleDelete(item.uuid)}
				/>
			))
		);
	}, [navigate, certificates]);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW CERTIFICATE"
					onClick={() => navigate(routes.addCertificate)}
				/>
			</div>

			{certificates.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>
						Your Certification & Courses
					</h4>

					<div className={styles.certificates_section}>
						{formattedCertificates}
					</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Certificate;
