import { useEffect, useState } from "react";
import LoginForm from "./Components/LoginForm/LoginForm";
import styles from "./Registration.module.scss";
import loginBackground from "images/login-background.jpg";
import RegistrationForm from "./Components/RegistrationForm/RegistrationForm";
import GenderForm from "./Components/GenderForm/GenderForm";
import OtpForm from "./Components/OtpForm/OtpForm";
import PasswordForm from "./Components/PasswordForm/PasswordForm";
import EmailForm from "./Components/EmailForm/EmailForm";
import MobileForm from "./Components/MobileForm/MobileForm";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import ForgotPasswordForm from "./Components/ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "./Components/ResetPasswordForm/ResetPasswordForm";
import { resetOtp } from "store/slices/otp";
import LazyImage from "components/Utils/LazyImage/LazyImage";

const stepStage = {
	login: 0,
	registration: 1,
	gender: 2,
	password: 3,
	otp: 4,
	email: 5,
	mobile: 6,
	forgotPassword: 7,
	resetPassword: 8,
};

const FormFooter = ({
	text,
	linkText,
	onStepChange,
	defaultFooter = false,
}) => {
	return (
		<div className={styles.form_footer}>
			{defaultFooter ? (
				<>
					<img
						src={
							require("images/gaawk-logos/gaawk-gray.svg").default
						}
						alt="logo"
					/>
					<span>© 2023</span>
				</>
			) : (
				<>
					{text}{" "}
					<button type="button" onClick={onStepChange}>
						{linkText}
					</button>
				</>
			)}
		</div>
	);
};

const Registration = () => {
	const dispatch = useDispatch();
	const { pathname, state } = useLocation();

	const [step, setStep] = useState(stepStage.login);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get("q") === "forgot") {
			setStep(stepStage.forgotPassword);
		} else if (pathname === "/password/reset") {
			setStep(stepStage.resetPassword);
		} else {
			setStep(stepStage.login);
		}
	}, [pathname, searchParams]);

	const [formData, setFormData] = useState(null);

	useEffect(() => {
		dispatch(resetOtp());
	}, [dispatch]);

	// useEffect(() => {
	// 	if (pathname !== routes.login) {
	// 		dispatch(resetRegistration());
	// 	}
	// }, [dispatch, pathname]);

	let stepDisplay;
	let footer;

	switch (step) {
		case stepStage.login:
			stepDisplay = (
				<LoginForm
					onRedirect={() => setStep(stepStage.otp)}
					from={state?.from.pathname}
					onForgotPassword={() => setStep(stepStage.forgotPassword)}
				/>
			);
			footer = (
				<FormFooter
					text="Don't have an account?"
					linkText="Sign Up"
					onStepChange={() => {
						setStep(stepStage.registration);
					}}
				/>
			);
			break;

		case stepStage.registration:
			stepDisplay = (
				<RegistrationForm
					onBack={() => {
						setStep(stepStage.login);
					}}
					onNext={(data) => {
						setFormData((prevState) => ({ ...prevState, ...data }));
						setStep(stepStage.gender);
					}}
				/>
			);

			footer = (
				<FormFooter
					text="Have an account?"
					linkText="Login"
					onStepChange={() => {
						setStep(stepStage.login);
					}}
				/>
			);
			break;

		case stepStage.gender:
			stepDisplay = (
				<GenderForm
					onBack={() => {
						setStep(stepStage.registration);
					}}
					onNext={(data) => {
						setFormData((prevState) => ({ ...prevState, ...data }));
						setStep(stepStage.password);
					}}
				/>
			);
			footer = <FormFooter defaultFooter={true} />;
			break;

		case stepStage.password:
			stepDisplay = (
				<PasswordForm
					formData={formData}
					onStepChange={() => {
						setStep(stepStage.otp);
					}}
					onBack={() => {
						setStep(stepStage.gender);
					}}
				/>
			);
			footer = <FormFooter defaultFooter={true} />;
			break;

		case stepStage.otp:
			stepDisplay = (
				<OtpForm
					// onRegisterEmail={() => {
					// 	setStep(stepStage.email);
					// }}
					// onRegisterMobile={() => {
					// 	setStep(stepStage.mobile);
					// }}
					onCancelRegistration={() => {
						setStep(stepStage.login);
					}}
				/>
			);
			footer = <FormFooter defaultFooter={true} />;
			break;

		case stepStage.email:
			stepDisplay = (
				<EmailForm
					onNext={() => setStep(stepStage.otp)}
					onRegisterMobile={() => {
						setStep(stepStage.mobile);
					}}
				/>
			);
			footer = <FormFooter defaultFooter={true} />;
			break;

		case stepStage.mobile:
			stepDisplay = <MobileForm onNext={() => setStep(stepStage.otp)} />;
			footer = <FormFooter defaultFooter={true} />;
			break;

		case stepStage.forgotPassword:
			stepDisplay = (
				<ForgotPasswordForm onBack={() => setStep(stepStage.login)} />
			);
			footer = <FormFooter defaultFooter={true} />;
			break;

		case stepStage.resetPassword:
			stepDisplay = (
				<ResetPasswordForm
					onRedirect={() => setStep(stepStage.login)}
				/>
			);
			footer = <FormFooter defaultFooter={true} />;
			break;

		default:
			break;
	}

	return (
		<div className={styles.container}>
			<LazyImage
				customWrapper={styles.hide}
				image={<img src={loginBackground} alt="background" />}
			/>

			<div className={styles.form_wrapper}>
				{stepDisplay}
				{footer}
			</div>
		</div>
	);
};

export default Registration;
