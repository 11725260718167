import CtaButton from "components/Utils/Button/CtaButton";
import { useNavigate } from "react-router-dom";
import styles from "./Experience.module.css";
import ExperienceItem from "./ExperienceItem";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";

const Index = () => {
	const navigate = useNavigate();

	const { experiences } = useCurrentUser();

	const userExperiences = experiences.map((exp) => (
		<ExperienceItem key={exp.uuid} item={exp} />
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW EXPERIENCE"
					onClick={() => navigate(routes.addExperience)}
				/>
			</div>

			{experiences.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Experiences you have added</h4>

					<div className={styles.experience_section}>
						{userExperiences}
					</div>
				</div>
			)}
		</>
	);
};

export default Index;
