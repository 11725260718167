import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { successfulChangeToast } from "components/Utils/General";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { saveAs } from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
import deleteIcon from "images/bin-icon.svg";
import downloadIcon from "images/download-icon-blue.svg";
import uploadIcon from "images/upload_icon.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WarningModal from "../../../../components/Utils/GaawkModal/WarningModal";
import { coverUpdated as companyCoverUpdated } from "../../../../store/slices/company";
import { coverUpdated } from "../../../../store/slices/user";
import CropModal from "../../../Utils/CropModal/CropModal";
import AddCaption from "../ProfilePicEdit/AddCaption";
import FeedShare from "../ProfilePicEdit/FeedShare";
import PublicInfo from "../ProfilePicEdit/PublicInfo";
import styles from "./ProfileCoverEdit.module.css";

const CoverEdit = () => {
	const dispatch = useDispatch();

	const userData = useCurrentUser();
	const editCompany = userData.type === "COMPANY";

	const userCover = userData.profileCover
		? userData.profileCover.originalImageURL
		: undefined;
	const userCaption = userData.profileCover?.postText ?? "";

	const [cover, setCover] = useState(userCover);
	const [caption, setCaption] = useState(userCaption);

	const [shareCheck, setShareCheck] = useState(false);
	const [deleteModalOpened, setDeleteModalOpened] = useState(false);

	const [cropModal, setCropModal] = useState(false);

	const handleCover = (e) => {
		setCover(e.target.files[0]);
		setPreviewCropURL(URL.createObjectURL(e.target.files[0]));
		setCropModal(true);
	};

	const revokePreviewCropURL = () => {
		URL.revokeObjectURL(previewCropURL);
		setPreviewCropURL(undefined);
	};

	const handleCaption = (text) => {
		setCaption(text);
	};

	const didMountRef = useRef(false);

	useEffect(() => {
		// * below to apply change after saving in states, and also not trigger unsavedChanges
		if (didMountRef.current) {
			setCover(userData.profileCover?.originalImageURL);
			setCaption(userData.profileCover?.postText || "");
		}
		didMountRef.current = true;
	}, [userData.profileCover]);

	const navigate = useNavigate();

	const {
		action: { mutate: updateCoverPicture, isLoading },
	} = useMutate(profileApi.updateCoverPicture, (response) => {
		dispatch(
			editCompany
				? companyCoverUpdated(response.data)
				: coverUpdated(response.data)
		);
		successfulChangeToast();
		navigate(routes.profile());
	});

	const checkUnsavedChanges = () => {
		if (cover !== userCover || caption !== userCaption) {
			return true;
		} else return false;
	};

	const handleSave = () => {
		const formData = new FormData();

		if (cover instanceof Blob) {
			formData.append("coverImageFile", cover, cover.fileName);
		}

		formData.append("share", shareCheck);

		if (caption) {
			formData.append("caption", caption);
			// formData.append("caption", encodeURIComponent(caption));
		}

		if (editCompany) formData.append("companyId", userData.uuid);

		setAllowNavigation(true);

		updateCoverPicture({
			formData,
			isCompany: editCompany ? true : false,
		});
	};

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();
	const [previewCropURL, setPreviewCropURL] = useState(undefined);

	const isCoverBlob = cover instanceof Blob && !(cover instanceof File);

	useEffect(() => {
		if (isCoverBlob) {
			setObjectUrls([URL.createObjectURL(cover)]);
		}

		return () => {
			cleanupObjectUrls();
		};
	}, [cover]);

	const {
		action: { mutate: deleteCoverPic },
	} = useMutate(profileApi.deleteCoverPic, (response) => {
		dispatch(
			editCompany
				? companyCoverUpdated(response.data)
				: coverUpdated(response.data)
		);

		setDeleteModalOpened(false);
		successfulChangeToast();
		navigate(routes.profile());
	});

	const handleDownload = () => {
		saveAs(cover, "cover.jpg");
	};

	const [allowNavigation, setAllowNavigation] = useState(false);

	const onBlock = (currentLocation, nextLocation) => {
		if (allowNavigation) return false;

		if (
			checkUnsavedChanges() &&
			nextLocation.pathname !== currentLocation.pathname
		) {
			return true;
		}
	};

	const inputFile = useRef(null);

	return (
		<>
			<div className={styles.container}>
				<div
					className={styles.cover_container}
					style={
						cover
							? { backgroundColor: "transparent" }
							: { backgroundColor: "#a6b1bc" }
					}
				>
					<LazyImage
						image={
							<img
								src={
									cover instanceof Blob &&
									!(cover instanceof File)
										? objectUrls[0]
										: cover
								}
								alt={"profile"}
							/>
						}
					/>
				</div>

				<PublicInfo subtext="cover picture" fixedMargin={true} />

				<AddCaption
					onChange={handleCaption}
					disabled={!cover}
					value={caption}
				/>

				<div className={styles.img_options}>
					<input
						ref={inputFile}
						type="file"
						accept="image/png, image/jpg, image/jpeg"
						onChange={(e) => {
							handleCover(e);
							e.target.value = "";
						}}
						style={{ display: "none" }}
					/>
					<button
						onClick={() => {
							inputFile.current.click();
						}}
					>
						<img
							src={uploadIcon}
							className={styles.icon}
							alt={"upload"}
						/>
						Upload Cover Picture
					</button>

					{cover && (
						<button onClick={handleDownload}>
							<img
								src={downloadIcon}
								className={styles.icon}
								alt="download"
							/>
							Download
						</button>
					)}

					{cover && (
						<button onClick={() => setDeleteModalOpened(true)}>
							<img
								src={deleteIcon}
								className={styles.icon}
								alt="Delete"
							/>
							Delete Cover Picture
						</button>
					)}
				</div>

				{cover !== userCover && (
					<div className={styles.feed_checkbox_container}>
						<FeedShare
							checked={shareCheck}
							onChange={() =>
								setShareCheck((prevState) => !prevState)
							}
						/>
					</div>
				)}

				<div className={styles.button_wrapper}>
					<PrimaryButton
						onClick={handleSave}
						isLoading={isLoading}
						type="button"
						text={"save"}
						width={130}
						disabled={!(cover !== userCover)}
					/>
				</div>
			</div>

			<PromptModal when={onBlock} />

			<WarningModal
				show={deleteModalOpened}
				headerText="Are you sure you want to delete this image?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setDeleteModalOpened(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => {
					setAllowNavigation(true);
					deleteCoverPic(editCompany && userData.uuid);
				}}
			/>

			<CropModal
				image={cover instanceof File && previewCropURL}
				show={cropModal}
				onClose={() => {
					setCropModal(false);
					revokePreviewCropURL();
				}}
				aspectMode={25 / 7}
				onCrop={(cropped) => {
					cropped.fileName = cover.name;
					setCover(cropped);
					revokePreviewCropURL();
					setCropModal(false);
				}}
				returnBlob={true}
				canEscape={false}
			/>
		</>
	);
};

export default CoverEdit;
