import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useDebounce from "hooks/useDebounce";
import usePaginatedResource from "hooks/usePaginatedResource";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./UserListModal.module.scss";
import { classNames } from "primereact/utils";
import PrimaryButton from "../Button/PrimaryButton";
import TagContainer from "../SubComs/Tags/TagContainer";
import Tag from "../SubComs/Tags/Tag";

const itemsPerPage = 20;

const UserListModal = ({
	show,
	onClose,
	title,
	fetchUsers,
	renderUser,
	queryKey,
	hasSearch = false,
	closeAlign = "right",
	showSelectedContainer = false,
	selectedUsers = [],
	onConfirm,
	onRemove,
}) => {
	const [searchInput, setSearchInput] = useState("");
	const debouncedQuery = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		setSearchInput(target.value);
	};

	const handleClearSearch = () => {
		setSearchInput("");
	};

	const { data, isFetching, hasNextPage, fetchNextPage } =
		usePaginatedResource(
			queryKey(debouncedQuery),
			fetchUsers,
			itemsPerPage,
			show
		);

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	//TODO >> also filter the below is you have a selectedUSers arrays passed in props

	const userList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((user, i) => (
					<Fragment key={user.uuid}>
						{renderUser({
							user,
							ref: page.length === i + 1 ? viewRef : null,
						})}
					</Fragment>
				))
			),
		[data, renderUser]
	);

	const selectedTags = useMemo(
		() =>
			selectedUsers?.map((user, index) => (
				<Tag
					key={user.uuid}
					itemName={user.name}
					onRemove={() => onRemove(user, index)}
				/>
			)),
		[selectedUsers]
	);

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={closeAlign}
		>
			<div className={styles.container}>
				{hasSearch && (
					<SearchInput
						onChange={handleSearchInputChange}
						value={searchInput}
						showIcons={true}
						onClearClicked={handleClearSearch}
						border={false}
					/>
				)}

				{showSelectedContainer && (
					<TagContainer
						customStyle={styles.tags_container}
						scrollToBottomEnabled={true}
						items={selectedTags}
					/>
				)}

				<div
					className={classNames(styles.users_container, {
						[styles.no_margin_top]: !hasSearch,
					})}
				>
					<div className={styles.users_list}>{userList}</div>
					{isFetching && <LoadingSpinner />}
				</div>

				{onConfirm && (
					<PrimaryButton
						className={styles.confirm_btn}
						onClick={onConfirm}
						text={"Done"}
					/>
				)}
			</div>
		</GaawkModal>
	);
};

export default UserListModal;
