import servicesApi from "api/services";
import Picker from "emoji-picker-react";
import emojiIcon from "images/emoji.svg";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";
import { Mention, MentionsInput } from "react-mentions";
import { toast } from "react-toastify";
import hashtagApi from "../../../api/hashtag";
import profileApi from "../../../api/profile";
import useApi from "../../../hooks/useApi";
import useClickOutside from "../../../hooks/useClickOutside";
import ProfilePic from "../../Home/ProfilePic";
import styles from "./CustomMentions.module.css";

const CustomMentions = forwardRef(
	(
		{
			value,
			onChange,
			onEmojiSelected,
			placeholder,
			fixedHeight = false,
			h,
			onKeyDown,
			maxHeight = 120,
			isChat = false,
			isComment = false,
			searchApi,
			groupId,
			showEmojiButton = true,
			maxLength,
		},
		ref
	) => {
		const inputRef = useRef(null);

		useImperativeHandle(ref, () => ({
			// clearInput() {
			//     onChange("");
			// },
			focusInput() {
				inputRef.current?.focus();
			},
		}));

		const wrapperRef = useRef(null);
		useClickOutside(wrapperRef, () => setPickerOpened(false));

		const [pickerOpened, setPickerOpened] = useState(false);

		// * THE SEARCH PROFILE API =============================

		const [delayHandler, setDelayHandler] = useState(null);

		const getHashtagsApi = useApi(hashtagApi.getHashtags, true);
		const searchProfilesApi = useApi(profileApi.search, true);
		const searchServicesApi = useApi(servicesApi.searchServices, true);

		const apiCall = (query, mode) => {
			switch (mode) {
				case "users":
					if (isChat && groupId) {
						return searchApi.request(groupId, query);
					} else if (!isChat) {
						return searchProfilesApi.request(
							undefined,
							undefined,
							query
						);
					} else {
						return;
					}

				case "hashtags":
					return getHashtagsApi.request(query);

				case "services":
					return searchServicesApi.request(0, 10, query);

				default:
					break;
			}
		};

		const fetch = (query, callback, mode) => {
			if (!query) return;

			setDelayHandler(
				setTimeout(async () => {
					const response = await apiCall(query, mode);

					if (!response) return; // will return when in 1to1 convo (mention disabled)

					if (response.status === 200) {
						if (response.data?.length > 0) {
							let formattedData = [];
							if (mode === "users") {
								formattedData = response.data.map((user) => {
									const { name, url, ...rest } = user;

									return {
										display: name,
										id: url,
										...rest,
									};
								});
							} else if (mode === "hashtags") {
								formattedData = response.data.map((item) => ({
									display: item.name,
									id: item.name,
									usedCount: item.usedCount,
									uuid: item.uuid,
								}));
							} else if (mode === "services") {
								formattedData = response.data.map((item) => ({
									display: item.name,
									id: item.tag,
									tag: item.tag,
									minifiedProfile: item.minifiedProfile,
								}));
							}
							callback(formattedData);
						}
					} else {
						toast.error("Something Wrong Happened");
					}
				}, 500)
			);
		};

		useEffect(() => {
			return () => {
				clearTimeout(delayHandler);
			};
		}, [delayHandler]);

		// * ====================================================

		const renderUserSuggestion = (
			entry,
			search,
			highlightedDisplay,
			index,
			focused
		) => {
			const { id: url, profileImage: { image } = {}, type } = entry;

			return (
				<div className={styles.suggestion_item}>
					<ProfilePic
						enableName={false}
						thumbnail={image}
						type={type}
					/>

					<div>
						<div className={styles.name}>{highlightedDisplay}</div>
						<div>{url}</div>
					</div>
				</div>
			);
		};

		const renderHashSuggestion = (
			entry,
			search,
			highlightedDisplay,
			index,
			focused
		) => {
			const { usedCount } = entry;

			return (
				<div className={styles.suggestion_item}>
					<div>
						<div className={styles.name}>{highlightedDisplay}</div>
						<div>Used Count: {usedCount}</div>
					</div>
				</div>
			);
		};

		const renderServiceSuggestion = (
			entry,
			search,
			highlightedDisplay,
			index,
			focused
		) => {
			const { tag, minifiedProfile } = entry;

			return (
				<div className={styles.suggestion_item}>
					<ProfilePic
						thumbnail={minifiedProfile.profileImage.image}
						type={minifiedProfile.type}
					/>

					<div>
						<div className={styles.name}>{highlightedDisplay}</div>
						<div>{tag}</div>
					</div>
				</div>
			);
		};

		const inputStyle = useMemo(() => {
			if (fixedHeight && h) {
				return {
					"&multiLine": {
						control: {
							height: h,
						},
						highlighter: {
							height: h,
						},
						input: {
							overflow: "auto",
						},
					},
				};
			} else if (maxHeight) {
				return {
					"&multiLine": {
						control: {
							maxHeight: `${maxHeight}px`,
						},
						highlighter: {
							maxHeight: `${maxHeight}px`,
						},
						input: {
							overflow: "auto",
						},
					},
				};
			}
		}, [fixedHeight, h, maxHeight]);

		// const handleChange = (event, newValue, newPlainTextValue) => {
		// 	// Only update if within character limit
		// 	if (newPlainTextValue.length <= MAX_LENGTH) {
		// 		// setValue(newValue);
		// 		onChange(newValue);
		// 	}
		// };

		// const handlePaste = (event) => {
		// 	event.preventDefault();

		// 	const currentText = event.target.value;
		// 	const pastedText = event.clipboardData.getData("text");
		// 	const remainingSpace = MAX_LENGTH - currentText.length;

		// 	if (remainingSpace > 0) {
		// 		// Only take what fits within the limit
		// 		const truncatedPaste = pastedText.slice(0, remainingSpace);
		// 		const newValue = currentText + truncatedPaste;
		// 		onChange(newValue);
		// 	}
		// };

		const handleChange = (event, newValue, newPlainTextValue) => {
			// Only check length if maxLength is defined
			if (
				maxLength === undefined ||
				newPlainTextValue.length <= maxLength
			) {
				onChange(newValue);
			}
		};

		const handlePaste = (event) => {
			// If no maxLength, allow paste without restrictions
			if (maxLength === undefined) return;

			const currentLength = event.target.value.length;
			const pastedText = event.clipboardData.getData("text");

			if (currentLength + pastedText.length > maxLength) {
				event.preventDefault();
				// Optional: truncate instead of blocking
				const remainingSpace = maxLength - currentLength;
				if (remainingSpace > 0) {
					const truncatedPaste = pastedText.slice(0, remainingSpace);
					const newValue = event.target.value + truncatedPaste;
					onChange(newValue);
				}
			}
		};

		return (
			<div
				className={`${styles.container}  ${
					isChat ? styles.isChat : undefined
				} ${isComment ? styles.isComment : undefined}`}
			>
				<MentionsInput
					allowSuggestionsAboveCursor={true}
					value={value}
					// onChange={(
					// 	{ target },
					// 	newValue,
					// 	newPlainTextValue,
					// 	mentions
					// ) => {
					// 	onChange(target.value);
					// 	handleChange(newValue, newPlainTextValue, mentions);
					// }}
					onChange={handleChange}
					onPaste={handlePaste}
					placeholder={placeholder}
					classNames={styles}
					onKeyDown={onKeyDown}
					style={inputStyle}
					inputRef={inputRef}
					maxLength={maxLength}
				>
					<Mention
						displayTransform={(url) => `@${url}`}
						trigger="@"
						data={(query, callback) =>
							fetch(query, callback, "users")
						}
						// className={styles.mentions__mention} // disabled (bugged for safari / firefox)
						appendSpaceOnAdd
						renderSuggestion={renderUserSuggestion}
						// markup="@(user:__id__)"
						markup="@__id__ "
					/>

					<Mention
						displayTransform={(hashtag) => `#${hashtag}`}
						trigger="#"
						data={(query, callback) =>
							fetch(query, callback, "hashtags")
						}
						// className={styles.mentions__mention} // disabled (bugged for safari / firefox)
						appendSpaceOnAdd
						renderSuggestion={renderHashSuggestion}
						// markup="#(hashtag:__id__)"
						markup="#__id__ "
					/>

					<Mention
						displayTransform={(service) => `+${service}`}
						trigger="+"
						data={(query, callback) =>
							fetch(query, callback, "services")
						}
						// className={styles.mentions__mention} // disabled (bugged for safari / firefox)
						appendSpaceOnAdd
						renderSuggestion={renderServiceSuggestion}
						// markup="#(hashtag:__id__)"
						markup="+__id__ "
					/>
				</MentionsInput>

				<div ref={wrapperRef}>
					{showEmojiButton && (
						<button
							className={`${styles.emoji_button_wrapper}`}
							onClick={() => setPickerOpened(!pickerOpened)}
						>
							<img src={emojiIcon} alt={"emoji picker"} />
						</button>
					)}

					{pickerOpened && (
						<div
							style={{
								top: fixedHeight && h ? `${h}px` : undefined,
							}}
							className={`${styles.emoji_container}`}
						>
							<Picker
								preload={true}
								onEmojiClick={onEmojiSelected}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
);

export default CustomMentions;
