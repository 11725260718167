import { useState, useRef, useImperativeHandle, forwardRef } from "react";
import styles from "./CreatePost.module.css";
// import calendarIcon from "images/calendar-icon.svg";
// import cropIcon from "images/crop-icon-white.svg";
// import locationIcon from "images/location-icon-white.svg";
// import doubleTagIcon from "images/double-tag-white.svg";
// import servicesIcon from "images/services-icon-white.png";
import Popover from "../Utils/Popover/Popover";
import Calendar from "react-calendar";

import { ReactComponent as CropIcon } from "images/crop.svg";
import { ReactComponent as PinIcon } from "images/location_icon.svg";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import { ReactComponent as TagIcon } from "images/tag-icon.svg";
import { ReactComponent as ServiceIcon } from "images/services.svg";

const EditMediaButtons = forwardRef(
	(
		{
			mediaType,
			// isEditing,
			onLocation,
			onTag,
			onDatePicker,
			onCrop,
			onServices,
			isNewFile = false,
		},
		ref
	) => {
		const popoverRef = useRef(null);
		const [date, setDate] = useState(new Date());

		useImperativeHandle(ref, () => ({
			resetDate() {
				setDate(new Date());
			},
		}));

		return (
			<div className={styles.post_buttons_wrapper}>
				{mediaType === "IMAGE" && isNewFile && (
					<button onClick={onCrop}>
						<CropIcon />
					</button>
				)}
				<button onClick={onLocation}>
					<PinIcon />
				</button>

				<Popover
					ref={popoverRef}
					allow={["top", "bottom"]}
					render={
						<div className="popover_container">
							<Calendar
								className={"no-shadow"}
								onChange={(selectedDate) => {
									setDate(selectedDate);
									onDatePicker(selectedDate);
									popoverRef.current.closePopover();
								}}
								value={date}
							/>
						</div>
					}
				>
					<button>
						<CalendarIcon />
					</button>
				</Popover>

				<button onClick={onTag}>
					<TagIcon />
				</button>

				<button onClick={onServices}>
					<ServiceIcon fill="#000" />
				</button>
			</div>
		);
	}
);

export default EditMediaButtons;
