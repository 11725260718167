import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteSoftware } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./SoftwareEntry.module.css";
import SoftwareItem from "./SoftwareItem";

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { softwareList } = useCurrentUser();

	const [softId, setSoftId] = useState("");
	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const handleDelete = (softwareId) => {
		setSoftId(softwareId);
		setWarningDeleteModal(true);
	};

	const handleConfirmDelete = () => {
		dispatch(deleteSoftware(softId));
		setWarningDeleteModal(false);
	};

	//! usememo ?

	const userSoftwareList = softwareList.map((software) => (
		<SoftwareItem
			key={software.uuid}
			item={software}
			onEditClicked={() =>
				navigate(routes.editSoftware, {
					state: software,
				})
			}
			onDelete={() => handleDelete(software.uuid)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW SOFTWARE"
					onClick={() => navigate(routes.addSoftware)}
				/>
			</div>

			{softwareList.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Software you have added</h4>

					<div className={styles.software_section}>
						{userSoftwareList}
					</div>
				</div>
			)}

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDelete}
			/>
		</>
	);
};

export default Index;
