import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import useCompanyId from "hooks/useCompanyId";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CityModal from "../../components/Boards/BoardsComponents/CityModal";
import SearchFilterLeftSide from "../../components/Search/SearchFilterLeftSide";
import SearchFilterRightSide from "../../components/Search/SearchFilterRightSide";
import Filter from "../../components/Utils/Filter/Filter";
import Tag from "../../components/Utils/SubComs/Tags/Tag";
import useUserLocation from "../../hooks/useUserLocation";
import useWindowSize from "../../hooks/useWindowSize";
import useUrlParams from "hooks/useUrlParams";

const SearchFilterView = ({ userCoordinates: coordinates, flag }) => {
	const { width } = useWindowSize();
	const isMobile = width < 748;

	const leftSideRef = useRef();
	const didMountRef = useRef(false);

	const companyId = useCompanyId();

	const [showModal, setShowModal] = useState(false);
	const [userCoordinates, setUserCoordinates] = useState(coordinates);
	const [countryFlag, setCountryFlag] = useState(flag);

	const { userCoordinates: coord, handleSelectedCity } = useUserLocation(setCountryFlag);

	const tabItems = ["all", "people", "companies", "posts", "jobs", "products", "services"];

	const paramConfigs = {
		tab: {
			validator: (tab) => tabItems.includes(tab),
			defaultValue: "all",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);
	const { tab: activeTab } = params;

	useEffect(() => {
		if (didMountRef.current) {
			setUserCoordinates(coord);
		}
		didMountRef.current = true;
	}, [coord]);

	const [filterModal, setFilterModal] = useState();

	useEffect(() => {
		if (filterModal && !isMobile) setFilterModal(false);
	}, [isMobile, filterModal]);

	const storedFilters = useSelector((state) => state.filters);

	const tagsList = useMemo(() => {
		const valuesList = [];
		let selectedFilters = {};
		switch (activeTab) {
			case "people":
				selectedFilters = storedFilters.peopleFilters;
				break;
			case "companies":
				selectedFilters = storedFilters.companiesFilters;
				break;
			//* no dynamic filters for posts so not needed for the moment
			// case "posts":
			// 	selectedFilters = storedFilters.postsFilters;
			// 	break;
			case "jobs":
				selectedFilters = storedFilters.jobsFilters;
				break;
			case "products":
				selectedFilters = storedFilters.productsFilters;
				break;
			case "services":
				selectedFilters = storedFilters.servicesFilters;
				break;
			default:
				break;
		}

		for (const [k, v] of Object.entries(selectedFilters)) {
			// ignoring the tag creation when the key is 'locationIds'
			if (Array.isArray(v) && v?.length > 0 && k !== "locationIds") {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.label}
							itemName={item.label}
							onRemove={() => leftSideRef.current.updateDynamicFilters(k, item)}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [activeTab, storedFilters]);

	return (
		<>
			<Filter
				isMobile={isMobile}
				onCloseModal={() => setFilterModal(false)}
				hiddenMode={!filterModal}
				headerButton={
					<button onClick={() => setShowModal(true)}>
						<img src={countryFlag} alt="" />
					</button>
				}
				leftSideChildren={
					activeTab === "all" ? (
						<HomeLeftSidebar />
					) : (
						<SearchFilterLeftSide
							ref={leftSideRef}
							activeTab={activeTab}
							userCoordinates={userCoordinates}
							companyId={companyId}
						/>
					)
				}
				rightSideChildren={
					<SearchFilterRightSide
						onTabClick={(e) => setParams({ tab: e.target ? e.target?.innerHTML : e })}
						activeTab={activeTab}
						isMobile={isMobile}
						onShowModal={() => setFilterModal(true)}
						tagsList={tagsList}
						userCoordinates={userCoordinates}
						tabItems={tabItems}
					/>
				}
			/>

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default SearchFilterView;
