import React, { forwardRef, useState } from "react";

import styles from "./TextInput.module.scss";

import { ReactComponent as VisibleIcon } from "images/eye-solid.svg";
import { ReactComponent as InvisibleIcon } from "images/eye-slash-solid.svg";
import { classNames } from "primereact/utils";

const TextInput = forwardRef(
	(
		{
			value,
			onChange,
			id,
			name,
			placeholder = "Type your message here",
			bgColor = false,
			onKeyDown,
			onKeyUp,
			borderRadius,
			maxLength,
			disabled,
			onBlur,
			error,
			onClick,
			readOnly = false,
			keepFocused = false,
			cursor = "text",
			disabledText = "You can't message this person",
			onFocus,
			isPassword = false,
			autoComplete = "off",
			type = "text",
			leftSideItem,
			inputClassName,
			inputStyle = {},
			min,
		},
		ref
	) => {
		const style = {
			backgroundColor: bgColor ? bgColor : "transparent",
			borderRadius: borderRadius ? borderRadius : "5px",
			cursor: `${cursor}`,
		};

		const [showPwd, setShowPwd] = useState(false);

		return (
			<div className={styles.input_wrapper}>
				{leftSideItem}
				<input
					ref={ref}
					id={id}
					name={name}
					className={classNames(styles.input, inputClassName, {
						[styles.disabled]: disabled,
						[styles.error]: error,
						[styles.focused]: keepFocused,
						[styles.unfocused]: !keepFocused,
					})}
					type={isPassword ? (showPwd ? "text" : "password") : type}
					value={value}
					onChange={onChange}
					placeholder={`${disabled ? disabledText : placeholder}`}
					style={{ style, ...inputStyle }}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
					maxLength={maxLength ? maxLength : undefined}
					disabled={disabled}
					onBlur={onBlur}
					onClick={onClick}
					readOnly={readOnly}
					onFocus={onFocus}
					autoComplete={autoComplete}
					min={min}
				/>

				{isPassword && (
					<button
						className={styles.visibility_button}
						type="button"
						onClick={() => setShowPwd((prevState) => !prevState)}
					>
						{showPwd ? (
							<InvisibleIcon fill="#a6b1bc" />
						) : (
							<VisibleIcon fill="#a6b1bc" />
						)}
					</button>
				)}
			</div>
		);
	}
);
export default TextInput;
