import { joiResolver } from "@hookform/resolvers/joi";
import softwareApi from "api/software";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import useApi from "hooks/useApi";
import { softwareKeys } from "queryKeys/software-key-factory";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { addSoftware, updateSoftware } from "../../../../store/slices/user";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import RectangleCheckbox from "../../../Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
import ProficiencySelector from "./ProficiencySelector";
import softwareSchema from "./software-schema";
import styles from "./SoftwareEntry.module.css";

const itemsPerPage = 20;

const SoftwareEntry = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname, state } = useLocation();

	const editMode = pathname === routes.editSoftware;

	const {
		uuid,
		activelyGaining,
		months: expMonths,
		years: expYears,
		proficiency: expProficiency,
		software,
	} = state || {};

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		watch,
		handleSubmit,
		control,
		setError,
		clearErrors,
	} = useForm({
		resolver: joiResolver(softwareSchema),
		mode: "onChange",
		defaultValues: editMode
			? {
					proficiency: expProficiency,
					software: { label: software?.name, value: software?.uuid },
					expAmount: {
						years:
							expYears > 0
								? { label: expYears, value: expYears }
								: null,
						months:
							expMonths > 0
								? { label: expMonths, value: expMonths }
								: null,
					},
					activelyGaining,
			  }
			: {
					proficiency: null,
					software: null,
					expAmount: { years: null, months: null },
					activelyGaining: false,
			  },
	});

	const expWatcher = watch("expAmount");
	const proficiency = watch("proficiency");

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			if (expWatcher.years || expWatcher.months) {
				clearErrors("expAmount");
			} else {
				setError("expAmount", {
					message: "Enter at least one: years or months",
				});
			}
		}
		didMountRef.current = true;
	}, [expWatcher.years, expWatcher.months]);

	const didMountRef2 = useRef(false);

	useEffect(() => {
		if (didMountRef2.current) {
			if (proficiency) {
				clearErrors("proficiency");
			} else {
				setError("proficiency", {
					message: "Proficiency is required",
				});
			}
		}
		didMountRef2.current = true;
	}, [proficiency]);

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	//TODO >> improvement, remove already added software by the user from the list below ?
	//TODO >> otherwise user can add the same software with different values

	const searchSoftwareApi = useApi(softwareApi.searchSoftware, true, true);

	const fetchSoftware = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchSoftwareApi.request(
			pageParam,
			itemsPerPage,
			searchInput
		);
		return response.data;
	};
	//TODO END OF TODO==== ==================================================

	const handleSave = (data) => {
		const {
			activelyGaining,
			expAmount: { years, months },
			proficiency,
			software: { value: softwareId },
		} = data;

		dispatch(
			editMode
				? updateSoftware({
						uuid,
						activelyGaining,
						months: months ? months.value : 0,
						years: years ? years.value : 0,
						proficiency,
						shareAsPost: true,
						softwareId,
				  })
				: addSoftware({
						activelyGaining,
						months: months ? months.value : 0,
						years: years ? years.value : 0,
						proficiency,
						shareAsPost: true,
						softwareId,
				  })
		);

		setShouldBlockSave(false);
	};

	useEffect(() => {
		if (!shouldBlockSave) navigate(routes.software, { replace: true });
	}, [navigate, shouldBlockSave]);

	if (editMode && !state) {
		return <Navigate to={routes.software} />;
	}

	return (
		<>
			<div className={styles.container}>
				<form
					className={styles.add_form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<InfiniteSearchInput
						label={"Software name"}
						required={true}
						queryName={softwareKeys.search}
						queryFn={fetchSoftware}
						itemsPerPage={itemsPerPage}
						formatter={defaultFormatter}
						error={errors?.software}
						control={control}
						name="software"
						customStyle={styles.no_margin_top}
					/>

					<ProficiencySelector
						proficiency={proficiency}
						register={register}
						setValue={setValue}
						errors={errors}
					/>

					<div className={styles.form_input_wrapper}>
						<label>
							Amount of experience
							<span className="required">*</span>
						</label>

						<div className={styles.dropdown_container}>
							<div className={styles.dropdown_item}>
								<CustomSelect
									fontSize="14px"
									placeholder="Years"
									options={Array.from(
										{ length: 99 },
										(_, i) => i + 1
									).map((item) => ({
										label: item,
										value: item,
									}))}
									isClearable={true}
									error={errors?.expAmount?.root?.message}
									control={control}
									name="expAmount.years"
									isSearchable={false}
								/>
							</div>

							<div className={styles.dropdown_item}>
								<CustomSelect
									fontSize="14px"
									placeholder="Months"
									options={Array.from(
										{ length: 11 },
										(_, i) => i + 1
									).map((item) => ({
										label: item,
										value: item,
									}))}
									isClearable={true}
									error={errors?.expAmount?.root?.message}
									control={control}
									name="expAmount.months"
									isSearchable={false}
								/>
							</div>
						</div>
						{errors?.expAmount?.root?.message && (
							<p
								className={styles.error_message}
								style={{ textAlign: "left" }}
							>
								{errors?.expAmount?.root?.message}
							</p>
						)}
						<div className={styles.actively_looking_wrapper}>
							<RectangleCheckbox
								{...register("activelyGaining")}
								name="activelyGaining"
							/>

							<span>
								I am still actively gaining experience in this
								software
							</span>
						</div>
					</div>

					<div className={styles.button_container}>
						<PrimaryButton
							className={styles.save_btn}
							text={"save"}
						/>
					</div>
				</form>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default SoftwareEntry;
