import { useInfiniteQuery } from "@tanstack/react-query";
import servicesApi from "api/services";
import useApi from "hooks/useApi";
import { classNames } from "primereact/utils";
import { servicesKeys } from "queryKeys/services-key-factory";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import ServiceAccordionItem from "./ServiceAccordionItem";
import styles from "./ServicesAccordion.module.css";
import useCurrentUser from "hooks/useCurrentUser";
// import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";

const PAGE_SIZE = 20;

const ServicesTree = ({
	data,
	showServicesWithProducts = false,
	productsToShow = 8,
	radioMode = false,
	serviceLvl = 1,
	parentId = "",
	onRadioSelect,
	selected = "",
	onServicesLoad,
	enabled = false,
	accordionParentId = "",
	serviceDetailsView = false,
	userId,
}) => {
	// const [selectedItem, setSelectedItem] = useState(selected);

	const {
		minifiedProfile: { uuid: serviceOwnerId } = {},
		uuid: serviceId,
		level,
		parents,
	} = data || {};

	// console.log("🚀 ~ serviceId >>", serviceId);

	// const { serviceId: serviceIdParams } = useParams();
	// console.log("🚀 ~ serviceIdParams >>", serviceIdParams);

	const { uuid: loggedUserId } = useCurrentUser();
	// const companyId = useCompanyId();
	// const { uuid: loggedUserId } = useSelector((state) =>
	// 	companyId ? state.company.companyInfo : state.user.profileInfo
	// );

	const userIdToUse = userId ?? loggedUserId;

	const getServicesApi = useApi(
		showServicesWithProducts
			? servicesApi.getServicesWithProducts
			: servicesApi.getServices,
		true,
		true
	);

	const fetchServices = async ({ pageParam = 0, queryKey }) => {
		const [, , level, ownerId, parentId, hasProducts] = queryKey;

		const response = await getServicesApi.request(
			ownerId,
			pageParam,
			PAGE_SIZE,
			hasProducts ? productsToShow : "",
			parentId && parentId,
			level && level
		);

		return response.data;
	};

	const {
		data: services,
		hasNextPage,
		fetchNextPage,
		isFetching,
		// isLoading,
	} = useInfiniteQuery({
		queryKey: servicesKeys.serviceLvl(
			serviceLvl,
			serviceOwnerId ?? userIdToUse,
			parentId,
			showServicesWithProducts
		),
		queryFn: fetchServices,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === PAGE_SIZE ? pages.length : undefined;
			return nextPage;
		},

		refetchOnWindowFocus: false,
		enabled: !!data || enabled,
		// cacheTime: 30000,
		// staleTime: 30000, // 5 minutes
	});

	useEffect(() => {
		onServicesLoad && onServicesLoad(services);
	}, [services]);

	const { ref: viewRef, inView } = useInView({
		triggerOnce: true,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) fetchNextPage();
	}, [fetchNextPage, hasNextPage, inView, isFetching]);

	const [idToOpen, setIdToOpen] = useState("");

	// console.log(
	// 	"%c 🆂 Services >> ",
	// 	"color: orange; font-weight: bolder",
	// 	services
	// );

	// console.log("%c 🅻 serviceLvl >>", "color: cyan", serviceLvl);

	// console.log(
	// 	"%c 🅳 Service details >>",
	// 	"color: lime; font-weight: bolder;",
	// 	data
	// );

	// console.log(
	// 	"%c 🅸🅳 idToOpen >> ",
	// 	"color: fuchsia; font-weight: bolder; border: 1px solid fuchsia",
	// 	idToOpen
	// );

	// console.log("==================");

	useEffect(() => {
		let idToFound;

		if (serviceLvl === 1) {
			if (level === 2 || level === 3) {
				idToFound = parents?.p3?.uuid;
			} else {
				idToFound = serviceId;
			}
		} else if (serviceLvl === 2) {
			if (level === 3) {
				idToFound = parents?.p2?.uuid;
			} else {
				idToFound = serviceId;
			}
		}
		setIdToOpen(idToFound);

		const flattenServices = services?.pages.flat();

		const serviceIndex = flattenServices?.findIndex(
			(service) => service.uuid === idToFound
		);

		if (serviceIndex === -1) {
			fetchNextPage();
		}

		// return () => setIdToOpen("");
	}, [
		services,
		serviceLvl,
		level,
		parents?.p3?.uuid,
		parents?.p2?.uuid,
		serviceId,
		fetchNextPage,
	]);

	const servicesItems = useMemo(
		() =>
			services?.pages?.map((page, i) =>
				page?.map((service) => {
					// console.log("🚀 ~ service >>", service);
					// service.uuid === idToOpen &&
					// 	console.log(
					// 		"%c <<< CURRENT ACTIVE SERVICE >>>",
					// 		"color: red;"
					// 	);
					// console.log(
					// 	"%c SERVICE NAME >>",
					// 	"color: cyan;",
					// 	service.name
					// );
					// console.log(
					// 	"%c SERVICE UUID >>",
					// 	"color: orange;",
					// 	service.uuid
					// );
					// console.log("%c idToOpen >>", "color: lime;", idToOpen);
					// console.log("==============");
					const isActive = (serviceId || selected) === service.uuid;

					return (
						<div
							ref={page.length === i + 1 ? viewRef : null}
							key={service.uuid}
						>
							<ServiceAccordionItem
								// onToggle={() => console.log("service ", service)}
								isOpen={
									(radioMode &&
										service.uuid === accordionParentId) ||
									(service.uuid === idToOpen &&
										level !== service.level) //!  should be commented ?
								}
								name={service.name}
								LinkComponent={
									!radioMode ? (
										<Link
											to={
												showServicesWithProducts
													? `/services/${service.uuid}/products`
													: `/services/${service.uuid}`
											}
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											{service.name}
										</Link>
									) : (
										service.name
									)
								}
								className={classNames(styles.accordion_item, {
									[styles.active]: isActive,
									[styles.level_2]: service.level === 2,
									[styles.level_3]: service.level === 3,
									[styles.details_view]: serviceDetailsView,
									[styles.level_3_fullwidth]:
										serviceDetailsView && level === 2,
								})}
								isActiveService={isActive}
								level={service.level}
								selectedItem={radioMode ? selected : idToOpen}
								itemKey={service.uuid}
								content={
									<ServicesTree
										serviceLvl={service.level + 1}
										data={data}
										radioMode={radioMode}
										enabled={true}
										parentId={service.uuid}
										onRadioSelect={onRadioSelect}
										selected={selected}
										showServicesWithProducts={
											showServicesWithProducts
										}
										productsToShow={productsToShow}
										serviceDetailsView={serviceDetailsView}
										userId={userId}
									/>
								}
								radioMode={radioMode}
								onRadioSelect={(serviceId) => {
									onRadioSelect(
										serviceId,
										serviceLvl + 1,
										parentId
									);
								}}
								productsCount={
									showServicesWithProducts &&
									service.productsCount
								}
								// showServicesWithProducts={
								// 	showServicesWithProducts
								// }
							/>
						</div>
					);
				})
			),
		[
			serviceId,
			idToOpen,
			level,
			services?.pages,
			selected,
			radioMode,
			showServicesWithProducts,
		] //serviceLvl
	);

	// if (isLoading) return <LoadingSpinner />;

	return <div>{servicesItems}</div>;
};

export default ServicesTree;
