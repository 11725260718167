import { featuresSection } from "components/Utils/General";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import useGetQueryParam from "hooks/useGetQueryParam";
import { companiesKeys } from "queryKeys/companies-key-factory";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import profileApi from "../../api/profile";
import CityModal from "../../components/Boards/BoardsComponents/CityModal";
import Companies from "../../components/More/CompaniesPage/Companies";
import InfiniteUsersPage from "../../components/More/MoreComponents/UsersComponents/InfiniteUsersPage";
import Filter from "../../components/Utils/Filter/Filter";
import useUserLocation from "../../hooks/useUserLocation";
import useWindowSize from "../../hooks/useWindowSize";

const CompaniesView = () => {
	const { pathname } = useLocation();

	const { paramValue: inputValue } = useGetQueryParam("q");

	const { width } = useWindowSize();
	const isMobile = width < 748;

	const [showModal, setShowModal] = useState(false);
	const [countryFlag, setCountryFlag] = useState();

	const { userCoordinates, handleSelectedCity } = useUserLocation(setCountryFlag);

	let component;
	let headerTitle;

	switch (pathname) {
		case "/companies":
			component = <Companies userCoordinates={userCoordinates} />;
			headerTitle = inputValue ? "Search Companies" : "Companies";
			break;

		case "/companies/nearby":
			component = (
				<InfiniteUsersPage
					queryKey={companiesKeys.nearbyCompanies("infinite", userCoordinates)}
					endpoint={profileApi.getNearbyCompanies}
				/>
			);
			headerTitle = "Companies near you";
			break;

		case "/companies/hiring":
			component = (
				<InfiniteUsersPage
					queryKey={companiesKeys.hiringCompanies("infinite", userCoordinates)}
					endpoint={profileApi.getHiringCompanies}
				/>
			);
			headerTitle = "Companies hiring now";
			break;

		case "/companies/network":
			component = (
				<InfiniteUsersPage
					queryKey={companiesKeys.networkCompanies("infinite")}
					endpoint={profileApi.getNetworkCompanies}
				/>
			);
			headerTitle = "Companies you may know";
			break;

		default:
			break;
	}

	return (
		<>
			<Filter
				title={headerTitle}
				isMobile={isMobile}
				headerButton={
					<button onClick={() => setShowModal(true)}>
						<img src={countryFlag} alt="" />
					</button>
				}
				leftSideChildren={<FeaturesSidebar active={featuresSection.companies} />}
				rightSideChildren={component}
			/>
			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default CompaniesView;
