import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import Registration from "components/Registeration/Registration";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Chat from "./components/Chat";
import Search from "./components/Search";
import CompanyView from "./views/company/CompanyView";
import CompaniesView from "./views/companies/CompaniesView";
import PeopleView from "./views/people/PeopleView";
import VaultFolder from "./views/vault/VaultFolder";
import Vault from "./views/vault/Vault";
import VaultUpload from "./views/vault/VaultUpload";
import More from "./components/More/Index";
import ConnectionPage from "./components/Profile/Connection/ConnectionPage";
// import PrivateRoute from "./components/Routing/PrivateRoute";
// import PublicRoute from "./components/Routing/PublicRoute";
import CreateAlbumView from "./views/gallery/CreateAlbumView";
import AlbumView from "./views/gallery/AlbumView";
import AlbumUploaderView from "./views/gallery/AlbumUploaderView";
import UpdateWorkStatusView from "./views/work-status/UpdateWorkStatusView";
import CalendarView from "./views/calendar/CalendarView";
import CalendarEntryView from "./views/calendar/CalendarEntryView";
import CompanyManageRoute from "./components/Routing/CompanyManageRoute";
import EditProfileView from "./views/edit-profile/EditProfileView";
import SpecialProfileView from "./views/special-profile/SpecialProfileView";
import JobsView from "./views/jobs/JobsView";
import TalentFilterView from "./views/talents/TalentFilterView";
import VaultItemViewer from "./components/Vault/VaultItemViewer";
import BoardsView from "./views/boards/BoardsView";
import ServicesView from "./views/services/ServicesView";
import ProductsView from "./views/products/ProductsView";
import Settings from "./components/Settings/Settings";
import PasswordChange from "./components/Settings/PasswordChange";
import PhoneChange from "./components/Settings/PhoneChange";
import Faq from "components/Static/Faq/Faq";
import PrivacyPolicy from "components/Static/PrivacyPolicy/PrivacyPolicy";
import Help from "components/Static/Help/Help";
import RequireAuth from "components/Routing/RequireAuth";
import routes from "components/Routing/routing-keys";
import Notifications from "components/Notifications/Notifications";
import FeedByHashtag from "components/Home/FeedByHashtag";
import RootBoundary from "components/Errors/RootBoundary";
import PostPreview from "components/Home/PostPreview";
import AppBlocker from "./admin/AppBlocker";
import AdminLogin from "./admin/AdminLogin";
import Terms from "components/Static/Terms/Terms";
import About from "components/Static/About/About";
import SocketProvider from "components/Utils/PageArchs/SocketProvider/SocketProvider";
import StorageView from "views/storage/StorageView";
import PlansView from "views/plans/PlansView";

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route errorElement={<RootBoundary />}>
			<Route path="/admin" element={<AdminLogin />} />
			<Route element={<AppBlocker />}>
				<Route path="/help" element={<Help />} />
				<Route path="/about" element={<About />} />
				<Route path="/terms" element={<Terms />} />
				<Route path="/faq" element={<Faq />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/password/reset" element={<Registration />} />
				<Route
					path={routes.login}
					element={
						<RequireAuth restricted>
							<Registration />
						</RequireAuth>
					}
				/>

				<Route element={<SocketProvider />}>
					<Route
						path={routes.root}
						element={
							<RequireAuth>
								<Home />
							</RequireAuth>
						}
					/>

					<Route
						path="/posts"
						element={
							<RequireAuth>
								<FeedByHashtag />
							</RequireAuth>
						}
					/>

					<Route
						path="/post/:id/:subPostId?"
						element={
							<RequireAuth>
								<PostPreview />
							</RequireAuth>
						}
					/>

					<Route
						path="/profile/role"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"role"}
									activeKey={"profile"}
									headerTitle={"Roles"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/edit/bio"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"bio"}
									activeKey={"profile"}
									headerTitle={"Bio"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/experience/edit"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"experience-add-edit"}
									activeKey={"profile"}
									headerTitle={"Edit Experience"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/experience/add"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"experience-add-edit"}
									activeKey={"profile"}
									headerTitle={"Add Experience"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/experience"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"experience"}
									activeKey={"profile"}
									headerTitle={"Experiences"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/software/edit"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"software-add-edit"}
									activeKey={"profile"}
									headerTitle={"Edit Software"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/software/add"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"software-add-edit"}
									activeKey={"profile"}
									headerTitle={"Add Software"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/software"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"software"}
									activeKey={"profile"}
									headerTitle={"Software"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/education/edit"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"education-add-edit"}
									activeKey={"profile"}
									headerTitle={"Edit Education"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/education/add"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"education-add-edit"}
									activeKey={"profile"}
									headerTitle={"Add Education"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/education"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"education"}
									activeKey={"profile"}
									headerTitle={"Education"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/certificate/add"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"certificate-add-edit"}
									activeKey={"profile"}
									headerTitle={"Add Certificate / Award"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/certificate/edit"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"certificate-add-edit"}
									activeKey={"profile"}
									headerTitle={"Edit Certificate / Award"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/certificate"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"certificate"}
									activeKey={"profile"}
									headerTitle={"Certificates & Awards"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/language/edit"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"language-add-edit"}
									activeKey={"profile"}
									headerTitle={"Edit Language"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/language/add"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"language-add-edit"}
									activeKey={"profile"}
									headerTitle={"Add Language"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/language"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"language"}
									activeKey={"profile"}
									headerTitle={"Languages"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/skill"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"skill"}
									activeKey={"profile"}
									headerTitle={"Skills"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/key-people/add"
						element={
							<RequireAuth companyOnly={true}>
								<EditProfileView
									activeItem={"keyPeople-add"}
									activeKey={"profile"}
									headerTitle={"Add Key People"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/key-people"
						element={
							<RequireAuth companyOnly={true}>
								<EditProfileView
									activeItem={"keyPeople"}
									activeKey={"profile"}
									headerTitle={"Key People"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/sp/add"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"specialProfile-add"}
									activeKey={"special"}
									headerTitle={"Create Special Profile"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/sp"
						element={
							<RequireAuth userOnly={true}>
								<EditProfileView
									activeItem={"specialProfile-edit"}
									activeKey={"special"}
									headerTitle={"Special Profile"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/contact/edit"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"contact-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Edit Phone"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/contact/add"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"contact-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Add Phone"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/contact"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"contact"}
									activeKey={"contacts"}
									headerTitle={"Phone"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/email/edit"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"email-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Edit Web"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/email/add"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"email-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Add Web"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/email"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"email"}
									activeKey={"contacts"}
									headerTitle={"Web"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/social/edit"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"social-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Edit Social Media"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/social/add"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"social-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Add Social Media"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/social"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"social"}
									activeKey={"contacts"}
									headerTitle={"Social Media"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/address/edit"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"address-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Edit Address"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/address/add"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"address-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Add Address"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/address"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"address"}
									activeKey={"contacts"}
									headerTitle={"Addresses"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/other/edit"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"other-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Edit Other Info"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/other/add"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"other-add-edit"}
									activeKey={"contacts"}
									headerTitle={"Add Other Info"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/other"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"other"}
									activeKey={"contacts"}
									headerTitle={"Other Info"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/profilePic"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"profilePic"}
									activeKey={"profile"}
									headerTitle={"Edit Profile Picture"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/coverPic"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"coverPic"}
									activeKey={"profile"}
									headerTitle={"Edit Cover Picture"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/edit"
						element={
							<RequireAuth>
								<EditProfileView
									activeItem={"edit-info"}
									headerTitle={"Edit Info"}
								/>
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/album/add"
						element={
							<RequireAuth>
								<CreateAlbumView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/album/:albumId/uploader"
						element={
							<RequireAuth>
								<AlbumUploaderView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/album/:albumId/edit"
						element={
							<RequireAuth>
								<AlbumView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/album/:albumId"
						element={
							<RequireAuth>
								<AlbumView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/workstatus"
						element={
							<RequireAuth userOnly={true}>
								<UpdateWorkStatusView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/calendar/edit"
						element={
							<RequireAuth userOnly={true}>
								<CalendarEntryView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/calendar/add"
						element={
							<RequireAuth userOnly={true}>
								<CalendarEntryView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/calendar"
						element={
							<RequireAuth userOnly={true}>
								<CalendarView />
							</RequireAuth>
						}
					/>
					<Route
						path="/products"
						element={
							<RequireAuth>
								<ProductsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl/products"
						element={
							<RequireAuth>
								<ProductsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl/product/:productId"
						element={
							<RequireAuth>
								<ProductsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/services/nearby"
						element={
							<RequireAuth>
								<ServicesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/services/connections"
						element={
							<RequireAuth>
								<ServicesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/services/:serviceId/medias"
						element={
							<RequireAuth>
								<ServicesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/services/:serviceId/products"
						element={
							<RequireAuth>
								<ServicesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/services/:serviceId"
						element={
							<RequireAuth>
								<ServicesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/services"
						element={
							<RequireAuth>
								<ServicesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl?/connections"
						element={
							<RequireAuth>
								<ConnectionPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl/manage"
						element={
							<RequireAuth>
								<CompanyManageRoute />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl/manage/agency"
						element={
							<RequireAuth>
								<CompanyManageRoute />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl/manage/transfer"
						element={
							<RequireAuth>
								<CompanyManageRoute />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl?/talents"
						element={
							<RequireAuth>
								<TalentFilterView />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile/:userUrl?"
						element={
							<RequireAuth>
								<Profile />
							</RequireAuth>
						}
					/>
					<Route
						path="/sp/:userUrl/edit/general"
						element={
							<RequireAuth>
								<SpecialProfileView />
							</RequireAuth>
						}
					/>
					<Route
						path="/sp/:userUrl/edit/physical"
						element={
							<RequireAuth>
								<SpecialProfileView />
							</RequireAuth>
						}
					/>
					<Route
						path="/sp/:userUrl/edit/media"
						element={
							<RequireAuth>
								<SpecialProfileView />
							</RequireAuth>
						}
					/>
					<Route
						path="/sp/:userUrl/edit/vocal"
						element={
							<RequireAuth>
								<SpecialProfileView />
							</RequireAuth>
						}
					/>
					<Route
						path="/sp/:userUrl/edit/more"
						element={
							<RequireAuth>
								<SpecialProfileView />
							</RequireAuth>
						}
					/>
					<Route
						path="/sp/:userUrl/edit"
						element={
							<RequireAuth>
								<SpecialProfileView />
							</RequireAuth>
						}
					/>
					<Route
						path="/sp/:userUrl"
						element={
							<RequireAuth>
								<SpecialProfileView />
							</RequireAuth>
						}
					/>

					<Route
						path="/boards/:boardId"
						element={
							<RequireAuth>
								<BoardsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/boards"
						element={
							<RequireAuth>
								<BoardsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/agency"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/talents"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/filter"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/results"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/advanced"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/applied"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/saved"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/project/:projectId/edit"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/project/:projectId"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/:jobId/confirmation"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>

					<Route
						path="/jobs/create"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/:jobId/edit"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>

					{/* //! CREATE, SEE ALL AND EDIT >> only for COMPANY */}
					<Route
						path="/jobs/:jobId/apply"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/:jobId/applicants/:applicationId"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/:jobId/applicants"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					{/* //! REVIEW AND APPLY >> only for USER */}
					<Route
						path="/jobs/:jobId"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					<Route
						path="/jobs/project/add"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>
					{/* <Route
						path="/project/create"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/> //TODO >> to be deleted, replaced by the above /jobs/project/add */}

					<Route
						path="/jobs/projects"
						element={
							<RequireAuth>
								<JobsView />
							</RequireAuth>
						}
					/>

					{/* //! CREATE AND SEE ALL PROJECTS >> only for COMPANY */}
					<Route
						path="/chat"
						element={
							<RequireAuth>
								<Chat />
							</RequireAuth>
						}
					/>

					<Route
						path="/more"
						element={
							<RequireAuth>
								<More />
							</RequireAuth>
						}
					/>

					<Route
						path="/search"
						element={
							<RequireAuth>
								<Search />
							</RequireAuth>
						}
					/>
					<Route
						path="/my-companies/add"
						element={
							<RequireAuth>
								<CompanyView />
							</RequireAuth>
						}
					/>
					<Route
						path="/my-companies"
						element={
							<RequireAuth>
								<CompanyView />
							</RequireAuth>
						}
					/>
					<Route
						path="/companies/network"
						element={
							<RequireAuth>
								<CompaniesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/companies/nearby"
						element={
							<RequireAuth>
								<CompaniesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/companies/hiring"
						element={
							<RequireAuth>
								<CompaniesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/companies"
						element={
							<RequireAuth>
								<CompaniesView />
							</RequireAuth>
						}
					/>
					<Route
						path="/people/nearby"
						element={
							<RequireAuth>
								<PeopleView />
							</RequireAuth>
						}
					/>
					<Route
						path="/people/similar"
						element={
							<RequireAuth>
								<PeopleView />
							</RequireAuth>
						}
					/>
					<Route
						path="/people/network"
						element={
							<RequireAuth>
								<PeopleView />
							</RequireAuth>
						}
					/>
					<Route
						path="/people/my-company"
						element={
							<RequireAuth>
								<PeopleView />
							</RequireAuth>
						}
					/>
					<Route
						path="/people"
						element={
							<RequireAuth>
								<PeopleView />
							</RequireAuth>
						}
					/>

					<Route
						path="/storage"
						element={
							<RequireAuth>
								<StorageView />
							</RequireAuth>
						}
					/>

					<Route
						path="/plans"
						element={
							<RequireAuth>
								<PlansView />
							</RequireAuth>
						}
					/>

					<Route
						path="/plans/subscribe"
						element={
							<RequireAuth>
								<PlansView />
							</RequireAuth>
						}
					/>

					<Route
						path="/plans/payment"
						element={
							<RequireAuth>
								<PlansView />
							</RequireAuth>
						}
					/>

					<Route
						path="/plans/invoice"
						element={
							<RequireAuth>
								<PlansView />
							</RequireAuth>
						}
					/>

					<Route
						path="/vault/uploader/:id?"
						element={
							<RequireAuth>
								<VaultUpload />
							</RequireAuth>
						}
					/>
					<Route
						path="/vault/item/:id"
						element={
							<RequireAuth>
								<VaultItemViewer />
							</RequireAuth>
						}
					/>
					<Route
						path="/vault/:id"
						element={
							<RequireAuth>
								<VaultFolder />
							</RequireAuth>
						}
					/>
					<Route
						path="/vault"
						element={
							<RequireAuth>
								<Vault />
							</RequireAuth>
						}
					/>
					<Route
						path="/settings/change-password"
						element={
							<RequireAuth>
								<PasswordChange />
							</RequireAuth>
						}
					/>
					<Route
						path="/settings/change-phone"
						element={
							<RequireAuth>
								<PhoneChange />
							</RequireAuth>
						}
					/>
					<Route
						path="/settings"
						element={
							<RequireAuth>
								<Settings />
							</RequireAuth>
						}
					/>
					<Route
						path="/notifications"
						element={
							<RequireAuth>
								<Notifications />
							</RequireAuth>
						}
					/>
				</Route>
			</Route>
		</Route>
	)
);
