import { useEffect, useState } from "react";
import styles from "./People.module.scss";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import UserSection from "../MoreComponents/UsersComponents/UserSection";
import profileApi from "../../../api/profile";
import SearchUserSection from "../MoreComponents/UsersComponents/SearchUserSection";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCompanyId from "hooks/useCompanyId";
import { peopleKeys } from "queryKeys/people-key-factory";
import searchApi from "api/search";
import { searchKeys } from "queryKeys/search-key-factory";
import { useSelector } from "react-redux";
import routes from "components/Routing/routing-keys";

const People = ({ userCoordinates }) => {
	const navigate = useNavigate();
	const companyId = useCompanyId();

	//! =========== SEARCH INPUT HANDLER =============

	const [searchParams, setSearchParams] = useSearchParams({ q: "" });

	const [searchInput, setSearchInput] = useState(searchParams.get("q"));

	const debouncedSearch = useDebounce(searchInput);

	useEffect(() => {
		setSearchParams(
			(prev) => {
				prev.set("q", debouncedSearch);
				return prev;
			},
			{ replace: true }
		);
	}, [debouncedSearch]);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	// !===========

	const peopleFiltersObject = useSelector((state) => state.filters.peopleFilters);

	const storedPeopleFilters = {
		...peopleFiltersObject,
		q: debouncedSearch,
		locationIdForSort: userCoordinates.id,
	};

	return (
		<div className={styles.container}>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={() => setSearchInput("")}
					border={false}
					placeholder={"Search People"}
					classNameInput={styles.search_input}
				/>
			</div>

			{searchInput ? (
				<SearchUserSection
					searchQueryKey={searchKeys.people(storedPeopleFilters)}
					searchEndpoint={searchApi.searchUsers}
				/>
			) : (
				<>
					<UserSection
						title="People you may know"
						queryKey={peopleKeys.networkPeople("static")}
						endpoint={profileApi.getNetworkPeople}
						onViewMore={() => navigate(routes.peopleNetwork)}
					/>

					<UserSection
						title="People in your area"
						queryKey={peopleKeys.nearbyPeople("static", userCoordinates)}
						endpoint={profileApi.getPeopleNearby}
						onViewMore={() => navigate(routes.peopleNearby)}
					/>

					<UserSection
						title="People who work in your company"
						queryKey={peopleKeys.companyPeople("static")}
						endpoint={profileApi.getPeopleFromMyCompany}
						onViewMore={() => navigate(routes.peopleInMyCompany)}
					/>

					{!companyId && (
						<UserSection
							title="People with similar interests"
							queryKey={peopleKeys.interestPeople("static", userCoordinates)}
							endpoint={profileApi.getPeopleInterests}
							onViewMore={() => navigate(routes.peopleSimilar)}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default People;
