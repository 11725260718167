import { useQueryClient } from "@tanstack/react-query";
import { formatFiltersValues } from "components/Utils/General";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { searchKeys } from "queryKeys/search-key-factory";
import { useSelector } from "react-redux";
import searchApi from "../../../api/search";
import PostsComponent from "../SearchComponents/Posts";
import styles from "./All.module.scss";

const PAGE_SIZE = 40;

const Posts = ({ isMobile, onShowModal, tagsList, userCoordinates }) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const postsFiltersObject = useSelector((state) => state.filters.postsFilters);

	const storedPostsFilters = {
		...postsFiltersObject,
		q: inputValue,
		locationIdForSort: userCoordinates.id,
	};

	const searchPostsApi = useApi(searchApi.searchPosts, true, true);

	const fetchPosts = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchPostsApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const { items, isFetchingNextPage, viewRef, isLoading } = useInfiniteScroll({
		queryKey: searchKeys.posts(storedPostsFilters),
		queryFn: fetchPosts,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.list?.length === PAGE_SIZE ? pages.length : undefined;
				return nextPage;
			},
		},
	});

	const data = items?.flatMap((page) => page.list);

	const count = items?.[0]?.count;

	const queryClient = useQueryClient();

	const handleInvalidation = () => {
		queryClient.invalidateQueries(searchKeys.posts(storedPostsFilters));
	};

	return (
		<div className={styles.section}>
			<div className={`${styles.sub_section} ${styles.posts_container}`}>
				<PostsComponent
					viewRef={viewRef}
					data={data}
					count={count}
					isPaginated={true}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
					isFetchingNextPage={isFetchingNextPage}
					onRefresh={handleInvalidation}
				/>
			</div>
		</div>
	);
};

export default Posts;
