import { useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import { formatFiltersValues } from "components/Utils/General";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import { searchKeys } from "queryKeys/search-key-factory";
import { useSelector } from "react-redux";
import JobsComponent from "../SearchComponents/Jobs";
import styles from "./All.module.scss";

const PAGE_SIZE = 40;

const Jobs = ({ isMobile, onShowModal, tagsList, userCoordinates }) => {
	const queryClient = useQueryClient();

	const { paramValue: inputValue } = useGetQueryParam("q");

	const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

	const storedJobsFilters = {
		...jobsFiltersObject,
		q: inputValue,
		locationId: userCoordinates.id,
	};

	const searchJobsApi = useApi(jobApi.search, true, true);

	const fetchJobs = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchJobsApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const { items, isFetchingNextPage, viewRef, isLoading } = useInfiniteScroll({
		queryKey: searchKeys.jobs(storedJobsFilters),
		queryFn: fetchJobs,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.list?.length === PAGE_SIZE ? pages.length : undefined;
				return nextPage;
			},
		},
	});

	const data = items?.flatMap((page) => page.list);

	const count = items?.[0]?.count;

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, (data, variables) => {
		const { jobId } = variables;
		//* OPTIMISTIC UPDATE
		queryClient.setQueryData(searchKeys.jobs(storedJobsFilters), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) => ({
					count: page.count,
					list: page.list.map((job) =>
						job.uuid === jobId ? { ...job, savedJob: !job.savedJob } : job
					),
				})),
			};
		});
	});

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<JobsComponent
					viewRef={viewRef}
					data={data}
					count={count}
					isPaginated={true}
					isFetchingNextPage={isFetchingNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
					onToggleSave={toggleSave}
				/>
			</div>
		</div>
	);
};

export default Jobs;
