import useCurrentUser from "hooks/useCurrentUser";
import CompanyEntry from "../../../Companies/CompanyEntry";
import EditUserInfo from "./EditUserInfo";

const EditInfo = () => {
	const { type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	return <>{editCompany ? <CompanyEntry editMode /> : <EditUserInfo />}</>;
};

export default EditInfo;
