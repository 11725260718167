import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import styles from "./Filters.module.css";

import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import {
	vocalAge,
	vocalRange,
	pitch,
} from "../../ProfileEdit/SpecialProfile/special-types";

const VocalFilters = forwardRef(({ onChange, selectedStaticFilters }, ref) => {
	const didMountRef = useRef(false);

	const vocalAgeRef = useRef();
	const pitchRef = useRef();
	const vocalRangeRef = useRef();

	const [vocalFilters, setVocalFilters] = useState(selectedStaticFilters);
	const {
		vocalAges = [],
		pitches = [],
		vocalRanges = [],
	} = vocalFilters || {};

	useEffect(() => {
		let timer;
		if (didMountRef.current && Object.keys(vocalFilters).length > 0) {
			timer = setTimeout(() => {
				onChange(vocalFilters);
			}, 500);
		}
		didMountRef.current = true;
		return () => clearTimeout(timer);
	}, [vocalFilters]);

	useImperativeHandle(ref, () => ({
		updateDynamicFilters(key, tag) {
			switch (key) {
				case "pitches":
					pitchRef.current.removeItem(tag);
					break;
				case "vocalAges":
					vocalAgeRef.current.removeItem(tag);
					break;
				case "vocalRanges":
					vocalRangeRef.current.removeItem(tag);
					break;
				default:
					break;
			}
		},
		clear() {
			setVocalFilters({});
			vocalAgeRef.current.clear();
			pitchRef.current.clear();
			vocalRangeRef.current.clear();
		},
	}));

	return (
		<div className={styles.padding_container}>
			<MultiCheckbox
				ref={vocalAgeRef}
				title={"Vocal Age"}
				options={vocalAge}
				onSelect={(vocals) => {
					setVocalFilters((prevState) => ({
						...prevState,
						vocalAges: vocals,
						// vocalAges: vocals.map((vocal) => vocal.value),
					}));
				}}
				selected={vocalAges}
				perRow="2, 150px"
				customStyle={styles.multicheckbox_container}
			/>

			<MultiCheckbox
				ref={pitchRef}
				title={"Pitch"}
				options={pitch}
				onSelect={(pitches) => {
					setVocalFilters((prevState) => ({
						...prevState,
						pitches: pitches,
						// pitches: pitches.map((pitch) => pitch.value),
					}));
				}}
				selected={pitches}
				perRow="2, 150px"
				customStyle={styles.multicheckbox_container}
			/>

			<MultiCheckbox
				ref={vocalRangeRef}
				title={"Vocal Range"}
				options={vocalRange}
				onSelect={(ranges) => {
					setVocalFilters((prevState) => ({
						...prevState,
						vocalRanges: ranges,
						// vocalRanges: ranges.map((range) => range.value),
					}));
				}}
				selected={vocalRanges}
				perRow="2, 150px"
				customStyle={styles.multicheckbox_container}
			/>
		</div>
	);
});

export default VocalFilters;
