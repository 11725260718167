import { useQuery, useQueryClient } from "@tanstack/react-query";
import boardsApi from "api/boards";
import Feeds from "components/Home/Feeds";
import BoardEntry from "components/More/BoardsPage/BoardEntry";
import routes from "components/Routing/routing-keys";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import SecondaryButton from "components/Utils/Button/SecondaryButton";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import { pluralize } from "components/Utils/General";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import ReadMore from "components/Utils/SubComs/ReadMore/ReadMore";
import useApi from "hooks/useApi";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { ReactComponent as ArrowIcon } from "images/arrow-icon.svg";
import { boardsKeys } from "queryKeys/boards-key-factory";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BoardItem from "../BoardsComponents/BoardItem";
import BoardPendingRequests from "../BoardsComponents/BoardPendingRequests";
import ManageFollowers from "../BoardsComponents/ManageFollowers";
import ManageModerators from "../BoardsComponents/ManageModerators";
import styles from "./BoardDetails.module.scss";
// import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";

const BoardDetails = forwardRef(({ onLoad }, ref) => {
	const { uuid: profileId } = useCurrentUser();
	const navigate = useNavigate();
	const feedsRef = useRef();

	useImperativeHandle(ref, () => ({
		showPostModal() {
			feedsRef.current.showCreateModal();
		},
		editBoard() {
			setShowBoardModal(true);
		},
		deleteBoard() {
			setShowDeleteModal(true);
		},

		moderatorLeave() {
			setWarningModal(true);
		},

		manageModerators() {
			setModeratorModal(true);
		},
	}));

	const queryClient = useQueryClient();

	// !===== MANAGE FOLLOWERS MODAL =====

	const [showFollowersModal, setShowFollowersModal] = useState(false);

	//! ==== MANAGE MODERATORS MODAL ====
	const [moderatorModal, setModeratorModal] = useState(false);

	//! ==== LEAVE MODERATOR ROLE ====

	const [warningModal, setWarningModal] = useState(false);

	const {
		action: { mutate: deleteModerator },
	} = useMutate(boardsApi.deleteBoardModerator, () => {
		setWarningModal(false);
		queryClient.invalidateQueries(boardsKeys.detail(boardId));
	});

	// ! ==== PENDING FOLLOWERS ====

	const [pendingModal, setPendingModal] = useState(false);

	// ! ==== board entry ====

	const [showBoardModal, setShowBoardModal] = useState(false);

	// ! ==== DELETE BOARD ====

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const {
		action: { mutate: deleteBoard },
	} = useMutate(boardsApi.deleteBoard, () => navigate(routes.root));

	// ! ===== fetch board ======

	const { boardId } = useParams();

	const getBoardApi = useApi(boardsApi.getBoard, true, true);

	const fetchBoard = async ({ queryKey }) => {
		const [_, __, boardId] = queryKey;
		const response = await getBoardApi.request(boardId);
		return response.data;
	};

	const {
		data: board,
		isLoading,
		// isFetching,
		// isError,
	} = useQuery({
		queryKey: boardsKeys.detail(boardId),
		queryFn: fetchBoard,
	});

	const {
		image: { file: { customName: coverImage } = {} } = {},
		// feedImage: { file: { customName: feedImage } = {} } = {},
		// name,
		// description,
		// locationDTO: {
		// 	name: cityName = "",
		// 	countryDTO: { name: countryName } = {},
		// } = {},
		// followersCount,
		// followers,
		// moderatorsCount,
		// moderators,
		// pinnedCount,
		// follower,
		// moderator,
		owner: { uuid: ownerId } = {},
		moderator,
		pendingFollowersCount,
		member,
		description,
		status,
		privateBoard,
	} = board || {};

	const isBoardOwner = ownerId === profileId;

	useEffect(() => {
		onLoad(board);
	}, [board]);

	// ! ======= REQUEST HANDLERS ========

	const [unfollowModal, setUnfollowModal] = useState(false);

	const onSuccessRequest = (response) => {
		queryClient.invalidateQueries(boardsKeys.detail(board.uuid));
		if (response.status === 200) setUnfollowModal(false);
	};

	const {
		action: { mutate: sendFollowRequest },
	} = useMutate(boardsApi.addFollower, onSuccessRequest);
	const {
		action: { mutate: cancelRequest },
	} = useMutate(boardsApi.deleteFollower, onSuccessRequest);

	// !=================================

	if (isLoading) return <LoadingPage />;

	return (
		<>
			<div className={styles.cover_wrapper}>
				<img src={coverImage} alt="" />
			</div>

			<div className={styles.section}>
				<div className={styles.boards_info}>
					<BoardItem
						data={board}
						className={styles.board_item}
						detailView={true}
						onShowModerators={() => setModeratorModal(true)}
						onShowFollowers={() => setShowFollowersModal(true)}
					/>

					<div className={styles.button_container}>
						{member && (
							<PrimaryButton
								type={"button"}
								text={"POST"}
								className={styles.board_controls}
								onClick={() => feedsRef.current.showCreateModal()}
							/>
						)}

						{!isBoardOwner &&
							(member ? (
								<SecondaryButton
									type={"button"}
									text={"unfollow"}
									className={`${styles.board_controls} ${styles.secondary}`}
									onClick={() => setUnfollowModal(true)}
								/>
							) : (
								<>
									{status === "NONE" && (
										<PrimaryButton
											type={"button"}
											text={"follow"}
											className={styles.board_controls}
											onClick={() =>
												sendFollowRequest({
													boardId,
													profileId,
												})
											}
										/>
									)}

									{status === "REQUESTED" && (
										<SecondaryButton
											type={"button"}
											text={"request pending"}
											className={`${styles.board_controls} ${styles.secondary}`}
											onClick={() =>
												cancelRequest({
													boardId,
													profileId,
												})
											}
										/>
									)}
								</>
							))}
					</div>
				</div>

				{(isBoardOwner || moderator) && pendingFollowersCount > 0 && (
					<div className={styles.pending_wrapper}>
						<button onClick={() => setPendingModal(true)}>
							{`${pendingFollowersCount} ${pluralize(
								pendingFollowersCount,
								"follower"
							)}
							request pending`}
							<ArrowIcon />
						</button>
					</div>
				)}

				{description && (
					<ReadMore customStyle={styles.description} lines={3}>
						{description}
					</ReadMore>
				)}
			</div>

			{(!privateBoard || (privateBoard && member)) && (
				<div className={styles.feed_section}>
					<FeedContainer>
						<div className={styles.search_wrapper}>
							<h3>Posts</h3>
						</div>

						{board && <Feeds ref={feedsRef} hasSearch={true} board={board} />}
					</FeedContainer>
				</div>
			)}

			<BoardEntry
				data={board}
				show={showBoardModal}
				onClose={() => setShowBoardModal(false)}
			/>

			<GaawkModal
				show={warningModal}
				title={"Leave your role as moderator"}
				closeAlign={"right"}
				handleClose={() => setWarningModal(false)}
				defaultModal={false}
				showHeader={true}
				children={
					<div className={styles.modal_container}>
						Are you sure you want to remove your role as a moderator in the board?
						You'll no longer be part of it.
						<div className={styles.modal_button_wrapper}>
							<SecondaryButton
								className={styles.cancel_btn}
								text={"cancel"}
								onClick={() => setWarningModal(false)}
							/>
							<PrimaryButton
								className={styles.confirm_btn}
								text={"confirm"}
								onClick={() => deleteModerator({ boardId, profileId })}
							/>
						</div>
					</div>
				}
			/>

			<GaawkModal
				show={unfollowModal}
				title={"Unfollow this board"}
				closeAlign={"right"}
				handleClose={() => setUnfollowModal(false)}
				defaultModal={false}
				showHeader={true}
				children={
					<div className={styles.modal_container}>
						{`Are you sure you want to unfollow ${board.name}`}
						<div className={styles.modal_button_wrapper}>
							<SecondaryButton
								className={styles.cancel_btn}
								text={"cancel"}
								onClick={() => setUnfollowModal(false)}
							/>
							<PrimaryButton
								className={styles.confirm_btn}
								text={"confirm"}
								onClick={() =>
									cancelRequest({
										boardId,
										profileId,
									})
								}
							/>
						</div>
					</div>
				}
			/>

			<GaawkModal
				show={showDeleteModal}
				title={"Delete board"}
				closeAlign={"right"}
				handleClose={() => setShowDeleteModal(false)}
				defaultModal={false}
				showHeader={true}
				children={
					<div className={styles.modal_container}>
						{`Are you sure you want to delete the board: ${board.name}`}
						<div className={styles.modal_button_wrapper}>
							<SecondaryButton
								className={styles.cancel_btn}
								text={"cancel"}
								onClick={() => setShowDeleteModal(false)}
							/>
							<PrimaryButton
								className={styles.confirm_btn}
								text={"confirm"}
								onClick={() => deleteBoard(boardId)}
							/>
						</div>
					</div>
				}
			/>

			<ManageModerators
				canEdit={isBoardOwner || moderator}
				show={moderatorModal}
				onClose={() => setModeratorModal(false)}
				boardId={board?.uuid}
			/>

			<ManageFollowers
				canEdit={isBoardOwner || moderator}
				show={showFollowersModal}
				boardId={board?.uuid}
				appointModeratorEnabled={false}
				onClose={() => setShowFollowersModal(false)}
			/>

			<BoardPendingRequests
				show={pendingModal}
				onClose={() => setPendingModal(false)}
				boardId={board?.uuid}
			/>
		</>
	);
});

export default BoardDetails;
