import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";

import styles from "./UserVault.module.css";
import VaultItems from "./VaultItems";
import SortingButtons from "../Utils/SubComs/SortingButtons/SortingButtons";
import SelectCommands from "../Utils/SubComs/SelectCommands/SelectCommands";
import VaultUserButtons from "./VaultUserButtons";

const UserVault = forwardRef(
	(
		{
			fileItems = [],
			sharedItems = [],
			favoriteItems = [],
			gaawkItems = [],

			order,
			layout,
			// pageSize,
			isLoading,
			// displayCount,
			perPageCount,

			onChangeOrderAction,
			onChangeMeLayout,

			onDelete,
			onDetails,
			onShare,
			onMultiShare,
			onMove,
			onRename,
			onCopy,
			onCreateFolder,
			onFavoriteItem,
			onClick,

			onLoadMoreItems,
			onTabChange,
			vaultTab,
		},
		ref
	) => {
		/**
		 * States
		 */

		const [selectedItems, setSelectedItems] = useState([]);
		// console.log("🚀 ~ selectedItems >>", selectedItems);

		//!! =============== NEW FOLDER HANDLER =========

		const [newFolderModal, setNewFolderModal] = useState(false);
		const [folderName, setFolderName] = useState("");

		//!! ============================================

		useImperativeHandle(ref, () => ({
			resetSelect() {
				setSelectedItems([]);
			},
		}));

		/**
		 * Handlers
		 */

		const handleCreateFolder = () => {
			onCreateFolder(folderName, true, null);
			setFolderName("");
			setNewFolderModal(false);
		};

		const handleSelect = (item) => {
			if (selectedItems.includes(item)) {
				const filteredArray = [...selectedItems].filter(
					(x) => x.uuid !== item.uuid
				);
				setSelectedItems(filteredArray);
			} else {
				setSelectedItems((prevState) => [...prevState, item]);
			}
		};

		const handleMultiDelete = () => {
			onDelete(selectedItems);
		};

		const handleMultiShare = () => {
			onMultiShare(selectedItems);
		};

		const handleMultiMove = () => {
			onMove(selectedItems);
		};

		const handleMultiCopy = () => {
			onCopy(selectedItems);
		};

		const handleClearSelection = () => {
			setSelectedItems([]);
		};

		useEffect(() => {
			handleClearSelection();
		}, [vaultTab]);

		// const handleLoadMoreItems = () => {
		//     switch (vaultTab) {
		//         case "shared":
		//             onLoadMoreItems("shared");
		//             break;
		//         case "favorites":
		//             onLoadMoreItems("favorites");
		//             break;
		//         default:
		//             onLoadMoreItems("files");
		//             break;
		//     }
		// };

		/**
		 * JSX
		 */

		return (
			<>
				<div className={styles.vault_root_container}>
					<div
						className={`${styles.header_container} ${
							selectedItems.length > 0
								? styles.select_commands
								: undefined
						}`}
					>
						{selectedItems.length > 0 ? (
							<SelectCommands
								items={selectedItems}
								onDelete={handleMultiDelete}
								onShare={handleMultiShare}
								onMove={handleMultiMove}
								onCopy={handleMultiCopy}
								onClear={handleClearSelection}
							/>
						) : (
							<>
								<VaultUserButtons
									onCreate={() => setNewFolderModal(true)}
									vaultTab={vaultTab}
								/>

								<SortingButtons
									order={order}
									stateLayout={layout}
									onChangeOrder={onChangeOrderAction}
									onChangeLayout={onChangeMeLayout}
								/>
							</>
						)}
					</div>

					<VaultItems
						items={
							vaultTab === "shared"
								? sharedItems
								: vaultTab === "favorites"
								? favoriteItems
								: vaultTab === "gaawk"
								? gaawkItems
								: fileItems
						}
						isLoading={isLoading}
						perPageCount={perPageCount}
						order={order}
						layout={layout}
						isActive={selectedItems.length > 0}
						selectedItems={selectedItems}
						onSelect={handleSelect}
						onChangeOrder={onChangeOrderAction}
						onChangeLayout={onChangeMeLayout}
						onDelete={onDelete}
						onDetails={onDetails}
						onShare={onShare}
						onMove={onMove}
						onRename={onRename}
						onCopy={onCopy}
						onFavoriteItem={onFavoriteItem}
						onLoadMoreItems={onLoadMoreItems}
						onClick={onClick}
					/>
				</div>

				<GaawkModal
					show={newFolderModal}
					handleClose={() => setNewFolderModal(false)}
					defaultModal={false}
					showHeader={true}
					title={"Create new folder"}
					closeAlign={"right"}
					children={
						<div className={styles.create_folder_container}>
							<label>
								Folder name
								<span className="required">*</span>
							</label>

							<div className={styles.text}>
								<TextInput
									value={folderName}
									onChange={(e) =>
										setFolderName(e.target.value)
									}
									maxLength="45"
								/>
							</div>

							<div className={styles.buttons}>
								<SecondaryButton
									text={"CANCEL"}
									className={styles.cancel_button}
									onClick={() => setNewFolderModal(false)}
								/>
								<PrimaryButton
									text={"CREATE"}
									disabled={folderName === ""}
									className={styles.create_button}
									onClick={handleCreateFolder}
								/>
							</div>
						</div>
					}
				/>
			</>
		);
	}
);

export default UserVault;
