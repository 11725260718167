import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./BioEdit.module.css";
import { bioUpdated } from "../../../../store/slices/user";
import { bioUpdate as companyBioUpdate } from "../../../../store/slices/company";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import PromptModal from "components/Utils/Prompt/PromptModal";
import SecondaryButton from "components/Utils/Button/SecondaryButton";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import { maxPostLength, successfulChangeToast } from "components/Utils/General";
import useCurrentUser from "hooks/useCurrentUser";

const Index = () => {
	const dispatch = useDispatch();
	const [isFocused, setIsFocused] = useState(false);

	const { bio: savedBio, uuid, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const [bio, setBio] = useState("");

	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const validCount = bio.length > 0 && bio.length <= maxPostLength;

	const updateStoreState = (data) => {
		dispatch(editCompany ? companyBioUpdate(data) : bioUpdated(data));
		successfulChangeToast();
	};

	const {
		action: { mutate: updateBio, isLoading },
	} = useMutate(
		editCompany ? profileApi.updateCompanyBio : profileApi.updateBio,
		(response) => {
			updateStoreState(response.data);
		}
	);

	const saveBio = () => {
		const body = editCompany
			? { data: { bio, share: false }, uuid }
			: { data: { bio, share: false } };

		updateBio(body);
	};

	const {
		action: { mutate: deleteBio },
	} = useMutate(
		editCompany ? profileApi.deleteCompanyBio : profileApi.deleteBio,
		(response) => {
			updateStoreState(response.data);
			setWarningDeleteModal(false);
		}
	);

	const handleDelete = () => {
		deleteBio(editCompany ? uuid : "");
	};

	useEffect(() => {
		setBio(savedBio);
	}, [savedBio]);

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<h4 className={styles.title}>Bio</h4>

					<label className={styles.bio_max_label}>
						Maximum 5000 characters
					</label>

					<div
						className={`${styles.textarea_wrapper} ${
							isFocused ? styles.focused : undefined
						}`}
					>
						<textarea
							maxLength={maxPostLength}
							onChange={(e) => setBio(e.target.value)}
							value={bio}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
						/>

						<div className={styles.word_count_wrapper}>
							<span
								className={
									validCount
										? styles.text_count
										: styles.text_count +
										  " " +
										  styles.danger
								}
							>
								{`${bio.length}/${maxPostLength}`}
							</span>
						</div>
					</div>

					<div className={styles.button_wrapper}>
						{bio.length > 0 && savedBio !== "" && (
							<SecondaryButton
								className={styles.delete_button}
								onClick={() => setWarningDeleteModal(true)}
								text="clear"
							/>
						)}

						<PrimaryButton
							type="button"
							className={styles.save_button}
							text={"Save"}
							isLoading={isLoading}
							disabled={!validCount}
							onClick={saveBio}
						/>
					</div>
				</div>
			</div>

			<PromptModal when={savedBio !== bio} />

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleDelete}
			/>
		</>
	);
};

export default Index;
