// import specialProfileApi from "api/special-profile";
// import { formatFiltersValues } from "components/Utils/General";
// import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
// import useApi from "hooks/useApi";
// import useInfiniteScroll from "hooks/useInfiniteScroll";
// import { filterKeys } from "queryKeys/filters-key-factory";
// import {
// 	forwardRef,
// 	useEffect,
// 	useImperativeHandle,
// 	useRef,
// 	useState,
// } from "react";
// import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
// import {
// 	countryFormatter,
// 	defaultFormatter,
// 	languageFormatter,
// } from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
// import styles from "./Filters.module.css";

// const filtersPageSize = 100;

// const TalentDynamicFilters = forwardRef(
// 	(
// 		{
// 			filters: {
// 				accents = [],
// 				languages = [],
// 				ethnicities = [],
// 				nationalities = [],
// 				recordingTypes = [],
// 				voiceCharacteristics = [],
// 			},
// 			selectedDynamicFilters,
// 			onChange,
// 			pageSize,
// 			agencyId,
// 			agenciesOnly,
// 		},
// 		ref
// 	) => {
// 		const didMountRef = useRef(false);

// 		const voiceRef = useRef();
// 		const accentRef = useRef();
// 		const languageRef = useRef();
// 		const ethnicityRef = useRef();
// 		const nationalityRef = useRef();
// 		const recordingTypeRef = useRef();

// 		const [selectedFilters, setSelectedFilters] = useState(
// 			selectedDynamicFilters
// 		);

// 		const {
// 			accentIds = [],
// 			languageIds = [],
// 			voiceCharIds = [],
// 			ethnicityIds = [],
// 			nationalityIds = [],
// 			recordingTypeIds = [],
// 		} = selectedFilters || {};

// 		useEffect(() => {
// 			let timer;
// 			if (
// 				didMountRef.current &&
// 				Object.keys(selectedFilters).length > 0
// 			) {
// 				timer = setTimeout(() => {
// 					onChange(selectedFilters);
// 				}, 500);
// 			}
// 			didMountRef.current = true;
// 			return () => clearTimeout(timer);
// 		}, [selectedFilters]);

// 		useImperativeHandle(ref, () => ({
// 			updateDynamicFilters(key, tag) {
// 				switch (key) {
// 					case "accentIds":
// 						accentRef.current.removeItem(tag);
// 						break;
// 					case "languageIds":
// 						languageRef.current.removeItem(tag);
// 						break;
// 					case "voiceCharIds":
// 						voiceRef.current.removeItem(tag);
// 						break;
// 					case "ethnicityIds":
// 						ethnicityRef.current.removeItem(tag);
// 						break;
// 					case "nationalityIds":
// 						nationalityRef.current.removeItem(tag);
// 						break;
// 					case "recordingTypeIds":
// 						recordingTypeRef.current.removeItem(tag);
// 						break;
// 					default:
// 						break;
// 				}
// 			},
// 			clear() {
// 				setSelectedFilters({});
// 				voiceRef.current?.clear();
// 				accentRef.current?.clear();
// 				languageRef.current?.clear();
// 				ethnicityRef.current?.clear();
// 				nationalityRef.current?.clear();
// 				recordingTypeRef.current?.clear();
// 			},
// 		}));

// 		// ! ======= FETCHING PAGINATED DYNAMIC FILTERS ======

// 		const getDynamicFilterApi = useApi(
// 			specialProfileApi.getDynamicFilter,
// 			true,
// 			true
// 		);

// 		const fetchDynamicFilters = async ({
// 			queryKey,
// 			signal,
// 			pageParam = 0,
// 		}) => {
// 			const [_, __, type, agencyId, filters, agenciesOnly] = queryKey;
// 			const response = await getDynamicFilterApi.request(
// 				type,
// 				pageParam,
// 				filtersPageSize,
// 				agencyId,
// 				formatFiltersValues(filters),
// 				agenciesOnly
// 			);
// 			return response.data;
// 		};

// 		// * ETHNICITIES ===============

// 		const {
// 			items: ethnicitiesItems,
// 			loadMore: loadMoreEthnicities,
// 			hasNextPage: hasNextPageEthnicities,
// 			refetch: refetchEthnicities,
// 		} = useInfiniteScroll({
// 			queryKey: filterKeys.dynamicTalentFilter(
// 				"ethnicity",
// 				agencyId,
// 				selectedFilters,
// 				agenciesOnly
// 			),
// 			queryFn: fetchDynamicFilters,
// 			pageSize: filtersPageSize,
// 			enabled: false,
// 		});

// 		// * NATIONALITIES ===============

// 		const {
// 			items: nationalitiesItems,
// 			loadMore: loadMoreNationalities,
// 			hasNextPage: hasNextPageNationalities,
// 			refetch: refetchNationalities,
// 		} = useInfiniteScroll({
// 			queryKey: filterKeys.dynamicTalentFilter(
// 				"nationalities",
// 				agencyId,
// 				selectedFilters,
// 				agenciesOnly
// 			),
// 			queryFn: fetchDynamicFilters,
// 			pageSize: filtersPageSize,
// 			enabled: false,
// 		});

// 		// * LANGUAGES ===================

// 		const {
// 			items: languagesItems,
// 			loadMore: loadMoreLanguages,
// 			hasNextPage: hasNextPageLanguages,
// 			refetch: refetchLanguages,
// 		} = useInfiniteScroll({
// 			queryKey: filterKeys.dynamicTalentFilter(
// 				"languages",
// 				agencyId,
// 				selectedFilters,
// 				agenciesOnly
// 			),
// 			queryFn: fetchDynamicFilters,
// 			pageSize: filtersPageSize,
// 			enabled: false,
// 		});

// 		// * ACCENTS ===================

// 		const {
// 			items: accentsItems,
// 			loadMore: loadMoreAccents,
// 			hasNextPage: hasNextPageAccents,
// 			refetch: refetchAccents,
// 		} = useInfiniteScroll({
// 			queryKey: filterKeys.dynamicTalentFilter(
// 				"accents",
// 				agencyId,
// 				selectedFilters,
// 				agenciesOnly
// 			),
// 			queryFn: fetchDynamicFilters,
// 			pageSize: filtersPageSize,
// 			enabled: false,
// 		});

// 		// * RECORDING TYPE ===================

// 		const {
// 			items: recordingItems,
// 			loadMore: loadMoreRecording,
// 			hasNextPage: hasNextPageRecording,
// 			refetch: refetchRecording,
// 		} = useInfiniteScroll({
// 			queryKey: filterKeys.dynamicTalentFilter(
// 				"recordingTypes",
// 				agencyId,
// 				selectedFilters,
// 				agenciesOnly
// 			),
// 			queryFn: fetchDynamicFilters,
// 			pageSize: filtersPageSize,
// 			enabled: false,
// 		});

// 		// * VOICE CHARACTERISTICS ===================

// 		const {
// 			items: voiceCharsItems,
// 			loadMore: loadMoreVoiceChars,
// 			hasNextPage: hasNextPageVoiceChars,
// 			refetch: refetchVoiceChars,
// 		} = useInfiniteScroll({
// 			queryKey: filterKeys.dynamicTalentFilter(
// 				"voiceChars",
// 				agencyId,
// 				selectedFilters,
// 				agenciesOnly
// 			),
// 			queryFn: fetchDynamicFilters,
// 			pageSize: filtersPageSize,
// 			enabled: false,
// 		});

// 		return (
// 			<div className={styles.container}>
// 				<div className={styles.padding_container}>
// 					<div className={styles.multicheckbox_container}>
// 						{ethnicities.length > 0 && (
// 							<MultiCheckbox
// 								ref={ethnicityRef}
// 								title={"Ethnicities"}
// 								options={(ethnicitiesItems?.length > 0
// 									? ethnicitiesItems
// 									: ethnicities
// 								).map((item) => defaultFormatter(item))}
// 								onSelect={(ethnicities) => {
// 									setSelectedFilters((prevState) => ({
// 										...prevState,
// 										ethnicityIds: ethnicities,
// 									}));
// 								}}
// 								selected={ethnicityIds}
// 								perRow="2, 150px"
// 							/>
// 						)}

// 						{((ethnicitiesItems?.length === 0 &&
// 							ethnicities.length === pageSize) ||
// 							(ethnicitiesItems?.length > 0 &&
// 								hasNextPageEthnicities)) && (
// 							<SeeMore
// 								onClick={
// 									ethnicitiesItems?.length === 0
// 										? refetchEthnicities
// 										: loadMoreEthnicities
// 								}
// 								className={styles.see_more_btn}
// 							/>
// 						)}
// 					</div>

// 					<div className={styles.multicheckbox_container}>
// 						{nationalities.length > 0 && (
// 							<MultiCheckbox
// 								ref={nationalityRef}
// 								title={"Nationalities"}
// 								options={(nationalitiesItems?.length > 0
// 									? nationalitiesItems
// 									: nationalities
// 								).map((item) => countryFormatter(item))}
// 								onSelect={(nationalities) => {
// 									setSelectedFilters((prevState) => ({
// 										...prevState,
// 										nationalityIds: nationalities,
// 									}));
// 								}}
// 								selected={nationalityIds}
// 								perRow="2, 150px"
// 							/>
// 						)}

// 						{((nationalitiesItems?.length === 0 &&
// 							nationalities.length === pageSize) ||
// 							(nationalitiesItems?.length > 0 &&
// 								hasNextPageNationalities)) && (
// 							<SeeMore
// 								onClick={
// 									nationalitiesItems?.length === 0
// 										? refetchNationalities
// 										: loadMoreNationalities
// 								}
// 								className={styles.see_more_btn}
// 							/>
// 						)}
// 					</div>

// 					<div className={styles.multicheckbox_container}>
// 						{languages.length > 0 && (
// 							<MultiCheckbox
// 								ref={languageRef}
// 								title={"Languages Spoken"}
// 								options={(languagesItems?.length > 0
// 									? languagesItems
// 									: languages
// 								).map((item) => languageFormatter(item))}
// 								onSelect={(languages) => {
// 									setSelectedFilters((prevState) => ({
// 										...prevState,
// 										languageIds: languages,
// 									}));
// 								}}
// 								selected={languageIds}
// 								perRow="2, 150px"
// 							/>
// 						)}

// 						{((languagesItems?.length === 0 &&
// 							languages.length === pageSize) ||
// 							(languagesItems?.length > 0 &&
// 								hasNextPageLanguages)) && (
// 							<SeeMore
// 								onClick={
// 									languagesItems?.length === 0
// 										? refetchLanguages
// 										: loadMoreLanguages
// 								}
// 								className={styles.see_more_btn}
// 							/>
// 						)}
// 					</div>

// 					<div className={styles.multicheckbox_container}>
// 						{accents.length > 0 && (
// 							<MultiCheckbox
// 								ref={accentRef}
// 								title={"Dialects Spoken / Accents"}
// 								options={(accentsItems?.length > 0
// 									? accentsItems
// 									: accents
// 								).map((item) => defaultFormatter(item))}
// 								onSelect={(accents) => {
// 									setSelectedFilters((prevState) => ({
// 										...prevState,
// 										accentIds: accents,
// 									}));
// 								}}
// 								selected={accentIds}
// 								perRow="2, 150px"
// 							/>
// 						)}

// 						{((accentsItems?.length === 0 &&
// 							accents.length === pageSize) ||
// 							(accentsItems?.length > 0 &&
// 								hasNextPageAccents)) && (
// 							<SeeMore
// 								onClick={
// 									accentsItems?.length === 0
// 										? refetchAccents
// 										: loadMoreAccents
// 								}
// 								className={styles.see_more_btn}
// 							/>
// 						)}
// 					</div>

// 					<div className={styles.multicheckbox_container}>
// 						{recordingTypes.length > 0 && (
// 							<MultiCheckbox
// 								ref={recordingTypeRef}
// 								title={"Recording Types"}
// 								options={(recordingItems?.length > 0
// 									? recordingItems
// 									: recordingTypes
// 								).map((item) => defaultFormatter(item))}
// 								onSelect={(recordingTypes) => {
// 									setSelectedFilters((prevState) => ({
// 										...prevState,
// 										recordingTypeIds: recordingTypes,
// 									}));
// 								}}
// 								selected={recordingTypeIds}
// 								perRow="2, 150px"
// 							/>
// 						)}

// 						{((recordingItems?.length === 0 &&
// 							recordingTypes.length === pageSize) ||
// 							(recordingItems?.length > 0 &&
// 								hasNextPageRecording)) && (
// 							<SeeMore
// 								onClick={
// 									recordingItems?.length === 0
// 										? refetchRecording
// 										: loadMoreRecording
// 								}
// 								className={styles.see_more_btn}
// 							/>
// 						)}
// 					</div>

// 					<div className={styles.multicheckbox_container}>
// 						{voiceCharacteristics.length > 0 && (
// 							<MultiCheckbox
// 								ref={voiceRef}
// 								title={"Voice Characteristics"}
// 								options={(voiceCharsItems?.length > 0
// 									? voiceCharsItems
// 									: voiceCharacteristics
// 								).map((item) => defaultFormatter(item))}
// 								onSelect={(voiceChars) => {
// 									setSelectedFilters((prevState) => ({
// 										...prevState,
// 										voiceCharIds: voiceChars,
// 									}));
// 								}}
// 								selected={voiceCharIds}
// 								perRow="2, 150px"
// 							/>
// 						)}

// 						{((voiceCharsItems?.length === 0 &&
// 							voiceCharacteristics.length === pageSize) ||
// 							(voiceCharsItems?.length > 0 &&
// 								hasNextPageVoiceChars)) && (
// 							<SeeMore
// 								onClick={
// 									voiceCharsItems?.length === 0
// 										? refetchVoiceChars
// 										: loadMoreVoiceChars
// 								}
// 								className={styles.see_more_btn}
// 							/>
// 						)}
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// );

// export default TalentDynamicFilters;

// * BELOW CODE IS THE REFACTORED VERSION OF THE ABOVE (keeping the above for reference)

import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import specialProfileApi from "api/special-profile";
import { formatFiltersValues } from "components/Utils/General";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import {
	countryFormatter,
	defaultFormatter,
	languageFormatter,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import styles from "./Filters.module.css";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";

const FILTERS_PAGE_SIZE = 100;
const DEBOUNCE_DELAY = 500;

// * query keys in FILTER_CONFIG are not used for the moment

// Define filter configurations
const FILTER_CONFIGS = {
	ethnicities: {
		title: "Ethnicities",
		formatter: defaultFormatter,
		stateKey: "ethnicityIds",
		queryKey: "ethnicity",
	},
	nationalities: {
		title: "Nationalities",
		formatter: countryFormatter,
		stateKey: "nationalityIds",
		queryKey: "nationalities",
	},
	languages: {
		title: "Languages Spoken",
		formatter: languageFormatter,
		stateKey: "languageIds",
		queryKey: "languages",
	},
	accents: {
		title: "Dialects Spoken / Accents",
		formatter: defaultFormatter,
		stateKey: "accentIds",
		queryKey: "accents",
	},
	recordingTypes: {
		title: "Recording Types",
		formatter: defaultFormatter,
		stateKey: "recordingTypeIds",
		queryKey: "recordingTypes",
	},
	voiceCharacteristics: {
		title: "Voice Characteristics",
		formatter: defaultFormatter,
		stateKey: "voiceCharIds",
		queryKey: "voiceChars",
	},
};

const FilterSection = React.memo(
	({
		initialData,
		config,
		selectedIds,
		onSelect,
		pageSize,
		filterRef,
		items,
		loadMore,
		hasNextPage,
		refetch,
		isFetching,
	}) => {
		if (initialData.length === 0) return null;

		return (
			<div className={styles.multicheckbox_container}>
				<MultiCheckbox
					ref={filterRef}
					title={config.title}
					options={(items?.length > 0 ? items : initialData).map(
						config.formatter
					)}
					onSelect={(selected) => onSelect(config.stateKey, selected)}
					selected={selectedIds}
					perRow="2, 150px"
				/>

				{isFetching && <LoadingSpinner />}

				{((items?.length === 0 && initialData.length === pageSize) ||
					(items?.length > 0 && hasNextPage)) && (
					<SeeMore
						onClick={items?.length === 0 ? refetch : loadMore}
						className={styles.see_more_btn}
					/>
				)}
			</div>
		);
	}
);

const TalentDynamicFilters = forwardRef(
	(
		{
			filters,
			selectedDynamicFilters,
			onChange,
			pageSize,
			agencyId,
			agenciesOnly,
		},
		ref
	) => {
		const didMountRef = useRef(false);

		// Create refs at the top level
		const ethnicityRef = useRef();
		const nationalityRef = useRef();
		const languageRef = useRef();
		const accentRef = useRef();
		const recordingTypeRef = useRef();
		const voiceRef = useRef();

		// Map of refs
		const filterRefs = {
			ethnicities: ethnicityRef,
			nationalities: nationalityRef,
			languages: languageRef,
			accents: accentRef,
			recordingTypes: recordingTypeRef,
			voiceCharacteristics: voiceRef,
		};

		const [selectedFilters, setSelectedFilters] = useState(
			selectedDynamicFilters
		);

		const getDynamicFilterApi = useApi(
			specialProfileApi.getDynamicFilter,
			true,
			true
		);

		const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, type, agencyId, filters, agenciesOnly] = queryKey;
			const response = await getDynamicFilterApi.request(
				type,
				pageParam,
				FILTERS_PAGE_SIZE,
				agencyId,
				formatFiltersValues(filters),
				agenciesOnly
			);
			return response.data;
		};

		// Create all filter data hooks at the top level
		const ethnicitiesData = useInfiniteScroll({
			queryKey: filterKeys.dynamicTalentFilter(
				"ethnicity",
				agencyId,
				selectedFilters,
				agenciesOnly
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const nationalitiesData = useInfiniteScroll({
			queryKey: filterKeys.dynamicTalentFilter(
				"nationalities",
				agencyId,
				selectedFilters,
				agenciesOnly
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const languagesData = useInfiniteScroll({
			queryKey: filterKeys.dynamicTalentFilter(
				"languages",
				agencyId,
				selectedFilters,
				agenciesOnly
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const accentsData = useInfiniteScroll({
			queryKey: filterKeys.dynamicTalentFilter(
				"accents",
				agencyId,
				selectedFilters,
				agenciesOnly
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const recordingTypesData = useInfiniteScroll({
			queryKey: filterKeys.dynamicTalentFilter(
				"recordingTypes",
				agencyId,
				selectedFilters,
				agenciesOnly
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const voiceCharacteristicsData = useInfiniteScroll({
			queryKey: filterKeys.dynamicTalentFilter(
				"voiceChars",
				agencyId,
				selectedFilters,
				agenciesOnly
			),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		// Map of filter data
		const filterData = {
			ethnicities: ethnicitiesData,
			nationalities: nationalitiesData,
			languages: languagesData,
			accents: accentsData,
			recordingTypes: recordingTypesData,
			voiceCharacteristics: voiceCharacteristicsData,
		};

		useEffect(() => {
			let timer;
			if (
				didMountRef.current &&
				Object.keys(selectedFilters).length > 0
			) {
				timer = setTimeout(() => {
					onChange(selectedFilters);
				}, DEBOUNCE_DELAY);
			}
			didMountRef.current = true;
			return () => clearTimeout(timer);
		}, [selectedFilters]);

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				const refKey = Object.keys(FILTER_CONFIGS).find(
					(k) => FILTER_CONFIGS[k].stateKey === key
				);
				if (refKey && filterRefs[refKey].current) {
					filterRefs[refKey].current.removeItem(tag);
				}
			},
			clear() {
				setSelectedFilters({});
				Object.values(filterRefs).forEach((ref) => {
					if (ref.current) {
						ref.current.clear();
					}
				});
			},
		}));

		const handleFilterSelect = (stateKey, selected) => {
			setSelectedFilters((prev) => ({
				...prev,
				[stateKey]: selected,
			}));
		};

		return (
			<div className={styles.container}>
				<div className={styles.padding_container}>
					{Object.entries(FILTER_CONFIGS).map(([key, config]) => (
						<FilterSection
							key={key}
							initialData={filters[key] || []}
							config={config}
							selectedIds={selectedFilters[config.stateKey] || []}
							onSelect={handleFilterSelect}
							pageSize={pageSize}
							filterRef={filterRefs[key]}
							{...filterData[key]}
						/>
					))}
				</div>
			</div>
		);
	}
);

export default TalentDynamicFilters;
