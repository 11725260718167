import { useQuery } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import useApi from "hooks/useApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import { searchKeys } from "queryKeys/search-key-factory";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { resetAgencyFilters, updateAgencyFilters } from "store/slices/filters";
import profileApi from "../../../api/profile";
import useWindowSize from "../../../hooks/useWindowSize";
import CompanyItem from "../../Companies/CompanyItem";
import FilterLeftSide from "../../Profile/TalentTab/FilterLeftSide";
import FilterRightSide from "../../Profile/TalentTab/FilterRightSide";
import Filter from "../../Utils/Filter/Filter";
import styles from "../../Utils/Filter/Filter.module.css";
import Tag from "../../Utils/SubComs/Tags/Tag";
import AgencyDynamicFilters from "./AgencyDynamicFilters";
import { formatFiltersValues } from "components/Utils/General";

const pageSize = 20;

const AgencyFilterView = () => {
	const dispatch = useDispatch();
	const leftSideRef = useRef();
	const { width } = useWindowSize();
	const isMobile = width < 748;

	const [filterModal, setFilterModal] = useState();

	useEffect(() => {
		// closes the filter modal if opened in mobile,
		// and then switching to a desktop breakpoint
		if (filterModal && !isMobile) setFilterModal(false);
	}, [isMobile, filterModal]);

	const handleDynamicFilters = (selectedFilters) => {
		dispatch(updateAgencyFilters(selectedFilters));
	};

	// !=========== AGENCY FILTERS ===========

	const agencyFilterApi = useApi(profileApi.getAgencyFilters, true, true);

	const fetchAgencyFilters = async ({ queryKey, signal }) => {
		const [_, __, filters] = queryKey;
		const response = await agencyFilterApi.request(
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const agencyFilters = useSelector((state) => state.filters.agencyFilters);

	const { data: filtersData, isFetching: isFetchingFilters } = useQuery({
		queryKey: filterKeys.agency(agencyFilters),
		queryFn: fetchAgencyFilters,
	});

	// !=========== AGENCY SEARCH ==============

	const searchAgenciesApi = useApi(profileApi.searchAgency, true, true);

	const fetchAgencies = async ({ pageParam = 0, queryKey, signal }) => {
		const [_, __, filters] = queryKey;
		const response = await searchAgenciesApi.request(
			pageParam,
			pageSize,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const { items, viewRef, isFetchingNextPage, isLoading } = useInfiniteScroll(
		{
			queryKey: searchKeys.agency(agencyFilters),
			queryFn: fetchAgencies,
			pageSize,
		}
	);

	useEffect(() => {
		return () => {
			//CLEARS AGENCIES SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetAgencyFilters());
		};
	}, []);

	const agenciesList = useMemo(
		() =>
			items.map((agency, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<Fragment key={agency.uuid}>
						<Link
							to={routes.profile(agency.url)}
							className={styles.item_link}
							key={agency.uuid}
							ref={isLastItem ? viewRef : null}
						>
							<CompanyItem
								customStyle={styles.clickable}
								item={agency}
								subdiv={
									<>
										<div className={styles.item_tagLine}>
											{agency.tagLine}
										</div>
										<div className={styles.item_location}>
											{`${agency.locationDTO.name}, ${agency.locationDTO.countryDTO.name}`}
										</div>
									</>
								}
							/>
						</Link>
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</Fragment>
				);
			}),
		[isFetchingNextPage, items, viewRef]
	);

	const handleDeleteTag = (key, tag) => {
		leftSideRef.current.updateDynamicFilters(key, tag);
	};

	const tagsList = useMemo(() => {
		const valuesList = [];
		for (const [k, v] of Object.entries(agencyFilters)) {
			if (Array.isArray(v) && v?.length > 0) {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.label}
							itemName={`${item.label}`}
							onRemove={() => handleDeleteTag(k, item)}
							customStyle={styles.filter_tag}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [agencyFilters]);

	return (
		<Filter
			title="Agencies"
			isMobile={isMobile}
			onCloseModal={() => setFilterModal(false)}
			hiddenMode={!filterModal}
			leftSideChildren={
				<FilterLeftSide
					ref={leftSideRef}
					onResetFilter={() => dispatch(resetAgencyFilters())}
					isLoading={isFetchingFilters}
					leftSideChildren={[
						<AgencyDynamicFilters
							id="dynamicFilter"
							filters={filtersData}
							onChange={handleDynamicFilters}
							selectedDynamicFilters={agencyFilters}
						/>,
					]}
				/>
			}
			rightSideChildren={
				<FilterRightSide
					data={agenciesList}
					title="Agencies"
					tagsList={tagsList}
					isMobile={isMobile}
					onShowModal={() => setFilterModal(true)}
					loading={isLoading}
					// hasMore={hasMore}
					// loading={loading}
					// onLoad={() =>
					// 	handleLoadData(undefined, undefined, undefined, true)
					// }
				/>
			}
		/>
	);
};

export default AgencyFilterView;
