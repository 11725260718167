import React, { useState, useEffect, useMemo } from "react";
import styles from "./CreatePost.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import WarningModal from "../Utils/GaawkModal/WarningModal";
import PostItem from "./PostItem";
import StaticMap from "../Utils/StaticMap/StaticMap";
import PostMedia from "./PostMedia";
import VisibilitySelector from "../Utils/Visibility/VisibilitySelector";
import Loader from "react-loader-spinner";
import LocationModal from "./LocationModal";
import Close from "images/cross-white-14-x-14.svg";
import publicIcon from "images/public_icon.svg";
import editIcon from "images/edit-icon-20-x-20-black.svg";
import closeIcon from "images/close-icon-black-20-x-20.svg";
import MediaList from "./MediaList";
import useApi from "../../hooks/useApi";
import postApi from "../../api/post";
import PostButtons from "./PostButtons";
import EmotionModal from "./EmotionModal";
import EditMediaModal from "./EditMediaModal";
import PostHeader from "./PostHeader";
import CustomMentions from "../Utils/Mentions/CustomMentions";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import ProfilePic from "./ProfilePic";
import {
	// getFileIcon,
	getLocationType,
	getVideoFrame,
	maxPostLength,
	replaceItemInArray,
} from "../Utils/General";
import useEditSubPost from "../../hooks/useEditSubPost";
import PostDocuments from "./Components/PostDocuments";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
import UserListModal from "components/Utils/UserListModal/UserListModal";
import profileApi from "api/profile";
import GroupMember from "components/Chat/GroupMember";
import { profileKeys } from "queryKeys/profile-key-factory";

const itemsPerPage = 20;

const EditPostModal = ({
	existingPost,
	show,
	toggleShow,
	name,
	profileImg,
	type,
	// onSaveEdit,
	// onDeleteMedia,
	// onDeleteAllMedia,
	onInvalidate,
	onClose,
	// url,
}) => {
	const [showWarning, setShowWarning] = useState(false);

	const [post, setPost] = useState("");

	const [subPosts, setSubPosts] = useState([]);

	const [pdfPosts, setPdfPosts] = useState([]);

	useEffect(() => {
		const mediaListArray = [];

		if (post.totalMediaCount > 0) {
			mediaListArray.push(...post.mediaList);
		}

		setSubPosts(mediaListArray);
	}, [post.mediaList, post.totalMediaCount]);

	useEffect(() => {
		const pdfListArray = [];

		if (post.totalPDFCount > 0) {
			pdfListArray.push(...post.pdfList);
		}

		setPdfPosts(pdfListArray);
	}, [post.pdfList, post.totalPDFCount]);

	const postMedia = useMemo(
		() =>
			subPosts.map((media) => {
				if (media.file) {
					return media.file;
				}
				return media;
			}),
		[subPosts]
	);

	useEffect(() => {
		if (existingPost) {
			setPost(existingPost);
		}
	}, [existingPost]);

	const thumbnail = profileImg ? profileImg.originalImageURL : undefined;

	const handleWarningDiscard = () => {
		setShowWarning(false);
		closeEditModal();
	};

	const closeEditModal = () => {
		toggleShow(false);
		setPost("");
		setSubPosts([]);
		setPdfPosts([]);
		onClose && onClose();
	};

	// ! ===== TEXT HANDLER

	const handlePostTextChange = (text) => {
		setPost((post) => ({
			...post,
			postText: text,
		}));
	};

	const onEmojiClick = (event, emojiObject) => {
		setPost((post) => ({
			...post,
			postText: post.postText + emojiObject.emoji,
		}));
	};

	// ! ===== MEDIA HANDLER

	const updateMediaApi = useApi(postApi.updateMedia, true);
	const deleteMediaApi = useApi(postApi.deleteMedia);
	const deleteAllMediaApi = useApi(postApi.deleteAllMedia);

	const [showPhotosVideos, setShowPhotosVideos] = useState(false);
	const [subIdToDelete, setSubIdToDelete] = useState("");

	const [showEditMedia, setShowEditMedia] = useState(false);
	const [editItem, setEditItem] = useState("");
	const [editItemIndex, setEditItemIndex] = useState("");

	const handleShowPhotoVideoModal = () => {
		toggleShow(false);
		setShowPhotosVideos(true);
	};

	const handleHidePhotoVideoModal = () => {
		setShowPhotosVideos(false);
		toggleShow(true);
	};

	const handleShowEditModal = (media, index) => {
		setEditItem(media);
		setEditItemIndex(index);
		setShowPhotosVideos(false);
		setShowEditMedia(true);
	};

	const handleHideEditMedia = () => {
		setShowEditMedia(false);
		setShowPhotosVideos(true);
	};

	const handleUpdateSubPost = (updatedSubPost) => {
		setSubPosts((prevState) =>
			replaceItemInArray(updatedSubPost, prevState, "uuid")
		);
	};

	const { handleEdit } = useEditSubPost(handleUpdateSubPost, updateMediaApi);

	const handleConfirmEdit = (newItem) => {
		if (newItem.newFile) {
			const subPostsCopy = [...subPosts];
			subPostsCopy[editItemIndex] = newItem;
			setSubPosts(subPostsCopy);
		} else {
			handleEdit(newItem);
		}
	};

	// useEffect(() => {
	// 	if (post && post.subPosts.length > 0) {
	// 		setSubPosts(post.subPosts);
	// 	}
	// }, [post.subPosts]);

	const {
		action: { mutate: deletePdf },
	} = useMutate(postApi.deleteMedia, () => {
		setPdfPosts((prevState) =>
			prevState.filter((item) => item.uuid !== subIdToDelete)
		);
		setSubIdToDelete("");
		onInvalidate();
		// onDeleteMedia && onDeleteMedia();
	});

	const handleRemovePDFItem = (item, index) => {
		if (item.newFile) {
			const pdfPostsCopy = [...pdfPosts];
			pdfPostsCopy.splice(index, 1);
			setPdfPosts(pdfPostsCopy);
		} else {
			setSubIdToDelete(item.uuid);
			deletePdf({ postId: post.uuid, pdfId: item.uuid });
		}
	};

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when subPosts changes

		// const newObjectUrls = subPosts.map((media) =>
		// 	// * condition necessary to keep index between existing files and newFiles
		// 	media.newFile ? URL.createObjectURL(media.multipartFile) : ""
		// );
		// setObjectUrls(newObjectUrls);

		const loadItems = async () => {
			const promises = subPosts.map(async (media) => {
				if (media.newFile) {
					if (media.multipartFile.type.startsWith("image")) {
						return URL.createObjectURL(media.multipartFile);
					} else if (media.multipartFile.type.startsWith("video")) {
						const videoFirstFrame = await getVideoFrame(
							media.multipartFile
						);
						return videoFirstFrame;
					}
					// else {
					// 	return getFileIcon(media, media.name).icon;
					// }
				} else {
					return "";
				}
			});

			const resolvedUrls = await Promise.all(promises);
			setObjectUrls(resolvedUrls);
		};

		loadItems();

		// Cleanup function
		return () => {
			// newObjectUrls.forEach((url) => URL.revokeObjectURL(url));
			cleanupObjectUrls();
		};
	}, [subPosts]);

	const handleRemoveMediaItem = (sub, index) => () => {
		if (sub.newFile) {
			// const subPostsCopy = [...subPosts];
			// subPostsCopy.splice(index, 1);
			// setSubPosts(subPostsCopy);

			// if (subPostsCopy.length === 0) {
			// 	handleHidePhotoVideoModal();
			// }

			setSubPosts((prevSub) => {
				const newSub = [...prevSub];
				newSub.splice(index, 1);
				return newSub;
			});

			// setObjectUrls((prevUrls) => {
			// 	const newUrls = [...prevUrls];
			// 	URL.revokeObjectURL(newUrls[index]);
			// 	newUrls.splice(index, 1);
			// 	return newUrls;
			// });

			if (subPosts.length - 1 === 0) handleHidePhotoVideoModal();
		} else {
			//! useMutate instead*
			setSubIdToDelete(sub.uuid);
			deleteMediaApi.request(post.uuid, sub.uuid);
		}
	};

	//! useMutate instead*
	useEffect(() => {
		if (!deleteMediaApi.loading && deleteMediaApi.responseCode === 200) {
			const subPostsCopy = [...subPosts];
			const updatedSubPosts = subPostsCopy.filter(
				(sub) => sub.uuid !== subIdToDelete
			);
			setSubPosts(updatedSubPosts);
			if (subPosts.length === 1) {
				handleHidePhotoVideoModal();
			}
			// onDeleteMedia &&
			// 	onDeleteMedia(post.uuid, subIdToDelete, updatedSubPosts);
			onInvalidate();
			setSubIdToDelete("");
		}
	}, [deleteMediaApi.loading, deleteMediaApi.responseCode]);

	const handleRemoveAllMedia = () => {
		deleteAllMediaApi.request(post.uuid);
	};

	useEffect(() => {
		if (
			!deleteAllMediaApi.loading &&
			deleteAllMediaApi.responseCode === 200
		) {
			// onDeleteAllMedia && onDeleteAllMedia(post.uuid);
			onInvalidate();
			setSubPosts([]);
		}
	}, [deleteAllMediaApi.loading, deleteAllMediaApi.responseCode]);

	// const mediaList = subPosts.map((sub, index) => {
	// 	return (
	// 		<MediaList
	// 			key={index}
	// 			mediaType={sub.newFile ? sub.type : sub.file.mediaType}
	// 			url={
	// 				sub.newFile
	// 					? URL.createObjectURL(sub.multipartFile)
	// 					: sub.file.url
	// 			}
	// 			fileName={
	// 				sub.newFile ? sub.multipartFile.name : sub.file.originalName
	// 			}
	// 			text={sub.newFile ? sub.caption : sub.postText}
	// 			onClick={handleRemoveMediaItem(sub, index)}
	// 			onEdit={() => handleShowEditModal(sub, index)}
	// 			// onChange={(e) => handleMediaCaption(e, index)}
	// 		/>
	// 	);
	// });

	const mediaList = useMemo(
		() =>
			subPosts.map((sub, index) => (
				<MediaList
					key={index}
					mediaType={sub.newFile ? sub.type : sub.file.mediaType}
					url={
						sub.newFile
							? // ? URL.createObjectURL(sub.multipartFile)
							  objectUrls[index]
							: sub.file.url
					}
					fileName={
						sub.newFile
							? sub.multipartFile.name
							: sub.file.originalName
					}
					text={sub.newFile ? sub.caption : sub.postText}
					onClick={handleRemoveMediaItem(sub, index)}
					onEdit={() => handleShowEditModal(sub, index)}
					// onChange={(e) => handleMediaCaption(e, index)}
				/>
			)),
		[subPosts, objectUrls]
	);

	const handleAppendFileChange = ({ target }) => {
		const files = target.files;
		const fileData = [];

		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				fileData.push({
					caption: "",
					multipartFile: files[i],
					postId: "",
					type: files[i].type.includes("video")
						? "VIDEO"
						: files[i].type.includes("image")
						? "IMAGE"
						: "PDF",
					taggedIds: [],
					newFile: true,
				});
			}
		}

		setSubPosts((prevState) => [...prevState, ...fileData]);
	};
	const handleAppendPdf = ({ target }) => {
		const files = target.files;
		const fileData = [];

		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				fileData.push({
					caption: "",
					multipartFile: files[i],
					postId: "",
					type: "PDF",
					taggedIds: [],
					newFile: true,
				});
			}
		}

		setPdfPosts((prevState) => [...prevState, ...fileData]);
	};

	// ! ===== LOCATION HANDLER

	const [showLocationModal, setShowLocationModal] = useState(false);

	const handleShowLocationModal = () => {
		toggleShow(false);
		setShowLocationModal(true);
	};

	const handleClearLocation = () => {
		setPost((prevState) => ({ ...prevState, location: null }));
	};

	const handleLocationClicked = (place) => {
		setPost((prevState) => ({
			...prevState,
			location: {
				latitude: place.geometry.location.lat,
				longitude: place.geometry.location.lng,
				title: place.name,
				placeId: place.place_id,
				type: getLocationType(place.types),
			},
		}));

		handleCloseLocationModal();
	};

	const handleCloseLocationModal = () => {
		setShowLocationModal(false);
		toggleShow(true);
	};

	// ! ===== VISIBILITY HANDLER

	const handlePostVisibilityChange = (value) => () => {
		setPost((prevState) => ({
			...prevState,
			postVisibility: value,
		}));
	};

	// ! ===== EMOTION HANDLER

	const [showEmotionModal, setShowEmotionModal] = useState(false);

	const handleEmotionClicked = (emotion) => {
		setPost((prevState) => ({
			...prevState,
			emotion,
		}));
		handleHideEmotionModal();
	};

	const handleShowEmotionModal = () => {
		toggleShow(false);
		setShowEmotionModal(true);
	};

	const handleHideEmotionModal = () => {
		setShowEmotionModal(false);
		toggleShow(true);
	};

	const handleRemoveEmotion = (e) => {
		e.stopPropagation();
		setPost((prevState) => ({
			...prevState,
			emotion: null,
		}));
	};

	// !=========== TAGS HANDLER ==================
	const [showTagModal, setShowTagModal] = useState(false);
	const [postTags, setPostTags] = useState([]);

	const handleShowTagModal = () => {
		toggleShow(false);
		setShowTagModal(true);
		setPostTags(post.taggedProfiles || []);
	};

	const handleHideTagModal = () => {
		setShowTagModal(false);
		toggleShow(true);
		setPostTags([]);
	};

	const fetchTags = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, query] = queryKey;
		const response = await profileApi.searchTags(
			pageParam,
			itemsPerPage,
			query
		);
		return response.data;
	};

	const handleConfirmTags = () => {
		setPost((prevState) => ({
			...prevState,
			taggedProfiles: postTags,
		}));
		handleHideTagModal();
	};

	const handleRemoveTag = (tag) => {
		setPostTags((prevTags) => prevTags.filter((t) => t.uuid !== tag.uuid));
	};

	const renderTag = ({ user: tag, ref }) => {
		const isSelected = postTags.some((t) => t.uuid === tag.uuid);

		return (
			<GroupMember
				ref={ref}
				key={tag.uuid}
				redirect={false}
				onClick={() => {
					if (!isSelected) {
						setPostTags((prevState) => [...prevState, tag]);
					}
				}}
				disabled={isSelected}
				data={tag}
			/>
		);
	};

	// ! ===== SAVE HANDLER
	const addMediaApi = useApi(postApi.addMedia);
	const updatePostApi = useApi(postApi.updatePost);
	const [newMediaCount, setNewMediaCount] = useState(0);
	const [mediaLoopCounter, setMediaLoopCounter] = useState(0);
	const [mediaLoading, setMediaLoading] = useState(false);

	const handleSave = () => {
		const {
			uuid,
			emotion,
			mediaList,
			pdfList,
			location,
			postText,
			postVisibility,
			taggedProfiles,
			board,
			title,
		} = post;

		const updatedPost = {
			postId: uuid,
			...(emotion && { emotionId: emotion.uuid }),
			...(mediaList?.length > 0 ||
			pdfList?.length > 0 ||
			subPosts.length > 0 ||
			pdfPosts.length > 0
				? { filesIncluded: true }
				: { filesIncluded: false }),
			...(location && {
				location: {
					latitude: location.latitude,
					longitude: location.longitude,
					title: location.title,
					type: location.type,
					placeId: location.placeId,
				},
			}),
			postText,
			postVisibility: board ? "PUBLIC" : postVisibility,
			// sharedPostId: "",
			...(taggedProfiles.length > 0 && {
				taggedProfiles: taggedProfiles.map((profile) => profile.uuid),
			}),
			...(board && {
				boardId: board.uuid,
				title,
			}),
		};

		//IMPROVEMENT: should compare original post using shallowEqual, to see if it's necessary to call the edit post API in case there are 0 changes?
		updatePostApi.request(updatedPost);
	};

	useEffect(() => {
		if (
			!updatePostApi.loading &&
			updatePostApi.data &&
			updatePostApi.responseCode === 200
		) {
			const newMediaArray = [...subPosts, ...pdfPosts].filter(
				(media) => media.newFile
			);

			if (newMediaArray.length > 0) {
				setNewMediaCount(newMediaArray.length);
				setMediaLoading(true);

				newMediaArray.forEach((media) => {
					const formData = new FormData();
					formData.append("parentPostId", post.uuid);
					formData.append(
						"multipartFile",
						media.multipartFile,
						media.multipartFile instanceof Blob
							? media.multipartFile.fileName
							: undefined
					);
					formData.append("caption", media.caption);
					if (media.date) formData.append("date", media.date);
					if (media.location) {
						formData.append(
							"location.latitude",
							media.location.latitude
						);
						formData.append(
							"location.longitude",
							media.location.longitude
						);
						formData.append("location.type", media.location.type);
						formData.append("location.title", media.location.title);
					}
					if (media.taggedIds) {
						formData.append(
							"taggedIds",
							media.taggedIds.map((profile) => profile.uuid)
						);
					}

					if (media.services) {
						formData.append(
							"serviceIds",
							media.services.map((service) => service.uuid)
						);
					}

					if (post?.board) {
						formData.append("boardId", post.board.uuid);
						formData.append("title", post.title);
					}

					addMediaApi.request(formData);
				});
			} else {
				// onSaveEdit(updatePostApi.data);
				onInvalidate();
				closeEditModal();
			}
		}
	}, [updatePostApi.data]);

	useEffect(() => {
		if (
			!addMediaApi.loading &&
			addMediaApi.data &&
			addMediaApi.responseCode === 200
		) {
			setMediaLoopCounter((prevState) => (prevState += 1));

			if (mediaLoopCounter + 1 === newMediaCount) {
				// onSaveEdit(addMediaApi.data);
				onInvalidate();
				closeEditModal();
				setMediaLoopCounter(0);
				setMediaLoading(false);
			}
		}
	}, [addMediaApi.data]);

	return (
		<>
			<GaawkModal
				show={show}
				handleClose={() => setShowWarning(true)}
				defaultModal={false}
				showHeader={true}
				title={"Edit a Post"}
				closeAlign={"right"}
				children={
					<div className={styles.create_post_modal_container}>
						<div className={styles.user_wrapper}>
							<PostHeader
								name={name}
								img={thumbnail}
								emotion={post.emotion}
								location={post.location}
								tags={post.taggedProfiles}
								createMode={true}
								postTime={null}
								visibility={null}
								type={type}
								// url={owner.url}
								onTagClicked={(e) => {
									e.stopPropagation();
									handleShowTagModal();
								}}
								onOtherTagsClicked={(e) => {
									e.stopPropagation();
									handleShowTagModal();
								}}
							/>
						</div>

						{post?.board && (
							<>
								<div className={styles.board_name}>
									<ProfilePic
										thumbnail={post.board.feedImage}
										type="BOARD"
									/>
									{post.board.name}
								</div>
								<div className={styles.title_input}>
									<label>Title</label>

									<TextInput
										onChange={({ target }) =>
											setPost((prevState) => ({
												...prevState,
												title: target.value,
											}))
										}
										value={post.title}
										placeholder="Enter title"
										cursor="text"
									/>
								</div>
							</>
						)}

						<div className={styles.create_body_wrapper}>
							<div className={styles.post_text_container}>
								<CustomMentions
									placeholder={"What's on your mind?"}
									value={post && post.postText}
									onChange={handlePostTextChange}
									onEmojiSelected={onEmojiClick}
									fixedHeight={true}
									h={177}
									maxLength={maxPostLength}
								/>
								{/* <CustomTextarea
                                    isExtendable={false}
                                    value={post && post.postText}
                                    onChange={handlePostTextChange}
                                    onEmojiSelected={onEmojiClick}
                                    hasBorder={true}
                                /> */}
							</div>

							{post && post.location && postMedia.length <= 0 && (
								<div
									className={
										styles.post_location_map_container
									}
								>
									<StaticMap
										lat={post.location.latitude}
										lng={post.location.longitude}
										title={post.location.title}
									/>

									<button
										className={styles.location_clear_button}
										onClick={handleClearLocation}
									>
										<img src={Close} alt={"dismiss"} />
									</button>
								</div>
							)}

							{(post.postType === "PROFILE_PIC" ||
								post.postType === "COVER_PIC") && (
								<div
									className={
										styles.create_post_media_container
									}
								>
									<PostMedia
										mediaList={[post.file]}
										viewer={true}
									/>
								</div>
							)}

							{postMedia.length > 0 && (
								<div
									className={
										styles.create_post_media_container
									}
								>
									<PostMedia
										mediaList={postMedia}
										viewer={true}
									/>
									<div
										className={
											styles.create_post_media_controllers
										}
									>
										<button
											className={
												styles.edit_post_media_button
											}
											onClick={handleShowPhotoVideoModal}
										>
											<img src={editIcon} alt={"edit"} />
											<label>Edit</label>
										</button>

										<button
											className={
												styles.remove_post_media_button
											}
											onClick={handleRemoveAllMedia}
										>
											<img
												src={closeIcon}
												alt={"close"}
											/>
										</button>
									</div>
								</div>
							)}

							{post.sharedPost && (
								<PostItem
									post={post.sharedPost}
									inShareModal={true}
									isSharePost={true}
								/>
							)}

							{pdfPosts.length > 0 && (
								<PostDocuments
									items={pdfPosts}
									onDelete={handleRemovePDFItem}
									canEdit={true}
								/>
							)}

							<div className={styles.post_visibility_row}>
								{post?.board ? (
									<div className={styles.board_visibility}>
										<img src={publicIcon} alt="" />
										Posting to boards is always public.
									</div>
								) : (
									<>
										<label>Post to:</label>

										<div
											className={
												styles.visibility_wrapper
											}
										>
											<VisibilitySelector
												value={
													post && post.postVisibility
												}
												onValueChange={
													handlePostVisibilityChange
												}
											/>
										</div>
									</>
								)}
							</div>

							{!post.sharedPost &&
								post.postType !== "PROFILE_PIC" &&
								post.postType !== "COVER_PIC" && (
									<PostButtons
										onChange={handleAppendFileChange}
										onChangePdf={handleAppendPdf}
										onEmotion={handleShowEmotionModal}
										onLocation={handleShowLocationModal}
										onTag={handleShowTagModal}
									/>
								)}

							<div className={styles.post_button_wrapper}>
								<button
									className={styles.post_button}
									onClick={handleSave}
								>
									{updatePostApi.loading || mediaLoading ? (
										<Loader
											type="Oval"
											color="#fff"
											height={20}
											width={20}
										/>
									) : (
										"Update"
									)}
								</button>
							</div>
						</div>
					</div>
				}
			/>

			<WarningModal
				show={showWarning}
				modalOnTop={true}
				headerText="Are you sure you want to discard your changes?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarning(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={handleWarningDiscard}
			/>

			<GaawkModal
				show={showPhotosVideos}
				handleClose={handleHidePhotoVideoModal}
				defaultModal={false}
				showHeader={true}
				title={"Photos / Videos"}
				closeAlign={"left"}
				children={
					<div
						className={`${styles.create_post_modal_container} ${styles.scrollable_modal}`}
					>
						{mediaList}
					</div>
				}
			/>

			<EditMediaModal
				show={showEditMedia}
				onClose={handleHideEditMedia}
				item={editItem}
				// isEditing={true}
				onConfirm={handleConfirmEdit}
				onToggle={(bool) => setShowEditMedia(bool)}
				onReset={() => setEditItem("")}
			/>

			<EmotionModal
				emotionId={post.emotion && post.emotion.uuid}
				show={showEmotionModal}
				onClose={handleHideEmotionModal}
				onSelect={handleEmotionClicked}
				onRemoveEmotion={handleRemoveEmotion}
			/>

			<UserListModal
				show={showTagModal}
				onClose={handleHideTagModal}
				title="Tag People / Companies"
				fetchUsers={fetchTags}
				renderUser={renderTag}
				hasSearch={true}
				queryKey={(query) => profileKeys.searchTags(query)}
				closeAlign={"left"}
				showSelectedContainer={true}
				selectedUsers={postTags}
				onConfirm={handleConfirmTags}
				onRemove={handleRemoveTag}
			/>

			<LocationModal
				show={showLocationModal}
				onClose={handleCloseLocationModal}
				onLocationClicked={handleLocationClicked}
				activeLocation={post.location}
				onRemove={handleClearLocation}
			/>
		</>
	);
};

export default EditPostModal;
