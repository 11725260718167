import { forwardRef, useImperativeHandle, useState } from "react";
import styles from "./ServicesPage.module.scss";
import CityModal from "../../Boards/BoardsComponents/CityModal";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import useUserLocation from "../../../hooks/useUserLocation";
import useDebounce from "../../../hooks/useDebounce";
import useApi from "../../../hooks/useApi";
import ServiceSection from "../../Profile/ServicesTab/ServicesComponent/ServiceSection";
import SearchServiceSection from "../../Profile/ServicesTab/ServicesComponent/SearchServiceSection";
import servicesApi from "../../../api/services";
import { useNavigate } from "react-router-dom";

const itemsPerPage = 12;

const ServicesPage = forwardRef(({ onFlagChange }, ref) => {
	const navigate = useNavigate();

	//! ========= GETTING USER COORDINATES ============

	const [showModal, setShowModal] = useState(false);

	useImperativeHandle(ref, () => ({
		showModal() {
			setShowModal(true);
		},
	}));

	const { userCoordinates, handleSelectedCity } =
		useUserLocation(onFlagChange);

	//! =========== SEARCH INPUT HANDLER ==============

	const searchServicesApi = useApi(servicesApi.searchServices, true, true);

	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const fetchSearch = async ({ pageParam = 0, signal }) => {
		const response = await searchServicesApi.request(
			pageParam,
			itemsPerPage,
			debouncedSearch
		);
		return response.data;
	};

	//! =============== NEARBY SERVICES ===============

	const nearbyServicesApi = useApi(servicesApi.getNearbyServices, true, true);

	const fetchNearbyServices = async () => {
		const response = await nearbyServicesApi.request(
			0,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	//! =============== CONNECTION SERVICES ===============

	const connectionServicesApi = useApi(
		servicesApi.getConnectionServices,
		true,
		true
	);

	const fetchConnectionServices = async () => {
		const response = await connectionServicesApi.request(0, itemsPerPage);
		return response.data;
	};

	//! ===============================================

	return (
		<div className={styles.container}>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={() => setSearchInput("")}
					border={false}
					placeholder={"Search Services"}
				/>
			</div>

			{searchInput ? (
				<SearchServiceSection
					title="Search Results"
					queryFn={fetchSearch}
					// errorMessage={"Failed to get searched services"}
					debouncedSearch={debouncedSearch}
					itemsPerPage={itemsPerPage}
				/>
			) : (
				<>
					<ServiceSection
						title="Services in your area"
						queryKey={["nearbyServices", userCoordinates]}
						queryFn={fetchNearbyServices}
						// errorMessage={"Failed to get nearby services"}
						onViewMore={() => navigate("/services/nearby")} //TODO >> add to routing-keys.js
					/>

					<ServiceSection
						title="Services by your connections"
						queryKey={["connectionServices"]}
						queryFn={fetchConnectionServices}
						// errorMessage={"Failed to get connection services"}
						onViewMore={() => navigate("/services/connections")} //TODO >> add to routing-keys.js
					/>
				</>
			)}

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</div>
	);
});

export default ServicesPage;
