import { useQuery } from "@tanstack/react-query";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import useApi from "./useApi";
import profileApi from "api/profile";

export default function useConnectionsCount(profileId) {
	const getConnectionsCountApi = useApi(
		profileApi.getConnectionsCount,
		true,
		true
	);

	const fetchConnectionsCount = async ({ queryKey }) => {
		const [_, profileId] = queryKey;
		const response = await getConnectionsCountApi.request(profileId);
		return response.data;
	};

	const query = useQuery({
		queryKey: connectionsKeys.count(profileId),
		queryFn: fetchConnectionsCount,
		enabled: !!profileId,
	});

	return query;
}
