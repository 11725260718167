import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import styles from "./GroupModalStep1.module.scss";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import imgUpload from "images/imgUpload.svg";
import CropModal from "components/Utils/CropModal/CropModal";
import LazyImage from "components/Utils/LazyImage/LazyImage";

const GroupModalStep1 = forwardRef(
	({ show, onClose, onNext, editMode = false, groupInfo }, ref) => {
		const {
			groupDTO: {
				name: groupName,
				description,
				image: { file: { customName } = {} } = {},
			} = {},
		} = groupInfo || {};

		const schema = Joi.object({
			groupName: Joi.string()
				.required()
				.label("Group Name")
				.messages({ "string.empty": "Group Name can't be empty" }),
			description: Joi.string().allow("").label("Group Description"),
			file: Joi.array().min(0),
			croppedImg: Joi.any(),
		});

		const {
			register,
			formState: { errors },
			handleSubmit,
			control,
			setValue,
			watch,
			reset,
		} = useForm({
			resolver: joiResolver(schema),
			mode: "all",
			...(editMode && {
				defaultValues: {
					groupName,
					description,
					file: undefined,
					croppedImg: undefined,
				},
			}),
		});

		useImperativeHandle(ref, () => ({
			reset,
			revokeCroppedPreview,
		}));

		const inputRef = useRef(null);

		const [showCropModal, setShowCropModal] = useState(false);
		const [showCurrentModal, setShowCurrentModal] = useState(true);

		const handleShowCropModal = (reverse = false) => {
			setShowCropModal(reverse ? false : true);
			setShowCurrentModal(reverse ? true : false);
		};

		const fileWatcher = watch("file");
		const blobWatcher = watch("croppedImg");

		const [filePreviewURL, setFilePreviewURL] = useState(undefined);
		const [croppedPreviewURL, setCroppedPreviewURL] = useState(undefined);

		const revokeFilePreview = () => {
			URL.revokeObjectURL(filePreviewURL);
			setFilePreviewURL(undefined);
		};
		const revokeCroppedPreview = () => {
			URL.revokeObjectURL(croppedPreviewURL);
			setCroppedPreviewURL(undefined);
		};

		const handleClose = () => {
			revokeFilePreview();
			revokeCroppedPreview();
			onClose();
		};

		return (
			<>
				<CropModal
					image={fileWatcher && filePreviewURL}
					show={showCropModal}
					aspectMode={1}
					onClose={() => handleShowCropModal(true)}
					onCrop={(cropped) => {
						setValue("croppedImg", cropped);
						setCroppedPreviewURL(URL.createObjectURL(cropped)); //* added
						handleShowCropModal(true);
					}}
					returnBlob={true}
					canEscape={false}
				/>

				<GaawkModal
					show={show && showCurrentModal}
					handleClose={handleClose}
					defaultModal={false}
					showHeader={true}
					title={editMode ? "Update Group Info" : "Create a group"}
					closeAlign={"right"}
					children={
						<form
							className={styles.add_group_container}
							onSubmit={handleSubmit((data) => {
								onNext(data);
								revokeFilePreview();
							})}
						>
							<div className={styles.form_input_wrapper}>
								<label htmlFor="group-name">
									Group Name
									<span className="required">*</span>
								</label>

								<TextInput
									{...register("groupName")}
									error={!!errors.groupName}
									placeholder="Enter the group name"
								/>

								{errors?.groupName?.message && (
									<p className={styles.error_message}>
										{errors?.groupName?.message}
									</p>
								)}
							</div>

							<div className={styles.form_input_wrapper}>
								<label htmlFor="group-description">
									Group description
								</label>
								<textarea
									{...register("description")}
									placeholder={"Enter group description"}
								/>
							</div>

							<div className={styles.form_input_wrapper}>
								<label htmlFor="group-description">
									Group image
								</label>

								<div className={styles.img_preview_container}>
									{blobWatcher ? (
										<img
											// src={URL.createObjectURL(
											// 	blobWatcher
											// )}
											src={croppedPreviewURL} //* added
											alt=""
										/>
									) : customName ? (
										<LazyImage
											image={
												<img src={customName} alt="" />
											}
										/>
									) : (
										<img
											src={imgUpload}
											className={styles.img_placeholder}
											alt="preview"
										/>
									)}
								</div>

								{/* // TODO >> improve by using <FileInput /> component */}

								<button
									type="button"
									className={styles.custom_upload_button}
									onClick={() => {
										inputRef.current.click();
									}}
								>
									<Controller
										name="file"
										control={control}
										render={() => (
											<input
												onClick={(e) =>
													e.stopPropagation()
												}
												ref={inputRef}
												type="file"
												accept="image/png, image/jpg, image/jpeg"
												onChange={(val) => {
													revokeFilePreview();
													setValue("file", [
														val.target.files[0],
													]);
													setFilePreviewURL(
														URL.createObjectURL(
															val.target.files[0]
														)
													);
													val.target.value = "";
													handleShowCropModal();
												}}
												tabIndex="-1"
											/>
										)}
									/>
									UPLOAD AN IMAGE
								</button>
							</div>

							<div className={styles.form_controls}>
								<button
									className={styles.cancel}
									onClick={handleClose}
									type="button"
								>
									CANCEL
								</button>
								<button className={styles.next} type="submit">
									{editMode ? "SAVE" : "NEXT"}
								</button>
							</div>
						</form>
					}
				/>
			</>
		);
	}
);
export default GroupModalStep1;
