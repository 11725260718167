import profileApi from "api/profile";
import { router } from "AppRoutes";
import useMutate from "hooks/useMutate";
import { persistor } from "index";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { companyInfoUpdated } from "store/slices/company";
import { profileInfoUpdated } from "store/slices/user";
import useGetCompanies from "./useGetCompanies";

const useProfileSwitch = (enabled = true, callback) => {
	const dispatch = useDispatch();

	const { data, isFetching, isLoading, refetch } = useGetCompanies(enabled);

	const userCompanies = useMemo(() => {
		const companies = [];

		data &&
			Object.values(data).forEach((category) => {
				companies.push(...category);
			});

		return companies;
	}, [data]);

	const updateLocalStorage = async (response) => {
		const data = response.data;
		const isUser = data.type === "USER";

		if (isUser) localStorage.removeItem("companyId");
		else localStorage.setItem("companyId", data.uuid);

		dispatch(isUser ? profileInfoUpdated(data) : companyInfoUpdated(data));

		window.dispatchEvent(new Event("storage"));

		persistor.flush().then(() => {
			if (callback) {
				callback();
			} else {
				router.navigate(0); //refresh current page
			}
		});
	};

	const {
		action: { mutate: switchToProfile },
	} = useMutate(profileApi.getUserProfile, updateLocalStorage);

	const {
		action: { mutate: switchToCompany },
	} = useMutate(profileApi.getCompanyProfile, updateLocalStorage);

	return {
		switchToProfile,
		switchToCompany,
		userCompanies,
		isFetching,
		isLoading,
		refetch,
	};
};

export default useProfileSwitch;
