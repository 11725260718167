import {
	configureStore,
	//  getDefaultMiddleware
} from "@reduxjs/toolkit";
import reducer from "./reducer/combinedReducers";
import error from "./middleware/error";
import api from "./middleware/api";
import store from "./middleware/store";

import storage from "redux-persist/lib/storage";
// import storage from "redux-persist/lib/storage/session"; //sessionStorage
import { persistReducer } from "redux-persist";
import { createFilter } from "redux-persist-transform-filter";
import socket from "./middleware/socket";

// const userSubset = createFilter("user", ["profileInfo"]);

const activeTabSubset = createFilter("activeTab", [
	"profileTab",
	"connectionTab",
]);

export const persistConfig = {
	key: "root",
	version: 1.1,
	storage,
	whitelist: ["user", "otp", "company", "fcmToken", "activeTab", "chat"],
	transforms: [activeTabSubset],
	// whitelist: ['user', 'post'],
	// blacklist: ['jwt'],
	// transforms: [
	//     encryptTransform({
	//         // todo:: set the secret key
	//         secretKey: "PASSMEIN@@@@___!!!!!",
	//         onError: function (error) {
	//             // Handle the error.
	//             console.error(error)
	//         },
	//     }),
	// ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default function createAppStore() {
	return configureStore({
		reducer: persistedReducer,
		// middleware: [
		// 	...getDefaultMiddleware({
		// 		serializableCheck: false,
		// 	}),
		// error,
		// api,
		// socket,
		// store,
		// ],
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({ serializableCheck: false }).concat([
				error,
				api,
				socket,
				store,
			]),
	});
}
