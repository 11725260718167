import GroupMember from "components/Chat/GroupMember";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { Fragment, useMemo } from "react";
import styles from "./ConnectionSection.module.scss";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const ConnectionSection = ({ queryFn, queryKey, pageSize }) => {
	const { items, viewRef, isFetchingNextPage, isFetching } =
		useInfiniteScroll({
			queryKey,
			queryFn,
			pageSize,
		});

	const itemsList = useMemo(
		() =>
			items.map((item, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<Fragment key={item.uuid}>
						<GroupMember
							ref={isLastItem ? viewRef : null}
							data={item}
							redirect={true}
						/>
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</Fragment>
				);
			}),
		[isFetchingNextPage, items, viewRef]
	);

	return (
		<div className={styles.container}>
			{itemsList}
			{isFetching && <ContactLoader />}
			<NoResults
				isSearch={true}
				visible={items.length === 0 && !isFetching}
				text={"No results"}
			/>
		</div>
	);
};

export default ConnectionSection;
