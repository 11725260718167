import client from "./client";

const endpoint = "/software";

//! USER ENDPOINTS ===========================

const searchSoftware = (page, size, q = "") =>
	client.get(endpoint + `/search?page=${page}&size=${size}&q=${q}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	searchSoftware,
};
