import SliderBar from "../../../Utils/SliderBar/SliderBar";
import blackTickIcon from "images/black-tick-circle-empty-20-x-20.svg";
import styles from "./SoftwareEntry.module.css";
import EditButton from "components/Utils/Button/EditButton";
import DeleteButton from "components/Utils/Button/DeleteButton";

const SoftwareItem = ({
	item,
	onEditClicked,
	onDelete,
	showControls = true,
}) => {
	const years = item.years > 1 ? `${item.years} years` : `${item.years} year`;
	const months =
		item.months > 1 ? `${item.months} months` : `${item.months} month`;

	return (
		<div className={styles.software_item} key={item.uuid}>
			<div className={styles.software_header}>
				<div className={styles.software_icon}>
					<img src={item.software.icon} alt="" />
				</div>
				<div>
					<div className={styles.software_name}>
						<label>{item.software.name}</label>
					</div>
					<div className={styles.software_time}>
						<label>{`${item.years > 0 ? years : ""}${
							item.years > 0 && item.months > 0 ? "," : ""
						} ${item.months > 0 ? months : ""}`}</label>
					</div>
				</div>
			</div>
			<div className={styles.software_slider_wrapper}>
				<SliderBar
					width={"97%"}
					trackColor="#dbdfe4"
					barColor="#6cc5d1"
					maxDomain={10}
					value={[item.proficiency]}
					tickImg={blackTickIcon}
					tickWidth={23}
					tickHeight={25}
					margin={-6}
					tickPadding={"1px 0 0 0"}
					labelOnTick={true}
				/>
			</div>
			{/* {showControls && (
				<div className={styles.software_controls}>
					<img src={editIcon} onClick={onEditClicked} alt="Edit" />
					<img src={binIcon} onClick={onDelete} alt="Delete" />
				</div>
			)} */}

			{showControls && (
				<div className={styles.controls}>
					<EditButton onClick={onEditClicked} />
					<DeleteButton onClick={onDelete} />
				</div>
			)}
		</div>
	);
};

export default SoftwareItem;
