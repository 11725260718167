import { classNames } from "primereact/utils";
import styles from "./FeedContainer.module.scss";

const FeedContainer = ({ children, className, fixedContainer = false }) => {
	return (
		<div
			className={classNames(styles.container, className, {
				[styles.fixed_container]: fixedContainer,
			})}
		>
			{children}
		</div>
	);
};

export default FeedContainer;
