import useUserLocation from "hooks/useUserLocation";
import styles from "./SuggestedProfiles.module.scss";
import profileApi from "api/profile";
import { peopleKeys } from "queryKeys/people-key-factory";
import ConnectionItem from "./ConnectionItem";
import ConnectionStatus from "components/Utils/SubComs/ConnectionStatus/ConnectionStatus";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import useApi from "hooks/useApi";
import { useQuery } from "@tanstack/react-query";

const PAGE_SIZE = 5;
const SuggestedProfiles = () => {
	const { userCoordinates } = useUserLocation();

	const suggestedPeopleApi = useApi(profileApi.getPeopleNearby, true, true);

	const fetchSuggested = async ({ queryKey }) => {
		const [_, __, userCoordinates] = queryKey;
		const response = await suggestedPeopleApi.request(
			0,
			PAGE_SIZE,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const { data: suggestedPeople } = useQuery({
		queryKey: peopleKeys.nearbyPeople(userCoordinates),
		queryFn: fetchSuggested,
	});

	const suggestedList = suggestedPeople?.map((profile) => (
		<ConnectionItem
			key={profile.uuid}
			data={profile}
			rightSideAction={
				<ConnectionStatus
					friendStatus={profile.connection.friendStatus}
					inMyWorkCircle={profile.connection.inMyWorkCircle}
				/>
			}
		/>
	));

	if (!suggestedPeople || suggestedPeople?.length === 0) return null;

	return (
		<ProfileTabContainer customStyle={styles.section_container}>
			<h3>Suggested Profile</h3>
			<div className={styles.items_container}>{suggestedList}</div>
		</ProfileTabContainer>
	);
};

export default SuggestedProfiles;
