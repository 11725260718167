export const postsKeys = {
	all: ["posts"],
	details: () => [...postsKeys.all, "details"],
	detail: (postId) => [...postsKeys.details(), postId],
	reactions: (reactionType, tab, id) => [
		...postsKeys.all,
		"reactions",
		reactionType,
		tab,
		id,
	],
	sharedBy: (postId) => [...postsKeys.all, "sharedBy", postId],
	suggested: (lat, lng) => [
		...postsKeys.all,
		"suggested",
		{
			lat,
			lng,
		},
	],
};
