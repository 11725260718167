export const filterKeys = {
	all: ["filters"],
	agency: (filters) => [...filterKeys.all, "agency", filters],

	talent: (agencyId, filters, agenciesOnly = false) => [
		...filterKeys.all,
		"talent",
		agencyId,
		filters,
		agenciesOnly,
	],
	dynamicTalentFilter: (type, agencyId, filters, agenciesOnly = false) => [
		...filterKeys.all,
		"talentDynamicFilter",
		type,
		agencyId,
		filters,
		agenciesOnly,
	],

	people: (filters) => [...filterKeys.all, "people", filters],
	dynamicPeopleFilter: (type, filters) => [
		...filterKeys.all,
		"peopleDynamicFilter",
		type,
		filters,
	],

	companies: (filters) => [...filterKeys.all, "companies", filters],
	dynamicCompaniesFilter: (type, filters) => [
		...filterKeys.all,
		"companiesDynamicFilter",
		type,
		filters,
	],

	jobs: (filters) => [...filterKeys.all, "jobs", filters],
	dynamicJobsFilter: (type, filters) => [...filterKeys.all, "jobsDynamicFilter", type, filters],

	products: (filters) => [...filterKeys.all, "products", filters],
	dynamicProductsFilter: (type, filters) => [
		...filterKeys.all,
		"productsDynamicFilter",
		type,
		filters,
	],

	services: (filters) => [...filterKeys.all, "services", filters],
	servicesCategoriesFilters: (filters) => [...filterKeys.all, "services", "categories", filters],
};
