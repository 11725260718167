import React from "react";
import ContentLoader from "react-content-loader";

const ContactLoader = (props) => (
	<ContentLoader
		speed={2}
		width={"100%"}
		height={60}
		// viewBox="0 0 100% 60"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<circle cx="20" cy="31" r="20" />
		<rect x="50" y="18" rx="2" ry="2" width="100%" height="10" />
		<rect x="50" y="34" rx="2" ry="2" width="75%" height="10" />
	</ContentLoader>
);

export default ContactLoader;
