import { formatFiltersValues } from "components/Utils/General";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { searchKeys } from "queryKeys/search-key-factory";
import { useSelector } from "react-redux";
import searchApi from "../../../api/search";
import PeopleAndCompanies from "../SearchComponents/PeopleAndCompanies";
import styles from "./All.module.scss";

const PAGE_SIZE = 40;

const Companies = ({ userCoordinates, isMobile, onShowModal, tagsList }) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const companiesFiltersObject = useSelector((state) => state.filters.companiesFilters);

	const storedCompaniesFilters = {
		...companiesFiltersObject,
		q: inputValue,
		locationIdForSort: userCoordinates.id,
	};

	const searchCompaniesApi = useApi(searchApi.searchCompanies, true, true);

	const fetchCompanies = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchCompaniesApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const { items, isFetchingNextPage, viewRef, isLoading } = useInfiniteScroll({
		queryKey: searchKeys.companies(storedCompaniesFilters),
		queryFn: fetchCompanies,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.list?.length === PAGE_SIZE ? pages.length : undefined;
				return nextPage;
			},
		},
	});

	const data = items?.flatMap((page) => page.list);

	const count = items?.[0]?.count;

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<PeopleAndCompanies
					viewRef={viewRef}
					data={data}
					count={count}
					isPaginated={true}
					isFetchingNextPage={isFetchingNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
					isCompany={true}
				/>
			</div>
		</div>
	);
};

export default Companies;
