import useApi from "hooks/useApi";
import servicesApi from "api/services";
import { useInfiniteQuery } from "@tanstack/react-query";
import { servicesKeys } from "queryKeys/services-key-factory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const PAGE_SIZE = 100;
const productsToShow = 8;
export const useGetServicesProduct = (userId, enabled = true) => {
	const { ref: viewRefLevel1, inView: inViewLevel1 } = useInView({
		triggerOnce: true,
	});
	const getServicesApi = useApi(
		servicesApi.getServicesWithProducts,

		true,
		true
	);
	const fetchServices = async ({ pageParam = 0, queryKey }) => {
		const [, , , id] = queryKey;
		const response = await getServicesApi.request(
			id,
			pageParam,
			PAGE_SIZE,
			productsToShow,
			""
		);

		return response.data;
	};
	const {
		data: productsServicesLevel1,
		hasNextPage: hasNextPageLevel1,
		fetchNextPage: fetchNextPageLevel1,
		isFetching: isFetchingLevel1,
		isLoading: isLoadingLvl1,
		isInitialLoading: isInitialLoadingLvl1,
	} = useInfiniteQuery({
		queryKey: servicesKeys.serviceLvl(1, userId, "", false),
		queryFn: fetchServices,
		getNextPageParam: (lastPage, pages) => {
			const nextPage =
				lastPage.length === PAGE_SIZE ? pages.length : undefined;
			return nextPage;
		},
		enabled: enabled,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (inViewLevel1 && hasNextPageLevel1) fetchNextPageLevel1();
	}, [inViewLevel1, hasNextPageLevel1, fetchNextPageLevel1]);
	return {
		viewRefLevel1,
		productsServicesLevel1,
		isFetchingLevel1,
		isLoadingLvl1,
		isInitialLoadingLvl1,
	};
};
