import client from "./client";

const endpoint = "/profile";

const fileContentType = {
	"Content-Type": "multipart/form-data",
};

//! USER ENDPOINTS ===========================

const getVaultInfo = () => client.get(endpoint + `/storage`);

const deleteGalleryImageStorage = () => client.delete(endpoint + `/storage/gallery/images`);

const deleteGalleryPdfStorage = () => client.delete(endpoint + `/storage/gallery/pdfs`);

const deleteGalleryVideoStorage = () => client.delete(endpoint + `/storage/gallery/video`);

const deleteVaultFileStorage = () => client.delete(endpoint + `/storage/vault/files`);

const deleteVaultImageStorage = () => client.delete(endpoint + `/storage/vault/images`);

const deleteVaultVideoStorage = () => client.delete(endpoint + `/storage/vault/video`);

const search = (page = 0, size = 20, q) =>
	client.get(endpoint + `/search?page=${page}&size=${size}&q=${q}`);

const searchTags = (page = 0, size = 20, q = "") =>
	client.get(endpoint + `/search/tags?page=${page}&size=${size}&q=${q}`);

const searchGroupProfile = (page = 0, size = 20, groupId, q) =>
	client.get(endpoint + `/search/groups?page=${page}&size=${size}&groupId=${groupId}&q=${q}`);

const searchUser = (page = 0, size = 20, q) =>
	client.get(endpoint + `/user/search?page=${page}&size=${size}&q=${q}`);

const getUserProfile = (userId) => client.get(endpoint + `/user/get?requestedUserId=${userId}`);

const getUserByUrl = (url) => client.get(endpoint + `/url?url=${url}`);

const urlCheck = (url) => client.get(endpoint + `/checkURL?url=${url}`);

const updateUserInfo = (data) => client.put(endpoint + "/user", data);
const getProfileLocations = () => client.get(endpoint + "/locations");
const updateProfileLocations = (locationIds) => {
	const url = endpoint + "/locations";
	const params = locationIds.map((id) => `locationIds=${id}`).join("&");
	const fullUrl = `${url}?${params}`;
	return client.put(fullUrl);
};

const block = (profileId) => client.patch(endpoint + `/block?blockedProfileId=${profileId}`);

const unblock = (profileId) => client.patch(endpoint + `/unblock?blockedProfileId=${profileId}`);

const passwordLink = (email, link) =>
	client.post(endpoint + `/user/forgotPassword/sendLink?email=${email}&link=${link}`);

const checkEmail = (email) => client.get(endpoint + `/checkEmail?email=${email}`);

const resetPassword = (email, newPassword, key) =>
	client.post(
		endpoint + `/user/resetPassword?email=${email}&newPassword=${newPassword}&key=${key}`
	);

const updatePassword = (oldPassword, newPassword) =>
	client.put(
		endpoint + `/user/updatePassword?oldPassword=${oldPassword}&newPassword=${newPassword}`
	);

const getConnectionsCount = (requestedProfileId) =>
	client.get(endpoint + `/connections/count?requestedProfileId=${requestedProfileId}`);

const getCommonConnections = (page, size, requestedProfileId) =>
	client.get(
		endpoint +
			`/connections/common?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}`
	);

const getRecentConnections = (page, size) =>
	client.get(endpoint + `/connections/recent?page=${page}&size=${size}`);

const getFavorites = (page, size, query = "") =>
	client.get(endpoint + `/favorites?page=${page}&size=${size}${query && `&q=${query}`}`);

const addFavorite = (targetProfileId) =>
	client.post(endpoint + `/favorites?targetProfileId=${targetProfileId}`);

const removeFavorite = (targetProfileId) =>
	client.delete(endpoint + `/favorites?targetProfileId=${targetProfileId}`);

const addCertificateFiles = (formData) =>
	client.post(endpoint + "/user/certificate/file", formData);

const deleteCertificateFile = (certificationId, fileId) =>
	client.delete(
		endpoint + `/user/certificate/file?certificationId=${certificationId}&fileId=${fileId}`
	);

const addEducationFiles = (formData) => client.post(endpoint + "/user/education/file", formData);

const deleteEducationFile = (educationId, fileId) =>
	client.delete(endpoint + `/user/education/file?educationId=${educationId}&fileId=${fileId}`);

const getNearbyCompanies = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint + `/user/landing/company/nearby?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getHiringCompanies = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint +
			`/user/landing/users/company/hiring?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getNetworkCompanies = (page, size = 5) =>
	client.get(endpoint + `/user/landing/network/company?page=${page}&size=${size}`);

const getPeopleInterests = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint + `/user/landing/users/skills?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getPeopleNearby = (page, size = 5, lat = 0, lng = 0) =>
	client.get(
		endpoint + `/user/landing/users/nearby?page=${page}&size=${size}&lat=${lat}&lng=${lng}`
	);

const getPeopleFromMyCompany = (page, size = 5) =>
	client.get(endpoint + `/user/landing/users/myCompany?page=${page}&size=${size}`);

const getNetworkPeople = (page, size = 5) =>
	client.get(endpoint + `/user/landing/network/users?page=${page}&size=${size}`);

const sendOtp = (email = "", mobile = "", code = "") =>
	client.get(endpoint + `/user/sendOTP?email=${email}&mobile=${mobile}&code=${code}`);

const updatePhone = (countryCode, mobile) =>
	client.put(endpoint + `/user/login/mobile?countryCode=${countryCode}&mobile=${mobile}`);

const deleteAccount = () => client.delete(endpoint + `/user`);

const logout = (fcmToken) => client.patch(endpoint + `/user/logout?fcmToken=${fcmToken}`);

const registerToken = (fcmToken, agent) =>
	client.post(endpoint + `/user/registerToken?fcmToken=${fcmToken}&agent=${agent}`);

const unregisterToken = (fcmToken) =>
	client.delete(endpoint + `/user/registerToken?fcmToken=${fcmToken}`);

const getCurrentPositions = () => client.get(endpoint + `/user/experience/current`);

//! COMPANY ENDPOINTS ===========================

const getCompanyProfile = (companyId) =>
	client.get(
		endpoint + `/company/get?companyId=${companyId}`,
		{}
		// { headers: { useUserId: true } }
	);
const getUserCompany = () => client.get(endpoint + "/company/getUserCompany");

const createCompany = (formData) => client.post(endpoint + "/company", formData);

const updateCompany = (formData) => client.put(endpoint + "/company", formData);

const deleteCompany = (companyId) => client.delete(endpoint + `/company?companyId=${companyId}`);

const transferCompany = (companyId, toProfileId) =>
	client.put(
		endpoint + `/company/users/transferOwner?companyId=${companyId}&toProfileId=${toProfileId}`
	);

const getCompanyUsers = (companyId, userType) =>
	client.get(endpoint + `/company/users?companyId=${companyId}&type=${userType}`);

const addCompanyUser = (companyId, profileId, userType) =>
	client.post(
		endpoint + `/company/users?companyId=${companyId}&profileId=${profileId}&type=${userType}`
	);

const deleteCompanyUser = (companyId, profileId, userType) =>
	client.delete(
		endpoint + `/company/users?companyId=${companyId}&profileId=${profileId}&type=${userType}`
	);

const addCompanyCertificateFiles = (formData) =>
	client.post(endpoint + "/company/certificate/file", formData);

const addKeyPeople = (companyId, profileId, role) =>
	client.post(
		endpoint + `/company/users/key?companyId=${companyId}&profileId=${profileId}&role=${role}`
	);

const searchCompany = (q = "", page = 0, size = 10, agenciesOnly = false) =>
	client.get(
		endpoint + `/company/search?q=${q}&page=${page}&size=${size}&agenciesOnly=${agenciesOnly}`
	);

const becomeAgency = () => client.put(endpoint + "/company/agency/add");

const deleteAgency = () => client.delete(endpoint + "/company/agency/delete");

const sendAgencyRequest = (agencyId, specialProfileId) =>
	client.post(
		endpoint +
			`/company/agency/request/send?agencyId=${agencyId}&specialProfileId=${specialProfileId}`
	);

const unlinkAgency = (agencyId, specialProfileId) =>
	client.delete(
		endpoint +
			`/company/agency/request/delete?agencyId=${agencyId}&specialProfileId=${specialProfileId}`
	);

const getPendingTalent = (requestedProfileId) =>
	client.get(endpoint + `/company/agency/pending/count?requestedProfileId=${requestedProfileId}`);

const acceptTalentRequest = (agencyId, specialProfileId, alias) =>
	client.put(
		endpoint +
			`/company/agency/request/confirm?agencyId=${agencyId}&specialProfileId=${specialProfileId}&alias=${alias}`
	);

const declineTalentRequest = (agencyId, specialProfileId) =>
	client.delete(
		endpoint +
			`/company/agency/request/cancel?agencyId=${agencyId}&specialProfileId=${specialProfileId}`
	);

const getAgencyFilters = (body, lat = 0, lng = 0) =>
	client.post(endpoint + `/company/agency/search/filter?latitude=${lat}&longitude=${lng}`, body);

const searchAgency = (page, size = 10, body, lat = 0, lng = 0) =>
	client.post(
		endpoint +
			`/company/agency/search?page=${page}&size=${size}&latitude=${lat}&longitude=${lng}`,
		body
	);

const getPendingEmployees = (page, size = 10) =>
	client.get(endpoint + `/company/experience/role/pending?page=${page}&size=${size}`);

const getVerifiedEmployees = (page, size = 10, requestedCompanyId, q = "") =>
	client.get(
		endpoint +
			`/company/experience/role/verified?page=${page}&size=${size}&requestedCompanyId=${requestedCompanyId}&q=${q}`
	);

const verifyRole = (targetProfileId, roleId) =>
	client.put(
		endpoint +
			`/company/experience/role/verify?targetProfileId=${targetProfileId}&roleId=${roleId}`
	);

const rejectRole = (targetProfileId, roleId) =>
	client.put(
		endpoint +
			`/company/experience/role/reject?targetProfileId=${targetProfileId}&roleId=${roleId}`
	);

const deleteEmployee = (employeeId) =>
	client.delete(endpoint + `/company/experience/employee?employeeId=${employeeId}`);

const registerUser = (data) => client.post(endpoint + "/user/register", data);

const updateRoles = (roleIds) => client.put(endpoint + "/user/role", roleIds);

const updateSkills = (skillsIds) => client.put(endpoint + "/user/skills", skillsIds);

const updateBio = (data) => client.put(endpoint + "/user/bio", data);

const deleteBio = () => client.delete(endpoint + "/user/bio");

const updateCompanyBio = (data, companyId) =>
	client.put(endpoint + `/company/bio?companyId=${companyId}`, data);

const deleteCompanyBio = (companyId) =>
	client.delete(endpoint + `/company/bio?companyId=${companyId}`);

const updatePicture = (formData, isCompany) => {
	return client.put(endpoint + `/${isCompany ? "company" : "user"}/profileImage`, formData, {
		headers: fileContentType,
	});
};

const deleteCompanyCertificateFile = (certificationId, fileId, companyId) =>
	client.delete(
		endpoint +
			`/company/certificate/file?certificationId=${certificationId}&fileId=${fileId}&companyId=${companyId}`
	);

// const updatePicture = (formData, imgData, companyId = undefined) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}

// 	return client.put(
// 		endpoint +
// 			`/${companyId ? "company" : "user"}/profileImage?${array.join(
// 				"&"
// 			)}${companyId ? `&companyId=${companyId}` : ""}`,
// 		formData,
// 		{ headers: fileContentType }
// 	);
// };

const deleteProfilePic = (companyId = undefined) =>
	client.delete(
		endpoint +
			`/${companyId ? "company" : "user"}/profileImage${
				companyId ? `?companyId=${companyId}` : ""
			}`
	);

const updateCoverPicture = (formData, isCompany) => {
	return client.put(endpoint + `/${isCompany ? "company" : "user"}/coverImage`, formData, {
		headers: fileContentType,
	});
};
// const updateCoverPicture = (formData, imgData, companyId = undefined) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}

// 	return client.put(
// 		endpoint +
// 			`/${companyId ? "company" : "user"}/coverImage?${array.join("&")}${
// 				companyId ? `&companyId=${companyId}` : ""
// 			}`,
// 		formData,
// 		{ headers: fileContentType }
// 	);
// };

const deleteCoverPic = (companyId = undefined) =>
	client.delete(
		endpoint +
			`/${companyId ? "company" : "user"}/coverImage${
				companyId ? `?companyId=${companyId}` : ""
			}`
	);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getVaultInfo,
	search,
	searchTags,
	searchGroupProfile,
	searchUser,
	searchCompany,
	getUserProfile,
	getUserByUrl,
	updateUserInfo,
	urlCheck,
	block,
	unblock,
	passwordLink,
	resetPassword,
	updatePassword,
	getConnectionsCount,
	getCommonConnections,
	getRecentConnections,
	getFavorites,
	addFavorite,
	removeFavorite,
	checkEmail,
	getCompanyProfile,
	getUserCompany,
	createCompany,
	deleteCompany,
	transferCompany,
	addEducationFiles,
	deleteEducationFile,
	addCertificateFiles,
	addCompanyCertificateFiles,
	addKeyPeople,
	updateCompany,
	becomeAgency,
	deleteAgency,
	sendAgencyRequest,
	unlinkAgency,
	getPendingTalent,
	acceptTalentRequest,
	declineTalentRequest,
	getAgencyFilters,
	getProfileLocations,
	updateProfileLocations,
	searchAgency,
	getVerifiedEmployees,
	getPendingEmployees,
	verifyRole,
	rejectRole,
	deleteEmployee,
	getNearbyCompanies,
	getHiringCompanies,
	getNetworkCompanies,
	getPeopleInterests,
	getPeopleNearby,
	getPeopleFromMyCompany,
	getNetworkPeople,
	sendOtp,
	updatePhone,
	deleteAccount,
	registerUser,
	logout,
	registerToken,
	unregisterToken,
	deleteCertificateFile,
	deleteCompanyCertificateFile,
	updateRoles,
	updateSkills,
	updateBio,
	deleteBio,
	updateCompanyBio,
	deleteCompanyBio,
	updatePicture,
	updateCoverPicture,
	deleteProfilePic,
	deleteCoverPic,
	deleteGalleryImageStorage,
	deleteGalleryPdfStorage,
	deleteGalleryVideoStorage,
	deleteVaultFileStorage,
	deleteVaultImageStorage,
	deleteVaultVideoStorage,
	getCompanyUsers,
	deleteCompanyUser,
	addCompanyUser,
	getCurrentPositions,
};
