import { useQuery } from "@tanstack/react-query";
import searchApi from "api/search";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { formatFiltersValues } from "components/Utils/General";
import MultiCheckbox from "components/Utils/MultiCheckbox/MultiCheckbox";
import Checkbox from "components/Utils/SubComs/Inputs/Checkbox/Checkbox";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetServicesFilters, updateServicesFilters } from "store/slices/filters";
import ConnectionFilter from "./ConnectionFilter";
import styles from "./PeopleFilter.module.scss";
import useFilterAndSort from "./useFilterAndSort";
// import styles from "./ServicesFilter.module.scss";

const FILTERS_PAGE_SIZE = 100;

const ServicesFilter = forwardRef(({ userCoordinates, companyId }, ref) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const dispatch = useDispatch();

	const servicesFiltersObject = useSelector((state) => state.filters.servicesFilters);

	const storedServicesFilters = {
		...servicesFiltersObject,
		q: inputValue,
		locationIds: [{ uuid: userCoordinates.id, value: userCoordinates.id }], //* formatting it like that (object in array) because of how the api request is structured and because of how formatFiltersValues() works
	};

	useEffect(() => {
		return () => {
			//CLEARS SERVICES SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetServicesFilters());
		};
	}, []);

	// ! ========= FETCHING OF DEFAULT SERVICES FILTERS ============

	const servicesFiltersApi = useApi(searchApi.servicesFilters, true, true);

	const fetchServicesFilters = async ({ queryKey }) => {
		const [_, __, filters] = queryKey;
		const response = await servicesFiltersApi.request(formatFiltersValues(filters));
		return response.data;
	};

	const {
		data: servicesFilters,
		// isLoading, //TODO >> loading spinner for 1st render
		isFetching,
		// isError,
	} = useQuery({
		queryKey: filterKeys.services(storedServicesFilters),
		queryFn: fetchServicesFilters,
	});

	// !========================================================

	const servicesCategoriesApi = useApi(searchApi.servicesCategories, true, true);

	const fetchServicesCategories = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, ___, filters] = queryKey;
		const response = await servicesCategoriesApi.request(
			pageParam,
			FILTERS_PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		items: dynamicCategories,
		hasNextPage: hasNextPageCategories,
		refetch: refetchCategories,
		loadMore: loadMoreCategories,
	} = useInfiniteScroll({
		queryKey: filterKeys.servicesCategoriesFilters(storedServicesFilters),
		queryFn: fetchServicesCategories,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		gaawkCategories = [],
		includeMedia,
		includeProducts,
		friends,
		inMyCircle,
		inTheirCircle,
	} = storedServicesFilters || {};

	const { gaawkCategories: gaawkList = [] } = servicesFilters || {};

	const categoriesRef = useRef();

	const filteredCategories = useFilterAndSort(
		gaawkCategories,
		dynamicCategories,
		gaawkList,
		dynamicCategories.length > 0,
		"uuid",
		true
	);

	useImperativeHandle(ref, () => ({
		updateDynamicFilters(key, tag) {
			switch (key) {
				case "gaawkCategories":
					categoriesRef.current?.removeItem(tag);
					break;

				default:
					break;
			}
		},
	}));

	if (isFetching) return <LoadingSpinner customStyle={styles.loading_spinner} />;

	return (
		<div className={styles.container}>
			{gaawkList?.length > 0 && (
				<div className={styles.multicheckbox_container}>
					<MultiCheckbox
						ref={categoriesRef}
						title={"Gaawk Categories"}
						options={filteredCategories.map((item) => defaultFormatter(item))}
						onSelect={(gaawkCategories) =>
							dispatch(updateServicesFilters({ gaawkCategories }))
						}
						selected={gaawkCategories}
						perRow="2, 150px"
					/>
					{((dynamicCategories?.length === 0 && gaawkList.length === 4) ||
						(dynamicCategories?.length > 0 && hasNextPageCategories)) && (
						<SeeMore
							onClick={
								dynamicCategories?.length === 0
									? refetchCategories
									: loadMoreCategories
							}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			<div className={styles.agency_wrapper}>
				<div className={styles.agency_checkbox}>
					<Checkbox
						className={styles.agency_checkbox}
						w="14px"
						h="14px"
						checked={includeProducts}
						onChange={() =>
							dispatch(updateServicesFilters({ includeProducts: !includeProducts }))
						}
						name={"media"}
						customBackgroundColor={"gaawk"}
					/>
					<span
						onClick={() =>
							dispatch(updateServicesFilters({ includeProducts: !includeProducts }))
						}
					>
						Only services that have products in it
					</span>
				</div>
			</div>

			<div className={styles.agency_wrapper}>
				<div className={styles.agency_checkbox}>
					<Checkbox
						className={styles.agency_checkbox}
						w="14px"
						h="14px"
						checked={includeMedia}
						onChange={() =>
							dispatch(updateServicesFilters({ includeMedia: !includeMedia }))
						}
						name={"media"}
						customBackgroundColor={"gaawk"}
					/>
					<span
						onClick={() =>
							dispatch(updateServicesFilters({ includeMedia: !includeMedia }))
						}
					>
						Only services that have media tagged in it
					</span>
				</div>
			</div>

			<ConnectionFilter
				friends={friends}
				friendLabel="From my friends"
				onFriendChange={
					!companyId && ((value) => dispatch(updateServicesFilters({ friends: value })))
				}
				inMyCircle={inMyCircle}
				myCircleLabel="From my work circle"
				onMyWorkChange={(value) => dispatch(updateServicesFilters({ inMyCircle: value }))}
				inTheirCircle={inTheirCircle}
				theirCircleLabel="From accounts who added me to their work circle"
				onTheirWorkChange={(value) =>
					dispatch(updateServicesFilters({ inTheirCircle: value }))
				}
			/>

			<div className={styles.button_container}>
				<PrimaryButton
					type="button"
					text={"clear filters"}
					className={`${styles.btn} ${styles.clear}`}
					onClick={() => dispatch(resetServicesFilters())}
				/>
			</div>
		</div>
	);
});

export default ServicesFilter;
