import styles from "./SoftwareEntry.module.css";

const ProficiencySelector = ({ proficiency, register, setValue, errors }) => {
	const renderButtons = () => {
		return Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
			<button
				key={value}
				type="button"
				className={proficiency >= value ? styles.selected : ""}
				{...register("proficiency")}
				onClick={() =>
					setValue("proficiency", value, { shouldDirty: true })
				}
			>
				{value}
			</button>
		));
	};

	return (
		<div className={styles.form_input_wrapper}>
			<label>
				Proficiency
				<span className="required">*</span>
			</label>

			<div
				className={`${styles.proficiency_wrapper} ${
					errors?.proficiency ? styles.error : undefined
				}`}
			>
				{renderButtons()}
			</div>

			{errors?.proficiency?.message && (
				<p className={styles.error_message}>
					{errors?.proficiency?.message}
				</p>
			)}
		</div>
	);
};

export default ProficiencySelector;
