import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import { useConnectionQueries } from "hooks/useConnectionQueries";
import useConnectionsCount from "hooks/useConnectionsCount";
import useDebounce from "hooks/useDebounce";
import useFetchProfile from "hooks/useFetchProfile";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useState } from "react";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./ConnectionDetails.module.css";
import ConnectionScrollableMenu from "./ConnectionScrollableMenu";
import ConnectionSection from "./ConnectionSection";
import { getCounter } from "./connection-counter-messages";
import useUrlParams from "hooks/useUrlParams";

const pageSize = 20;

const ConnectionDetails = () => {
	const { profileData: profile, isFetching, isFetchingUrl, canEdit } = useFetchProfile();

	const queryFunctions = useConnectionQueries(pageSize);

	const { firstName, lastName, name, type } = profile || {};

	const fullName = type === "USER" ? `${firstName} ${lastName}` : `${name}`;

	// ! ======= get count =======

	const { data: connectionCount } = useConnectionsCount(profile?.uuid);

	// ! ========== tab change ==============

	const authorizedTabs = canEdit
		? [
				"friends",
				"pendingSent",
				"pendingReceived",
				"yourWorkCircle",
				"theirWorkCircle",
				"following",
				"followers",
				"favorites",
				"blocked",
		  ]
		: ["friends", "yourWorkCircle", "theirWorkCircle", "following", "followers"];

	const paramConfigs = {
		tab: {
			validator: (tab) => authorizedTabs.includes(tab),
			defaultValue: "friends",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);
	const { tab: activeTab } = params;

	// ! ========= Search handlers =========
	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleClear = () => {
		setSearchInput("");
	};

	const handleChangeInput = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	if (isFetching || isFetchingUrl) return <LoadingPage />;

	return (
		<div>
			<div className={styles.scrollableMenu_wrapper}>
				<ConnectionScrollableMenu
					onItemClicked={(tab) => {
						setParams({ tab });
						setSearchInput("");
					}}
					canEdit={canEdit}
					activeTab={activeTab}
					profile={profile}
				/>
			</div>
			{connectionCount && (
				<div className={styles.counter}>
					{getCounter(activeTab, connectionCount, canEdit, fullName)}
				</div>
			)}
			<div className={styles.search_container}>
				<SearchInput
					onChange={handleChangeInput}
					value={searchInput}
					showIcons={true}
					onClearClicked={handleClear}
					border={false}
				/>
			</div>
			<div>
				<ConnectionSection
					pageSize={pageSize}
					queryFn={queryFunctions[activeTab]}
					queryKey={connectionsKeys.list(profile.uuid, activeTab, debouncedSearch)}
				/>
			</div>
		</div>
	);
};

export default ConnectionDetails;
