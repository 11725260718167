import MultiCheckbox from "components/Utils/MultiCheckbox/MultiCheckbox";
import styles from "../PeopleFilter.module.scss";
import { genderTypes } from "components/Utils/General";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { defaultFormatter, languageFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import useApi from "hooks/useApi";
import ethnicityApi from "api/ethnicity";
import { useQuery } from "@tanstack/react-query";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import { forwardRef, useImperativeHandle, useRef } from "react";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";

const CastingFilters = forwardRef(
	(
		{
			onGenderChange,
			gender,
			onMinAge,
			onMaxAge,
			minAge,
			maxAge,
			onEthnicity,
			ethnicity,
			languagesList,
			onLanguage,
			languages,
			isFetchedLanguages,
			hasNextPageLanguages,
			onRefetch,
			onFetchNextPage,
			baseLanguagesCount,
		},
		ref
	) => {
		// male : 0
		// female: 1
		// both: 2
		const languageRef = useRef();

		useImperativeHandle(ref, () => ({
			clear() {
				languageRef.current?.clear();
			},
			removeItem(item) {
				languageRef.current.removeItem(item);
			},
		}));

		const getEthnicitiesApi = useApi(ethnicityApi.getEthnicities, true, true);

		const fetchEthnicity = async () => {
			const response = await getEthnicitiesApi.request();
			return response.data;
		};

		const {
			// isLoading,
			// isError,
			data = [],
		} = useQuery({
			queryKey: "ethnicity",
			queryFn: fetchEthnicity,

			staleTime: 300000, // 5 minutes
		});

		return (
			<div className={styles.separator_container}>
				<div className={styles.container}>
					<MultiCheckbox
						// ref={timeRef}
						title={"Gender"}
						type="radio"
						options={genderTypes}
						onSelect={onGenderChange}
						selected={gender}
						perRow="3, 100px"
						customStyle={styles.multicheckbox_container}
					/>

					<div className={styles.datePickerContainer}>
						<div className={styles.datePickerWrapper}>
							<div className={styles.datePickerItem}>
								<InputWrapper label="Min age" component={<TextInput value={minAge} placeholder="Enter min age" onChange={onMinAge} type="number" />} className={styles.no_margin} />
							</div>
							<div className={styles.datePickerItem}>
								<InputWrapper label="Max age" component={<TextInput value={maxAge} placeholder="Enter max age" onChange={onMaxAge} type="number" />} className={styles.no_margin} />
							</div>
						</div>
					</div>

					<InputWrapper
						label="Ethnicity"
						className={styles.multicheckbox_container}
						component={<CustomSelect fontSize="14px" height="35px" options={data?.map((item) => defaultFormatter(item))} onChange={onEthnicity} value={ethnicity} isClearable={true} />}
					/>

					{baseLanguagesCount > 0 && (
						<MultiCheckbox
							ref={languageRef}
							title={"Languages"}
							options={languagesList.map((item) => languageFormatter(item))}
							onSelect={onLanguage}
							selected={languages}
							perRow="2, 150px"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{((!isFetchedLanguages && baseLanguagesCount === 4) || (isFetchedLanguages && hasNextPageLanguages)) && (
						<SeeMore className={styles.margin_top} onClick={!isFetchedLanguages ? onRefetch : onFetchNextPage} />
					)}
				</div>
			</div>
		);
	}
);

export default CastingFilters;
