import { useEffect, useState, useMemo, useRef } from "react";
// import { useSelector } from "react-redux";
import { NavigationType, useBlocker, useNavigate } from "react-router-dom";
import { copyToClipboard, replaceItemInArray } from "../Utils/General";

import DefaultPage from "../Utils/PageArchs/DefaultPage/DefaultPage";
import FileViewer from "../Utils/FileViewer/FileViewer";
import PostItem from "./PostItem";
import useApi from "../../hooks/useApi";
import postApi from "../../api/post";

import styles from "./PostFullscreen.module.css";
import BackButton from "../Utils/Button/BackButton";
import useWindowSize from "../../hooks/useWindowSize";

// import EditPostModal from "./EditPostModal";
import WarningModal from "../Utils/GaawkModal/WarningModal";
import CreatePost from "./CreatePost";
import { toast } from "react-toastify";
import PostActions from "./PostActions";
import fullscreenIcon from "images/fullscreen-icon-white.svg";
// import useCompanyId from "../../hooks/useCompanyId";
import EditMediaModal from "./EditMediaModal";
import useEditSubPost from "../../hooks/useEditSubPost";
import FileSaver from "file-saver";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import ReportModal from "../Utils/ReportModal/ReportModal";
import useCurrentUser from "hooks/useCurrentUser";
import { router } from "AppRoutes";

const PostFullScreen = ({
	post: propPost,
	initialSlideIndex,
	postId,
	subPostId: subId,
	isModal,
	onClose,
	onUpdateUrl,
	onRefresh,
}) => {
	const navigate = useNavigate();
	const reportRef = useRef();
	const postRef = useRef();
	const fileViewerRef = useRef();

	const profile = useCurrentUser();

	const profileName =
		profile.type === "COMPANY"
			? profile.name
			: `${profile.firstName} ${profile.lastName}`;

	const [post, setPost] = useState(() => propPost);

	// const [initialSlide, setInitialSlide] = useState(initialSlideIndex);

	const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlideIndex);

	const { mediaList, pdfList, file } = post || {};

	const subPosts = useMemo(() => {
		if (mediaList && pdfList) {
			//* means its a normal post (not like a profile picture that doesn't have mediaList or pdfList)
			return [...mediaList, ...pdfList];
		} else {
			return [];
		}
	}, [pdfList, mediaList]);

	const { width } = useWindowSize();
	const filesArray = useMemo(
		() => subPosts?.map((subPost) => subPost.file),
		[subPosts]
	);

	// ! GET POST =================================

	//TODO >> to be replaced with react-query later on

	const getPostByIdApi = useApi(postApi.getPostById);

	useEffect(() => {
		if (!post) getPostByIdApi.request(postId);
	}, [post, postId]);

	useEffect(() => {
		if (
			!getPostByIdApi.loading &&
			getPostByIdApi.data &&
			getPostByIdApi.responseCode === 200
		) {
			const response = getPostByIdApi.data;

			setPost(response);

			const subPosts = [];

			if (response.totalMediaCount > 0) {
				subPosts.push(...response.mediaList);
			}

			if (response.totalPDFCount > 0) {
				subPosts.push(...response.pdfList);
			}

			if (subId) {
				setActiveSlideIndex(
					subPosts.findIndex((subPost) => subPost.uuid === subId)
				);
			}
		} else if (!getPostByIdApi.loading && getPostByIdApi.error) {
			throw new Response("", {
				statusText: getPostByIdApi.data.message,
				status: getPostByIdApi.data.code,
			});
		}
	}, [getPostByIdApi.data]);

	// ! ============= POST ACTIONS ================

	const deleteMediaApi = useApi(postApi.deleteMedia);

	const [showWarningModal, setShowWarningModal] = useState(false);

	// const [showEditModal, setShowEditModal] = useState(false);

	// const handleEdit = () => {
	//     if (
	//         ((subPosts?.length === 0 && file) || subPosts?.length > 0) &&
	//         post.postType !== "PROFILE_PIC" &&
	//         post.postType !== "COVER_PIC"
	//     ) {
	//         setShowEditMediaModal(true);
	//     } else {
	//         //CASE WHERE IT'S A TEXT POST ONLY
	//         setShowEditModal(true);
	//     }
	// };

	// const handleDeleteMedia = (_, subPostId, __) => {
	//     setPost((prevState) => ({
	//         ...prevState,
	//         subPosts: prevState.subPosts.filter(
	//             (sub) => sub.uuid !== subPostId
	//         ),
	//     }));
	// };

	// const handleDeleteAllMedia = () => {
	//     setPost((prevState) => ({
	//         ...prevState,
	//         subPosts: [],
	//         childPostsCount: 0,
	//     }));
	// };

	const handleDeletePost = () => {
		setShowWarningModal(true);
	};

	// const [subPostIdToDelete, setSubPostIdToDelete] = useState("");
	const [subPostToDelete, setSubPostToDelete] = useState(undefined);

	const handleConfirmDeletePost = () => {
		deleteMediaApi.request(post.uuid, subPosts[activeSlideIndex].uuid);
		// setSubPostIdToDelete(subPosts[activeSlideIndex].uuid);
		setSubPostToDelete(subPosts[activeSlideIndex]);
		setShowWarningModal(false);
	};

	// console.log(
	// 	"%c LE POST A SUPPRIMER >>",
	// 	"color: orange; font-weight: bolder",
	// 	post
	// );

	// console.log("SUB ACTIVE >>", subPosts[activeSlideIndex]);

	useEffect(() => {
		if (!deleteMediaApi.loading && deleteMediaApi.responseCode === 200) {
			if (subPosts.length === 1) {
				if (isModal) {
					onRefresh();
					onClose();
					onUpdateUrl();
				} else {
					navigate("/");
				}
			} else {
				if (subPosts.length === activeSlideIndex + 1) {
					//* Decrement activeSlideIndex by 1 when you're deleting the last subPost
					//* Otherwise it will throw an error
					setActiveSlideIndex((prevState) => prevState - 1);
				}

				onRefresh();

				const isPdf = subPostToDelete.file.type === "pdf";

				setPost((prevState) => ({
					...prevState,
					...(isPdf
						? {
								pdfList: prevState.pdfList.filter(
									(pdfItem) =>
										pdfItem.uuid !== subPostToDelete.uuid
								),
								totalPDFCount: prevState.totalPDFCount - 1,
						  }
						: {
								mediaList: prevState.mediaList.filter(
									(mediaItem) =>
										mediaItem.uuid !== subPostToDelete.uuid
								),
								totalMediaCount: prevState.totalMediaCount - 1,
						  }),
					// subPosts: prevState.subPosts.filter(
					// 	(sub) => sub.uuid !== subPostIdToDelete
					// ), //TODO >> no subposts >> need to know which array to remove the item from : mediaList of pdfList ?
					// childPostsCount: prevState.childPostsCount - 1, //TODO >> this has changed to totalPDFCount and totalMediaCount
				}));
			}
		} else if (deleteMediaApi.responseCode >= 400) {
			toast.error("Something wrong happened");
		}
	}, [deleteMediaApi.loading, deleteMediaApi.responseCode]);

	const handleShare = () => {
		if (post.sharedPost) {
			postRef.current.sharePost(post.sharedPost);
		} else {
			postRef.current.sharePost(post);
		}
	};

	//TODO >> la logique de l'url qui change en fonction de la slide devrait être géré par usePostViewer
	const postItem = useMemo(() => {
		if (post) {
			if (subPosts?.length === 0) {
				return (
					<PostItem
						sm={true}
						post={post}
						visibleByDefault={true}
						onPostUpdate={(reaction) => {
							setPost((prevState) => ({
								...prevState,
								reactionStats: reaction.reactionStats,
								profileReactionType:
									reaction.profileReactionType,
							}));
						}}
						onShare={handleShare}
						isModal={isModal}
					/>
				);
			} else if (subPosts?.length > 0 && activeSlideIndex > -1) {
				window.history.replaceState(
					null,
					"",
					`/post/${postId}/${subPosts[activeSlideIndex].uuid}`
				);

				return (
					<PostItem
						sm={true}
						post={subPosts[activeSlideIndex]}
						visibleByDefault={true}
						onPostUpdate={(reaction) => {
							const { postId } = reaction;

							// check in post.mediaList

							const updatedMediaItem = mediaList.find(
								(media) => media.uuid === postId
							);

							if (updatedMediaItem) {
								updatedMediaItem["reactionStats"] =
									reaction.reactionStats;

								updatedMediaItem["profileReactionType"] =
									reaction.profileReactionType;

								setPost((prevState) => ({
									...prevState,
									mediaList: replaceItemInArray(
										updatedMediaItem,
										prevState.mediaList,
										"uuid"
									),
								}));
							}

							// check in post.pdfLidf

							const updatedPdfItem = pdfList.find(
								(pdf) => pdf.uuid === postId
							);

							if (updatedPdfItem) {
								updatedPdfItem["reactionStats"] =
									reaction.reactionStats;

								updatedPdfItem["profileReactionType"] =
									reaction.profileReactionType;

								setPost((prevState) => ({
									...prevState,
									pdfList: replaceItemInArray(
										updatedPdfItem,
										prevState.pdfList,
										"uuid"
									),
								}));
							}

							// const subPostsCopy = { ...post }.subPosts; //TODO !!!!!!

							// const updatedSubPost = subPostsCopy.find(
							// 	(subPost) => subPost.uuid === reaction.postId
							// );

							// updatedSubPost["reactionStats"] =
							// 	reaction.reactionStats;

							// updatedSubPost["profileReactionType"] =
							// 	reaction.profileReactionType;

							// setPost((prevState) => ({
							// 	...prevState,
							// 	subPosts: replaceItemInArray(
							// 		updatedSubPost,
							// 		prevState.subPosts,
							// 		"uuid"
							// 	),
							// }));
						}}
						onShare={handleShare}
						activeSlideIndex={activeSlideIndex}
						isModal={isModal}
					/>
				);
			} else if (activeSlideIndex === -1) {
				throw new Response("", {
					statusText: "This post doesn't exist",
					status: 404,
				});
			}
		}
	}, [post, subPosts, activeSlideIndex]);

	useEffect(() => {
		let unlisten;
		if (isModal) {
			unlisten = router.subscribe((state) => {
				if (state.historyAction === NavigationType.Pop) {
					onClose();
				}
			});
		}

		return () => unlisten && unlisten();
	}, [isModal]);

	//Triggered when clicking on the home button (bottomnavbar) while a post is opened in a modal
	//if commented, won't close the modal when clicking on the home button, but only change the url
	useBlocker(
		({
			currentLocation: { pathname: currentPathname },
			nextLocation: { pathname: nextPathname },
		}) => {
			if (nextPathname !== window.location.pathname) {
				if (isModal) {
					onClose();
				}
			}
		}
	);

	//! ========== EDIT MEDIA MODAL =============

	const updateMediaApi = useApi(postApi.updateMedia, true);

	const [showEditMediaModal, setShowEditMediaModal] = useState(false);

	const editItem = useMemo(() => {
		if (post) {
			if (subPosts?.length === 0) {
				return post;
			} else {
				return subPosts[activeSlideIndex];
			}
		}
	}, [post, subPosts, activeSlideIndex]);

	const { handleEdit: handleConfirmEditMedia } = useEditSubPost(
		setPost,
		updateMediaApi,
		true
	);

	const handleBackBtn = () => {
		onClose();
		onUpdateUrl();
	};

	const handleCopyLink = () => {
		copyToClipboard(`${window.location.origin}/post/${post.uuid}`);
		toast.success("Link copied !");
	};

	const handleDownload = () => {
		const downloadable = filesArray[activeSlideIndex];
		FileSaver.saveAs(`${downloadable.url}`, `${downloadable.originalName}`);
	};

	return (
		<>
			{getPostByIdApi.loading ? (
				<LoadingPage />
			) : (
				<DefaultPage
					leftSideChildren={postItem}
					defaultHeader={false}
					disableScrollbar={true}
					showFooter={false}
					headerRightContent={
						//TODO REPLACE BELOW BY <ViewerHeader /> component ?

						<div className={styles.header_container}>
							<BackButton
								onBack={() =>
									isModal ? handleBackBtn() : navigate(-1)
								}
							/>

							<div className={styles.post_actions_wrapper}>
								<img
									src={fullscreenIcon}
									className={styles.fullscreen_icon}
									alt="Fullscreen"
									onClick={() =>
										fileViewerRef.current.enterFullscreen()
									}
								/>

								<PostActions
									whiteButton={true}
									isOwner={post?.owner?.uuid === profile.uuid}
									isPublic={post?.postVisibility === "PUBLIC"}
									// onEdit={handleEdit}
									onEdit={() => setShowEditMediaModal(true)}
									onDelete={handleDeletePost}
									onCopy={handleCopyLink}
									onShare={handleShare}
									onDownload={handleDownload}
									onReport={() =>
										reportRef.current.showReportModal(
											subId
												? subPosts[activeSlideIndex]
														?.uuid
												: postId
										)
									}
									postType={post?.postType}
									isFullScreen={true}
								/>
							</div>
						</div>
					}
					rightSideChildren={
						filesArray?.length > 0 || file ? (
							<div className={styles.fileviewer_container}>
								<FileViewer
									ref={fileViewerRef}
									inModal={false}
									files={
										subPosts.length > 0
											? filesArray
											: [file]
									}
									initialSlide={activeSlideIndex}
									onActiveSlide={(index) =>
										setActiveSlideIndex(index)
									}
									deletableItems={true}
								/>
								{/* //* Below DIV for mobile version */}
								<div className={styles.mobile_post_container}>
									{postItem}
								</div>
							</div>
						) : (
							<div className={styles.fileviewer_container}>
								<div className={styles.mobile_post_container}>
									{postItem}
								</div>
							</div>
						)
					}
					rightSideFullWidth={true}
					rightSideBgColor={width < 748 ? "#f2f3f5" : "#FFF"}
				/>
			)}

			<CreatePost
				ref={postRef}
				name={profileName}
				profileImg={profile.profileImage}
				type={profile.type}
				onCreate={() => toast.success("Post successfully shared!")}
			/>

			<EditMediaModal
				show={showEditMediaModal}
				onClose={() => setShowEditMediaModal(false)}
				// onReset={() => setEditItem("")}
				item={editItem}
				onConfirm={handleConfirmEditMedia}
				onToggle={(bool) => setShowEditMediaModal(bool)}
				// isEditing={true}
			/>

			<WarningModal
				show={showWarningModal}
				modalOnTop={false}
				headerText="Are you sure you want to delete your post?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleConfirmDeletePost}
			/>

			<ReportModal ref={reportRef} category="post" />
		</>
	);
};

export default PostFullScreen;
