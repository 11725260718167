import {
	getFileExtension,
	getFileIcon,
	getVideoFrame,
} from "components/Utils/General";
import { ReactComponent as CrossIcon } from "images/cross-icon.svg";
import { ReactComponent as PlayIcon } from "images/play-button.svg";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import LazyImage from "../Utils/LazyImage/LazyImage";
import styles from "./FileItem.module.css";

const acceptedImageFile = ["image/png", "image/jpeg", "image/jpg"]; //* added this to exclude HEIC previewing since you can upload these in the vault

const FileItem = ({ item, onRemove, isChat = false, p2p }) => {
	const isFile = item instanceof File;
	const name = isFile ? item.name : item.file.originalName;

	const isVideo = isFile
		? item.type.startsWith("video")
		: item.file.previewType === "VIDEO";

	const isImage = isFile
		? item.type.startsWith("image") && acceptedImageFile.includes(item.type)
		: item.file.previewType === "IMAGE";

	const [objectUrl, setObjectUrl] = useState(null);

	useEffect(() => {
		const loadFile = async () => {
			let url;
			if (item instanceof File) {
				if (
					item.type.startsWith("image") &&
					acceptedImageFile.includes(item.type)
				) {
					url = URL.createObjectURL(item);
				} else if (item.type.startsWith("video")) {
					url = await getVideoFrame(item);
				} else {
					url = getFileIcon(item, item.name).icon;
				}
			} else {
				if (item.file.mediaType === "VIDEO") {
					url = item.file.thumbnail;
				} else if (item.file.mediaType === "IMAGE") {
					url = item.file.url;
				} else {
					url = item.file.fileTypeDTO.url;
				}
			}

			setObjectUrl(url);
		};

		loadFile();

		// Cleanup function
		return () => {
			if (objectUrl) {
				URL.revokeObjectURL(objectUrl);
			}
		};
	}, [item]);

	return (
		<div
			className={`${styles.dropped_file_item} ${
				isChat ? styles.isChat : undefined
			}
            ${p2p ? styles.p2p : undefined}
            `}
		>
			{!isChat && (
				<button className={styles.close} onClick={onRemove}>
					<CrossIcon width={12} />
				</button>
			)}

			<div
				className={classNames(styles.icon, {
					[styles.p2p]: p2p,
					[styles.isChat]: isChat,
					[styles.full_size]: isImage,
				})}
			>
				{isVideo && <PlayIcon className={styles.play_icon} />}
				<LazyImage
					image={<img src={objectUrl} alt="" />}
					inlineStyle={`${
						!isImage && !isVideo
							? styles.fileIcon
							: styles.imgOrVideo
					}`}
				/>
			</div>

			{!isChat && (
				<>
					<p className={styles.name}>{name}</p>
					<p className={styles.type}>{`${getFileExtension(
						name
					).toUpperCase()} FILE`}</p>
				</>
			)}
		</div>
	);
};

export default FileItem;
