import { joiResolver } from "@hookform/resolvers/joi";
import { getLocationType } from "components/Utils/General";
import Joi from "joi";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import galleryApi from "../../../api/gallery";
import useApi from "../../../hooks/useApi";
import LocationModal from "../../Home/LocationModal";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import SecondaryButton from "../../Utils/Button/SecondaryButton";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import TagInput from "../../Utils/SubComs/Inputs/TagInput/TagInput";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import VisibilitySelector from "../../Utils/Visibility/VisibilitySelector";
import styles from "./CreateAlbum.module.css";
import routes from "components/Routing/routing-keys";

const EditAlbum = ({ album: albumProp }) => {
	const { albumId } = useParams();
	const navigate = useNavigate();

	const getAlbumIdApi = useApi(galleryApi.getAlbumById);
	const editAlbumApi = useApi(galleryApi.editAlbum);
	const [album, setAlbum] = useState(albumProp);

	const {
		title,
		description,
		location,
		date,
		visibility,
		tagged,
		contributes,
		uuid,
		gaawkAlbum,
		post: { uuid: postId } = {},
	} = album || {};

	useEffect(() => {
		if (!albumProp) {
			getAlbumIdApi.request(albumId);
		}
	}, []);

	useEffect(() => {
		if (
			!getAlbumIdApi.loading &&
			getAlbumIdApi.data &&
			getAlbumIdApi.responseCode === 200
		) {
			setAlbum(getAlbumIdApi.data);
		}
	}, [getAlbumIdApi.data]);

	const schema = Joi.object({
		albumTitle: Joi.string().required().label("Album Title").messages({
			"string.empty": "Album title should not be empty",
		}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	// !========== title change ==============

	const handleTitleChange = ({ target }) => {
		setAlbum((prevState) => ({
			...prevState,
			title: target.value,
		}));
	};

	// !========== description change ==============

	const handleDescriptionChange = ({ target }) => {
		setAlbum((prevState) => ({
			...prevState,
			description: target.value,
		}));
	};
	// !========== visibility change ==============

	const handleVisibilityChange = (value) => () => {
		setAlbum((prevState) => ({
			...prevState,
			visibility: value,
		}));
	};

	// !========== Location change ===============
	const [showLocationModal, setShowLocationModal] = useState(false);

	const handleLocationClicked = (place) => {
		setAlbum((prevState) => ({
			...prevState,
			location: {
				latitude: place.geometry.location.lat,
				longitude: place.geometry.location.lng,
				title: place.name,
				placeId: place.place_id,
				type: getLocationType(place.types),
			},
		}));

		setShowLocationModal(false);
	};

	const handleRemoveLocation = () => {
		const albumCopy = { ...album };
		delete albumCopy.location;
		setAlbum(albumCopy);
	};

	// !========== Date change ===============

	const handleDate = (date) => {
		setAlbum((prevState) => ({
			...prevState,
			date: date !== null ? date.getTime() : null,
		}));
	};

	// !======== Delete album ==========
	const deleteAlbumApi = useApi(galleryApi.deleteAlbum);

	const handleDeleteAlbum = () => {
		deleteAlbumApi.request(uuid);
		// todo: add a warning modal before deleting the album straight away
	};

	useEffect(() => {
		if (!deleteAlbumApi.loading && deleteAlbumApi.responseCode === 200) {
			navigate("/profile");
		}
	}, [deleteAlbumApi.loading, deleteAlbumApi.responseCode]);

	// !======== tagged people change ==========

	const contributorRef = useRef(null);
	const taggedRef = useRef(null);

	// !========== confirm edit ===============

	const handleEditAlbum = (data) => {
		const contributors = contributorRef.current.getIds();
		const tagged = taggedRef.current.getIds();

		const body = {
			albumId: uuid,
			postId,
			title,
			visibility,
			...(description && { description }),
			...(location && {
				location: {
					latitude: location.latitude,
					longitude: location.longitude,
					title: location.title,
					type: location.type,
				},
			}),
			...(date && { date }),
			...(contributors.length > 0 && { contributors }),
			...(tagged.length > 0 && { tagged }),
		};

		editAlbumApi.request(body);
	};

	useEffect(() => {
		if (
			!editAlbumApi.loading &&
			editAlbumApi.data &&
			editAlbumApi.responseCode === 200
		) {
			navigate(routes.album(uuid), { replace: true });
		}
	}, [editAlbumApi.data]);

	return (
		<div className={styles.form_container}>
			<form onSubmit={handleSubmit((data) => handleEditAlbum(data))}>
				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>
						Album Title<span className="required">*</span>
					</label>

					<TextInput
						{...register("albumTitle")}
						placeholder="Enter Album Title"
						error={!!errors.albumTitle}
						value={title}
						onChange={handleTitleChange}
					/>
					{errors?.albumTitle?.message && (
						<p className={styles.error_message}>
							{errors?.albumTitle?.message}
						</p>
					)}
				</div>

				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>Description</label>
					<textarea
						onChange={handleDescriptionChange}
						value={description}
					/>
				</div>

				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>Location</label>

					<TextInput
						readOnly={true}
						value={location?.title ?? ""}
						placeholder={"Select location"}
						onClick={() => setShowLocationModal(true)}
					/>

					<LocationModal
						show={showLocationModal}
						onClose={() => setShowLocationModal(false)}
						onLocationClicked={handleLocationClicked}
						activeLocation={location}
						onRemove={handleRemoveLocation}
					/>
				</div>

				<div className={styles.datepicker}>
					<label className={styles.input_label}>Date</label>
					<DayPicker
						isClearable={true}
						onChange={handleDate}
						value={date ? new Date(date) : null}
					/>
				</div>

				<div
					className={`${styles.form_input_wrapper} ${styles.visibility_wrapper}`}
				>
					<label className={styles.input_label}>Visibility</label>
					<VisibilitySelector
						value={visibility}
						onValueChange={handleVisibilityChange}
					/>
				</div>

				<TagInput
					ref={contributorRef}
					profiles={contributes}
					title={"Contributors"}
					placeholder={"Add contributor"}
				/>
				<TagInput
					ref={taggedRef}
					profiles={tagged}
					title={"Tagged In"}
					placeholder={"Tag people / companies"}
				/>

				<div className={styles.create_button_wrapper}>
					{!gaawkAlbum && (
						<SecondaryButton
							onClick={handleDeleteAlbum}
							className={`${styles.button} ${styles.delete}`}
							text={"delete album"}
						/>
					)}
					<PrimaryButton
						disabled={editAlbumApi.loading}
						text={"save"}
						className={`${styles.button} ${styles.save}`}
					/>
				</div>
			</form>
		</div>
	);
};

export default EditAlbum;
