import ServiceItemAlt from "components/Profile/ServicesTab/ServicesComponent/ServiceItemAlt";
import { pluralize } from "components/Utils/General";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import filterIcon from "images/filter-icon-white.svg";
import { useMemo } from "react";
import Masonry from "react-masonry-css";
import styles from "./Services.module.scss";

const Services = ({
	count,
	viewRef,
	data,
	isPaginated = false,
	isFetchingNextPage,
	// hasNextPage,
	// isFetching,
	// onLoad,
	isLoading,
	isMobile,
	onShowModal,
	tagsList,
	onClick,
}) => {
	const formattedServices = useMemo(
		() =>
			data?.map((service, index) => {
				const isLastItem = index === data.length - 1;

				return (
					<div key={service.uuid} ref={isLastItem && isPaginated ? viewRef : null}>
						<GaawkLink
							disableDecoration={true}
							className={styles.service_item}
							to={`/services/${service.uuid}`}
						>
							<ServiceItemAlt item={service} />
						</GaawkLink>
					</div>
				);
			}),
		[data, isPaginated, viewRef]
	);

	if (isLoading) return <LoadingSpinner />;

	if (!isLoading && count === 0)
		return <NoResults visible={true} isSearch={true} text={"No results"} />;

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Service")} found`}</h4>
				{isMobile && isPaginated && (
					<button className={styles.filter_button} onClick={onShowModal}>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && <div className={styles.active_filters}>{tagsList}</div>}

			<Masonry
				breakpointCols={{
					default: 2,
					1399: 1,
				}}
				className={styles.services_container}
				columnClassName={styles.services_container_grid_columns}
			>
				{formattedServices}
			</Masonry>

			{isFetchingNextPage && <LoadingSpinner />}

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text={"view more"} />
				</div>
			)}
		</>
	);
};

export default Services;
