import { router } from "AppRoutes";
import FileSaver from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useVaultThumbCount from "hooks/useVaultThumbCount";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavigationType, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import vaultApi from "../../api/vault";
import styles from "../../components/Vault/VaultFolder.module.css";
import useApi from "../../hooks/useApi";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import FullScreenModal from "../Utils/GaawkModal/FullScreenModal";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import {
	removeItemIdFromArray,
	removeItemsFromArray,
	replaceItemInArray,
	viewerMapper,
} from "../Utils/General";
import VaultLoader from "../Utils/SubComs/CustomLoader/VaultLoader";
import RectangleCheckbox from "../Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import SelectCommands from "../Utils/SubComs/SelectCommands/SelectCommands";
import SortingButtons from "../Utils/SubComs/SortingButtons/SortingButtons";
import VaultActions from "./VaultActions";
import VaultDeleteModal from "./VaultDeleteModal";
import VaultDetailsModal from "./VaultDetailsModal";
import VaultFolderHeader from "./VaultFolderHeader";
import VaultItems from "./VaultItems";
import VaultListItemsModal from "./VaultListItemsModal";
import VaultRenameModal from "./VaultRenameModal";
import VaultShareModal from "./VaultShareModal";
import VaultUserButtons from "./VaultUserButtons";

// const perPageCount = 16;

// const MyListLoader = () => <List />;

const VaultFolderRightSide = () => {
	/**
	 * Selectors
	 */

	const navigate = useNavigate();

	const { uuid: profileId } = useCurrentUser();

	// Refs

	const fileviewerRef = useRef();

	/**
	 * Hooks
	 */
	const { id: itemId } = useParams();

	// size
	// const { width } = useWindowSize();

	// APIs
	const getItemsByParentApi = useApi(vaultApi.getItemsByParentId, true);
	const removeItemsApi = useApi(vaultApi.removeItems);
	const deleteItemsApi = useApi(vaultApi.deleteItems);
	const updateFavoriteApi = useApi(vaultApi.updateFavorite);
	const createFolderApi = useApi(vaultApi.createFolder);
	const copyItemsApi = useApi(vaultApi.copyItems);
	const moveItemsApi = useApi(vaultApi.moveItems);
	const renameApi = useApi(vaultApi.renameItem);

	/**
	 * States
	 */

	// const [folder, setFolder] = useState(null);

	// const [childrenItems, setChildrenItems] = useState([]);
	const [content, setContent] = useState(null);

	useEffect(() => {
		if (content === null) {
			loadItems(0);
		}

		if (content) {
			setHideLoadMore(
				content.parentItem.subCount === content.items.length
			);
		}
	}, [content]);

	// console.log(
	//     "%c THE CONTENT >>",
	//     "color: lime; font-weight: bolder;",
	//     content
	// );

	const [page, setPage] = useState(0);
	// const [itemInfo, setItemInfo] = useState(null);
	const [selectedAll, setSelectedAll] = useState(false);
	const [selectedItem, setSelectedItem] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);
	const [hideLoadMore, setHideLoadMore] = useState(false);

	// const [width, setWidth] = useState(0);

	const [order, setOrder] = useState(
		localStorage.getItem("folderSort") || "NEWEST"
	);
	const [layout, setLayout] = useState(
		localStorage.getItem("folderLayout") || "grid"
	);

	const isList = layout === "list";

	const displayCount = useVaultThumbCount();

	// const [displayCount] = useState(() =>
	// 	(width >= 640 && width < 748) || (width >= 974 && width < 1166)
	// 		? 12
	// 		: width < 640 || (width >= 748 && width < 974)
	// 		? 6
	// 		: 16
	// );

	// modals
	const [renameModal, setRenameModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);
	const [shareModal, setShareModal] = useState(false);
	const [copyModal, setCopyModal] = useState(false);
	const [moveModal, setMoveModal] = useState(false);
	const [targetFolderId, setTargetFolderId] = useState("");

	/**
	 * Effects
	 */
	const reloadItems = async () => {
		const response = await getItemsByParentApi.request(
			itemId,
			page - 1,
			displayCount,
			order
		);

		if (response.status === 200) {
			setContent((prevState) => ({
				parentItem: response.data.parentItem,
				items: [
					...prevState.items,
					...removeItemIdFromArray(
						prevState.items,
						response.data.items
					),
				],
			}));
		}
	};

	const loadItems = async (pageNumber = page) => {
		const response = await getItemsByParentApi.request(
			itemId,
			pageNumber,
			displayCount,
			order
		);

		if (response.status === 200) {
			if (
				response.data.parentItem.sharedItem ||
				response.data.parentItem.owner.uuid === profileId
			) {
				if (content?.items.length > 0) {
					setContent((prevState) => ({
						parentItem: response.data.parentItem,
						items: [...prevState.items, ...response.data.items],
					}));
				} else {
					setContent(response.data);
				}

				setPage(pageNumber + 1);
			} else {
				navigate("/vault");
			}
		}
	};

	// get the item info
	useEffect(() => {
		if (itemId) {
			setContent(null);
		}
	}, [itemId]);

	// created folder respose
	useEffect(() => {
		if (
			!createFolderApi.loading &&
			createFolderApi.data &&
			createFolderApi.responseCode === 201
		) {
			toast.success("folder created!");

			setContent(null);
		}
	}, [createFolderApi.data]);

	// favorite api
	useEffect(() => {
		if (
			!updateFavoriteApi.loading &&
			updateFavoriteApi.data &&
			Object.keys(updateFavoriteApi.data).length > 0
		) {
			// update content
			setContent((prev) => ({
				...prev,
				items: replaceItemInArray(
					updateFavoriteApi.data,
					content.items,
					"uuid"
				),
			}));

			setSelectedItems([]);
		}
	}, [updateFavoriteApi.data]);

	// renmane Item
	useEffect(() => {
		if (
			!renameApi.loading &&
			renameApi.data &&
			renameApi.responseCode === 200
		) {
			// update content
			setContent(null);
		}
	}, [renameApi.data]);

	// delete item
	useEffect(() => {
		if (
			(!deleteItemsApi.loading &&
				!deleteItemsApi.error &&
				deleteItemsApi.responseCode === 200) ||
			(!removeItemsApi.loading &&
				!removeItemsApi.error &&
				removeItemsApi.responseCode === 200)
		) {
			toast.success("successflly deleted!");

			// update content

			setContent((prevState) => ({
				parentItem: {
					...prevState.parentItem,
					subCount:
						prevState.parentItem.subCount -
						(selectedItem ? 1 : selectedItems.length),
				},
				items: removeItemsFromArray(
					selectedItem ? [selectedItem] : selectedItems,
					content.items
				),
			}));

			if (!hideLoadMore) reloadItems();

			setSelectedItem("");
			setSelectedItems([]);
		}
	}, [
		deleteItemsApi.loading,
		deleteItemsApi.error,
		deleteItemsApi.responseCode,
		removeItemsApi.loading,
		removeItemsApi.error,
		removeItemsApi.responseCode,
	]);

	// copy items
	useEffect(() => {
		if (
			!copyItemsApi.loading &&
			!copyItemsApi.error &&
			copyItemsApi.responseCode === 201
		) {
			toast.success("successflly copied!");

			if (targetFolderId === itemId) {
				setContent(null);
			} else {
				// if copying in folder increase 'subCount' label of the target folder (if it's a folder in the same directory)
				const updatedFolder = content.items.find(
					(folder) => folder.uuid === targetFolderId
				);
				if (updatedFolder) {
					updatedFolder["subCount"] += copyItemsApi.data.length;

					setContent((prevState) => ({
						...prevState,
						items: replaceItemInArray(
							updatedFolder,
							prevState.items,
							"uuid"
						),
					}));
				}
			}

			setTargetFolderId("");
			setSelectedItem("");
			setSelectedItems([]);
		}
	}, [copyItemsApi.data]);

	// move items
	useEffect(() => {
		if (!moveItemsApi.loading && moveItemsApi.responseCode === 200) {
			toast.success("successflly moved!");

			setContent(null);

			setTargetFolderId("");
			setSelectedItem("");
			setSelectedItems([]);
		}
	}, [moveItemsApi.data]);

	/**
	 * Functions
	 */

	const handleSelectAll = () => {
		if (!selectedAll) {
			setSelectedItems(content.items);
		} else {
			setSelectedItems([]);
		}
		setSelectedAll((prevState) => !prevState);
	};

	const handleMultiDelete = () => {
		handleDelete(selectedItems);
	};

	const [isMultiShare, setIsMultiShare] = useState(false);

	const handleMultiShare = () => {
		setIsMultiShare(true);
		handleShare(selectedItems);
	};

	const handleMultiMove = () => {
		handleMove(selectedItems);
	};

	const handleMultiCopy = () => {
		handleCopy(selectedItems);
	};

	const handleClearSelection = () => {
		setSelectedItems([]);
	};

	const onChangeOrder = (sort) => {
		localStorage.setItem("folderSort", sort);
		setOrder(sort);

		setContent(null);
	};

	const onChangeLayout = (layout) => {
		localStorage.setItem("folderLayout", layout);
		setLayout(layout);
	};

	const handleSelect = (item) => {
		if (selectedItems.includes(item)) {
			const filteredArray = [...selectedItems].filter(
				(x) => x.uuid !== item.uuid
			);
			setSelectedItems(filteredArray);
		} else {
			setSelectedItems((prevState) => [...prevState, item]);
		}
	};

	const handleFavoriteItem = (item) => {
		if (showSlider) {
			const activeFile = getActiveFile();
			updateFavoriteApi.request(activeFile.uuid, !activeFile.favorite);
		} else {
			updateFavoriteApi.request(item.uuid, !item.favorite);
		}
	};

	const handleRename = (item) => {
		setSelectedItem(item);
		setRenameModal(true);
	};

	const doRenameItem = (item, newTitle) => {
		renameApi.request(item.uuid, newTitle);
	};

	const handleDetails = (item) => {
		setSelectedItem(item);
		setDetailsModal(true);

		// getItemInfoApi.request(item.uuid);
	};

	const handleShare = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);

		setShareModal(true);
	};

	// const doShareItem = (profiles) => {
	// 	shareItemsApi.request(
	// 		selectedItem ? [selectedItem] : selectedItems,
	// 		profiles
	// 	);
	// };

	const handleDelete = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);

		setDeleteModal(true);
	};

	const doDeleteItems = () => {
		let deleteItems = [];
		let removeItems = [];

		if (selectedItem) {
			if (selectedItem.owner.uuid === profileId) {
				deleteItems = [selectedItem];
			} else {
				removeItems = [selectedItem];
			}
		} else {
			selectedItems.forEach((item) => {
				const mine = item.owner.uuid === profileId;

				if (mine) deleteItems = [...deleteItems, item];
				else removeItems = [...removeItems, item];
			});
		}

		if (deleteItems.length > 0) deleteItemsApi.request(deleteItems);

		if (removeItems.length > 0) removeItemsApi.request(removeItems);
	};

	const handleCopy = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);

		setCopyModal(true);
	};

	const doCopyItems = (toFolder) => {
		let toFolderId = "";
		if (toFolder) {
			toFolderId = toFolder.uuid;
			setTargetFolderId(toFolderId);
		}

		copyItemsApi.request(
			selectedItem ? [selectedItem] : selectedItems,
			toFolderId
		);
	};

	const handleMove = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);

		setMoveModal(true);
	};

	const doMoveItems = (toFolder) => {
		let toFolderId = "";
		if (toFolder) {
			toFolderId = toFolder.uuid;
			setTargetFolderId(toFolderId);
		}

		moveItemsApi.request(
			selectedItem ? [selectedItem] : selectedItems,
			toFolderId
		);
	};

	const handleCreateFolder = (folderName) => {
		if (content && content.parentItem) {
			createFolderApi.request(
				folderName,
				false,
				content.parentItem.uuid,
				(progress) => console.log(progress)
			);
		}
	};

	// !======== CREATE FOLDER HANDLER ========

	const [newFolderModal, setNewFolderModal] = useState(false);
	const [folderName, setFolderName] = useState("");

	const createFolder = () => {
		if (folderName) {
			setNewFolderModal(false);
			handleCreateFolder(folderName);
		}
	};

	// !======== SLIDER HANDLER ========

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const filesArray = useMemo(
		() =>
			content && viewerMapper(content?.items.filter((item) => item.file)),
		[content]
	);

	const handleShowSlider = (item) => {
		const fileIndex = filesArray.findIndex(
			(file) => file.uuid === item.file.uuid
		);
		setInitialSlideIndex(fileIndex);
		setShowSlider(true);
		window.history.pushState(null, "", `/vault/item/${item.uuid}`);
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		window.history.replaceState(null, "", `/vault/${itemId}`);
	};

	useEffect(() => {
		let unlisten;
		if (showSlider) {
			unlisten = router.subscribe((state) => {
				if (state.historyAction === NavigationType.Pop) {
					handleCloseSlider();
				}
			});
		}

		return () => unlisten && unlisten();
	}, [showSlider]);

	useEffect(() => {
		if (showSlider) {
			window.history.replaceState(
				null,
				"",
				`/vault/item/${getActiveFile().uuid}`
			);
		}
	}, [activeSlideIndex]);

	const getActiveFile = () => {
		const fileArray = content?.items?.filter((item) => item.file);
		return fileArray[activeSlideIndex];
	};

	const handleSliderDownload = () => {
		FileSaver.saveAs(
			filesArray[activeSlideIndex].url,
			filesArray[activeSlideIndex].originalName
		);
	};

	// const loadingItems = [...Array(displayCount / 2)].map((_, index) => (
	// 	// <VaultLoader key={index} />
	// 	<VaultLoader key={index} isList={isList} />
	// ));

	/**
	 * JSX
	 */
	return (
		<>
			<VaultFolderHeader folder={content ? content.parentItem : null} />

			<div className={styles.vault_root_container}>
				<div
					className={`${styles.folder_controls} ${
						content && content.items.length > 0
							? styles.select_all
							: undefined
					}`}
				>
					<div style={{ display: "flex", gap: "20px" }}>
						<VaultUserButtons
							onCreate={() => setNewFolderModal(true)}
						/>
						{content && content.items.length > 0 && (
							<div
								className={styles.checkbox_container}
								onClick={(e) => e.stopPropagation()}
							>
								<RectangleCheckbox
									checked={
										content &&
										content.items.length ===
											selectedItems.length
									}
									onChange={handleSelectAll}
								/>
								<label>Select all</label>
							</div>
						)}
					</div>
					{selectedItems.length > 0 ? (
						<SelectCommands
							items={selectedItems}
							onDelete={handleMultiDelete}
							onShare={handleMultiShare}
							onMove={handleMultiMove}
							onCopy={handleMultiCopy}
							onClear={handleClearSelection}
						/>
					) : (
						<SortingButtons
							order={order}
							stateLayout={layout}
							onChangeOrder={onChangeOrder}
							onChangeLayout={onChangeLayout}
						/>
					)}
				</div>

				<VaultItems
					items={content ? content.items : []}
					perPageCount={displayCount}
					layout={layout}
					isActive={selectedItems.length > 0}
					onSelect={handleSelect}
					selectedItems={selectedItems}
					onDelete={handleDelete}
					onDetails={handleDetails}
					onShare={handleShare}
					onMove={handleMove}
					onRename={handleRename}
					onCopy={handleCopy}
					onFavoriteItem={handleFavoriteItem}
					onLoadMoreItems={() => loadItems()}
					hideShowMoreButton={hideLoadMore}
					onClick={handleShowSlider}
					isLoading={getItemsByParentApi.loading}
				/>
			</div>

			{/* {getItemsByParentApi.loading &&
				(!content || content?.items.length === 0) && (
					<div style={{ padding: "20px" }}>
						<FileContainer isList={isList}>
							{loadingItems}
						</FileContainer>
					</div>
				)} */}

			<GaawkModal
				show={newFolderModal}
				handleClose={() => setNewFolderModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Create new folder"}
				closeAlign={"right"}
				children={
					<div className={styles.create_folder_container}>
						<label>
							Folder name
							<span className="required">*</span>
						</label>

						<div className={styles.text}>
							<TextInput
								value={folderName}
								onChange={(e) => setFolderName(e.target.value)}
							/>
						</div>

						<div className={styles.buttons}>
							<SecondaryButton
								text={"CANCEL"}
								className={styles.cancel_button}
								onClick={() => setNewFolderModal(false)}
							/>
							<PrimaryButton
								text={"CREATE"}
								disabled={folderName === ""}
								className={styles.create_button}
								onClick={createFolder}
							/>
						</div>
					</div>
				}
			/>

			<VaultDeleteModal
				visible={deleteModal}
				onConfirm={doDeleteItems}
				onClose={() => setDeleteModal(false)}
				// onReset={() => {
				//     setSelectedItem("");
				//     setSelectedItems([]);
				// }}
			/>

			<VaultDetailsModal
				visible={detailsModal}
				item={selectedItem}
				// itemInfo={itemInfo}
				// loading={getItemInfoApi.loading}
				onClose={() => {
					setSelectedItem("");
					setDetailsModal(false);
				}}
			/>

			<VaultShareModal
				show={shareModal}
				onClose={() => {
					setShareModal(false);
					setIsMultiShare(false);
				}}
				items={isMultiShare ? selectedItems : selectedItem}
				onReset={() => {
					setSelectedItem("");
					setSelectedItems([]);
					setIsMultiShare(false);
				}}
			/>

			{copyModal && (
				<VaultListItemsModal
					visible={copyModal}
					onConfirm={doCopyItems}
					onClose={() => setCopyModal(false)}
					// onReset={() => {
					//     setSelectedItem("");
					//     setSelectedItems([]);
					// }}
					title={
						selectedItems.length > 1
							? "Copy Items to"
							: "Copy item to"
					}
					buttonTitle={"COPY HERE"}
				/>
			)}

			{moveModal && (
				<VaultListItemsModal
					visible={moveModal}
					onConfirm={doMoveItems}
					onClose={() => setMoveModal(false)}
					// onReset={() => {
					//     setSelectedItem("");
					//     setSelectedItems([]);
					// }}
					title={"Move Item/s to"}
					isMove={true}
					selectedItems={
						selectedItem ? [selectedItem] : selectedItems
					}
					buttonTitle={"MOVE HERE"}
				/>
			)}

			<VaultRenameModal
				visible={renameModal}
				item={selectedItem}
				onConfirm={doRenameItem}
				onClose={() => {
					setSelectedItem("");
					setRenameModal(false);
				}}
			/>

			<FullScreenModal
				show={showSlider}
				onClose={handleCloseSlider}
				header={
					<ViewerHeader
						onClose={handleCloseSlider}
						leftSide={
							<div className={styles.parentFolderName}>
								{content?.parentItem?.name}
							</div>
						}
						rightSide={
							<VaultActions
								isFavorite={
									filesArray &&
									filesArray[activeSlideIndex]?.favorite
								}
								onFavorite={handleFavoriteItem}
								onDownload={handleSliderDownload}
								onShare={() => handleShare(getActiveFile())}
								onFullscreen={() =>
									fileviewerRef.current.enterFullscreen()
								}
							/>
						}
					/>
				}
				children={
					<div className={styles.fileviewer_container}>
						<FileViewer
							ref={fileviewerRef}
							files={filesArray}
							initialSlide={initialSlideIndex}
							onLoadMore={() => {
								!hideLoadMore && loadItems();
							}}
							isLoading={getItemsByParentApi.loading}
							onActiveSlide={(index) =>
								setActiveSlideIndex(index)
							}
							text={content?.items && getActiveFile()?.name}
							// fsActions={
							//     <VaultFullscreenActions
							//         favorite={filesArray && filesArray[activeSlideIndex]?.favorite}
							//         onFavorite={handleFavoriteItem}
							//         onDownload={handleSliderDownload}
							//         onShare={() => handleShare(getActiveFile())}
							//     />
							// }
						/>
					</div>
				}
			/>
		</>
	);
};

export default VaultFolderRightSide;
