import { useState, useRef, useEffect } from "react";
import styles from "./CreateAlbum.module.css";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import VisibilitySelector from "../../Utils/Visibility/VisibilitySelector";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import LocationModal from "../../Home/LocationModal";
import TagInput from "../../Utils/SubComs/Inputs/TagInput/TagInput";
import useApi from "../../../hooks/useApi";
import galleryApi from "../../../api/gallery";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import { getLocationType } from "components/Utils/General";
import routes from "components/Routing/routing-keys";

const CreateAlbum = () => {
	const schema = Joi.object({
		albumTitle: Joi.string().required().label("Album Title").messages({
			"string.empty": "Album title should not be empty",
		}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	const contributorRef = useRef(null);
	const taggedRef = useRef(null);
	const navigate = useNavigate();

	const [description, setDescription] = useState("");

	// !==================== LOCATION =====================

	const [selectedLocation, setSelectedLocation] = useState(null);
	console.log("🚀 ~ selectedLocation >>", selectedLocation);
	const [showLocationModal, setShowLocationModal] = useState(false);

	const handleLocationClicked = (place) => {
		setSelectedLocation({
			latitude: place.geometry.location.lat,
			longitude: place.geometry.location.lng,
			title: place.name,
			placeId: place.place_id,
			type: getLocationType(place.types),
		});

		setShowLocationModal(false);
	};

	// !=============== VISIBILITY SELECTOR ====================

	const [visibility, setVisibility] = useState("WORK_CIRCLE_FRIENDS");

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};
	// !=============== DATE HANDLER ====================

	const [date, setDate] = useState(null);

	const handleDate = (date) => {
		setDate(date);
	};

	// !=======================================================

	const addAlbumApi = useApi(galleryApi.addAlbum);

	const handleCreateAlbum = (data) => {
		const contributors = contributorRef.current.getIds();
		const tagged = taggedRef.current.getIds();

		const body = {
			title: data.albumTitle,
			visibility,
			...(description && { description }),
			...(selectedLocation && {
				location: selectedLocation,
			}),
			...(date && { date: date.getTime() }),
			...(contributors.length > 0 && { contributors }),
			...(tagged.length > 0 && { tagged }),
		};

		addAlbumApi.request(body);
	};

	useEffect(() => {
		if (
			!addAlbumApi.loading &&
			addAlbumApi.data &&
			addAlbumApi.responseCode === 201
		) {
			navigate(routes.album(addAlbumApi.data.uuid), {
				replace: true,
			});
		}
	}, [addAlbumApi.data]);

	return (
		<div className={styles.form_container}>
			<form onSubmit={handleSubmit((data) => handleCreateAlbum(data))}>
				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>
						Album Title<span className="required">*</span>
					</label>
					<TextInput
						{...register("albumTitle")}
						placeholder="Enter Album Title"
						error={!!errors.albumTitle}
					/>
					{errors?.albumTitle?.message && (
						<p className={styles.error_message}>
							{errors?.albumTitle?.message}
						</p>
					)}
				</div>

				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>Description</label>
					<textarea
						onChange={(e) => setDescription(e.target.value)}
						value={description}
					/>
				</div>

				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>Location</label>

					<TextInput
						readOnly={true}
						value={selectedLocation?.title ?? ""}
						placeholder={"Select location"}
						onClick={() => setShowLocationModal(true)}
					/>

					<LocationModal
						show={showLocationModal}
						onClose={() => setShowLocationModal(false)}
						onLocationClicked={handleLocationClicked}
						activeLocation={selectedLocation}
						onRemove={() => setSelectedLocation(null)}
					/>
				</div>

				<div className={styles.datepicker}>
					<label className={styles.input_label}>Date</label>

					<DayPicker
						isClearable={true}
						onChange={handleDate}
						value={date}
					/>
				</div>

				<div
					className={`${styles.form_input_wrapper} ${styles.visibility_wrapper}`}
				>
					<label className={styles.input_label}>Visibility</label>
					<VisibilitySelector
						value={visibility}
						onValueChange={handleVisibilityChange}
					/>
				</div>

				<TagInput
					ref={contributorRef}
					title={"Contributors"}
					placeholder={"Add contributor"}
				/>
				<TagInput
					ref={taggedRef}
					title={"Tagged In"}
					placeholder={"Tag people / companies"}
				/>

				<div className={styles.create_button_wrapper}>
					<PrimaryButton
						type="submit"
						disabled={addAlbumApi.loading}
						text={"create album"}
					/>
				</div>
			</form>
		</div>
	);
};

export default CreateAlbum;
