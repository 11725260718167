import client from "./client";

const endpoint = "/job";

const search = (page, size = 10, body) => client.post(endpoint + `/search?page=${page}&size=${size}`, body);

const jobsFilters = (page, size = 4, searchRequest) => client.post(endpoint + `/search/filters?page=${page}&size=${size}`, searchRequest);

const jobsDynamicFilters = (type, page, size, body) => client.post(endpoint + `/search/filters/${type}?page=${page}&size=${size}`, body);

const getSavedJobs = (page, size = 10, jobType = "") => client.get(endpoint + `/saved?page=${page}&size=${size}&jobType=${jobType}`);

const getSavedCount = () => client.get(endpoint + "/saved/counts");

const getAppliedJobs = (page, size = 10, jobType = "") => client.get(endpoint + `/apply?page=${page}&size=${size}&jobType=${jobType}`);

const getAppliedCount = () => client.get(endpoint + "/apply/counts");

const toggleSaveJob = (jobId, state) => client.put(endpoint + `/saved/${jobId}?state=${state}`);

const getRecommendedJobs = (page, size = 10, lat = 0, long = 0) => client.get(endpoint + `/recommended?page=${page}&size=${size}&latitude=${lat}&longitude=${long}`);

const getNearbyJobs = (page, size = 10, lat = 0, long = 0) => client.get(endpoint + `/nearby?page=${page}&size=${size}&latitude=${lat}&longitude=${long}`);

const getJobById = (jobId) => client.get(endpoint + `/${jobId}`);

const getJobByCompanyId = (companyId, page, size, jobType = "", closed = false, excludeId = "", projectId = "") =>
	client.get(endpoint + `?companyId=${companyId}&page=${page}&size=${size}&jobType=${jobType}&closed=${closed}&excludeJobId=${excludeId}&projectId=${projectId}`);

const getSimilarJobs = (jobId, page, size) => client.get(endpoint + `/similar?jobId=${jobId}&page=${page}&size=${size}`);

const apply = (jobId, body) => client.post(endpoint + `/apply/${jobId}`, body);

const getJobsLanding = () => client.get(endpoint + "/landing");
const getProjectLanding = (projectId, requestedProfileId) => client.get(endpoint + `/landing/${projectId}?requestedProfileId=${requestedProfileId}`);

const createJob = (body) => client.post(endpoint + "/longTerm", body);
const updateJob = (body, jobId) => client.put(endpoint + `/longTerm/${jobId}`, body);

const createFreelance = (body) => client.post(endpoint + "/freelance", body);
const updateFreelance = (body, jobId) => client.put(endpoint + `/freelance/${jobId}`, body);

const createCasting = (body) => client.post(endpoint + "/castingCall", body);
const updateCasting = (body, jobId) => client.put(endpoint + `/castingCall/${jobId}`, body);

const deleteJob = (jobId) => client.delete(endpoint + `/${jobId}`);
const updateJobStatus = (jobId, status) => client.put(endpoint + `/${jobId}/status?active=${status}`);

const getApplicantsCount = (jobId) => client.get(endpoint + `/applicants/count?jobId=${jobId}`);

const getApplicants = (jobId, page, size, statusId = 0) => client.get(endpoint + `/applicants?jobId=${jobId}&page=${page}&size=${size}&statusId=${statusId}`);

const getApplicant = (applicationId) => client.get(endpoint + `/applicants/${applicationId}`);

const updateApplicantStatus = (applicationId, status) => client.put(endpoint + `/applicants/${applicationId}?status=${status}`);

const deleteApplication = (applicationId) => client.delete(endpoint + `/applicants/${applicationId}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	search,
	jobsFilters,
	jobsDynamicFilters,
	getSavedJobs,
	getSavedCount,
	getAppliedJobs,
	getAppliedCount,
	toggleSaveJob,
	getRecommendedJobs,
	getJobById,
	getJobByCompanyId,
	getSimilarJobs,
	apply,
	getJobsLanding,
	getProjectLanding,
	createJob,
	updateJob,
	createFreelance,
	updateFreelance,
	createCasting,
	updateCasting,
	deleteJob,
	updateJobStatus,
	getApplicantsCount,
	getApplicants,
	updateApplicantStatus,
	deleteApplication,
	getApplicant,
	// getJobSkills,
	// getJobLanguages,
	getNearbyJobs,
};
