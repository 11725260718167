import attachIcon from "images/icon-attach.svg";
import { ReactComponent as PlayIcon } from "images/play-button.svg";
import { useEffect, useMemo } from "react";
import ReactPlayer from "react-player/lazy";
import { getFileIcon, removeFileExtension } from "../Utils/General";
import LazyImage from "../Utils/LazyImage/LazyImage";
import styles from "./PostMedia.module.css";
import { useLocation } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import { classNames } from "primereact/utils";

const PostMedia = ({
	post,
	mediaList,
	onItemClicked,
	chat = false,
	viewer = false,
	multipartViewer = false, //TODO >> delete ? only used in <CreatePost /> and media.newFile does the same it seems
	childPostsCount = 0,
}) => {
	const { pathname } = useLocation();
	const isThreeColumnsLayout =
		pathname === routes.root || pathname.startsWith("/post");

	const isDoc = (media) => {
		const imageVideoTypes = ["IMAGE", "VIDEO"];
		return (
			!imageVideoTypes.includes(media.previewType) &&
			!imageVideoTypes.includes(media.mediaType) &&
			!imageVideoTypes.includes(media.type)
		);
	};

	const objectUrls = useMemo(() => {
		return mediaList.reduce((acc, media) => {
			if ((multipartViewer || media.newFile) && !chat) {
				if (media.type === "IMAGE" || media.type === "VIDEO") {
					acc.set(media, URL.createObjectURL(media.multipartFile));
				}
			}
			return acc;
		}, new Map());
	}, [mediaList, multipartViewer, chat]);

	useEffect(() => {
		return () => {
			objectUrls.forEach(URL.revokeObjectURL);
		};
	}, [objectUrls]);

	const getImage = (media) => {
		let src;
		let fileData;

		if (chat) {
			src = media.customName;
			fileData = getFileIcon(media, media.originalName);
		} else if (multipartViewer || media.newFile) {
			if (media.type !== "IMAGE" && media.type !== "VIDEO") {
				fileData = getFileIcon(
					media.multipartFile,
					media.multipartFile.name
				);
			} else {
				src = objectUrls.get(media);
			}
		} else {
			src = media.url;
			fileData = getFileIcon(media, media.originalName);
		}

		if (
			media.previewType === "IMAGE" ||
			media.mediaType === "IMAGE" ||
			media.type === "IMAGE"
		) {
			return (
				<LazyImage
					image={<img src={src} alt="post media" />}
					inlineStyle={
						post?.postType === "PROFILE_PIC"
							? styles.profile_pic
							: undefined
					}
				/>
			);
		}

		if (
			media.previewType === "VIDEO" ||
			media.mediaType === "VIDEO" ||
			media.type === "VIDEO"
		) {
			return (
				<div className={styles.video_container}>
					<ReactPlayer
						width="100%"
						height="100%"
						url={src}
						playing={false}
					/>
					<div className={styles.video_play_icon}>
						<PlayIcon className={styles.play_icon} />
					</div>
				</div>
			);
		}

		return (
			<img
				src={fileData.icon}
				alt={media.originalName}
				className={`${styles.file_icon} ${chat ? styles.chat : ""}`}
			/>
		);
	};

	const displayFile = (file) => {
		if (isDoc(file)) {
			if (chat) {
				return (
					<div className={styles.file_container}>
						<div className={styles.icon_container}>
							{getImage(mediaList[0])}
						</div>
						<div className={styles.file_details}>
							<div className={styles.file_title}>
								{removeFileExtension(mediaList[0].originalName)}
							</div>
							<div className={styles.file_extension_container}>
								<img src={attachIcon} alt="attach icon" />
								<span>{mediaList[0].extension} file</span>
							</div>
						</div>
					</div>
				);
			} else {
				const fileName = multipartViewer
					? file.multipartFile.name
					: file.originalName;
				return (
					<div className={styles.doc_container}>
						{getImage(file)}
						<p>{fileName}</p>
					</div>
				);
			}
		} else {
			return getImage(file);
		}
	};

	const mediaArch = () => {
		return (
			<>
				{mediaList.length === 1 && (
					<div
						className={`${
							isDoc(mediaList[0])
								? styles.post_doc
								: styles.post_media
						} ${viewer ? styles.preview : undefined} ${
							chat ? styles.chat : undefined
						}`}
						onClick={() => onItemClicked && onItemClicked(0)}
					>
						{displayFile(mediaList[0])}
					</div>
				)}

				{mediaList.length === 2 && (
					<div className={styles.post_media_2}>
						<div
							className={`${styles.post_media_2_1} ${
								viewer ? styles.preview : undefined
							}`}
							onClick={() => onItemClicked && onItemClicked(0)}
						>
							{displayFile(mediaList[0])}
						</div>

						<div
							className={`${styles.post_media_2_1} ${
								viewer ? styles.preview : undefined
							}`}
							onClick={() => onItemClicked && onItemClicked(1)}
						>
							{displayFile(mediaList[1])}
						</div>
					</div>
				)}

				{mediaList.length === 3 && (
					<div className={styles.post_media_3}>
						<div
							className={`${styles.post_media_3_1} ${
								viewer ? styles.preview : undefined
							}`}
							onClick={() => onItemClicked && onItemClicked(0)}
						>
							{displayFile(mediaList[0])}
						</div>

						<div className={styles.post_media_3_2}>
							<div
								className={`${styles.post_media_3_2_1} ${
									viewer ? styles.preview : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(1)
								}
							>
								{displayFile(mediaList[1])}
							</div>
							<div
								className={`${styles.post_media_3_2_1} ${
									viewer ? styles.preview : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(2)
								}
							>
								{displayFile(mediaList[2])}
							</div>
						</div>
					</div>
				)}

				{mediaList.length === 4 && (
					<div className={styles.post_media_4}>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							}`}
							onClick={() => onItemClicked && onItemClicked(0)}
						>
							{displayFile(mediaList[0])}
						</div>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							}`}
							onClick={() => onItemClicked && onItemClicked(1)}
						>
							{displayFile(mediaList[1])}
						</div>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							}`}
							onClick={() => onItemClicked && onItemClicked(2)}
						>
							{displayFile(mediaList[2])}
						</div>
						<div
							className={`${styles.post_media_4_1} ${
								viewer ? styles.preview : undefined
							}`}
							onClick={() => onItemClicked && onItemClicked(3)}
						>
							{displayFile(mediaList[3])}
						</div>
					</div>
				)}

				{mediaList.length >= 5 && (
					<div className={styles.post_media_5}>
						<div className={styles.row}>
							<div
								className={`${styles.post_media_5_1} ${
									viewer ? styles.preview : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(0)
								}
							>
								{displayFile(mediaList[0])}
							</div>
							<div
								className={`${styles.post_media_5_1} ${
									viewer ? styles.preview : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(1)
								}
							>
								{displayFile(mediaList[1])}
							</div>
						</div>
						<div className={styles.row}>
							<div
								className={`${styles.post_media_5_2}  ${
									viewer ? styles.preview : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(2)
								}
							>
								{displayFile(mediaList[2])}
							</div>
							<div
								className={`${styles.post_media_5_2}  ${
									viewer ? styles.preview : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(3)
								}
							>
								{displayFile(mediaList[3])}
							</div>
							<div
								className={`${styles.post_media_5_2}  ${
									viewer ? styles.preview : undefined
								}`}
								onClick={() =>
									onItemClicked && onItemClicked(4)
								}
							>
								{displayFile(mediaList[4])}
								{(mediaList.length > 5 ||
									childPostsCount > 5) && (
									<div className={styles.media_5_more}>
										<div className={styles.shades} />
										<span>{`+${
											childPostsCount > 5
												? childPostsCount - 5
												: mediaList.length - 5
										}`}</span>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</>
		);
	};
	return (
		<div
			className={classNames(styles.post_media_container, {
				[styles.chat]: chat,
				[styles.preview]: viewer,
				[styles.twoColumns]: !isThreeColumnsLayout,
			})}
		>
			{mediaArch()}
		</div>
	);
};

export default PostMedia;
