import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import styles from "../../Profile/TalentTab/Filters/Filters.module.css";
import { specialProfileTypes } from "../../Utils/General";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import { shortCityFormatter } from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";

const AgencyDynamicFilters = forwardRef(
	(
		{
			filters: { locations: cities = [], types: profileTypes = [] } = {},
			selectedDynamicFilters,
			onChange,
		},
		ref
	) => {
		const didMountRef = useRef(false);
		const citiesRef = useRef();
		const typesRef = useRef();

		const [selectedFilters, setSelectedFilters] = useState(
			selectedDynamicFilters
		);

		const { locationIds = [], types = [] } = selectedFilters || {};

		useEffect(() => {
			let timer;
			if (
				didMountRef.current &&
				Object.keys(selectedFilters).length > 0
			) {
				timer = setTimeout(() => {
					onChange(selectedFilters);
				}, 500);
			}
			didMountRef.current = true;
			return () => clearTimeout(timer);
		}, [selectedFilters]);

		useImperativeHandle(ref, () => ({
			updateDynamicFilters(key, tag) {
				switch (key) {
					case "locationIds":
						citiesRef.current.removeItem(tag);
						break;
					case "types":
						typesRef.current.removeItem(tag);
						break;
					default:
						break;
				}
			},
			clear() {
				setSelectedFilters({});
				citiesRef.current?.clear();
				typesRef.current?.clear();
			},
		}));

		return (
			<>
				<div className={styles.padding_container}>
					{cities.length > 0 && (
						<MultiCheckbox
							ref={citiesRef}
							title={"Locations"}
							options={cities.map((item) =>
								shortCityFormatter(item)
							)}
							onSelect={(cities) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									locationIds: cities,
								}));
							}}
							selected={locationIds}
							perRow="2, 150px"
							customStyle={styles.multicheckbox_container}
						/>
					)}

					{profileTypes.length > 0 && (
						<MultiCheckbox
							ref={typesRef}
							title={"Must Have Talents"}
							options={profileTypes.map((item) =>
								specialProfileTypes.find(
									(specialType) => specialType.value === item
								)
							)}
							onSelect={(types) => {
								setSelectedFilters((prevState) => ({
									...prevState,
									types: types,
								}));
							}}
							selected={types}
							perRow="2, 150px"
							customStyle={styles.multicheckbox_container}
						/>
					)}
				</div>
			</>
		);
	}
);

export default AgencyDynamicFilters;
