export const companiesKeys = {
	all: ["companies"],
	nearbyCompanies: (type, userCoordinates) => [
		...companiesKeys.all,
		"nearby",
		type,
		userCoordinates,
	],
	hiringCompanies: (type, userCoordinates) => [
		...companiesKeys.all,
		"hiring",
		type,
		userCoordinates,
	],
	networkCompanies: (type, userCoordinates) => [
		...companiesKeys.all,
		"network",
		type,
		userCoordinates,
	],
};
