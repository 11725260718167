import { formatFiltersValues } from "components/Utils/General";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { searchKeys } from "queryKeys/search-key-factory";
import { useSelector } from "react-redux";
import searchApi from "../../../api/search";
import ProductsComponent from "../SearchComponents/Products";
import styles from "./All.module.scss";

const PAGE_SIZE = 40;

const Products = ({ isMobile, onShowModal, tagsList, userCoordinates }) => {
	// const [order, setOrder] = useState("NEWEST");
	// const [layout, setLayout] = useState("grid");

	// const queryClient = useQueryClient();

	const { paramValue: inputValue } = useGetQueryParam("q");

	const productsFiltersObject = useSelector((state) => state.filters.productsFilters);

	const storedProductsFilters = {
		...productsFiltersObject,
		q: inputValue,
		locationIds: [{ uuid: userCoordinates.id, value: userCoordinates.id }], //* formatting it like that (object in array) because of how the api request is structured and because of how formatFiltersValues() works
	};

	const searchProductsApi = useApi(searchApi.searchProducts, true, true);

	const fetchProducts = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchProductsApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const { items, isFetchingNextPage, viewRef, isLoading } = useInfiniteScroll({
		queryKey: searchKeys.products(storedProductsFilters),
		queryFn: fetchProducts,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.list?.length === PAGE_SIZE ? pages.length : undefined;
				return nextPage;
			},
		},
	});

	const data = items?.flatMap((page) => page.list);

	const count = items?.[0]?.count;

	// const handleInvalidation = () => {
	// 	queryClient.invalidateQueries(searchKeys.products(storedProductsFilters));
	// 	queryClient.invalidateQueries(filterKeys.products(storedProductsFilters));
	// };

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<ProductsComponent
					viewRef={viewRef}
					data={data}
					count={count}
					isPaginated={true}
					isFetchingNextPage={isFetchingNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
					// onRefresh={() => {
					// 	handleInvalidation();
					// 	onRefetch();
					// }}
				/>
			</div>
		</div>
	);
};

export default Products;
